import _ from "lodash";
import { DWOLLA_ROOT_URL } from "../../config/contants";
import { failure, toastFailure, toastSuccess } from "../uiReducer";
import {
  addBeneficialOwner,
  certifyBeneficialOwnership,
  createBusinessVerifiedCustomer,
  createPlaidFundingSource,
  getBusinessClassifications,
  getCustomerCertificationStatus,
  getCustomerTransactions,
  removeBeneficialOwner,
} from "./dwollaApi";
import {
  addBeneficialOwnerSuccess,
  certifyBeneficialOwnershipSuccess,
  createCustomerSuccess,
  createPlaidFundingSourceSuccess,
  getBusinessClassificationsSuccess,
  getCustomerCertificationStatusSuccess,
  getTransactionsSuccess,
  loading,
  removeBeneficialOwnerSuccess,
} from "./dwollaReducer";

export const getBusinessClassificationsAction = () => async (dispatch) => {
  console.log(`handle get business classifications action`);
  dispatch(loading(true));

  try {
    const response = await getBusinessClassifications();

    console.log("response: ", response);

    console.log("response data: ", response.data);
    dispatch(getBusinessClassificationsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const addBeneficialOwnerAction =
  (customerId, ownerInfo) => async (dispatch) => {
    console.log(`handle add beneficial owner action`);
    dispatch(loading(true));

    try {
      const response = await addBeneficialOwner(customerId, ownerInfo);

      console.log("response: ", response);

      console.log("response data: ", response.data);
      dispatch(addBeneficialOwnerSuccess(response.data));
      toastSuccess("Success!", "The owner was added.");
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const getCustomerCertificationStatusThunk =
  (customerId) => async (dispatch) => {
    console.log(`handle get business certification status thunk`);
    dispatch(loading(true));

    try {
      const response = await getCustomerCertificationStatus(customerId);

      console.log("response: ", response);

      console.log(
        "certify beneficial ownership response data: ",
        response.data
      );
      dispatch(getCustomerCertificationStatusSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const getCustomerTransactionsThunk =
  (customerId, correlationId) => async (dispatch) => {
    console.log(`handle get customer transactions thunk`);
    dispatch(loading(true));

    try {
      const response = await getCustomerTransactions(customerId, correlationId);

      console.log("response data!: ", response.data);

      dispatch(getTransactionsSuccess(response.data));
    } catch (error) {
      console.log("error: ", error);
      toastFailure(error);
      // dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const certifyBeneficialOwnershipThunk =
  (customerId) => async (dispatch) => {
    console.log(`handle certify beneficial ownership thunk`);
    dispatch(loading(true));

    try {
      const response = await certifyBeneficialOwnership(customerId);

      console.log("response: ", response);

      console.log(
        "certify beneficial ownership response data: ",
        response.data
      );
      dispatch(certifyBeneficialOwnershipSuccess(response.data));
      toastSuccess("Success!", "You certified the beneficial ownership");
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const removeBeneficialOwnerAction = (ownerId) => async (dispatch) => {
  console.log(`handle remove beneficial owner action`);
  dispatch(loading(true));

  try {
    const response = await removeBeneficialOwner(ownerId);

    dispatch(removeBeneficialOwnerSuccess(ownerId));
    toastSuccess("Success!", "The owner was removed.");
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const createPlaidFundingSourceAction =
  (customerId, plaidToken, accountName) => async (dispatch) => {
    console.log(`handle create plaid funding source`);
    dispatch(loading(true));

    try {
      const response = await createPlaidFundingSource(
        customerId,
        plaidToken,
        accountName
      );

      dispatch(createPlaidFundingSourceSuccess());
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const createBusinessVerifiedCustomerAction =
  (customer) => async (dispatch) => {
    console.log(`handle create business verified customer action`);
    console.log("customer: ", customer);
    dispatch(loading(true));

    try {
      let response = await createBusinessVerifiedCustomer(customer);
      console.log("response: ", response);

      if (response.status === 201) {
        console.log("success!");

        const headers = response.headers;
        const customerIdSplit = headers?.location?.split(
          `${DWOLLA_ROOT_URL}/customers/`
        );

        if (_.isNil(customerIdSplit) || customerIdSplit.length != 2) {
          throw new Error("the customer id was null");
        }

        const customer = {
          customerId: customerIdSplit[1],
          location: headers.location,
        };

        dispatch(createCustomerSuccess(customer));
        toastSuccess("Success!", "The customer was created.");
      } else {
        toastFailure("there was an error");
        dispatch(failure("there was an error"));
        dispatch(loading(false));
      }
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };
