import { useSelector } from "react-redux";
import React from "react";

const ProtectedComponent = ({ children, roles }) => {
  const account = useSelector((state) => state.account);
  const authorities = account?.account?.authorities;

  const canAccess = () => {
    // at least one of the roles must exist in the authorities
    let canAccess = false;

    for (let i = 0; i < roles.length; i++) {
      if (authorities?.includes(roles[i])) {
        canAccess = true;
        break;
      }
    }

    return canAccess;
  };

  return canAccess() ? <>{children}</> : <></>;
};

export default ProtectedComponent;
