import React, { useEffect } from "react";

import { Paper } from "@mui/material";

export const KpiCard = ({ title, subTitle, IconComponent, color }) => (
  <Paper
    style={{
      width: "220px",
      height: "120px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      gap: "10px",
      backgroundColor: color + "10", // Slight opacity for background
    }}
    elevation={1}
  >
    {IconComponent && <IconComponent style={{ fontSize: "2em", color }} />}
    <h1 style={{ margin: "0px" }}>{title}</h1>
    <h6 style={{ margin: "0px" }}>{subTitle}</h6>
  </Paper>
);

export default KpiCard;
