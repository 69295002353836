import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";

import { updateAccountSetupAction } from "../../models/account/accountActions";
import _ from "lodash";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import HorizontalStepper from "../../components/HorizontalStepper/HorizontalStepper";
import { makeStyles } from "@mui/styles";
import CreateBusiness from "../../components/CreateBusiness/CreateBusiness";
import {
  createLenderThunk,
  createPlaidFundingSourceThunk,
  getLenderThunk,
  unlinkFundingSourceThunk,
} from "../../models/lender/lenderThunks";
import LinkAccountScreen from "../LinkAccountStep/LinkAccountScreen";
import { CircularProgress } from "@mui/material";
import { resetCreateUpdateLenderSuccess } from "../../models/lender/lenderReducer";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      margin: "0px",
    },
  },
  [theme.breakpoints.up("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "500px",
      height: "400px",
      marginTop: "0em",
    },
  },
}));

const freshLender = {
  businessName: "",
  businessType: "",
  businessClassification: "",
  industryClassification: "",
  dateOfBirth: null,

  fullAddress: {
    address1: "",
    address2: "",
    zip: "",
    city: "",
    stateCode: "",
  },
  controller: {
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    ssn: "",
    email: "",
    title: "",
    address: {
      address1: "",
      address2: "",
      zip: "",
      city: "",
      stateCode: "",
    },
  },
};

const LenderAccountSetupScreen = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const accountSetup = account.accountSetup;
  const lenderSlice = useSelector((state) => state.lenders);
  const lender = lenderSlice.lender;

  const dwolla = useSelector((state) => state.dwolla);

  const [businessInfo, setBusinessInfo] = useState(freshLender);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
    setValue,
    reset,
  } = useForm();

  const dispatch = useDispatch();

  const canProceedToLinkAccount = () => {
    if (_.isNil(lender) || _.isNil(lender.id)) {
      console.log("can proceed 1");
      return true;
    }

    if (
      businessInfo.hasBeneficialOwners === "true" ||
      lender?.beneficialOwners?.length > 0 ||
      dwolla?.owners?.length > 0
    ) {
      // if the user indicated that the business has beneficial owners, but did not save any,
      // do not proceed
      if (
        (_.isNil(dwolla.owners) || dwolla?.owners?.length === 0) &&
        lender.beneficialOwners.length === 0
      ) {
        console.log("can proceed 2");

        return true;
      }
      console.log("can proceed 3");

      // if there are beneficial owners listed, but the user did not confirm that the info is complete/correct, do not proceed
      return !businessInfo.certifiedCompleteAndCorrect;
    } else {
      console.log("can proceed 4");

      // if there were no beneficial owners, the user has to confirm that there are none + the info is complete/correct
      return !(
        businessInfo.certifiedNoBeneficialOwners &&
        businessInfo.certifiedCompleteAndCorrect
      );
    }
  };

  // needed incase the user has some pending lender (they didn't finish setting up)
  useEffect(() => {
    console.log("in the use effect that gets lender: ", account.account);
    if (!_.isNil(account?.account?.defaultLenderId)) {
      dispatch(getLenderThunk(account.account.defaultLenderId));
    }
  }, []);

  useEffect(() => {
    if (!_.isNil(lender?.id)) {
      const mergedInfo = {
        ...lender,
        dateOfBirth: null,
        businessClassification: lender.businessClassification,
        industryClassification: lender.industryClassification,
        fullAddress: {
          address1: lender.address1,
          address2: lender.address2,
          zip: lender.postalCode,
          city: lender.city,
          state: lender.state,
        },
        controller: {
          ...lender.controller,
          address: {
            ...lender?.controller?.address,
            zip: lender?.controller?.address?.postalCode,
            state: lender?.controller?.address?.stateProvinceRegion,
          },
        },
      };

      console.log("setting the business info: ", mergedInfo);
      setBusinessInfo(mergedInfo);
      setValue("businessName", lender.businessName);
      setValue("businessType", lender.businessType);
      setValue("address1", lender.address1);
      setValue("address2", lender.address2);
      setValue("city", lender.city);
      setValue("zip", lender.postalCode);

      setValue("birthDate", lender.dateOfBirth);
    }
  }, [lender?.id]);

  React.useEffect(() => {
    if (
      account &&
      account.account &&
      accountSetup?.activeStep === "COMPLETED_SETUP"
    ) {
      console.log("the account is setup");

      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [accountSetup?.activeStep]);

  const getActiveStep = () => {
    switch (accountSetup.activeStep) {
      case "BUSINESS_DETAILS":
      case "CONTROLLER_DETAILS":
      case "ADD_OWNERS_YES_NO":
      case "ADD_OWNERS_YES":
      case "ADD_OWNERS_NO":
        return 0;
      case "LINK_BANK_ACCOUNT":
        return 1;
      case "ACCEPT_TERMS_CONDITIONS":
      case "COMPLETED_SETUP":
        return 2;
      default:
        return 0;
    }
  };

  const businessDetailStep = {
    title: "Business",
    handleSubmit: async () => {
      dispatch(updateAccountSetupAction("LINK_BANK_ACCOUNT"));
    },
    handleBack: () => {
      console.log("no op");
    },
    // isNextDisabled: canProceedToLinkAccount(),
    isNextDisabled: dwolla?.certificationStatus?.status != "certified",
    panel: (
      <div className={classes.panel}>
        <CreateBusiness
          org={lender}
          resetCreateUpdateOrgSuccess={resetCreateUpdateLenderSuccess}
          defaultOrgId={account?.account?.defaultLenderId}
          loading={lenderSlice.loading}
          createOrgSuccess={lenderSlice.createLenderSuccess}
          updateOrgSuccess={lenderSlice.updateLenderSuccess}
          createOrgThunk={createLenderThunk}
          getOrgThunk={getLenderThunk}
          trigger={trigger}
          reset={reset}
          setValue={setValue}
          isDirty={isDirty}
          isValid={isValid}
          control={control}
          handleSubmit={handleSubmit}
          businessInfo={businessInfo}
          setBusinessInfo={setBusinessInfo}
          useAccountSetup={true}
          getActiveStep={() => {
            if (accountSetup.activeStep === "BUSINESS_DETAILS") {
              return 0;
            } else if (accountSetup.activeStep === "CONTROLLER_DETAILS") {
              return 1;
            } else if (
              accountSetup.activeStep === "ADD_OWNERS_YES_NO" ||
              accountSetup.activeStep === "ADD_OWNERS_YES" ||
              accountSetup.activeStep === "ADD_OWNERS_NO"
            ) {
              return 2;
            }
          }}
        />
      </div>
    ),
  };

  const linkAccountStep = {
    title: "Link your Business Bank Account",
    handleSubmit: () => {
      dispatch(updateAccountSetupAction("ACCEPT_TERMS_CONDITIONS"));
    },
    handleBack: () => {
      if (lender.businessType === "Sole Proprietorship") {
        dispatch(updateAccountSetupAction("BUSINESS_DETAILS"));
      } else if (lender?.beneficialOwners?.length > 0) {
        dispatch(updateAccountSetupAction("ADD_OWNERS_YES"));
      } else if (lender?.beneficialOwners?.length == 0) {
        dispatch(updateAccountSetupAction("ADD_OWNERS_NO"));
      } else {
        dispatch(updateAccountSetupAction("ADD_OWNERS_YES_NO"));
      }
    },

    isNextDisabled: !lender?.fundingSources?.length > 0,

    panel: (
      <div className={classes.panel}>
        <LinkAccountScreen
          loading={lenderSlice?.loading}
          org={lender}
          defaultOrgId={account?.account?.defaultLenderId}
          getOrgThunk={getLenderThunk}
          createPlaidFundingSourceThunk={createPlaidFundingSourceThunk}
          unlinkFundingSourceThunk={unlinkFundingSourceThunk}
        />
      </div>
    ),
  };

  const termsAndConditionsStep = {
    title: "Terms & Conditions",
    subTitle: "You must read and accept the Terms & Conditions to continue.",
    handleSubmit: () => {
      dispatch(updateAccountSetupAction("COMPLETED_SETUP"));
    },
    handleBack: () => {
      dispatch(updateAccountSetupAction("LINK_BANK_ACCOUNT"));
    },
    isNextDisabled: !acceptedTerms,

    panel: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "600px",
          marginTop: "0em",
        }}
      >
        {accountSetup.activeStep === "ACCEPT_TERMS_CONDITIONS" ? (
          <TermsAndConditions
            acceptedTerms={acceptedTerms}
            setAcceptedTerms={setAcceptedTerms}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              rowGap: "2em",
            }}
          >
            <h2>Hang tight while we get your account all set up</h2>
            <CircularProgress />
          </div>
        )}
      </div>
    ),
  };

  const accountSetupSteps = [
    businessDetailStep,
    linkAccountStep,
    termsAndConditionsStep,
  ];

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%", height: "90%" }}>
        <HorizontalStepper
          activeStep={getActiveStep()}
          stepperTitle={<h1 style={{ margin: "0px" }}>Welcome</h1>}
          stepperSubtitle={<h3>Let's get your account all set up</h3>}
          stepperCompletedMessage={"Hang tight while we create your account"}
          acceptedTerms={acceptedTerms}
          steps={accountSetupSteps}
          control={control}
          isDirty={isDirty}
          isValid={isValid}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      </div>
    </div>
  );
};

export default LenderAccountSetupScreen;
