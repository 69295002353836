import React from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { CircularProgress, createTheme, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ThemeProvider } from "@emotion/react";
import moment from "moment";
import { getDealThunk } from "../../models/deal/dealThunks";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedEvent } from "../../models/calendar/calendarReducer";
import { setSelectedInvestment } from "../../models/deal/dealReducer";

const MyInvestmentsTable = (props) => {
  const { data, setAddDeal } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const merchants = useSelector((state) => state.merchants);
  const loading = useSelector((state) => state.ui).loading;

  console.log("printing investment data", data);

  const normalized = data.map((curr) => {
    const totalPayback = Number(curr.fundedAmount * curr.factorRate).toFixed(2);
    return {
      ...curr,
      businessName: curr?.merchant?.businessName,
      totalPayback: `${Number(curr?.fundedAmount * curr?.factorRate).toFixed(
        2
      )}`,
      paymentAmount: Number(totalPayback / curr.numberOfPayments).toFixed(2),
      myContribution: curr?.investors[0].contributionPercentage,
      investmentStatus: curr?.investors[0].status,
    };
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableRow: {
          styleOverrides: {
            root: {
              cursor: "pointer",
            },
          },
        },
      },
    });

  const options = {
    filterType: "checkbox",

    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Sorry, no matching records found."
        ),
      },
    },

    onRowClick: (rowData, rowMeta) => {
      console.log("row meta: ", rowMeta);
      dispatch(setSelectedInvestment(data[rowMeta.dataIndex]));
      navigate(`${rowData[0]}/${data[rowMeta.dataIndex].id}`, {
        merchantName: rowData[0],
        id: data[rowMeta.dataIndex].id,
      });
      // navigate("funded-deal");
    },

    selectableRows: false,
  };

  const columns = [
    {
      name: "businessName",
      label: "Business Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fundedDate",
      label: "Date Funded",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fundedAmount",
      label: "Funded Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "factorRate",
      label: "Factor Rate",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "totalPayback",
      label: "Total Payback",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "paymentFrequency",
      label: "Payment Frequency",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "paymentAmount",
      label: "Payment Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "dealStatus",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "myContribution",
      label: "Contribution %",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "investmentStatus",
      label: "Investment Status",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        style={{ cursor: "pointer" }}
        title={"Merchant Deals"}
        data={normalized && normalized.length > 0 ? normalized : []}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default MyInvestmentsTable;
