const colors = {
  background: "#353d47",
  jhipsterBlue: "#3E8ACC",
  clear: "rgba(0,0,0,0)",
  facebook: "#3b5998",
  white: "#ffffff",
  transparent: "rgba(0,0,0,0)",
  silver: "#F7F7F7",
  steel: "#CCCCCC",
  error: "rgba(200, 0, 0, 0.8)",
  ricePaper: "rgba(255,255,255, 0.75)",
  frost: "#D8D8D8",
  cloud: "rgba(200,200,200, 0.35)",
  windowTint: "rgba(0, 0, 0, 0.4)",
  panther: "#161616",
  charcoal: "#595959",
  coal: "#2d2d2d",
  bloodOrange: "#fb5f26",
  snow: "white",
  ember: "rgba(164, 0, 48, 0.5)",
  fire: "#e73536",
  drawer: "rgba(30, 30, 29, 0.95)",
  eggplant: "#251a34",
  border: "#483F53",
  banner: "#5F3E63",
  text: "#E0D7E5",
  green: "#75F2B9",
  blue: "#1B76FF",
  red: "#ff0000",
  placeHolder: "#232323",
  black: "#000000",
  msftLightThemeGrayBorder: "#8c8c8c",
  msftLightThemeText: "#5e5e5e",
  googleButtonBlue: "#4285f4",
  lightGray: "#D3D3D3",
  colorPickerOrange: "#ff6900",
  colorPickerYellow: "#fcb900",
  colorPickerTeal: "#7bdcb5",
  colorPickerGreen: "#00d084",
  colorPickerLightBlue: "#8ed1fc",
  colorPickerDarkBlue: "#0693e3",
  colorPickerGray: "#abb8c3",
  colorPickerRed: "#eb144c",
  colorPickerPink: "#f78da7",
  colorPickerPurple: "#9900ef",
};

export default colors;
