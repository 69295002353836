import { Button, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FundingSourceSelect } from "../Form/FinancialForm";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFundsFlowConfigThunk } from "../../models/lender/lenderThunks";

const LenderFundsFlowConfig = (props) => {
  const { control, handleSubmit, reset, setValue } = useForm();

  const [fundingSourceOptions, setFundingSourceOptions] = useState([]);
  const [incomingFundingSource, setIncomingFundingSource] = useState();
  const [outgoingFundingSource, setOutgoingFundingSource] = useState();

  const lenderSlice = useSelector((state) => state.lenders);
  const lender = lenderSlice.lender;

  const dispatch = useDispatch();

  useEffect(() => {
    setFundingSourceOptions([...lender.fundingSources]);
  }, []);

  useEffect(() => {
    let incoming = lender.fundingSources.filter(
      (fundingSource) =>
        fundingSource.dwollaFundingSourceId ===
        lender.receivePaymentsFundingSourceId
    )[0];

    let outgoing = lender.fundingSources.filter(
      (fundingSource) =>
        fundingSource.dwollaFundingSourceId ===
        lender.sendPaymentsFundingSourceId
    )[0];

    setIncomingFundingSource(incoming);
    setOutgoingFundingSource(outgoing);
  }, []);

  const handleUpdateFundsFlowConfig = () => {
    dispatch(
      updateFundsFlowConfigThunk(lender.id, {
        outgoingFundingSourceId: outgoingFundingSource.dwollaFundingSourceId,
        incomingFundingSourceId: incomingFundingSource.dwollaFundingSourceId,
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        marginTop: "2em",
        flexDirection: "column",
        rowGap: "2em",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={
              "This the bank account that funds will be drawn from to fund merchants"
            }
            arrow
          >
            <HelpOutlineIcon />
          </Tooltip>
          <h5 style={{ margin: "0px" }}>Outgoing Funds</h5>
        </div>

        <FundingSourceSelect
          control={control}
          setValue={setValue}
          fundingSourceOptions={fundingSourceOptions}
          fundingSource={outgoingFundingSource}
          setFundingSource={setOutgoingFundingSource}
        />
      </div>

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={
              "This is the account that funds will be deposited into when syndicates pay into deals, and when merchant automated payments are made"
            }
            arrow
          >
            <HelpOutlineIcon />
          </Tooltip>
          <h5 style={{ margin: "0px" }}>Incoming Payments</h5>
        </div>

        <FundingSourceSelect
          control={control}
          setValue={setValue}
          fundingSourceOptions={fundingSourceOptions}
          fundingSource={incomingFundingSource}
          setFundingSource={setIncomingFundingSource}
        />
      </div>
      <Button
        style={{ width: "100px" }}
        variant="outlined"
        onClick={handleSubmit(handleUpdateFundsFlowConfig)}
      >
        Save
      </Button>
    </div>
  );
};

export default LenderFundsFlowConfig;
