import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { MyInvestmentPercent } from "../Form/FinancialForm";
import { FundedDealBarChart } from "../FundedDealBarChart/FundedDealBarChart";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import { IconButton } from "@mui/material";

import InvestmentStatus from "../InvestmentStatus/InvestmentStatus";

const MyInvestmentTab = (props) => {
  const organization = useSelector((state) => state.organizations);
  const deal = useSelector((state) => state.deals);
  const selectedInvestment = deal?.investment;
  const { control, handleSubmit, reset } = useForm();
  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const [max, setMax] = useState(100);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const selected = selectedInvestment.investors.map((curr) => {
      return {
        locked: true,
        contribution: curr.contributionPercentage,
        id: curr.id,
        investor: {
          name: `${curr.user.firstName} ${curr.user.lastName}`,
          email: curr.user.email,
          user: curr.user,
        },
      };
    });

    setSelectedInvestors(selected);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "1em",
          }}
        >
          <div
            style={{
              width: "40%",
              display: "flex",
              height: "100%",
              rowGap: "4em",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InvestmentStatus investment={selectedInvestment} />

            {selectedInvestors.map((selected) => {
              return (
                <div style={{ marginTop: "1em", marginBottom: "1em" }}>
                  <MyInvestmentPercent
                    investment={selectedInvestment}
                    investorContribution={selected}
                    control={control}
                    max={max}
                  />
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "60%",
            }}
          >
            <FundedDealBarChart data={selectedInvestors} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInvestmentTab;
