import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PieChartIcon from "@mui/icons-material/PieChart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import { useForm } from "react-hook-form";

import { Paper } from "@mui/material";

import BasicTabs from "../../components/BasicTabs/BasicTabs";

import InvestorTab from "../../components/InvestorTab/InvestorTab";
import PaymentCalendar from "../../components/PaymentCalendar/PaymentCalendar";
import _ from "lodash";
import KpiCard from "../../components/KpiCard/KpiCard";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const DealOpportunity = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const holiday = useSelector((state) => state.holiday);

  const deal = useSelector((state) => state.deals);
  const merchant = useSelector((state) => state.merchants);
  const opportunityDeal = deal?.deal;
  const currentMerchant = merchant?.merchant;

  let events = deal?.deal?.merchantPayments ? deal.deal.merchantPayments : [];

  const profit =
    opportunityDeal?.fundedAmount * opportunityDeal?.factorRate -
    opportunityDeal?.fundedAmount;

  let holidays = holiday?.holidays?.map((holiday) => {
    return {
      ...holiday,
      type: "holiday",
    };
  });

  const kpiData = [
    {
      title: `$${
        opportunityDeal?.fundedAmount
          ? opportunityDeal.fundedAmount.toLocaleString()
          : "--"
      }`,
      subTitle: "Seeking Capital",
      IconComponent: AttachMoneyIcon,
      color: "#4CAF50", // You can choose a suitable green color that represents money or growth
    },
    {
      title: `${
        opportunityDeal?.factorRate
          ? Number(opportunityDeal.factorRate).toFixed(2)
          : "--"
      }`,
      subTitle: "Factor Rate",
      IconComponent: PieChartIcon,
      color: "#FF9800", // You can choose a suitable orange color that represents a rate or percentage
    },
    {
      title: `$${
        !_.isNil(profit) && !_.isNaN(profit)
          ? Number(Number(profit).toFixed(2)).toLocaleString()
          : "--"
      }`,
      subTitle: "Forecasted Total Profit",
      IconComponent: TrendingUpIcon,
      color: "#3F51B5", // You can choose a suitable blue color that represents profit or growth
    },
    {
      title: `${
        !_.isNil(opportunityDeal.percentOfDealContributedTo)
          ? Number(opportunityDeal.percentOfDealContributedTo).toFixed(2)
          : "--"
      }`,
      subTitle: "Percent committed",
      IconComponent: CheckBoxIcon,
      color: "#4CAF50", // A solid green color to represent committed or filled
    },
    {
      title: `${
        !_.isNil(opportunityDeal.percentOfDealContributedTo)
          ? Number(100 - opportunityDeal.percentOfDealContributedTo).toFixed(2)
          : "--"
      }`,
      subTitle: "Max percent you can contribute",
      IconComponent: CheckBoxOutlineBlankIcon,
      color: "#BDBDBD", // A light grey or silver color to represent uncommitted or unfilled
    },
  ];

  console.log("holiday: ", holiday);
  events = [...events, ...holidays];

  console.log("the deal: ", opportunityDeal);
  console.log("the merchant: ", currentMerchant);

  const finance = useSelector((state) => state.finance);
  const [dealInfo, setDealInfo] = useState({
    fundedAmount: opportunityDeal?.fundedAmount,
    factorRate: opportunityDeal?.factorRate,
    fundedDate: opportunityDeal?.fundedDate,
    firstPaymentDate: opportunityDeal?.firstPaymentDate,
    paymentFrequency: opportunityDeal?.paymentFrequency,
  });

  const [merchantInfo, setMerchantInfo] = useState({
    name: currentMerchant.name,
    owner: currentMerchant.owner,
    address: currentMerchant.address,
  });

  useEffect(() => {
    setDealInfo({
      fundedAmount: opportunityDeal?.fundedAmount,
      factorRate: opportunityDeal?.factorRate,
      fundedDate: opportunityDeal?.fundedDate,
      firstPaymentDate: opportunityDeal?.firstPaymentDate,
      paymentFrequency: opportunityDeal?.paymentFrequency,
    });
  }, [opportunityDeal]);

  useEffect(() => {
    setMerchantInfo({
      name: currentMerchant.name,
      owner: currentMerchant.owner,
      address: currentMerchant.address,
    });
  }, [currentMerchant]);

  const { control, handleSubmit, reset, setValue } = useForm();

  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}
        >
          <div
            style={{
              marginTop: "3em",
              marginBottom: "3em",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1 style={{ margin: "0px" }}>{`${
              currentMerchant?.businessName
                ? currentMerchant?.businessName
                : "--"
            }`}</h1>
            <h4 style={{ margin: "0px" }}>
              Investment Opportunity: Seeking Investors
            </h4>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "2em",
            columnGap: "2em",
          }}
        >
          {kpiData.map((card) => (
            <KpiCard
              title={card.title}
              subTitle={card.subTitle}
              IconComponent={card.IconComponent}
              color={card.color}
            />
          ))}
        </div>

        <div style={{ marginTop: "2em" }}>
          <BasicTabs
            data={[
              {
                label: "Payment Schedule",
                panel: <PaymentCalendar events={events} />,
              },

              {
                label: "Investors",
                panel: <InvestorTab opportunity={true} />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DealOpportunity;
