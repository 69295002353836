import _ from "lodash";
import { JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const createPersonalVerifiedCustomer = (customer) => {
  return axiosInstance.post(
    `${ROOT_URL}api/dwolla/customers/verified-personal`,
    customer,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const addBeneficialOwner = (customerId, ownerInfo) => {
  return axiosInstance.post(
    `${ROOT_URL}api/dwolla/customers/${customerId}/beneficial-owners`,
    ownerInfo,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const removeBeneficialOwner = (ownerId) => {
  return axiosInstance.delete(
    `${ROOT_URL}api/dwolla/customers/beneficial-owners/${ownerId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const createBusinessVerifiedCustomer = (customer) => {
  console.log("the customer: ", customer);
  return axiosInstance.post(
    `${ROOT_URL}api/dwolla/customers/business-verified-customer`,
    customer,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const certifyBeneficialOwnership = (customerId) => {
  console.log("the customer: ", customerId);
  return axiosInstance.post(
    `${ROOT_URL}api/dwolla/customers/${customerId}/certify-beneficial-ownership`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getCustomerCertificationStatus = (customerId) => {
  console.log("the customer: ", customerId);
  return axiosInstance.get(
    `${ROOT_URL}api/dwolla/customers/${customerId}/certification-status`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getCustomerTransactions = (customerId, correlationId) => {
  console.log("the customer: ", customerId);
  console.log("the correlation id: ", correlationId);

  let url = `${ROOT_URL}api/dwolla/customers/${customerId}/transfers`;
  if (!_.isNil(correlationId)) {
    url += `?correlationId=${correlationId}`;
  }

  return axiosInstance.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const createPlaidFundingSource = (
  customerId,
  plaidToken,
  accountName
) => {
  console.log("createPlaidFundingSource got customerId: ", customerId);
  console.log("createPlaidFundingSource got plaidToken: ", plaidToken);

  console.log("createPlaidFundingSource got accountName: ", accountName);

  return axiosInstance.post(
    `${ROOT_URL}api/dwolla/customers/${customerId}/funding-sources`,
    { plaidToken: plaidToken, accountName: accountName },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getBusinessClassifications = () => {
  return axiosInstance.get(
    `${ROOT_URL}api/dwolla/customers/business-classifications`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};
