import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { CircularProgress, createTheme, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ThemeProvider } from "@emotion/react";
import { getMerchantThunk } from "../../models/merchant/merchantThunks";
import { useDispatch, useSelector } from "react-redux";
import ProtectedComponent from "../ProtectedComponent/ProtectedComponent";
import { ROLE_LENDER_ADMIN } from "../../config/contants";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

const formatPhoneNumber = (phoneNumberString) => {
  console.log("got phone as: ", phoneNumberString);
  if (typeof phoneNumberString === "string") {
    const phoneNumber = parsePhoneNumberFromString(phoneNumberString);
    return phoneNumber ? phoneNumber.formatInternational() : phoneNumberString;
  }
  return phoneNumberString; // or return a default value or placeholder
};

function getInviteStatusStyling(status) {
  switch (status) {
    case "Accepted":
      return {
        icon: <CheckCircleOutlineIcon style={{ color: "green" }} />,
        color: "green",
        backgroundColor: "#e8f5e9", // Light green
      };
    case "Pending":
      return {
        icon: <HourglassEmptyIcon style={{ color: "#FF9800" }} />, // Or any color representing 'pending' status
        color: "#FF9800",
        backgroundColor: "#FFF3E0", // Light orange
      };
    default:
      return {
        icon: null,
        color: "gray",
        backgroundColor: "#f5f5f5", // Light gray for unknown statuses
      };
  }
}

const MerchantTable = (props) => {
  const { data, setAddMerchant } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui).loading;

  const normalized = data?.map((curr) => {
    const phone = curr?.owner?.phoneNumber;
    const address =
      curr.city && curr.state && curr.postalCode
        ? `${curr?.city}, ${curr?.state} ${curr?.postalCode}`
        : "";

    return {
      ...curr,
      address: address ? address : "TBD",
      owner: `${curr?.owner?.firstName} ${curr?.owner?.lastName}`,
      ownerPhoneNumber: phone ? formatPhoneNumber(phone) : "TBD",
      inviteStatus: curr.inviteStatus,
    };
  });

  useEffect(() => {
    console.log("merchant table recieved data: ", data);
  }, []);

  const options = {
    filterType: "checkbox",
    download: false, // Set to false to remove the download option
    print: false, // Set to false to remove the print option
    viewColumns: false, // Disable view columns option
    filter: false, // Disable filter table option

    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Sorry, no matching records found."
        ),
      },
    },

    onRowClick: (rowData, rowMeta) => {
      const row = data[rowMeta.rowIndex];
      if (row.inviteStatus != "Pending") {
        dispatch(getMerchantThunk(row.id));
        navigate(`${rowData[0]}`, { merchantName: rowData[0] });
      }
    },

    customToolbar: () => {
      return (
        <ProtectedComponent roles={[ROLE_LENDER_ADMIN]}>
          <IconButton
            onClick={() => {
              setAddMerchant(true);
            }}
            style={{ order: -1 }}
          >
            <AddIcon />
          </IconButton>
        </ProtectedComponent>
      );
    },
    selectableRows: false,
  };

  const columns = [
    {
      name: "invitedAsName",
      label: "Business Name",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "owner",
      label: "Owner",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "ownerPhoneNumber",
      label: "Owner Phone Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "inviteStatus",
      label: "Invite Status",
      options: {
        filter: true,
        sort: true,

        customBodyRenderLite: (dataIndex) => {
          const status = data[dataIndex].inviteStatus;
          const { icon, color, backgroundColor } =
            getInviteStatusStyling(status);

          return (
            <div
              style={{
                padding: "0.5em",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                backgroundColor,
              }}
            >
              {icon}
              <span style={{ marginLeft: "0.5em", color }}>{status}</span>
            </div>
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      style={{ cursor: "pointer" }}
      title={"Merchants"}
      data={normalized}
      columns={columns}
      options={options}
    />
  );
};

export default MerchantTable;
