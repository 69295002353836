import axios from "axios";
import { JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const inviteMerchant = (merchant) => {
  console.log(`merchant invite: ${merchant}`);

  return axiosInstance.post(
    `${ROOT_URL}api/merchants/send-merchant-invite`,
    merchant,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getMerchant = (id) => {
  console.log(`get merchant with id: ${id}`);

  return axiosInstance.get(`${ROOT_URL}api/merchants/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getPendingMerchantInvites = (id) => {
  console.log(`get pending merchant invites with id: ${id}`);

  return axiosInstance.get(`${ROOT_URL}api/merchants/pending`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const createMerchantBusinessDetails = async (id, merchant) => {
  return axiosInstance.put(
    `${ROOT_URL}api/merchants/${id}/business-details`,
    merchant,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const setCurrentUserDefaultMerchant = async (id) => {
  return axiosInstance.put(
    `${ROOT_URL}api/account/default-merchant`,
    { id: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const unlinkFundingSource = (orgId, fundingSourceId) => {
  return axiosInstance.delete(
    `${ROOT_URL}api/merchants/${orgId}/funding-sources/${fundingSourceId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const updateFundsFlow = (orgId, fundsFlowDTO) => {
  return axiosInstance.post(
    `${ROOT_URL}api/merchants/${orgId}/funds-flow-config`,
    fundsFlowDTO,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const createPlaidFundingSource = (
  orgId,
  processorToken,
  accountName,
  accountId,
  accessToken
) => {
  console.log("createPlaidFundingSource got orgId: ", orgId);
  console.log("createPlaidFundingSource got plaidToken: ", processorToken);
  console.log("createPlaidFundingSource got accountName: ", accountName);
  console.log("createPlaidFundingSource got accessToken: ", accessToken);

  return axiosInstance.post(
    `${ROOT_URL}api/merchants/${orgId}/funding-sources`,
    {
      processorToken: processorToken,
      accountName: accountName,
      accountId: accountId,
      accessToken: accessToken,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getOwnedMerchant = () => {
  console.log(`get owned merchant`);

  return axiosInstance.get(`${ROOT_URL}api/merchants/owned`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getMerchants = (organizationId) => {
  console.log(`get merchants`);

  return axiosInstance.get(
    `${ROOT_URL}api/merchants?org-id=${organizationId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const addMerchantBankAccount = async (id, bankAccount) => {
  return axiosInstance.put(
    `${ROOT_URL}api/merchants/${id}/add-bank-account`,
    bankAccount,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getMerchantKPIs = (id) => {
  console.log(`get merchant KPIs with id: ${id}`);

  return axiosInstance.get(`${ROOT_URL}api/merchants/${id}/kpis`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};
