import React, { useCallback, useState } from "react";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Button, Link } from "@mui/material";

import _ from "lodash";
import { useSelector } from "react-redux";
import { PinPad } from "../../components/Form/Form";

import {
  mfaVerifySMSVerificationCode,
  sendSMSVerificationCode,
} from "../../models/account/accountActions";
import FingerprintIcon from "@mui/icons-material/Fingerprint";

import useRedirect from "../../shared/useRedirect";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const PinPadVerificationScreen = (props) => {
  const account = useSelector((state) => state.account);
  const [pins, setPins] = useState("");

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
  } = useForm();

  const handleSendCode = useCallback(() => {
    console.log("inside of handle send code: ", account.account.phoneNumber);
    dispatch(sendSMSVerificationCode(account?.account?.phoneNumber));
  }, [dispatch, account?.account?.phoneNumber]);

  const handleMfaVerifySMS = () => {
    dispatch(
      mfaVerifySMSVerificationCode({
        code: pins.join(""),
        phoneNumber: account?.account?.phoneNumber,
      })
    );
  };

  useRedirect();

  return (
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "1em",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "1000px",
              }}
            >
              <FingerprintIcon
                style={{
                  height: "40px",
                  width: "40px",
                  color: "white",
                }}
              />
            </div>

            <h2 style={{ margin: "0em" }}>Two Factor Authentication</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              rowGap: "1em",
              height: "300px",
            }}
          >
            <p style={{ margin: "0px" }}>
              We sent a 6 digit verification code to your device
            </p>
            <PinPad
              pinLength={6}
              pins={pins}
              setPins={setPins}
              control={control}
              errors={errors}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                columnGap: "1em",
              }}
            >
              <Button
                disabled={_.isNil(account?.account?.phoneNumber)}
                onClick={() => {
                  handleSendCode();
                }}
              >
                Send Code
              </Button>
              <Button
                disabled={!isDirty || !isValid} // here
                variant="outlined"
                onClick={handleSubmit(handleMfaVerifySMS)}
              >
                Verify
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default PinPadVerificationScreen;
