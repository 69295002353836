import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";

// import { sendLenderInviteThunk } from "../../models/lender/lenderThunks";
import OrganizationDetails from "../OrganizationDetails/OrganizationDetails";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function LenderModal(props) {
  const { open, setOpen } = props;
  const account = useSelector((state) => state.account);
  const lender = useSelector((state) => state.lenders);

  const [lenderInfo, setLenderInfo] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    address: {
      city: "",
      state: "",
      zip: "",
      place: "",
    },
  });

  const { control, handleSubmit, reset } = useForm();

  const dispatch = useDispatch();

  const handleAddLender = () => {
    console.log("add lender");
    // dispatch(sendLenderInviteThunk(lenderInfo));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setLenderInfo({
            name: "",
            email: "",
            phoneNumber: "",
            address: {
              city: "",
              state: "",
              zip: "",
              place: "",
            },
          });
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <h3>Create Lender</h3>

            <OrganizationDetails
              control={control}
              lenderInfo={lenderInfo}
              setLenderInfo={setLenderInfo}
            />

            <Button onClick={handleSubmit(handleAddLender)}>Submit</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
