import React, { Component, useEffect, useState } from "react";

import {
  FactorRateWithSlider,
  FundedAmount,
  NumberOfPaymentsWithSlider,
  PaymentAmountWithSlider,
  PaymentFrequency,
  TotalPaybackAmount,
  TotalProfit,
} from "../Form/FinancialForm";
import _ from "lodash";

const DealDetails = (props) => {
  const { dealInfo, setDealInfo, control } = props;

  const [paymentAmountRange, setPaymentAmountRange] = useState({});

  const [numPaymentsRange, setNumPaymentsRange] = useState({
    min: 30,
    max: 365,
  });

  useEffect(() => {
    getTotalPaybackAmount();
  }, [dealInfo.fundedAmount, dealInfo.factorRate]);

  useEffect(() => {
    console.log("payment frequency changed");
    const { paymentFrequency } = dealInfo;

    if (_.isNil(paymentFrequency) || _.isEmpty(paymentFrequency)) {
      return;
    }
    if (paymentFrequency.toLowerCase() === "daily") {
      setNumPaymentsRange({ min: 30, max: 365 });
    } else if (paymentFrequency.toLowerCase() === "weekly") {
      setNumPaymentsRange({ min: 4, max: 52 });
    } else if (paymentFrequency.toLowerCase() === "monthly") {
      setNumPaymentsRange({ min: 1, max: 12 });
    }
  }, [dealInfo.paymentFrequency]);

  useEffect(() => {
    const { min, max } = numPaymentsRange;

    const minPayment = Number(dealInfo.totalPayback / max);
    const maxPayment = Number(dealInfo.totalPayback / min);
    const midPayment = Number(dealInfo.totalPayback / ((max - min) / 2));

    setPaymentAmountRange({ min: minPayment, max: maxPayment });
    setDealInfo({
      ...dealInfo,
      paymentAmount: midPayment,
      numberOfPayments: Math.ceil(dealInfo.totalPayback / midPayment),
    });
  }, [dealInfo.totalPayback, numPaymentsRange]);

  const getTotalPaybackAmount = () => {
    const { factorRate, fundedAmount } = dealInfo;

    if (_.isNil(dealInfo) || _.isNil(factorRate) || _.isNil(fundedAmount)) {
      return;
    }

    const totalPayback = fundedAmount * factorRate;

    if (isNaN(totalPayback)) {
      return;
    }

    setDealInfo({ ...dealInfo, totalPayback: totalPayback });
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <FactorRateWithSlider
        control={control}
        factorRate={dealInfo.factorRate}
        setFactorRate={(rate) => {
          setDealInfo({ ...dealInfo, factorRate: rate });
        }}
      />

      <div style={{ display: "flex", width: "100%", columnGap: "1em" }}>
        <div style={{ display: "flex", width: "50%" }}>
          <FundedAmount
            control={control}
            amount={dealInfo.fundedAmount}
            setAmount={(amount) => {
              setDealInfo({ ...dealInfo, fundedAmount: amount });
            }}
          />
        </div>
        <div style={{ display: "flex", width: "50%" }}>
          <PaymentFrequency
            control={control}
            paymentFrequency={dealInfo.paymentFrequency}
            setPaymentFrequency={(value) => {
              setDealInfo({ ...dealInfo, paymentFrequency: value });
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", width: "100%", columnGap: "1em" }}>
        <div style={{ display: "flex", width: "50%" }}>
          <TotalPaybackAmount
            control={control}
            amount={dealInfo.totalPayback}
          />
        </div>

        <div style={{ display: "flex", width: "50%" }}>
          <TotalProfit
            control={control}
            amount={dealInfo.totalPayback - dealInfo.fundedAmount}
          />
        </div>
      </div>

      <PaymentAmountWithSlider
        control={control}
        paymentAmount={dealInfo.paymentAmount}
        min={paymentAmountRange.min}
        max={paymentAmountRange.max}
        setPaymentAmount={(amount) => {
          const numPayments = Math.ceil(
            dealInfo.totalPayback / dealInfo.paymentAmount
          );

          setDealInfo({
            ...dealInfo,
            paymentAmount: amount,
            numberOfPayments: numPayments,
          });
        }}
      />

      <NumberOfPaymentsWithSlider
        control={control}
        numberOfPayments={dealInfo.numberOfPayments}
        min={numPaymentsRange.min}
        max={numPaymentsRange.max}
        setNumberOfPayments={(amount) => {
          const paymentAmount = Number(
            dealInfo.totalPayback / dealInfo.numberOfPayments
          );

          setDealInfo({
            ...dealInfo,
            numberOfPayments: amount,
            paymentAmount: paymentAmount,
          });
        }}
      />
    </div>
  );
};

export default DealDetails;
