import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const NotificationBell = (props) => {
  const { onClick, id } = props;
  const account = useSelector((state) => state.account);
  const notification = useSelector((state) => state.notification);

  const dispatch = useDispatch();

  return (
    <div style={{ display: "block", position: "relative" }}>
      <IconButton
        aria-describedby={id}
        style={{ color: "white" }}
        onClick={onClick}
        sx={{ p: 0 }}
      >
        <NotificationsIcon style={{ width: "34px", height: "34px" }} />
      </IconButton>
      {notification.unopenedNotificationsCount ? (
        <div
          style={{
            backgroundColor: "red",
            alignItems: "center",
            justifyContent: "center",
            width: "21px",
            height: "21px",
            position: "absolute",
            left: "14px",
            right: "0px",
            top: "-4px",
            borderRadius: "50px",
            display: "flex",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {notification.unopenedNotificationsCount}
          </p>
        </div>
      ) : null}
    </div>
  );
};
