import React, { useEffect, useState } from "react";

import {
  BirthDate,
  FirstName,
  FullAddress,
  LastName,
  SocialSecurityNumber,
  SocialSecurityNumberLastFour,
} from "../Form/Form";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { addBeneficialOwnerAction } from "../../models/dwolla/dwollaActions";
import moment from "moment";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    columnGap: "1em",
  },
}));

const AddOwner = (props) => {
  const classes = useStyles();
  const [ownerInfo, setOwnerInfo] = useState({
    firstName: "",
    lastName: "",
    socialSecurityNumber: "",
    dateOfBirth: null,
    fullAddress: {
      streetAddress: "",
      city: "",
      stateCode: "",
      zip: "",
    },
  });
  const { setAddOwner, control, handleSubmit, reset } = props;

  const dwolla = useSelector((state) => state.dwolla);
  const lenderSlice = useSelector((state) => state.lenders);
  const lender = lenderSlice.lender;

  const dispatch = useDispatch();

  useEffect(() => {
    reset();
  }, []);

  const handleCancel = () => {
    setAddOwner(false);
  };

  const handleAddOwner = () => {
    console.log("add beneficial owner to: ", lender?.dwollaCustomerId);
    dispatch(addBeneficialOwnerAction(lender?.dwollaCustomerId, ownerInfo));
    handleCancel();
  };

  return (
    <div className={classes.flexColumn}>
      <div className={classes.flexRow}>
        <FirstName
          firstName={ownerInfo.firstName}
          setFirstName={(firstName) => {
            setOwnerInfo({ ...ownerInfo, firstName: firstName });
          }}
          control={control}
        />
        <LastName
          lastName={ownerInfo.lastName}
          setLastName={(lastName) => {
            setOwnerInfo({ ...ownerInfo, lastName: lastName });
          }}
          control={control}
        />
      </div>
      <div className={classes.flexRow}>
        <FullAddress
          control={control}
          fullAddress={ownerInfo.fullAddress}
          setFullAddress={(address) => {
            setOwnerInfo({ ...ownerInfo, fullAddress: address });
          }}
        />
      </div>
      <div className={classes.flexRow}>
        <BirthDate
          birthDate={ownerInfo.dateOfBirth}
          setBirthDate={(birthDate) => {
            setOwnerInfo({
              ...ownerInfo,
              dateOfBirth: moment(birthDate).format("YYYY-MM-DD"),
            });
          }}
          control={control}
        />

        <SocialSecurityNumber
          socialSecurityNum={ownerInfo.ssn}
          setSocialSecurityNumber={(social) => {
            setOwnerInfo({ ...ownerInfo, ssn: social });
          }}
          control={control}
        />
      </div>

      <div style={{ marginTop: "2em" }} className={classes.flexRow}>
        <Button onClick={handleCancel} variant="outlined" fullWidth>
          Cancel
        </Button>

        <LoadingButton
          onClick={handleSubmit(handleAddOwner)}
          fullWidth
          loading={dwolla.loading}
          loadingPosition="start"
          variant="outlined"
        >
          Add Owner
        </LoadingButton>
      </div>
    </div>
  );
};

export default AddOwner;
