import { Paper } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";

import { FundedDealBarChart } from "../FundedDealBarChart/FundedDealBarChart";
import PaymentChartGeo from "../PaymentChartGeo/PaymentChartGeo";
import { PaymentCompositionChart } from "../PaymentCompositionChart/PaymentCompositionChart";
import { LinearProgressWithLabel } from "../PaymentProgressChart/PaymentProgressChart";

const DealMetrics = (props) => {
  const { fundedDeal } = props;
  const merchant = useSelector((state) => state.merchants);
  const currentMerchant = merchant?.merchant;

  console.log("the current funded deal merchant: ", currentMerchant);

  const payments = fundedDeal?.merchantPayments;

  const successfulPayments = payments?.filter(
    (payment) => payment.paymentStatus === "Success"
  );

  let paymentProgress = successfulPayments?.length / payments?.length;

  if (_.isNil(paymentProgress) || _.isNaN(paymentProgress)) {
    paymentProgress = "--";
  } else {
    paymentProgress = Number(paymentProgress).toFixed(2);
  }

  console.log("funded deal: ", fundedDeal);

  return (
    <div
      style={{
        width: "100%",
        marginTop: "2em",
        display: "flex",
        columnGap: "2em",
      }}
    >
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          rowGap: "2em",
        }}
      >
        <Paper
          elevation={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1em",
            flexDirection: "column",
          }}
        >
          <p> Payment Progress </p>
          <LinearProgressWithLabel
            variant="determinate"
            value={paymentProgress}
          />
        </Paper>
        <Paper
          elevation={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1em",
            flexDirection: "column",
          }}
        >
          <p> Payment Composition </p>
          <PaymentCompositionChart
            fundedAmount={fundedDeal?.fundedAmount}
            factorRate={fundedDeal?.factorRate}
          />
        </Paper>
      </div>
      <Paper
        elevation={1}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1em",
          flexDirection: "column",
        }}
      >
        <PaymentChartGeo
          fundedAmount={fundedDeal?.fundedAmount}
          state={currentMerchant?.state}
        />
      </Paper>
    </div>
  );
};

export default DealMetrics;
