import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";

import { Paper } from "@mui/material";
import FinancialTransactionsTable from "../../components/FinancialTransactionsTable/FinancialTransactionsTable";
import BasicTabs from "../../components/BasicTabs/BasicTabs";
import moment from "moment/moment";

import InvestorTab from "../../components/InvestorTab/InvestorTab";
import PaymentCalendar from "../../components/PaymentCalendar/PaymentCalendar";
import _ from "lodash";
import DealStatus from "../../components/DealStatus/DealStatus";
import { getCustomerTransactionsThunk } from "../../models/dwolla/dwollaActions";
import DealActions from "../../components/DealStatus/DealActions";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentageIcon from "@mui/icons-material/PieChart"; // Representing Factor Rate as a portion or percentage.
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import KpiCard from "../../components/KpiCard/KpiCard";

const DealSelection = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const holiday = useSelector((state) => state.holiday);

  const lenderSlice = useSelector((state) => state.lenders);
  const lender = lenderSlice.lender;
  const dealSlice = useSelector((state) => state.deals);
  const merchantSlice = useSelector((state) => state.merchants);
  const dwolla = useSelector((state) => state.dwolla);

  const deal = dealSlice?.deal;
  const currentMerchant = merchantSlice?.merchant;

  const profit = deal?.fundedAmount * deal?.factorRate - deal?.fundedAmount;

  console.log("the deal: ", deal);
  console.log("the merchant: ", currentMerchant);

  const [dealInfo, setDealInfo] = useState({
    fundedAmount: deal?.fundedAmount,
    factorRate: deal?.factorRate,
    fundedDate: deal?.fundedDate,
    firstPaymentDate: deal?.firstPaymentDate,
    paymentFrequency: deal?.paymentFrequency,
  });

  const [merchantInfo, setMerchantInfo] = useState({
    name: currentMerchant.name,
    owner: currentMerchant.owner,
    address: currentMerchant.address,
  });

  useEffect(() => {
    dispatch(
      getCustomerTransactionsThunk(currentMerchant.dwollaCustomerId, deal.id)
    );
  }, []);

  useEffect(() => {
    setDealInfo({
      fundedAmount: deal?.fundedAmount,
      factorRate: deal?.factorRate,
      fundedDate: deal?.fundedDate,
      firstPaymentDate: deal?.firstPaymentDate,
      paymentFrequency: deal?.paymentFrequency,
    });
  }, [deal]);

  useEffect(() => {
    setMerchantInfo({
      name: currentMerchant.name,
      owner: currentMerchant.owner,
      address: currentMerchant.address,
    });
  }, [currentMerchant]);

  const { control, handleSubmit, reset, setValue } = useForm();

  const dispatch = useDispatch();

  const kpiData = [
    {
      title: `$${
        deal?.fundedAmount ? deal.fundedAmount.toLocaleString() : "--"
      }`,
      subTitle: "Funded Amount",
      IconComponent: AttachMoneyIcon,
      color: "#2196F3", // Blue color.
    },
    {
      title: `${deal?.factorRate ? Number(deal.factorRate).toFixed(2) : "--"}`,
      subTitle: "Factor Rate",
      IconComponent: PercentageIcon,
      color: "#673AB7",
    },
    {
      title: `$${
        !_.isNil(profit) && !_.isNaN(profit)
          ? Number(Number(profit).toFixed(2)).toLocaleString()
          : "--"
      }`,
      subTitle: "Forecasted Profit",
      IconComponent: TrendingUpIcon,
      color: "#4CAF50", // Green color.
    },
  ];

  const getEvents = () => {
    let holidays = holiday?.holidays?.map((holiday) => {
      return {
        ...holiday,
        type: "holiday",
      };
    });

    console.log("holidays: ", holidays);

    const holidayDates = new Set(
      holidays.map((h) => moment.unix(h.date).format("YYYY-MM-DD"))
    );

    console.log("holiday dates: ", holidayDates);

    let transfers = dwolla.transactions.filter((transfer) => {
      return transfer.source.email === currentMerchant.email;
    });

    const firstPaymentDate = deal.firstPaymentDate;
    let scheduledDate = firstPaymentDate;
    let futureTransfers = [];

    // Create a set of all transfer dates
    const transferDates = new Set(
      transfers.map((t) => moment(t.scheduledDate).format("YYYY-MM-DD"))
    );

    const combinedDatesSet = new Set([...holidayDates, ...transferDates]);

    if (deal.dealStatus != "Paused") {
      // Generate future transfers without overlapping the existing transfers
      for (let i = 0; i < deal.numberOfPayments; i++) {
        let formattedDate = moment(scheduledDate).format("YYYY-MM-DD");
        let dayOfWeek = moment(scheduledDate).day();

        // Check if the date is not in the combinedDatesSet and is not a Saturday or Sunday
        if (
          !combinedDatesSet.has(formattedDate) &&
          dayOfWeek !== 0 &&
          dayOfWeek !== 6
        ) {
          futureTransfers.push({
            scheduledDate: formattedDate,
            status: "scheduled",
          });
          i++; // Only increment our counter if we actually added a transfer
        }
        scheduledDate = moment(scheduledDate).add(1, "days");
      }
    }

    return [...transfers, ...holidays, ...futureTransfers];
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}
        >
          <div
            style={{
              marginTop: "3em",
              marginBottom: "3em",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1 style={{ margin: "0px" }}>{`${
              currentMerchant?.businessName
                ? currentMerchant?.businessName
                : "--"
            }`}</h1>
            <h5 style={{ margin: "0px" }}>{`Created: ${
              deal?.createdDate
                ? moment(deal?.createdDate).format("MMMM Do YYYY")
                : "TBD"
            }`}</h5>
            <h5 style={{ margin: "0px" }}>{`Funded: ${
              deal?.fundedDate
                ? moment(deal?.fundedDate).format("MMMM Do YYYY")
                : "TBD"
            }`}</h5>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DealStatus />
          </div>
        </div>
        <div>
          <DealActions />
        </div>

        <div style={{ width: "100%", display: "flex", columnGap: "1em" }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "2em",
            columnGap: "2em",
          }}
        >
          {kpiData.map((card) => (
            <KpiCard
              title={card.title}
              subTitle={card.subTitle}
              IconComponent={card.IconComponent}
              color={card.color}
            />
          ))}
        </div>

        <div style={{ marginTop: "2em" }}>
          <BasicTabs
            data={[
              {
                label: "Payment Schedule",
                panel: <PaymentCalendar events={getEvents()} />,
              },

              {
                label: "Investors",
                panel: <InvestorTab />,
              },
              {
                label: "Payment History",
                panel: (
                  <FinancialTransactionsTable
                    data={
                      dwolla?.transactions?.length > 0
                        ? dwolla?.transactions
                        : []
                    }
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DealSelection;
