import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import _ from "lodash";
import AddOwner from "../AddOwner/AddOwner";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import {
  certifyBeneficialOwnershipThunk,
  getCustomerCertificationStatusThunk,
  removeBeneficialOwnerAction,
} from "../../models/dwolla/dwollaActions";
import { updateAccountSetupAction } from "../../models/account/accountActions";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    columnGap: "1em",
  },
}));

const AddOwners = (props) => {
  const {
    org,
    loading,
    defaultOrgId,
    getOrgThunk,
    useAccountSetup,
    getActiveStep,
  } = props;
  const classes = useStyles();
  const account = useSelector((state) => state.account);
  const accountSetup = account?.accountSetup;

  const {
    businessInfo,
    certifiedNoBeneficialOwners,
    setCertifiedNoBeneficialOwners,
    certifiedCompleteAndCorrect,
    setCertifiedCompleteAndCorrect,
    hasBeneficialOwners,
    setHasBeneficialOwners,
    control,
    handleSubmit,
    reset,
  } = props;

  const [owners, setOwners] = useState([]);
  const [addOwner, setAddOwner] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const dwolla = useSelector((state) => state.dwolla);
  const dispatch = useDispatch();

  console.log("active step!!!!", activeStep);

  // const getActiveStep = () => {
  //   if (useAccountSetup == false) {
  //     return activeStep;
  //   } else {
  //     console.log(
  //       "the account setup active step was: ",
  //       accountSetup.activeStep
  //     );
  //     if (accountSetup.activeStep === "ADD_OWNERS_YES_NO") {
  //       return 0;
  //     } else if (accountSetup.activeStep === "ADD_OWNERS_YES") {
  //       setHasBeneficialOwners("true");
  //       return 1;
  //     } else if (accountSetup.activeStep === "ADD_OWNERS_NO") {
  //       setHasBeneficialOwners("false");

  //       return 1;
  //     }
  //   }
  // };

  const handleHasBeneficialOwners = (event) => {
    console.log("setting has beneficial owners to: ", event.target.value);
    setHasBeneficialOwners(event.target.value);
  };

  const handleSetCompleteAndCorrect = () => {
    console.log(certifiedCompleteAndCorrect);
    setCertifiedCompleteAndCorrect(!certifiedCompleteAndCorrect);
  };

  const handleNoBeneficialOwners = () => {
    console.log(certifiedNoBeneficialOwners);
    setCertifiedNoBeneficialOwners(!certifiedNoBeneficialOwners);
  };

  const handleRemoveOwner = (owner) => {
    console.log("remove beneficial owner with id:", owner.id);
    dispatch(removeBeneficialOwnerAction(owner.id));
  };

  useEffect(() => {
    if (org?.beneficialOwners?.length > 0) {
      console.log("setting owners: ", org.beneficialOwners);

      const updatedArray = [
        ...owners,
        ...org?.beneficialOwners?.filter(
          (owner) => !owners.some((item) => item.id === owner.id)
        ),
      ];

      setOwners([...updatedArray]);
    }

    if (org?.id && org?.dwollaCustomerId) {
      dispatch(getCustomerCertificationStatusThunk(org.dwollaCustomerId));
    }
  }, [org]);

  useEffect(() => {
    console.log("inside of add owners use effect...");
    if (org?.id) {
      console.log("getting organization...");
      dispatch(getOrgThunk(org?.id));
    } else {
      console.log("getting default organization...");
      dispatch(getOrgThunk(defaultOrgId));
    }

    return () => {
      if (org?.id) {
        dispatch(getOrgThunk(org?.id));
      }
    };
  }, []);

  useEffect(() => {
    if (dwolla.owners) {
      setOwners([...dwolla.owners]);
    }
  }, [dwolla.owners]);

  useEffect(() => {
    if (useAccountSetup) {
      setActiveStep(getActiveStep());
    }
  }, []);

  useEffect(() => {
    if (useAccountSetup) {
      const currentStep = activeStep;
      const nextStep = getActiveStep();
      if (currentStep != nextStep) {
        setActiveStep(nextStep);
      }
    }
  }, [accountSetup.activeStep]);

  const chooseBeneficialOwnerStatusStep = (
    <div className={classes.flexColumn} style={{ rowGap: "2em" }}>
      <div className={classes.flexRow}>
        <FormControl>
          <RadioGroup
            aria-labelledby="has-beneficial-owners-radio-buttons-group"
            name="beneficial-owners-radio-buttons-group"
            value={hasBeneficialOwners}
            onChange={handleHasBeneficialOwners}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label={`${businessInfo.businessName} has at least one individual who owns 25% or more of the business.`}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={`${businessInfo.businessName} does not have at least one individual who owns 25% or more of the business.`}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className={classes.flexRow}>
        <div className={classes.flexRow}>
          <Button
            fullWidth
            onClick={() => {
              if (useAccountSetup) {
                dispatch(updateAccountSetupAction("CONTROLLER_DETAILS"));
              } else {
                setActiveStep(activeStep - 1);
              }
            }}
            variant="outlined"
          >
            Back
          </Button>
        </div>

        <Button
          disabled={hasBeneficialOwners == null}
          fullWidth
          onClick={() => {
            if (useAccountSetup) {
              if (hasBeneficialOwners == "true") {
                dispatch(updateAccountSetupAction("ADD_OWNERS_YES"));
              } else {
                dispatch(updateAccountSetupAction("ADD_OWNERS_NO"));
              }
            } else {
              setActiveStep(activeStep + 1);
            }
          }}
          variant="outlined"
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const noBeneficialOwnersStep = (
    <div className={classes.flexColumn} style={{ rowGap: "2em" }}>
      <div className={classes.flexColumn}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={certifiedCompleteAndCorrect}
                onChange={handleSetCompleteAndCorrect}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={`I hereby certify that the information provided for ${businessInfo.businessName} is complete and correct.`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={certifiedNoBeneficialOwners}
                onChange={handleNoBeneficialOwners}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={`I hereby certify that ${businessInfo.businessName} has no Beneficial Owners.`}
          />
        </FormGroup>
      </div>
      <div className={classes.flexRow}>
        <Button
          fullWidth
          onClick={() => {
            if (useAccountSetup) {
              dispatch(updateAccountSetupAction("ADD_OWNERS_YES_NO"));
            } else {
              setActiveStep(activeStep - 1);
            }
          }}
          variant="outlined"
        >
          Back
        </Button>
        <LoadingButton
          disabled={
            !certifiedCompleteAndCorrect || !certifiedNoBeneficialOwners
          }
          onClick={() => {
            console.log("org: ", org);
            dispatch(certifyBeneficialOwnershipThunk(org.dwollaCustomerId));
          }}
          fullWidth
          loading={loading}
          loadingPosition="start"
          variant="outlined"
        >
          Certify
        </LoadingButton>
      </div>
    </div>
  );

  const addBeneficialOwnersStep = (
    <div className={classes.flexColumn} style={{ rowGap: "2em" }}>
      {addOwner ? (
        <AddOwner
          addOwner={addOwner}
          setAddOwner={setAddOwner}
          reset={reset}
          handleSubmit={handleSubmit}
          control={control}
        />
      ) : (
        <>
          <div className={classes.flexColumn}>
            <div
              style={{
                border: "solid",
                borderWidth: "1px",
                borderColor: "lightGray",
              }}
              className={classes.flexColumn}
            >
              {!_.isNil(owners) && owners.length > 0 ? (
                owners?.map((owner) => {
                  return (
                    <div className={classes.flexRow}>
                      <p>{`${owner.firstName} ${owner.lastName}`}</p>
                      <IconButton
                        onClick={() => {
                          handleRemoveOwner(owner);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </div>
                  );
                })
              ) : (
                <p>No owners have been added.</p>
              )}
            </div>
            <Button
              onClick={() => {
                setAddOwner(true);
              }}
              fullWidth
              variant="contained"
            >
              Add Owner
            </Button>
          </div>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={owners?.length === 0}
                  checked={certifiedCompleteAndCorrect}
                  onChange={handleSetCompleteAndCorrect}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={`I hereby certify that the information provided for ${businessInfo.businessName} is complete and correct.`}
            />
          </FormGroup>
          <div className={classes.flexRow}>
            <Button
              fullWidth
              onClick={() => {
                if (useAccountSetup) {
                  dispatch(updateAccountSetupAction("ADD_OWNERS_YES_NO"));
                } else {
                  setActiveStep(activeStep - 1);
                }
              }}
              variant="outlined"
            >
              Back
            </Button>
            <LoadingButton
              disabled={
                !owners || owners.length == 0 || !certifiedCompleteAndCorrect
              }
              onClick={() => {
                console.log("org: ", org);
                dispatch(certifyBeneficialOwnershipThunk(org.dwollaCustomerId));
              }}
              fullWidth
              loading={loading}
              loadingPosition="start"
              variant="outlined"
            >
              Certify
            </LoadingButton>
          </div>
        </>
      )}
    </div>
  );

  const steps = [
    chooseBeneficialOwnerStatusStep,
    <>
      {hasBeneficialOwners === "false"
        ? noBeneficialOwnersStep
        : addBeneficialOwnersStep}
    </>,
  ];

  return (
    <div className={classes.flexColumn}>
      <h3>Add Owners</h3>
      <div className={classes.flexRow}>
        <p>
          Due to regulatory requirements, we need to identify anyone who owns
          25% or more of the organization. This can include venture capitalists,
          investors, managing directors, individual owners, etc.
        </p>
      </div>
      {steps[activeStep]}
    </div>
  );
};

export default AddOwners;
