import { createSlice } from "@reduxjs/toolkit";

interface PlaidState {
  linkTokenResponse: any;
  processorTokenResponse: any;
  accessTokenResponse: any;
  loading: boolean;
}

const initialState: PlaidState = {
  linkTokenResponse: null,
  processorTokenResponse: null,
  accessTokenResponse: null,
  loading: false,
};

const plaidSlice = createSlice({
  name: "plaid",
  initialState,
  reducers: {
    createLinkTokenSuccess(state, action) {
      state.linkTokenResponse = action.payload;
      state.loading = false;
    },
    getDwollaProcessorTokenSuccess(state, action) {
      state.processorTokenResponse = action.payload;
      state.loading = false;
    },
    exchangePublicTokenForAccessTokenSuccess(state, action) {
      state.accessTokenResponse = action.payload;
      state.loading = false;
    },

    loading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  createLinkTokenSuccess,
  getDwollaProcessorTokenSuccess,
  exchangePublicTokenForAccessTokenSuccess,
  loading,
} = plaidSlice.actions;

export default plaidSlice.reducer;
