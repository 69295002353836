import axios from "axios";
import { applicationJson, JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const getAccount = (jwt) => {
  console.log("JWT:", jwt);
  return axiosInstance.get(`${ROOT_URL}api/account`, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: jwt,
    },
  });
};

export const updateAccount = (account) => {
  return axiosInstance.put(`${ROOT_URL}api/account`, account, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const updateAccountSetup = (accountSetup) => {
  return axiosInstance.put(`${ROOT_URL}api/account-setup`, accountSetup, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getAccountSetup = () => {
  return axiosInstance.get(`${ROOT_URL}api/account-setup`, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const sendSMSCode = async (phoneNumber) => {
  return axiosInstance.post(
    `${ROOT_URL}api/send-sms-verification`,
    { phoneNumber: phoneNumber },
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const verifySMSCode = async (code, phoneNumber) => {
  return axiosInstance.post(
    `${ROOT_URL}api/verify-phone-number`,
    { verificationCode: code, phoneNumber: phoneNumber },
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const mfaVerifySMSCode = async (code, phoneNumber) => {
  return axiosInstance.post(
    `${ROOT_URL}api/mfa-verify-sms`,
    { verificationCode: code, phoneNumber: phoneNumber },
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const activateAccount = (activationKey) => {
  return axiosInstance.post(`${ROOT_URL}api/activate`, {
    activationKey: activationKey,
  });
};

export const completeAccountSetup = () => {
  return axiosInstance.post(
    `${ROOT_URL}api/complete-account-setup`,
    {},
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const changePassword = (password) => {
  console.log("change password: ", password);
  return axiosInstance.post(
    `${ROOT_URL}api/account/change-password`,
    password,
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const forgotPasswordInit = (email) => {
  return axiosInstance.post(
    `${ROOT_URL}api/account/reset-password/init`,
    email,
    {
      headers: { "Content-Type": "text/plain" },
    }
  );
};

export const forgotPasswordVerify = (key) => {
  return axiosInstance.post(
    `${ROOT_URL}api/account/reset-password/verify`,
    key,
    {
      headers: { "Content-Type": "text/plain" },
    }
  );
};

export const changeProfilePicture = async (photo) => {
  return axiosInstance.post(`${ROOT_URL}api/account/set-profile-pic`, photo, {
    headers: {
      "Content-Type": "text/plain",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const resetPassword = (newPassword, key) => {
  console.log("newPassword: ", newPassword);
  console.log("resetKey: ", key);

  return axiosInstance.post(`${ROOT_URL}api/account/reset-password/finish`, {
    key: key,
    newPassword: newPassword,
  });
};

export const credentialsLogin = (email, password) => {
  console.log(`email: ${email} password: ${password}`);
  return axiosInstance.post(`${ROOT_URL}api/authenticate`, {
    username: email,
    password: password,
  });
};

export const credentialsSignup = (user) => {
  return axiosInstance.post(`${ROOT_URL}api/registerCredentialsUser`, {
    login: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: user.password,
    langKey: "en",
  });
};

export const refresh = () => {
  return axiosInstance.post(
    `${ROOT_URL}api/refresh`,
    {},
    {
      withCredentials: true, // Corrected placement of withCredentials option
    }
  );
};

export const googleSignup = (idToken) => {
  return axiosInstance.post(`${ROOT_URL}api/registerGoogleUser`, {
    idToken: idToken,
  });
};

export const msftSignup = (idToken) => {
  return axiosInstance.post(`${ROOT_URL}api/registerMsftUser`, {
    idToken: idToken,
  });
};

export const logout = (jwt) => {
  return axiosInstance.get(`${ROOT_URL}api/logout`, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: jwt,
    },
  });
};

export const sso = (idToken) => {
  console.log("id token: ", idToken);
  console.log("ROOT_URL: ", ROOT_URL);
  console.log("calling backend...");
  return axiosInstance.post(`${ROOT_URL}api/sso`, {
    idToken: idToken,
  });
};

export const getInvestmentBusinessAccount = async () => {
  return axiosInstance.get(
    `${ROOT_URL}api/account/investment-account/business`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getInvestmentPersonalAccount = async () => {
  return axiosInstance.get(
    `${ROOT_URL}api/account/investment-account/personal`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const createInvestmentBusinessAccount = (investmentBusinessAccount) => {
  return axiosInstance.post(
    `${ROOT_URL}api/account/investment-account/business`,
    investmentBusinessAccount,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const createInvestmentPersonalAccount = (investmentPersonalAccount) => {
  return axiosInstance.post(
    `${ROOT_URL}api/account/investment-account/personal`,
    investmentPersonalAccount,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const removeInvestmentBusinessAccount = () => {
  return axiosInstance.delete(
    `${ROOT_URL}api/account/investment-account/business`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const removeInvestmentPersonalAccount = () => {
  return axiosInstance.delete(
    `${ROOT_URL}api/account/investment-account/personal`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const unlinkInvestmentPersonalAccountFundingSource = (
  fundingSourceId
) => {
  return axiosInstance.delete(
    `${ROOT_URL}api/account/investment-account/personal/funding-sources/${fundingSourceId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const unlinkInvestmentBusinessAccountFundingSource = (
  fundingSourceId
) => {
  console.log("the funding source id: ", fundingSourceId);
  return axiosInstance.delete(
    `${ROOT_URL}api/account/investment-account/business/funding-sources/${fundingSourceId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const updateFundsFlow = (fundsFlowDTO) => {
  return axiosInstance.post(
    `${ROOT_URL}api/user/funds-flow-config`,
    fundsFlowDTO,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const createInvestmentBusinessAccountPlaidFundingSource = (
  orgId,
  processorToken,
  accountName,
  accountId,
  accessToken
) => {
  console.log("createPlaidFundingSource got orgId: ", orgId);
  console.log("createPlaidFundingSource got plaidToken: ", processorToken);
  console.log("createPlaidFundingSource got accountName: ", accountName);
  console.log("createPlaidFundingSource got accessToken: ", accessToken);

  return axiosInstance.post(
    `${ROOT_URL}api/account/investment-account/business/funding-sources`,
    {
      processorToken: processorToken,
      accountName: accountName,
      accountId: accountId,
      accessToken: accessToken,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const createInvestmentPersonalAccountPlaidFundingSource = (
  orgId,
  processorToken,
  accountName,
  accountId,
  accessToken
) => {
  console.log("createPlaidFundingSource got orgId: ", orgId);
  console.log("createPlaidFundingSource got plaidToken: ", processorToken);
  console.log("createPlaidFundingSource got accountName: ", accountName);
  console.log("createPlaidFundingSource got accessToken: ", accessToken);

  return axiosInstance.post(
    `${ROOT_URL}api/account/investment-account/personal/funding-sources`,
    {
      processorToken: processorToken,
      accountName: accountName,
      accountId: accountId,
      accessToken: accessToken,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};
