import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { useForm } from "react-hook-form";

import { Paper } from "@mui/material";
import UserTable from "../../components/UserTable/UserTable";
import UserModal from "../../components/UserModal/UserModal";
import _ from "lodash";
import { ROLE_LENDER_ADMIN } from "../../config/contants";
import BasicTabs from "../../components/BasicTabs/BasicTabs";
import { getOrganizationPendingInvites } from "../../models/lender/lenderApi";
import { getOrganizationPendingInvitesAction } from "../../models/lender/lenderThunks";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import KpiCard from "../../components/KpiCard/KpiCard";

const LenderDashboard = () => {
  const navigate = useNavigate();
  const accountSlice = useSelector((state) => state.account);
  const account = accountSlice.account;
  const lenderSlice = useSelector((state) => state.lenders);
  const lender = lenderSlice.lender;

  const [addUser, setAddUser] = useState(false);
  const { control, handleSubmit, errors } = useForm();

  const dispatch = useDispatch();

  const admins = _.groupBy(
    lenderSlice?.lender?.dwollaCustomerEntityUsers,
    function (orgUser) {
      return orgUser.user.authorities.includes(ROLE_LENDER_ADMIN);
    }
  ).true;

  console.log("admins: ", admins);

  const kpiData = [
    {
      title: `${admins && admins.length > 0 ? admins.length : "0"}`,
      subTitle: "Admins",
      IconComponent: AdminPanelSettingsIcon,
      color: "#3f51b5", // You can choose a suitable blue color that represents authority or admin roles
    },
    {
      title: `${
        lender && lender.lenderUsers && lender.lenderUsers.length > 0
          ? lender.lenderUsers.length
          : "0"
      }`,
      subTitle: "Total Members",
      IconComponent: PeopleAltIcon,
      color: "#4CAF50", // You can choose a suitable green color that represents community or members
    },
  ];

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "3em", marginBottom: "3em" }}>
          <h1 style={{ margin: "0px" }}>{`${
            lender?.businessName ? lender?.businessName : "--"
          }`}</h1>

          <h3 style={{ margin: "0px" }}>Lender Admin Dashboard</h3>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
            rowGap: "2em",
            columnGap: "2em",
            flexWrap: "wrap",
            marginBottom: "2em",
          }}
        >
          {kpiData.map((card) => (
            <KpiCard
              title={card.title}
              subTitle={card.subTitle}
              IconComponent={card.IconComponent}
              color={card.color}
            />
          ))}
        </div>
        <div style={{ width: "100%" }}>
          <UserTable setAddUser={setAddUser} data={lenderSlice.lenderUsers} />

          <UserModal open={addUser} setOpen={setAddUser} />
        </div>
      </div>
    </div>
  );
};

export default LenderDashboard;
