import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMerchantAction } from "../../models/merchant/merchantThunks";
import MerchantDetails from "../MerchantDetails/MerchantDetails";
import AccountDetails from "../AccountDetails/AccountDetails";
import { updateAccountAction } from "../../models/account/accountActions";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function AccountModal(props) {
  const { open, setOpen } = props;
  const account = useSelector((state) => state.account)?.account;
  const [accountInfo, setAccountInfo] = useState(account);

  console.log("the account! ", account);

  const { control, handleSubmit, reset, setValue } = useForm();

  const dispatch = useDispatch();

  const handleUpdateAccountInfo = () => {
    dispatch(updateAccountAction(accountInfo));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <h3>Account Details</h3>

            <AccountDetails
              setValue={setValue}
              control={control}
              accountInfo={accountInfo}
              setAccountInfo={setAccountInfo}
            />

            <Button onClick={handleSubmit(handleUpdateAccountInfo)}>
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
