import React, { useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  BirthDate,
  Email,
  FirstName,
  FullAddress,
  LastName,
  SocialSecurityNumberLastFour,
} from "../Form/Form";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    columnGap: "1em",
  },
}));

const PersonalVerifiedCustomer = (props) => {
  const accountSlice = useSelector((state) => state.account);
  const loading = accountSlice.loading;
  const classes = useStyles();
  const {
    createCustomerThunk,
    personalInfo,
    setPersonalInfo,
    handleCreatePersonalVerifiedCustomer,
  } = props;

  const dispatch = useDispatch();

  const handleSetpersonalInfo = (key, value) => {
    setPersonalInfo({ ...personalInfo, [key]: value });
  };

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
    setValue,
    reset,
  } = useForm();

  return (
    <div className={classes.flexColumn}>
      <>
        <div className={classes.flexRow}>
          <h3>Investor Details</h3>
        </div>
        <div className={classes.flexRow}>
          <div
            style={{
              width: "70%",
              display: "flex",
              columnGap: "1em",
              flexDirection: "row",
            }}
          >
            <FirstName
              controlName="firstName"
              firstName={personalInfo?.firstName}
              setFirstName={(firstName) => {
                handleSetpersonalInfo("firstName", firstName);
              }}
              control={control}
            />
            <LastName
              controlName="lastName"
              lastName={personalInfo?.lastName}
              setLastName={(lastName) => {
                handleSetpersonalInfo("lastName", lastName);
              }}
              control={control}
            />
          </div>

          <div style={{ width: "30%" }}>
            <BirthDate
              name="Birth Date"
              birthDate={personalInfo?.dateOfBirth}
              setBirthDate={(birthDate) => {
                console.log("birthdate: ", birthDate);
                console.log(
                  "formatted: ",
                  moment(birthDate).format("YYYY-MM-DD")
                );
                handleSetpersonalInfo(
                  "dateOfBirth",
                  moment(birthDate).format("YYYY-MM-DD")
                );
              }}
              control={control}
            />
          </div>
        </div>
        <div className={classes.flexRow}>
          <div style={{ width: "70%" }}>
            <Email
              controlName="email"
              name="Business Email"
              email={personalInfo?.email}
              setEmail={(email) => {
                handleSetpersonalInfo("email", email);
              }}
              control={control}
            />
          </div>
          <div style={{ width: "30%" }}>
            <SocialSecurityNumberLastFour
              socialSecurityNumber={personalInfo?.ssn}
              setSocialSecurityNumber={(ssn) => {
                handleSetpersonalInfo("ssn", ssn);
              }}
              control={control}
            />
          </div>
        </div>
        <div className={classes.flexRow}>
          <FullAddress
            control={control}
            setValue={setValue}
            fullAddress={personalInfo?.address}
            setFullAddress={(address) => {
              handleSetpersonalInfo("address", address);
            }}
          />
        </div>
      </>

      <div
        style={{ marginTop: "2em", columnGap: "1em" }}
        className={classes.flexRow}
      >
        <LoadingButton
          onClick={handleSubmit(handleCreatePersonalVerifiedCustomer)}
          fullWidth
          loading={loading}
          loadingPosition="start"
          variant="outlined"
        >
          Create Business
        </LoadingButton>
      </div>
    </div>
  );
};

export default PersonalVerifiedCustomer;
