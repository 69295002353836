import {
  addInvestorSuccess,
  getInvestorSuccess,
  getInvestorsSuccess,
} from "./investorReducer";

import _ from "lodash";
import { failure, loading, toastFailure, toastSuccess } from "../uiReducer";
import {
  addInvestor,
  getInvestor,
  getInvestors,
  getUserInvestments,
  investorPayIn,
  rejectInvestorStatus,
} from "./investorApi";
import {
  changeUserInvestmentStatusSuccess,
  getInvestmentSuccess,
  getUserInvestmentsSuccess,
} from "../deal/dealReducer";
import { getDataFromDataRef } from "../commonApi";
import { getMerchant } from "../merchant/merchantApi";
import { getMerchantSuccess } from "../merchant/merchantReducer";

export const addInvestorAction = (investor) => async (dispatch) => {
  console.log(`handle add investor: ${investor}`);
  dispatch(loading(true));

  try {
    const response = await addInvestor(investor);

    console.log("response: ", JSON.stringify(response));

    dispatch(addInvestorSuccess(response.data));
    toastSuccess("Success!", "The investor was added successfully.");
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getInvestorAction = (id) => async (dispatch) => {
  console.log(`handle get investor: ${id}`);
  dispatch(loading(true));

  try {
    const response = await getInvestor(id);

    console.log("response: ", JSON.stringify(response));

    dispatch(getInvestorSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getInvestorsAction = () => async (dispatch) => {
  console.log(`handle get investors`);
  dispatch(loading(true));

  try {
    const response = await getInvestors();

    console.log("response: ", JSON.stringify(response));

    dispatch(getInvestorsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getSelectedInvestmentFromDataRefAction =
  (dataRef) => async (dispatch) => {
    console.log("handle get selected investment from dataref", dataRef);
    dispatch(loading(true));

    try {
      const response = await getDataFromDataRef(dataRef);
      console.log(response);
      console.log("the investment response was: ", response.data);

      dispatch(getInvestmentSuccess(response.data));

      const responseMerchant = await getMerchant(response.data.merchant.id);

      dispatch(getMerchantSuccess(responseMerchant.data));
    } catch (error) {
      console.log("inside of get selected investment from dataref error");
      toastFailure(error);
      dispatch(loading(false));

      dispatch(failure(error.response.data.message));
    }
    dispatch(loading(false));
  };

export const getUserInvestmentsAction = () => async (dispatch) => {
  console.log(`get user investments action`);
  dispatch(loading(true));

  try {
    const response = await getUserInvestments();

    console.log("response: ", JSON.stringify(response));

    dispatch(getUserInvestmentsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const acceptInvestmentStatusAction =
  (dealId, payInRequest) => async (dispatch) => {
    console.log(`change investment status`);
    dispatch(loading(true));

    try {
      const response = await investorPayIn(dealId, payInRequest);

      console.log("response: ", JSON.stringify(response));

      dispatch(changeUserInvestmentStatusSuccess(response.data));
      toastSuccess(
        "ACH Successful",
        "Your investment contribution was confirmed, and your payment was debited. It should post in 4 business days or less."
      );
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const rejectInvestmentStatusAction =
  (dealId, status) => async (dispatch) => {
    console.log(`change investment status`);
    dispatch(loading(true));

    try {
      const response = await rejectInvestorStatus(dealId, status);

      console.log("response: ", JSON.stringify(response));

      dispatch(changeUserInvestmentStatusSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };
