import {
  addDealSuccess,
  getDealSuccess,
  getDealsSuccess,
  updateInvestorContributionsSuccess,
  submitDealForApprovalSuccess,
  confirmPaymentScheduleSuccess,
  setPaymentScheduleSuccess,
  markDealAsDefaultedSuccess,
  fundDealSuccess,
  exploreDealsSuccess,
} from "./dealReducer";

import _ from "lodash";
import { failure, loading, toastFailure, toastSuccess } from "../uiReducer";
import {
  addDeal,
  confirmPaymentSchedule,
  exploreDeals,
  fundDeal,
  getDeal,
  getDeals,
  getMerchantDeals,
  markDealAsDefaulted,
  markDealAsPaused,
  markDealAsUnPaused,
  setPaymentSchedule,
  submitDealForApproval,
  updateInvestorContributions,
} from "./dealApi";
import { getDataFromDataRef } from "../commonApi";
import { getMerchant } from "../merchant/merchantApi";
import { getMerchantSuccess } from "../merchant/merchantReducer";

export const addDealThunk = (deal, merchantId) => async (dispatch) => {
  console.log(`handle add deal: ${deal} with merchant id: ${merchantId}`);
  dispatch(loading(true));

  try {
    const response = await addDeal(deal);

    console.log("response: ", JSON.stringify(response));

    dispatch(addDealSuccess(response.data));
    toastSuccess("Success!", "The deal was added successfully.");
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getDealThunk = (id) => async (dispatch) => {
  console.log(`handle get deal with id: ${id}`);
  dispatch(loading(true));

  try {
    const response = await getDeal(id);

    console.log("response: ", JSON.stringify(response));

    dispatch(getDealSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getMerchantDealsThunk = (merchantId) => async (dispatch) => {
  console.log(`handle get deals}`);
  dispatch(loading(true));

  try {
    const response = await getMerchantDeals(merchantId);

    console.log("response: ", JSON.stringify(response));

    dispatch(getDealsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const exploreDealsThunk = () => async (dispatch) => {
  console.log(`handle explore deals}`);
  dispatch(loading(true));

  try {
    const response = await exploreDeals();

    console.log("response: ", JSON.stringify(response));

    dispatch(exploreDealsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const updateInvestorContributionsThunk =
  (dealUsers, dealId) => async (dispatch) => {
    console.log(`update investor contributions action: `, dealUsers);
    dispatch(loading(true));

    try {
      const response = await updateInvestorContributions(dealUsers, dealId);

      console.log("response: ", JSON.stringify(response));

      dispatch(updateInvestorContributionsSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const submitDealForApprovalThunk = (dealId) => async (dispatch) => {
  console.log(`submit deal for approval action: ${dealId}`);
  dispatch(loading(true));

  try {
    const response = await submitDealForApproval(dealId);

    console.log("response: ", JSON.stringify(response));

    dispatch(submitDealForApprovalSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getSelectedDealFromDataRefThunk =
  (dataRef) => async (dispatch) => {
    console.log("handle get selected deal from dataref", dataRef);
    dispatch(loading(true));

    try {
      const response = await getDataFromDataRef(dataRef);
      console.log(response);
      console.log("the response was: ", response.data);

      dispatch(getDealSuccess(response.data));

      const responseMerchant = await getMerchant(response.data.merchant.id);

      dispatch(getMerchantSuccess(responseMerchant.data));
    } catch (error) {
      console.log("inside of get selected team from dataref error");
      toastFailure(error);
      dispatch(loading(false));

      dispatch(failure(error.response.data.message));
    }
    dispatch(loading(false));
  };

export const confirmPaymentScheduleThunk = (dealId) => async (dispatch) => {
  console.log(`confirm payment schedule action`);
  dispatch(loading(true));

  try {
    const response = await confirmPaymentSchedule(dealId);

    console.log("response: ", JSON.stringify(response));

    dispatch(confirmPaymentScheduleSuccess(response.data));
    toastSuccess("Success!", "The payment schedule was confirmed");
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const fundDealThunk = (dealId, fundingSource) => async (dispatch) => {
  console.log(`fund deal action`);
  dispatch(loading(true));

  try {
    const response = await fundDeal(dealId, fundingSource);

    console.log("response: ", JSON.stringify(response));

    dispatch(fundDealSuccess(response.data));
    toastSuccess(
      "Success!",
      "The deal was funded. The funds should post in 4 business days or less."
    );
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const setPaymentScheduleThunk =
  (dealId, setPaymentScheduleDTO) => async (dispatch) => {
    console.log(`set payment schedule action`);
    dispatch(loading(true));

    try {
      const response = await setPaymentSchedule(dealId, setPaymentScheduleDTO);

      console.log("response: ", JSON.stringify(response));

      dispatch(setPaymentScheduleSuccess(response.data));
      toastSuccess("Success!", "The payment schedule was updated");
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const markDealAsDefaultedThunk = (dealId) => async (dispatch) => {
  console.log(`mark deal as defaulted`);
  dispatch(loading(true));

  try {
    const response = await markDealAsDefaulted(dealId);

    console.log("response: ", JSON.stringify(response));

    dispatch(markDealAsDefaultedSuccess(response.data));
    toastSuccess("Done.", "The deal was successfully marked as defaulted");
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const markDealAsPausedThunk = (dealId) => async (dispatch) => {
  console.log(`mark deal as paused`);
  dispatch(loading(true));

  try {
    const response = await markDealAsPaused(dealId);

    console.log("response: ", JSON.stringify(response));

    dispatch(markDealAsDefaultedSuccess(response.data));
    toastSuccess("Done.", "The deal was successfully paused");
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const markDealAsUnPausedThunk = (dealId) => async (dispatch) => {
  console.log(`mark deal as unpaused`);
  dispatch(loading(true));

  try {
    const response = await markDealAsUnPaused(dealId);

    console.log("response: ", JSON.stringify(response));

    dispatch(markDealAsDefaultedSuccess(response.data));
    toastSuccess("Done.", "The deal was successfully unpaused");
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};
