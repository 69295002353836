import React from "react";

import {
  Address,
  AddressWithZip,
  BusinessName,
  Email,
  FirstName,
  LastName,
  PhoneNumber,
  Zip,
} from "../Form/Form";
import _ from "lodash";
import { BankAccountNumbers } from "../Form/FinancialForm";

const MerchantDetails = (props) => {
  const { merchantInfo, setMerchantInfo, control, setValue } = props;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <BusinessName
        control={control}
        name="merchantBusinessName"
        label="Business Name"
        businessName={merchantInfo?.name}
        setBusinessName={(businessName) => {
          setMerchantInfo({ ...merchantInfo, name: businessName });
        }}
      />
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <FirstName
          control={control}
          firstName={merchantInfo?.owner?.firstName}
          setFirstName={(firstName) => {
            setMerchantInfo({
              ...merchantInfo,
              owner: { ...merchantInfo.owner, firstName: firstName },
            });
          }}
        />

        <LastName
          control={control}
          lastName={merchantInfo?.owner?.lastName}
          setLastName={(lastName) => {
            setMerchantInfo({
              ...merchantInfo,
              owner: { ...merchantInfo.owner, lastName: lastName },
            });
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <Email
          control={control}
          email={merchantInfo?.owner?.email}
          setEmail={(email) => {
            setMerchantInfo({
              ...merchantInfo,
              owner: { ...merchantInfo.owner, email: email },
            });
          }}
        />

        <PhoneNumber
          control={control}
          phone={merchantInfo?.owner?.phoneNumber}
          setPhone={(phone) => {
            setMerchantInfo({
              ...merchantInfo,
              owner: { ...merchantInfo.owner, phoneNumber: phone },
            });
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "1em",
          width: "100%",
        }}
      >
        <AddressWithZip
          control={control}
          setValue={setValue}
          address={merchantInfo?.address}
          setAddress={({ city, state, zip, addressLine1, place }) => {
            console.log("setting address: ");
            console.log("city: ", city);
            console.log("state: ", state);
            console.log("zip: ", zip);
            console.log("place: ", place);

            setMerchantInfo({
              ...merchantInfo,
              address: {
                ...merchantInfo?.address,
                addressLine1: addressLine1,
                city: city,
                state: state,
                zip: zip,
                place: place,
                placeId: place["place_id"],
                description: place.description,
              },
            });
          }}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <BankAccountNumbers
          control={control}
          routingNumber={merchantInfo?.routingNumber}
          setRoutingNumber={(routingNum) => {
            setMerchantInfo({ ...merchantInfo, routingNumber: routingNum });
          }}
          confirmRoutingNumber={merchantInfo?.confirmRoutingNumber}
          setConfirmRoutingNumber={(routingNum) => {
            setMerchantInfo({
              ...merchantInfo,
              confirmRoutingNumber: routingNum,
            });
          }}
          accountNumber={merchantInfo?.accountNumber}
          setAccountNumber={(accountNum) => {
            setMerchantInfo({ ...merchantInfo, accountNumber: accountNum });
          }}
          confirmAccountNumber={merchantInfo?.confirmAccountNumber}
          setConfirmAccountNumber={(accountNum) => {
            setMerchantInfo({
              ...merchantInfo,
              confirmAccountNumber: accountNum,
            });
          }}
        />
      </div>
    </div>
  );
};

export default MerchantDetails;
