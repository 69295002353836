import React, { useCallback, useState } from "react";

import { Button, Link } from "@mui/material";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { GoogleLogin } from "@react-oauth/google";

import { Copyright } from "@mui/icons-material";
import {
  credentialsSignupAction,
  googleSignupAction,
} from "../../models/account/accountActions";

import { CreatePassword, Email } from "../../components/Form/Form";
import logo from "../../shared/images/ls-logo-png.png";
import moment from "moment/moment";
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from "@mui/styles";
import useRedirect from "../../shared/useRedirect";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: "2em",
  },
  [theme.breakpoints.down("md")]: {
    form: {
      width: "80%",
    },
  },
  [theme.breakpoints.up("md")]: {
    form: {
      width: "400px",
    },
  },

  header: {
    marginTop: "5%",
    fontSize: "35px",
    fontWeight: "bold",
    margingTop: "5%",
  },
}));

function SignupScreen(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const { control, handleSubmit, errors } = useForm();
  const classes = useStyles();

  const [recaptchaToken, setRecaptchaToken] = useState();

  const onVerifyRecaptcha = useCallback((token) => {
    console.log("token: ", token);
    setRecaptchaToken(token);
  });

  const handleCredentialsSignup = () => {
    console.log("Sign up button pressed");
    dispatch(
      credentialsSignupAction({
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
      })
    );
  };

  const responseGoogle = (response) => {
    dispatch(googleSignupAction(response.credential));
  };

  useRedirect();

  return (
    <>
      <div>
        <div>
          <div className={classes.paper}>
            <div
              style={{
                height: "8em",
                width: "8em",

                position: "relative",
                marginBottom: "3em",
                backgroundPosition: "center center",
                margin: "3em",

                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${logo})`,
              }}
            />

            <div
              class="row"
              style={{
                marginTop: "5%",
                height: "100%",
                marginLeft: "8%",
                marginRight: "8%",
                display: "flex",
                flexDirection: "row",
                padding: "7.5px",
              }}
            ></div>
            <div className={classes.form}>
              <Email
                control={control}
                email={email}
                setEmail={(email) => {
                  setEmail(email);
                }}
              />

              <CreatePassword
                control={control}
                password={password}
                confirmPassword={confirmPassword}
                setPassword={(password) => {
                  setPassword(password);
                }}
                setConfirmPassword={(password) => {
                  setConfirmPassword(password);
                }}
              />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "1em",
                  rowGap: "1em",
                }}
              >
                <ReCAPTCHA
                  sitekey="6Lch5OslAAAAAKJ9GKfbycd5Sk7obGDBq2OgB9V9"
                  onChange={onVerifyRecaptcha}
                />

                <Button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    opacity: _.isNil(recaptchaToken) ? ".5" : "1",
                    marginBottom: "1em",
                  }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled
                  onClick={handleSubmit(handleCredentialsSignup)}
                >
                  Sign Up
                </Button>
              </div>

              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <hr />
                  </td>
                  <td
                    style={{
                      width: "1px",
                      padding: "0 10px",
                      whiteSpace: "nowrap",
                      color: "gray",
                    }}
                  >
                    OR
                  </td>
                  <td>
                    <hr />
                  </td>
                </tr>
              </table>
              <div
                style={{
                  display: "flex",
                  borderRadius: "15px",
                  marginTop: "2%",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <GoogleLogin
                  onSuccess={(response) => responseGoogle(response)}
                  onError={(response) => console.log(response)}
                />
              </div>

              <div
                style={{
                  marginTop: "5%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Link
                  style={{ fontWeight: "bold", marginTop: "1em" }}
                  href="/login"
                  variant="body2"
                >
                  Already have an account? Sign in
                </Link>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Copyright />
                  <p>{`${moment().year()} Launch Site LLC`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupScreen;
