import { Checkbox, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    overflow: "hidden",
  },
  section: {
    marginBottom: theme.spacing(2),
  },

  paragraph: {
    marginBottom: theme.spacing(2),
    maxWidth: 800, // Set a suitable maximum width
  },

  contentBox: {
    backgroundColor: "#f7f7f7", // light gray background
    border: "1px solid #e0e0e0", // subtle border
    borderRadius: "8px", // rounded corners
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // subtle shadow
    padding: "20px", // inner spacing
    maxHeight: "calc(100% - 40px)", // taking into account the padding
    overflow: "scroll",
    height: "100%",
    width: "60%",
    display: "flex",
    justifyContent: "center",
  },
}));

const TermsAndConditions = (props) => {
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

  const { termsText, acceptedTerms, setAcceptedTerms } = props;
  const classes = useStyles();

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 5; // Adding tolerance
    if (bottom) {
      setHasScrolledToEnd(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: "2em",
      }}
    >
      <h1 style={{ marginBottom: "1em" }}>Terms & Conditions</h1>

      <div
        className={classes.contentBox}
        onScroll={handleScroll} // <-- Add this line
      >
        <div>
          <h3>1. Introduction:</h3>
          <p className={classes.paragraph}>
            Welcome to [Your Fintech App Name] ("App"). Your access and use of
            the App are governed by these terms and conditions ("Terms"). By
            accessing or using the App, you acknowledge and agree to abide by
            these Terms. If you do not agree with any aspect of these Terms,
            kindly refrain from accessing or using the App.
          </p>
          <h3> 2. Definitions: </h3>
          <p className={classes.paragraph}>
            "Merchant Cash Advance companies" refers to entities providing
            funding solutions to merchants seeking financial support to enhance
            their business operations. "Investors" refers to individuals or
            entities participating in syndicated deals through the App,
            contributing funds with the expectation of potential returns.
            "Users" encompasses all individuals interacting with the App,
            including both Merchants and Investors.
          </p>
          <h3>3. User Eligibility:</h3>
          <p className={classes.paragraph}>
            By accessing the App, you represent and warrant that you possess the
            legal capacity and are of legal age to enter into a binding
            agreement. You are responsible for complying with applicable laws
            and regulations.
          </p>
          <h3>4. Account Registration and Security:</h3>
          <p className={classes.paragraph}>
            To utilize certain features of the App, you may need to create an
            account. You are responsible for maintaining the confidentiality of
            your account credentials and for all activities conducted under your
            account.
          </p>
          <h3>5. Merchant Funding and Syndication:</h3>
          <p className={classes.paragraph}>
            The App facilitates funding for merchants by Merchant Cash Advance
            companies, allowing Merchants to receive financial backing for their
            business initiatives. Additionally, the App enables Investors to
            participate in syndicated deals, collectively investing in these
            funded ventures.
          </p>
          <h3>6. Deal Default and Risk Disclosure: </h3>
          <p className={classes.paragraph}>
            Investing inherently involves risk. In the unfortunate event of a
            deal default, it may result in the initiation of legal proceedings
            and potential credit implications. Users should be cognizant of
            these potential consequences and carefully assess their investment
            decisions.
          </p>
          <h3> 7. App Usage and Intellectual Property:</h3>
          <p className={classes.paragraph}>
            You are granted a limited, non-exclusive license to access and use
            the App's features for the purposes outlined in these Terms. All
            intellectual property rights pertaining to the App remain vested
            with our company.
          </p>
          <h3>8. Liability and Disclaimers:</h3>
          <p className={classes.paragraph}>
            The App is provided "as is" without any warranties or
            representations. We disclaim any liability for damages or losses
            arising from your use of the App. Users should exercise prudence
            when utilizing the App's features.
          </p>
          <h3>9. Indemnification:</h3>
          <p className={classes.paragraph}>
            By using the App, you agree to indemnify and hold harmless our
            company from any claims, damages, liabilities, or costs resulting
            from your use of the App.
          </p>
          <h3>10. Privacy and Data Security:</h3>
          <p className={classes.paragraph}>
            Your data privacy is of paramount importance to us. Your use of the
            App signifies your consent to adhere to our Privacy Policy, which
            outlines how we collect, use, and protect your personal information.
          </p>
          <h3> 11. Dwolla ACH Processing:</h3>
          <p className={classes.paragraph}>
            Utilizing Dwolla ACH processing services through the App is
            contingent upon your compliance with Dwolla's terms of use. You are
            advised to review Dwolla's terms and ensure adherence to their
            guidelines.
          </p>
          <h3> 12. Termination of Services: </h3>
          <p className={classes.paragraph}>
            We retain the prerogative to suspend or terminate access to the App
            at our discretion, especially in cases of non-compliance with these
            Terms or if deemed necessary for operational reasons.
          </p>
          <h3>13. Governing Law and Dispute Resolution: </h3>
          <p className={classes.paragraph}>
            These Terms are governed by the laws of [Your Jurisdiction]. Any
            disputes arising shall be resolved through arbitration in accordance
            with [Arbitration Association] rules.
          </p>
          <h3> 14. Modifications and Updates:</h3>
          <p className={classes.paragraph}>
            We reserve the right to modify or update these Terms periodically.
            Your continued use of the App indicates your acceptance of any such
            revisions.
          </p>
          <h3> 15. Contact Information: </h3>
          <p className={classes.paragraph}>
            For any inquiries or concerns related to these Terms, please contact
            us at [Contact Email or Phone Number].
          </p>
        </div>
      </div>

      <div style={{ marginTop: "2em" }}>
        {setAcceptedTerms && (
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hasScrolledToEnd}
                checked={acceptedTerms}
                onChange={() => {
                  setAcceptedTerms(!acceptedTerms);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="I have read and accept the Terms & Conditions"
          />
        )}
      </div>
    </div>
  );
};

export default TermsAndConditions;
