import { JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const createLinkToken = () => {
  return axiosInstance.post(
    `${ROOT_URL}api/plaid/link-token`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const exchangePublicTokenForAccessToken = (publicToken) => {
  return axiosInstance.post(
    `${ROOT_URL}api/plaid/access-token`,
    { publicToken },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getDwollaProcessorToken = (accessToken, accountId) => {
  return axiosInstance.post(
    `${ROOT_URL}api/plaid/processor-token`,
    { accessToken: accessToken, accountId: accountId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};
