import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DealsTable from "../../components/DealsTable/DealsTable";
import AddIcon from "@mui/icons-material/Add";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
} from "@mui/material";
import DealModal from "../../components/DealModal/DealModal";
import {
  getDealThunk,
  getMerchantDealsThunk,
} from "../../models/deal/dealThunks";
import _ from "lodash";
import {
  getMerchantKPIsAction,
  getMerchantKPIsThunk,
} from "../../models/merchant/merchantThunks";
import { getElapsedTimeString } from "../../shared/commonUtils";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PeopleIcon from "@mui/icons-material/People";
import KpiCard from "../../components/KpiCard/KpiCard";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const MerchantSelection = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const lender = useSelector((state) => state.lenders);
  const dealSlice = useSelector((state) => state.deals);
  const deals = dealSlice.deals;
  const merchants = useSelector((state) => state.merchants);
  const merchant = merchants?.merchant;
  const kpis = merchants.kpis;
  const elapsedTimeInBusiness = getElapsedTimeString(merchant?.foundedDate);

  const [addDeal, setAddDeal] = useState(false);
  //   const merchant = useSelector((state) => state.merchant);
  const { control, handleSubmit, errors, setValue } = useForm();
  const [merchantInfo, setMerchantInfo] = useState({
    address: {
      city: "",
      state: "",
      zip: "",
      place: "",
    },
  });

  console.log("the merchant is: ", merchant);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isNil(merchant) && !_.isNil(merchant.id)) {
      dispatch(getMerchantDealsThunk(merchant.id));
      dispatch(getMerchantKPIsThunk(merchant.id));
    }
  }, [merchant]);

  const kpiData = [
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalFundedAmountPaidInFull)
          ? `$${kpis.totalFundedAmountPaidInFull.toLocaleString()}`
          : "--"
      }`,
      subTitle: "Total Funded Amount (Completed)",
      IconComponent: MonetizationOnIcon,
      color: "#4CAF50", // green
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalFundedAmountInProgress)
          ? `$${kpis.totalFundedAmountInProgress.toLocaleString()}`
          : "--"
      }`,
      subTitle: "Total Funded Amount (Open)",
      IconComponent: HourglassEmptyIcon,
      color: "#FF9800", // orange
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.averageFactorRate)
          ? `${Number(kpis.averageFactorRate).toFixed(2)}`
          : "--"
      }`,
      subTitle: "Average Factor Rate (All time)",
      IconComponent: TrendingUpIcon,
      color: "#3f51b5", // blue
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalCompletedDeals)
          ? kpis.totalCompletedDeals
          : "--"
      }`,
      subTitle: "Completed Deals",
      IconComponent: AssignmentTurnedInIcon,
      color: "#4CAF50", // green
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalOpenDeals)
          ? kpis.totalOpenDeals
          : "--"
      }`,
      subTitle: "Open Deals",
      IconComponent: AutorenewIcon,
      color: "#FF9800", // orange
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalInvestors)
          ? kpis.totalInvestors
          : "--"
      }`,
      subTitle: "Total Investors",
      IconComponent: PeopleIcon,
      color: "#3f51b5", // blue
    },
  ];

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "3em", marginBottom: "3em" }}>
          <h1 style={{ margin: "0px" }}>{merchant?.businessName}</h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
            rowGap: "2em",
            columnGap: "2em",
            flexWrap: "wrap",
            marginBottom: "2em",
          }}
        >
          {kpiData.map((card) => (
            <KpiCard
              title={card.title}
              subTitle={card.subTitle}
              IconComponent={card.IconComponent}
              color={card.color}
            />
          ))}
        </div>

        <div style={{ width: "100%", marginTop: "2em" }}>
          {_.isNil(deals) || deals.length === 0 ? (
            <>
              <h2>Looks like you haven't created any deals yet.</h2>
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                onClick={() => {
                  setAddDeal(true);
                }}
              >
                Create a deal
              </Button>
            </>
          ) : (
            <DealsTable setAddDeal={setAddDeal} data={deals ? deals : []} />
          )}

          <DealModal open={addDeal} setOpen={setAddDeal} />
        </div>
      </div>
    </div>
  );
};

export default MerchantSelection;
