import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Button, CardContent, Card } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import InvestmentAccountSetup from "../InvestmentBusinessAccountSetup/InvestmentBusinessAccountSetup";
import FundingSourceCard from "../FundingSourceCard/FundingSourceCard";
import {
  getInvestmentBusinessAccountThunk,
  getInvestmentPersonalAccountThunk,
  removeInvestmentAccountThunk,
  removeInvestmentBusinessAccountThunk,
  removeInvestmentPersonalAccountThunk,
  unlinkFundingSourceThunk,
  unlinkInvestmentBusinessAccountFundingSourceThunk,
  unlinkInvestmentPersonalAccountFundingSourceThunk,
} from "../../models/account/accountActions";
import { useDispatch, useSelector } from "react-redux";

import InvestmentBusinessAccountSetup from "../InvestmentBusinessAccountSetup/InvestmentBusinessAccountSetup";
import InvestmentPersonalAccountSetup from "../InvestmentPersonalAccountSetup/InvestmentPersonalAccountSetup";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    height: "375px",
    width: "100%",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    height: "100%",
  },
  card: {
    width: "250px",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "0.3s",
    cursor: "pointer",
    padding: theme.spacing(2),
    backgroundColor: "#FFF",
    "&:hover": {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      transform: "translateY(-5px)",
    },
  },
  selected: {
    borderColor: "#1565C0",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  title: {
    fontWeight: 500,
  },
  icon: {
    fontSize: "40px",
    color: "#1565C0",
    marginBottom: theme.spacing(2),
  },
  viewContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  view: {
    textAlign: "center",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    position: "absolute",
    top: "10px",
    left: "10px",
  },
}));

const AccountCard = (props) => {
  const { title, details } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "1rem",
        alignItems: "flex-start",
      }}
    >
      <h4 style={{ marginBottom: "10px" }}>{title}</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "5px",
          margin: "0px",
        }}
      >
        {details?.businessName && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <strong
              style={{
                fontSize: "14px",
                marginTop: "0px",
                marginBottom: "0px",
                marginRight: "0.5rem",
              }}
            >
              Business Name:
            </strong>
            <p
              style={{ margin: "0px", fontSize: "14px" }}
            >{`${details.businessName}`}</p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <strong
            style={{
              fontSize: "14px",
              marginTop: "0px",
              marginBottom: "0px",
              marginRight: "0.5rem",
            }}
          >
            Name:
          </strong>
          <p
            style={{ margin: "0px", fontSize: "14px" }}
          >{`${details?.firstName} ${details?.lastName}`}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <strong
            style={{
              fontSize: "14px",

              marginTop: "0px",
              marginBottom: "0px",
              marginRight: "0.5rem",
            }}
          >
            Email:
          </strong>
          <p
            style={{ margin: "0px", fontSize: "14px" }}
          >{`${details?.email}`}</p>
        </div>
      </div>
    </div>
  );
};

const InvestorBusinessAccountCard = (props) => {
  const dispatch = useDispatch();

  const { investorBusinessAccount } = props;

  const {
    firstName,
    lastName,
    email,
    address1,
    city,
    state,
    postalCode,
    businessName,
    businessType,
  } = investorBusinessAccount;

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "400px",
      }}
    >
      <Card variant="outlined" sx={{ p: 2 }}>
        <IconButton
          onClick={() => {
            dispatch(removeInvestmentBusinessAccountThunk());
          }}
          aria-label="delete"
          size="small"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <CardContent>
          <AccountCard
            title="Business Account"
            details={investorBusinessAccount}
          />
          <h4>Funding Sources:</h4>
          {investorBusinessAccount?.fundingSources?.map((fundingSource) => (
            <FundingSourceCard
              extraLabel={`Business investment account: ${investorBusinessAccount?.businessName}`}
              unlinkFundingSourceThunk={
                unlinkInvestmentBusinessAccountFundingSourceThunk
              }
              org={investorBusinessAccount}
              fundingSource={fundingSource}
            />
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

const CreateInvestmentAccountCard = ({ title, icon: Icon, onViewChange }) => {
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState(false);

  const handleCardClick = () => {
    setIsSelected(true);
    onViewChange();
  };

  return (
    <div
      className={`${classes.card} ${isSelected ? classes.selected : ""}`}
      onClick={handleCardClick}
    >
      <Icon className={classes.icon} />
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

const InvestmentAccountOptionCardsBusiness = () => {
  const accountSlice = useSelector((state) => state.account);
  const classes = useStyles();
  const [currentView, setCurrentView] = useState("cards");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvestmentBusinessAccountThunk());
    dispatch(getInvestmentPersonalAccountThunk());
  }, []);

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  return (
    <div className={classes.container}>
      {currentView === "cards" ? (
        <div className={classes.cardContainer}>
          {accountSlice.account.investorBusinessAccount ? (
            <div style={{ width: "100%" }}>
              <InvestorBusinessAccountCard
                investorBusinessAccount={
                  accountSlice?.account?.investorBusinessAccount
                }
              />
            </div>
          ) : (
            <CreateInvestmentAccountCard
              title="Create Business Investment Account"
              icon={BusinessCenterIcon}
              onViewChange={() => {
                handleViewChange("createBusiness");
              }}
            />
          )}
        </div>
      ) : (
        <div className={classes.viewContainer}>
          <div className={classes.view}>
            <Button
              className={classes.backButton}
              onClick={() => {
                handleViewChange("cards");
              }}
            >
              Cancel
            </Button>
          </div>
          <div style={{ height: "100%", marginTop: "1em" }}>
            <InvestmentBusinessAccountSetup
              handleViewChange={handleViewChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestmentAccountOptionCardsBusiness;
