import { createSlice } from "@reduxjs/toolkit";

interface DwollaAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

interface DwollaCustomer {
  firstName: string;
  lastName: string;
  ssn: string;
  address: DwollaAddress;
}

interface DwollaState {
  customer: DwollaCustomer | null;
  owners: any[];
  customers: DwollaCustomer[];
  businessClassifications: any[];
  loading: boolean;
  certificationStatus: any;
  transactions: any[];
}

const initialState: DwollaState = {
  customer: null,
  loading: false,
  customers: [],
  owners: [],
  certificationStatus: null,
  businessClassifications: [],
  transactions: [],
};
const dwollaSlice = createSlice({
  name: "dwolla",
  initialState,
  reducers: {
    createPersonalVerifiedCustomerSuccess(state, action) {
      state.customer = action.payload;
      state.loading = false;
      state.owners = [];
    },
    createBusinessVerifiedCustomerSolePropSuccess(state, action) {
      state.customer = action.payload;
      state.loading = false;
      state.owners = [];
    },
    createBusinessVerifiedCustomerLLCSuccess(state, action) {
      state.customer = action.payload;
      state.loading = false;
      state.owners = [];
    },
    createCustomerSuccess(state, action) {
      console.log("action: ", action);
      state.customer = action.payload;
      state.loading = false;
      state.owners = [];
    },
    addBeneficialOwnerSuccess(state, action) {
      state.owners = [...state.owners, action.payload];
      state.loading = false;
    },
    certifyBeneficialOwnershipSuccess(state, action) {
      state.loading = false;
      state.certificationStatus = action.payload;
    },
    getCustomerCertificationStatusSuccess(state, action) {
      state.certificationStatus = action.payload;
      state.loading = false;
    },
    removeBeneficialOwnerSuccess(state, action) {
      state.owners = state.owners.filter((owner) => owner.id != action.payload);
      state.loading = false;
    },
    createPlaidFundingSourceSuccess(state, action) {
      state.loading = false;
    },
    getCustomerSuccess(state, action) {
      state.customer = action.payload;
      state.loading = false;
      state.owners = [];
    },
    getTransactionsSuccess(state, action) {
      state.transactions = action.payload;
      state.loading = false;
    },
    getBusinessClassificationsSuccess(state, action) {
      state.businessClassifications = action.payload;
      state.loading = false;
    },
    loading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  createPersonalVerifiedCustomerSuccess,
  createBusinessVerifiedCustomerSolePropSuccess,
  certifyBeneficialOwnershipSuccess,
  createBusinessVerifiedCustomerLLCSuccess,
  createPlaidFundingSourceSuccess,
  getCustomerCertificationStatusSuccess,
  addBeneficialOwnerSuccess,
  removeBeneficialOwnerSuccess,
  createCustomerSuccess,
  getCustomerSuccess,
  getTransactionsSuccess,
  getBusinessClassificationsSuccess,
  loading,
} = dwollaSlice.actions;

export default dwollaSlice.reducer;
