import _ from "lodash";
import moment from "moment";
import colors from "../../config/colors";

export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILED = "GET_EVENTS_FAILED";

export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILED = "GET_EVENT_FAILED";

export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_FAILED = "EDIT_EVENT_SUCCESS";

export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED";

export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILED = "CREATE_EVENT_FAILED";

export const SET_SELECTED_CALENDAR_DAY = "SET_SELECTED_CALENDAR_DAY";
export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT";

export const ACTIVATE_SUBSCRIPTION_SUCCESS = "ACTIVATE_SUBSCRIPTION_SUCCESS";
export const ACTIVATE_SUBSCRIPTION_FAILED = "ACTIVATE_SUBSCRIPTION_FAILED";

export const DEACTIVATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const DEACTIVATE_SUBSCRIPTION_FAILED = "CREATE_SUBSCRIPTION_FAILED";

export const GET_SUBSCRIPTION_PREFERENCE_SUCCESS =
  "GET_SUBSCRIPTION_PREFERENCE_SUCCESS";

export const GET_SUBSCRIPTION_PREFERENCE_FAILED =
  "GET_SUBSCRIPTION_PREFERENCE_FAILED";

export const GET_SUBSCRIBERS_SUCCESS = "GET_SUBSCRIBERS_SUCCESS";
export const GET_SUBSCRIBERS_FAILED = "GET_SUBSCRIBERS_FAILED";

export const FILTER_EVENTS_BY_TEAMS = "FILTER_EVENTS_BY_TEAMS";
export const FILTER_EVENTS_BY_TEAMS_FAILED = "FILTER_EVENTS_BY_TEAMS_FAILED";

export const RESET_EVENTS = "RESET_EVENTS";

export enum CalendarEventsSource {
  SELF = "SELF",
  TEAM = "TEAM",
  CONNECTION = "CONNECTION",
}

export function resetEvents() {
  console.log("resetting events");
  return {
    type: RESET_EVENTS,
  };
}

export function setSelectedEvent(selectedEvent: CustomEvent) {
  console.log("set selected calendar event", selectedEvent);
  return {
    type: SET_SELECTED_EVENT,
    selectedEvent: selectedEvent,
  };
}

export function filterEventsByTeams(filters: any) {
  console.log("filter events by teams");
  return {
    type: FILTER_EVENTS_BY_TEAMS,
    filters: filters,
  };
}

export function filterEventsByTeamsFailed(error: string) {
  console.log("filter events by teams failed");

  return {
    type: FILTER_EVENTS_BY_TEAMS_FAILED,
    error: error,
  };
}

export function setSelectedCalendarDay(day: Date) {
  console.log("set selected calendar day", day);
  return {
    type: SET_SELECTED_CALENDAR_DAY,
    selectedDay: day,
  };
}

export function getEventsFailed(error: string) {
  return {
    type: GET_EVENTS_SUCCESS,
    error: error,
  };
}

export function getEventsSuccess(events: CustomEvent[]) {
  console.log("events: ", events);
  return {
    type: GET_EVENTS_SUCCESS,
    events: events,
  };
}

export function getSubscriptionPreferenceSuccess(subscriptionPreference: any) {
  console.log("subscription preference: ", subscriptionPreference);
  return {
    type: GET_SUBSCRIPTION_PREFERENCE_SUCCESS,
    subscriptionPreference: subscriptionPreference,
  };
}

export function getSubscriptionPreferenceFailed(error: string) {
  return {
    type: GET_SUBSCRIPTION_PREFERENCE_FAILED,
    error: error,
  };
}

export function getEventFailed(error: string) {
  return {
    type: GET_EVENT_FAILED,
    error: error,
  };
}

export function getEventSuccess(selectedEvent: CustomEvent) {
  console.log("selected event: ", selectedEvent);
  return {
    type: GET_EVENT_SUCCESS,
    selectedEvent: selectedEvent,
  };
}

export function createEventSuccess(createdEvent: CustomEvent) {
  console.log("createdEvent param: ", createdEvent);
  return {
    type: CREATE_EVENT_SUCCESS,
    createdEvent: createdEvent,
  };
}

export function createEventFailed(error: string) {
  console.log("error: ", error);
  return {
    type: CREATE_EVENT_FAILED,
    error: error,
  };
}

export function editEventSuccess(editedEvent: CustomEvent) {
  console.log("createdEvent param: ", editedEvent);
  return {
    type: EDIT_EVENT_SUCCESS,
    editedEvent: editedEvent,
  };
}

export function editEventFailed(error: string) {
  console.log("error: ", error);
  return {
    type: EDIT_EVENT_FAILED,
    error: error,
  };
}

export function deleteEventSuccess(id: number) {
  console.log("delete event success: ", id);
  return {
    type: DELETE_EVENT_SUCCESS,
    id: id,
  };
}

export function deleteEventFailed(error: string) {
  console.log("delete event error: ", error);
  return {
    type: DELETE_EVENT_FAILED,
    error: error,
  };
}

export function getSubscribersSuccess(subscribers: any) {
  console.log("subscribers: ", subscribers);
  return {
    type: GET_SUBSCRIBERS_SUCCESS,
    subscribers: subscribers,
  };
}

export function getSubscribersFailed(error: string) {
  console.log("error: ", error);
  return {
    type: GET_SUBSCRIBERS_FAILED,
    error: error,
  };
}

export function activateSubscriptionSuccess(calendarSubscription: any) {
  console.log("created subscription: ", calendarSubscription);
  return {
    type: ACTIVATE_SUBSCRIPTION_SUCCESS,
    calendarSubscription: calendarSubscription,
  };
}

export function activateSubscriptionFailed(error: string) {
  console.log("error: ", error);
  return {
    type: ACTIVATE_SUBSCRIPTION_FAILED,
    error: error,
  };
}

export function deactivateSubscriptionSuccess(calendarSubscription: any) {
  console.log("deactivate subscription: ", calendarSubscription);
  return {
    type: DEACTIVATE_SUBSCRIPTION_SUCCESS,
    calendarSubscription: calendarSubscription,
  };
}

export function deactivateSubscriptionFailed(error: string) {
  console.log("error: ", error);
  return {
    type: DEACTIVATE_SUBSCRIPTION_FAILED,
    error: error,
  };
}

function updateObjectInArray(array: any[], idx: number, data: any) {
  return array.map((item, index) => {
    if (index !== idx) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...data,
    };
  });
}

export interface CustomEvent {
  id: number;
  title: string;
  location: string;
  notes: string;
  startDateTime: any;
  endDateTime: any;
  createdDate: any;
  createdBy: string;
  lastModifiedBy: string;
}

interface CalendarState {
  events: CustomEvent[];
  originalEvents: CustomEvent[];
  groupedEvents: any;
  markedDays: any;
  teams: any;
  createdEvent: CustomEvent | null;
  error: string | null;
  selectedDay: Date | null;
  calendarSubscription: any;
  subscriptionPreference: any;
  mySubs: any;
  selectedEvent: any;
}

const initialState: CalendarState = {
  error: null,
  events: [],
  originalEvents: [],
  groupedEvents: {},
  markedDays: null,
  createdEvent: null,
  selectedDay: moment().toDate(),
  calendarSubscription: null,
  subscriptionPreference: null,
  mySubs: null,
  selectedEvent: null,
  teams: {},
};

const groupEvents = (events: CustomEvent[]) => {
  let groupedEvents = _.groupBy(events, function (event) {
    console.log("eventStartDateTime: ", event.startDateTime);

    const eventStart = moment(event.startDateTime).toDate();
    const eventStartString = moment(eventStart).format("YYYY-MM-DD");

    return eventStartString.split("T")[0];
  });

  // console.log("grouped events: ", groupedEvents);

  let marked = {};
  let teams = {};

  const keys = Object.keys(groupedEvents);

  // keys.forEach((key) => {
  //   marked[key] = { marked: true };
  // });

  keys.forEach((key) => {
    let groupedByTeam = _.groupBy(groupedEvents[key], function (event) {
      if (!_.isNil(event?.team)) {
        return event.team.id;
      } else {
        // use -1 to indicate self events, e.g my events that are not from a team
        return "-1";
      }
    });

    teams = { ...teams, ...groupedByTeam };

    console.log("fuckin teams: ", teams);

    const teamColors = Object.keys(groupedByTeam).map((teamId) => {
      console.log("the team id: ", teamId);
      if (!_.isNil(teamId) && teamId != "-1") {
        return groupedByTeam[teamId][0].team.color;
      } else {
        return colors.msftLightThemeGrayBorder;
      }
    });

    marked[key] = { marked: true, colors: [...teamColors] };
  });

  console.log("marked events: ", marked);

  return { groupedEvents: groupedEvents, markedDays: marked, teams: teams };
};
let res;

export default (state = initialState, action: any): UserState => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      console.log("get events success");
      const { events } = action;

      res = groupEvents(events);

      console.log("grouped events: ", res.groupedEvents);
      console.log("marked days ", res.markedDays);

      return {
        ...state,
        originalEvents: events,
        events: events,
        groupedEvents: res.groupedEvents,
        markedDays: res.markedDays,
        teams: res.teams,
      };

    case GET_EVENTS_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case GET_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        mySubs: action.subscribers,
      };

    case GET_SUBSCRIBERS_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case GET_SUBSCRIPTION_PREFERENCE_SUCCESS:
      return {
        ...state,
        subscriptionPreference: action.subscriptionPreference,
      };

    case GET_SUBSCRIPTION_PREFERENCE_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case SET_SELECTED_CALENDAR_DAY:
      return {
        ...state,
        selectedDay: action.selectedDay,
      };

    case SET_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.selectedEvent,
      };

    case ACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        calendarSubscription: action.calendarSubscription,
      };

    case ACTIVATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case DEACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        calendarSubscription: action.calendarSubscription,
      };

    case DEACTIVATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case GET_EVENT_SUCCESS:
      console.log("get event success");
      const { selectedEvent } = action;
      return {
        ...state,
        selectedEvent: selectedEvent,
      };

    case GET_EVENT_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case RESET_EVENTS:
      return {
        ...state,
        events: [],
        markedDays: {},
      };

    case DELETE_EVENT_SUCCESS:
      let evs = [...state.events];
      _.remove(evs, (evnt) => evnt.id === action.id);
      res = groupEvents(evs);

      return {
        ...state,
        originalEvents: evs,
        events: evs,
        selectedEvent: null,
        groupedEvents: res.groupedEvents,
        markedDays: res.markedDays,
        teams: res.teams,
      };

    case DELETE_EVENT_FAILED:
      console.log("delete event failed");

      return {
        ...state,
        error: action.error,
      };

    case EDIT_EVENT_SUCCESS:
      console.log("inside of edit event success");
      const { editedEvent } = action;
      console.log("edit event object: ", editedEvent);

      let idx = state.events.findIndex((evnt) => evnt.id === editedEvent.id);
      let evnts = updateObjectInArray(state.events, idx, editedEvent);

      // console.log("new events: ", evnts);

      res = groupEvents(evnts);

      return {
        ...state,
        selectedEvent: editedEvent,
        createdEvent: editedEvent,
        originalEvents: evnts,
        events: evnts,
        groupedEvents: res.groupedEvents,
        markedDays: res.markedDays,
        teams: res.teams,
      };

    case FILTER_EVENTS_BY_TEAMS:
      console.log("filter events by teams");

      const { filters } = action;

      let teamIds: number[] = [];

      Object.keys(filters).forEach((key) => {
        if (filters[key].active) {
          teamIds = [...teamIds, filters[key].teamId];
        }
      });

      console.log("filter by the following ids: ", teamIds);

      let filteredEvents = state.originalEvents.filter((event) => {
        // if this event comes from a team, check the team id
        if (event?.team) {
          if (teamIds.includes(event.team.id)) {
            return true;
          }
        } else {
          // if this was a self event, then we only include it when the filters has -1, which is the fake id for self events
          if (teamIds.includes(-1)) {
            return true;
          }
        }
      });

      console.log("filtered events: ", filteredEvents);

      res = groupEvents(filteredEvents);

      return {
        ...state,
        events: filteredEvents,
        groupedEvents: res.groupedEvents,
        markedDays: res.markedDays,
      };

    case EDIT_EVENT_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case CREATE_EVENT_SUCCESS:
      console.log("inside of create event success");
      const { createdEvent } = action;

      const newEvents = [...state.events, createdEvent];

      console.log("new events: ", newEvents);
      res = groupEvents(newEvents);

      return {
        ...state,
        createdEvent: createdEvent,
        originalEvents: newEvents,
        events: newEvents,
        groupedEvents: res.groupedEvents,
        markedDays: res.markedDays,
        teams: res.teams,
      };

    case CREATE_EVENT_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case FILTER_EVENTS_BY_TEAMS_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
