import React from "react";
import { Alert, AlertTitle, Button } from "@mui/material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { submitDealForApprovalThunk } from "../../models/deal/dealThunks";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const DealStatus = (props) => {
  const dealSlice = useSelector((state) => state.deals);
  const deal = dealSlice.deal;
  const dispatch = useDispatch();

  console.log("deal status deal: ", deal);

  const getAlert = () => {
    const status = deal?.dealStatus;
    let icon,
      color,
      backgroundColor,
      description = "";

    switch (status) {
      case "Needs Action":
        icon = <ErrorOutlineIcon />;
        color = "#b71c1c";
        backgroundColor = "#ffebee"; // Light red
        if (_.isNil(deal.investors) || deal.investors.length === 0) {
          description = "You must add investors to the deal";
        } else {
          const contributionSum = _.sumBy(
            deal.investors,
            "contributionPercentage"
          );
          if (contributionSum !== 100) {
            description = "The investor contributions must sum to 100%";
          }
        }
        break;
      case "In Progress":
        icon = <PlayCircleOutlineIcon />;
        color = "#4CAF50";
        backgroundColor = "#e8f5e9"; // Light green
        break;
      case "Paid In Full":
        icon = null; // Can choose another icon if needed
        color = "green";
        backgroundColor = "#e8f5e9"; // Light green
        break;
      case "Defaulted":
      case "Terminated":
        icon = <HighlightOffIcon />;
        color = "#b71c1c";
        backgroundColor = "#ffebee"; // Light red
        break;
      case "Scheduled":
      case "Pending Approval":
      case "Ready for Approval":
        icon = <PlayCircleOutlineIcon />;
        color = "#4CAF50";
        backgroundColor = "#e8f5e9"; // Light green
        break;
      case "Paused":
        icon = <PauseCircleOutlineIcon />;
        color = "#FF9800";
        backgroundColor = "#FFF3E0"; // Light orange
        break;
      default:
        icon = null;
        color = "gray";
        backgroundColor = "#f5f5f5"; // Light gray
        break;
    }

    return {
      color,
      backgroundColor,
      status,
      description,
      icon,
    };
  };

  const alert = getAlert();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "2em",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            borderRadius: "8px",
            backgroundColor: alert.backgroundColor,
            color: alert.color,
            gap: "8px",
          }}
        >
          {alert.icon}
          <div>{alert.status}</div>
          <div>{alert.description}</div>
        </div>
        <div>
          {alert.status === "Ready for Approval" && (
            <Button
              onClick={() => {
                dispatch(submitDealForApprovalThunk(deal.id));
              }}
            >
              Submit For Approval
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DealStatus;
