export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const STATUS_CHECK_SUCCESS = "STATUS_CHECK_SUCCESS";

export const getTransactionsSuccess = (
  transactions: FinancialTransaction[]
) => ({
  type: GET_TRANSACTIONS_SUCCESS,
  transactions: transactions,
});

export const usioStatusCheckSuccess = () => ({
  type: STATUS_CHECK_SUCCESS,
});

export const usioVerifyAccountSuccess = () => ({
  type: VERIFY_ACCOUNT_SUCCESS,
});

interface FinancialTransaction {
  ACHOpt1: string;
  ACHOpt2: string;
  AVSCode: string;
  AccountCode1: string;
  AccountCode2: string;
  AccountCode3: string;
  AccountMask: string;
  AccountName: string;
  AccountType: string;
  AdditionalSearch1: string;
  Address1: string;
  Address2: string;
  Amount: string;
  AuthCode: string;
  BatchNumber: string;
  CVVResp: string;
  City: string;
  Confirmation: string;
  ConvFee: 0.0;
  EmailAddress: string;
  ErrorMessage: string;
  FinalStatus: string;
  FirstName: string;
  LastName: string;
  MerchantPayAccount: string;
  PaymentOrigination: string;
  PaymentType: string;
  RefConfirmation: string;
  RepeatConfirmation: string;
  ResponseCode: string;
  ReturnDate: string;
  SimpResp: string;
  State: string;
  SubmitDate: string;
  UserID: string;
  Zip: string;
  HPPValues: any[];
}

interface FinancialState {
  financialTransactions: FinancialTransaction[];
}

const initialState: FinancialState = {
  financialTransactions: [],
};

export default (state = initialState, action: any): FinancialState => {
  switch (action.type) {
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        financialTransactions: action.transactions,
      };

    default:
      return state;
  }
};
