import React from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { CircularProgress, createTheme, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ThemeProvider } from "@emotion/react";
import moment from "moment";
import { getDealThunk } from "../../models/deal/dealThunks";
import { useDispatch, useSelector } from "react-redux";
import ProtectedComponent from "../ProtectedComponent/ProtectedComponent";
import { ROLE_LENDER_ADMIN } from "../../config/contants";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import _ from "lodash";
import { parse } from "libphonenumber-js";

const StatusPill = ({ status, icon, color, backgroundColor }) => {
  const styles = {
    display: "flex",
    alignItems: "center",
    backgroundColor,
    color,
    borderRadius: "12px",
    padding: "4px 8px",
    fontSize: "12px",
  };

  return (
    <div style={styles}>
      {icon}
      <span style={{ marginLeft: "4px" }}>{status}</span>
    </div>
  );
};

const getStatusStyling = (status) => {
  let icon;
  let color;
  let backgroundColor;
  let description;

  switch (status) {
    case "Needs Action":
      icon = <ErrorOutlineIcon />;
      color = "#b71c1c";
      backgroundColor = "#ffebee";
      break;
    case "In Progress":
      icon = <PlayCircleOutlineIcon />;
      color = "#4CAF50";
      backgroundColor = "#e8f5e9";
      break;
    case "Paid In Full":
      icon = null;
      color = "green";
      backgroundColor = "#e8f5e9";
      break;
    case "Defaulted":
    case "Terminated":
      icon = <HighlightOffIcon />;
      color = "#b71c1c";
      backgroundColor = "#ffebee";
      break;
    case "Scheduled":
    case "Pending Approval":
    case "Ready for Approval":
      icon = <PlayCircleOutlineIcon />;
      color = "#4CAF50";
      backgroundColor = "#e8f5e9";
      break;
    case "Paused":
      icon = <PauseCircleOutlineIcon />;
      color = "#FF9800";
      backgroundColor = "#FFF3E0";
      break;
    default:
      icon = null;
      color = "gray";
      backgroundColor = "#f5f5f5";
      break;
  }

  return { icon, color, backgroundColor, description };
};

const DealsTable = (props) => {
  const { data, setAddDeal, opportunities } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const merchants = useSelector((state) => state.merchants);
  const loading = useSelector((state) => state.ui).loading;
  let title =
    opportunities == true ? "Investment Opportunities" : "Merchant Deals";

  const formatCustomDateWithStatus = (date) => {
    if (!date) {
      return "TBD";
    }

    const now = moment();
    const inputDate = moment(date);

    if (inputDate.isSame(now, "day")) {
      return `today @ ${inputDate.format("h:mma")}`;
    } else if (inputDate.isSame(now.clone().subtract(1, "day"), "day")) {
      return `yesterday @ ${inputDate.format("h:mma")}`;
    } else {
      return inputDate.format("M/DD/YYYY @ h:mma");
    }
  };

  const normalized = data.map((curr) => {
    return {
      ...curr,
      businessName: merchants?.merchant?.businessName,

      createdDate: formatCustomDateWithStatus(curr.createdDate),
      fundedDate: formatCustomDateWithStatus(curr.fundedDate),
    };
  });

  const options = {
    filterType: "checkbox",
    download: false, // Set to false to remove the download option
    print: false, // Set to false to remove the print option
    viewColumns: false, // Disable view columns option
    filter: false, // Disable filter table option

    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Sorry, no matching records found."
        ),
      },
    },

    onRowClick: (rowData, rowMeta) => {
      console.log("fuck");
      console.log("row meta: ", rowMeta);
      console.log("row data: ", rowData);
      dispatch(getDealThunk(data[rowMeta.dataIndex].id));

      console.log("opportunities: ", opportunities);
      opportunities === true
        ? navigate("investment-opportunity")
        : navigate("funded-deal");
    },

    customToolbar: () => {
      return (
        <ProtectedComponent roles={[ROLE_LENDER_ADMIN]}>
          <IconButton
            onClick={() => {
              setAddDeal(true);
            }}
            style={{ order: -1 }}
          >
            <AddIcon />
          </IconButton>
        </ProtectedComponent>
      );
    },
    selectableRows: false,
  };

  const columns = [
    {
      name: "businessName",
      label: "Business Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdDate",
      label: "Created Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fundedDate",
      label: "Date Funded",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fundedAmount",
      label: "Funded Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const amount = parseFloat(data[dataIndex].fundedAmount);
          return (
            <div
              style={{
                color: "black",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              $
              {isNaN(amount)
                ? "N/A"
                : amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          );
        },
      },
    },

    {
      name: "factorRate",
      label: "Factor Rate",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "totalPayback",
      label: "Total Payback",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          console.log("dataIndex: ", data[dataIndex]);
          const curr = data[dataIndex];

          let totalPayback = Number(
            curr.fundedAmount * curr.factorRate
          ).toFixed(2);

          totalPayback = parseFloat(totalPayback);

          return (
            <div
              style={{
                color: "#4CAF50",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              $
              {isNaN(totalPayback)
                ? "N/A"
                : totalPayback.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          );
        },
      },
    },
    {
      name: "paymentFrequency",
      label: "Payment Frequency",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "paymentAmount",
      label: "Payment Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          console.log("dataIndex: ", data[dataIndex]);
          const curr = data[dataIndex];

          let totalPayback = Number(
            curr.fundedAmount * curr.factorRate
          ).toFixed(2);

          let paymentAmount = Number(
            totalPayback / curr.numberOfPayments
          ).toFixed(2);

          paymentAmount = parseFloat(paymentAmount);

          return (
            <div
              style={{
                color: "#4CAF50",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              $
              {isNaN(paymentAmount)
                ? "N/A"
                : paymentAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          );
        },
      },
    },
    {
      name: "dealStatus",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const deal = data[dataIndex];
          const { icon, color, backgroundColor } = getStatusStyling(
            deal.dealStatus
          );

          return (
            <StatusPill
              status={deal.dealStatus}
              icon={icon}
              color={color}
              backgroundColor={backgroundColor}
            />
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      style={{ cursor: "pointer" }}
      title={title ? title : "Merchant Deals"}
      data={normalized && normalized.length > 0 ? normalized : []}
      columns={columns}
      options={options}
    />
  );
};

export default DealsTable;
