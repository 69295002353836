import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { useForm } from "react-hook-form";

import { Paper } from "@mui/material";

import {
  getLenderUsersThunk,
  getOwnedLendersThunk,
} from "../../models/lender/lenderThunks";
import LendersTable from "../../components/LendersTable/LendersTable";
import LenderModal from "../../components/LenderModal/LenderModal";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import KpiCard from "../../components/KpiCard/KpiCard";
import { getLenderUsersSuccess } from "../../models/lender/lenderReducer";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const lenderSlice = useSelector((state) => state.lenders);

  const [addLender, setAddLender] = useState(false);

  const dispatch = useDispatch();

  const kpiData = [
    {
      title: lenderSlice.ownedLenders
        ? lenderSlice.ownedLenders.length.toString()
        : "--",
      subTitle: "Lenders Owned",
      IconComponent: GroupIcon,
      color: "#673AB7",
    },
  ];

  useEffect(() => {
    dispatch(getOwnedLendersThunk());
    dispatch(getLenderUsersThunk(lenderSlice?.lender?.id));
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "3em", marginBottom: "3em" }}>
          <SettingsIcon style={{ fontSize: "3rem", color: "#3f51b5" }} />
          <h1 style={{ margin: "0px" }}>Admin Dashboard</h1>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
            rowGap: "2em",
            columnGap: "2em",
            flexWrap: "wrap",
            marginBottom: "2em",
          }}
        >
          {kpiData.map((card) => (
            <KpiCard
              title={card.title}
              subTitle={card.subTitle}
              IconComponent={card.IconComponent}
              color={card.color}
            />
          ))}
        </div>
        <div style={{ width: "100%" }}>
          <LendersTable
            setAddLender={setAddLender}
            data={lenderSlice?.ownedLenders}
          />
          <LenderModal open={addLender} setOpen={setAddLender} />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
