import axios from "axios";
import { logoutAction, refreshToken } from "./account/accountActions";
import { persistor } from "./store";
import { showRefreshModal } from "./uiReducer";
import { JWT } from "../config/contants";

const UNAUTHORIZED = 401;

let axiosInstance = axios.create({
  withCredentials: true,
});

export const setupInterceptors = (store) => {
  const { dispatch } = store;

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { status } = error.response;
      const originalRequest = error.config;

      if (status === UNAUTHORIZED) {
        if (
          originalRequest.retry != true &&
          !originalRequest.url.includes("/api/refresh")
        ) {
          originalRequest.retry = true;
          await dispatch(refreshToken());
          const newJwt = sessionStorage.getItem(JWT);
          originalRequest.headers["Authorization"] = newJwt;

          return axiosInstance(originalRequest);
        } else {
          persistor.purge();
          dispatch(logoutAction());
          return new Promise(() => {});
        }
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
