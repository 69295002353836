import { Button, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FundingSourceSelect } from "../Form/FinancialForm";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountAction,
  updateFundsFlowConfigThunk,
} from "../../models/account/accountActions";

const InvestorFundsFlowConfig = (props) => {
  const { control, handleSubmit, reset, setValue } = useForm();

  const [fundingSourceOptions, setFundingSourceOptions] = useState([]);
  const [incomingFundingSource, setIncomingFundingSource] = useState(null);
  const [outgoingFundingSource, setOutgoingFundingSource] = useState(null);

  const accountSlice = useSelector((state) => state.account);
  const account = accountSlice.account;

  const dispatch = useDispatch();

  const handleUpdateFundsFlowConfig = () => {
    dispatch(
      updateFundsFlowConfigThunk({
        outgoingFundingSourceId: outgoingFundingSource.dwollaFundingSourceId,
        incomingFundingSourceId: incomingFundingSource.dwollaFundingSourceId,
      })
    );
  };

  useEffect(() => {
    const personalFundingSources =
      account?.investorPersonalAccount?.dwollaFundingSources ||
      account?.investorPersonalAccount?.fundingSources ||
      [];
    const businessFundingSources =
      account?.investorBusinessAccount?.dwollaFundingSources ||
      account?.investorBusinessAccount?.fundingSources ||
      [];

    const allFundingSources = [
      ...personalFundingSources,
      ...businessFundingSources,
    ];

    let incoming = allFundingSources.find(
      (fundingSource) =>
        fundingSource.dwollaFundingSourceId ===
        account.receivePaymentsFundingSourceId
    );

    let outgoing = allFundingSources.find(
      (fundingSource) =>
        fundingSource.dwollaFundingSourceId ===
        account.sendPaymentsFundingSourceId
    );

    setIncomingFundingSource(incoming);
    setOutgoingFundingSource(outgoing);
    setFundingSourceOptions(allFundingSources);

    console.log("account: ", account);
    console.log("all funding sources: ", allFundingSources);
    console.log("personal sources: ", personalFundingSources);
    console.log("personal account: ", account.investorPersonalAccount);
    console.log("set incoming as: ", incoming);
    console.log("set outgoing as: ", outgoing);
  }, [account.investorPersonalAccount, account.investorBusinessAccount]);

  useEffect(() => {
    dispatch(getAccountAction());
  }, []);

  return (
    <div
      style={{
        display: "flex",
        marginTop: "2em",
        flexDirection: "column",
        rowGap: "2em",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={
              "This the investment account that will funds will be drawn from to syndicate deals"
            }
            arrow
          >
            <HelpOutlineIcon />
          </Tooltip>
          <h5 style={{ margin: "0px" }}>Outgoing Funds</h5>
        </div>

        <FundingSourceSelect
          control={control}
          setValue={setValue}
          fundingSourceOptions={fundingSourceOptions}
          fundingSource={outgoingFundingSource}
          setFundingSource={setOutgoingFundingSource}
        />
      </div>

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={
              "This is the investment account that will be credited for syndication"
            }
            arrow
          >
            <HelpOutlineIcon />
          </Tooltip>
          <h5 style={{ margin: "0px" }}>Incoming Payments</h5>
        </div>

        <FundingSourceSelect
          control={control}
          setValue={setValue}
          fundingSourceOptions={fundingSourceOptions}
          fundingSource={incomingFundingSource}
          setFundingSource={setIncomingFundingSource}
        />
      </div>
      <Button
        style={{ width: "100px" }}
        variant="outlined"
        onClick={handleSubmit(handleUpdateFundsFlowConfig)}
      >
        Save
      </Button>
    </div>
  );
};

export default InvestorFundsFlowConfig;
