import { Autocomplete, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLendersThunk,
  getOwnedLendersThunk,
} from "../../models/lender/lenderThunks";
import { setSelectedLender } from "../../models/lender/lenderReducer";

const LenderSelection = () => {
  const lender = useSelector((state) => state.lenders);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("the org.org = ", lender.lender);

    dispatch(getLendersThunk());
  }, []);

  console.log("lenders: ", lender.lenders);

  return (
    <Autocomplete
      options={lender.lenders}
      getOptionLabel={(option) => (option ? option.businessName : "")}
      defaultValue={lender?.lender ? lender.lender : ""}
      value={lender?.lender ? lender.lender : ""}
      onChange={(event, newValue) => {
        console.log("the newValue: ", newValue);
        dispatch(setSelectedLender(newValue));
      }}
      autoSelect
      renderInput={(params) => (
        <TextField {...params} label="Lender" variant="standard" />
      )}
    />
  );
};

export default LenderSelection;
