import _ from "lodash";

export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAILED = "GET_TEAM_FAILED";

export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAILED = "GET_TEAMS_FAILED";

export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAILED = "CREATE_TEAM_FAILED";

export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAILED = "UPDATE_TEAM_FAILED";

export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAILED = "DELETE_TEAM_FAILED";

export const REMOVE_MEMBER_SUCCESS = "REMOVE_MEMBER_SUCCESS";
export const REMOVE_MEMBER_FAILED = "REMOVE_MEMBER_FAILED";

export const SEND_TEAM_INVITE_SUCCESS = "SEND_TEAM_INVITE_SUCCESS";
export const SEND_TEAM_INVITE_FAILED = "SEND_TEAM_INVITE_FAILED";

export const SET_SELECTED_TEAM_MEMBER = "SET_SELECTED_TEAM_MEMBER";
export const UPDATE_TEAM_MEMBER_PERMISSION_SUCCESS =
  "UPDATE_TEAM_MEMBER_PERMISSION_SUCCESS";
export const UPDATE_TEAM_MEMBER_PERMISSION_FAILED =
  "UPDATE_TEAM_MEMBER_PERMISSION_FAILED";

export const GET_CURRENT_ACCOUNT_TEAM_USER_SUCCESS =
  "GET_CURRENT_ACCOUNT_TEAM_USER_SUCCESS";

export const GET_CURRENT_ACCOUNT_TEAM_USER_FAILED =
  "GET_CURRENT_ACCOUNT_TEAM_USER_FAILED";

export const SET_SELECTED_TEAM = "SET_SELECTED_TEAM";

interface Team {
  id: number | undefined;
  teamUsers: any | undefined;
  teamCalendarEvents: any | undefined;
  owner: any | undefined;
}

export enum TeamPermission {
  ADD_EVENTS = "ADD_EVENTS",
  UPDATE_EVENTS = "UPDATE_EVENTS",
  DELETE_EVENTS = "DELETE_EVENTS",
  INVITE_USERS = "INVITE_USERS",
}

function updateObjectInArray(array: any[], idx: number, data: any) {
  return array.map((item, index) => {
    if (index !== idx) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...data,
    };
  });
}

export function setSelectedTeam(selectedTeam: any) {
  console.log("set selected team", selectedTeam);
  return {
    type: SET_SELECTED_TEAM,
    selectedTeam: selectedTeam,
  };
}

export function getCurrentAccountTeamUserSuccess(teamUser: any) {
  console.log("get current account team user success");
  return {
    type: GET_CURRENT_ACCOUNT_TEAM_USER_SUCCESS,
    teamUser: teamUser,
  };
}

export function getCurrentAccountTeamUserFailed(error: string) {
  console.log("get current account team user failed");
  return {
    type: GET_CURRENT_ACCOUNT_TEAM_USER_FAILED,
    error: error,
  };
}

export function updateTeamMemberPermissionSuccess(teamUser: any) {
  console.log("update team member permissions success");
  return {
    type: UPDATE_TEAM_MEMBER_PERMISSION_SUCCESS,
    teamUser: teamUser,
  };
}

export function updateTeamMemberPermissionFailed(error: string) {
  return {
    type: UPDATE_TEAM_MEMBER_PERMISSION_FAILED,
    error: error,
  };
}

export function setSelectedTeamMember(teamUser: any) {
  return {
    type: SET_SELECTED_TEAM_MEMBER,
    teamUser: teamUser,
  };
}

export function getTeamFailed(error: string) {
  return {
    type: GET_TEAM_FAILED,
    error: error,
  };
}

export function getTeamsFailed(error: string) {
  return {
    type: GET_TEAMS_FAILED,
    error: error,
  };
}

export function getTeamSuccess(team: Team) {
  return {
    type: GET_TEAM_SUCCESS,
    team: team,
  };
}

export function getTeamsSuccess(teams: Team[]) {
  return {
    type: GET_TEAMS_SUCCESS,
    teams: teams,
  };
}

export function createTeamSuccess(team: Team) {
  return {
    type: CREATE_TEAM_SUCCESS,
    team: team,
  };
}

export function createTeamFailed(error: string) {
  return {
    type: CREATE_TEAM_FAILED,
    error: error,
  };
}

export function updateTeamSuccess(team: Team) {
  return {
    type: UPDATE_TEAM_SUCCESS,
    team: team,
  };
}
export function updateTeamFailed(error: string) {
  return {
    type: UPDATE_TEAM_FAILED,
    error: error,
  };
}

export function sendTeamInviteSuccess() {
  return {
    type: SEND_TEAM_INVITE_SUCCESS,
  };
}

export function sendTeamInviteFailed(error: string) {
  return {
    type: SEND_TEAM_INVITE_FAILED,
    error: error,
  };
}

export function removeMemberSuccess(team: Team) {
  return {
    type: REMOVE_MEMBER_SUCCESS,
    team: team,
  };
}

export function removeMemberFailed(error: string) {
  return {
    type: REMOVE_MEMBER_FAILED,
    error: error,
  };
}

export function deleteTeamSuccess(id: number) {
  return {
    type: DELETE_TEAM_SUCCESS,
    id: id,
  };
}

export function deleteTeamFailed(error: string) {
  return {
    type: DELETE_TEAM_FAILED,
    error: error,
  };
}

interface TeamState {
  team: any;
  teams: any[];
  error: string | null;
  selectedTeamMember: any | null;
  currentAccountTeamMember: any | null;
}

const initialState: TeamState = {
  team: undefined,
  error: null,
  teams: [],
  selectedTeamMember: null,
  currentAccountTeamMember: null,
};

export default (state = initialState, action: any): TeamState => {
  switch (action.type) {
    case GET_TEAM_SUCCESS:
      console.log("get team success: ", action.team);

      return {
        ...state,
        team: action.team,
      };

    case GET_TEAM_FAILED:
      console.log("get team failed");

      return {
        ...state,
        error: action.error,
      };

    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.teams,
      };

    case GET_TEAMS_FAILED:
      console.log("get teams failed");

      return {
        ...state,
        error: action.error,
      };

    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        team: action.team,
        teams: [...state.teams, action.team],
      };

    case CREATE_TEAM_FAILED:
      console.log("create team failed");

      return {
        ...state,
        error: action.error,
      };

    case UPDATE_TEAM_SUCCESS:
      console.log("inside of update team success");

      let idx = state.teams.findIndex((tm) => tm.id === action.team.id);

      console.log("found team index: ", idx);

      return {
        ...state,
        team: action.team,
        teams: updateObjectInArray(state.teams, idx, action.team),
      };

    case UPDATE_TEAM_FAILED:
      console.log("update team failed");

      return {
        ...state,
        error: action.error,
      };

    case SET_SELECTED_TEAM:
      return {
        ...state,
        team: action.selectedTeam,
      };

    case SEND_TEAM_INVITE_SUCCESS:
      console.log("sent team invite success");
      return {
        ...state,
      };

    case SEND_TEAM_INVITE_FAILED:
      console.log("sent team invite failed");
      return {
        ...state,
        error: action.error,
      };

    case UPDATE_TEAM_MEMBER_PERMISSION_SUCCESS:
      console.log("add permission success");

      const copiedTeams = [...state.teams];
      const copiedTeam = { ...state.team };
      const copiedTeamUsers = [...state.team.teamUsers];

      const foundUserIndex = copiedTeamUsers.findIndex(
        (usr) => usr.id === action.teamUser.id
      );

      console.log("found user index: ", foundUserIndex);

      copiedTeamUsers[foundUserIndex] = action.teamUser;
      copiedTeam.teamUsers = copiedTeamUsers;

      const foundTeamIndex = copiedTeams.findIndex(
        (tm) => tm.id === state.team.id
      );

      console.log("found team index: ", foundTeamIndex);

      copiedTeams[foundTeamIndex] = copiedTeam;

      return {
        ...state,
        selectedTeamMember: action.teamUser,
        team: { ...copiedTeam },
        teams: [...copiedTeams],
      };

    case UPDATE_TEAM_MEMBER_PERMISSION_FAILED:
      console.log("add permission failed");
      return {
        ...state,
        error: action.error,
      };

    case REMOVE_MEMBER_SUCCESS:
      console.log("remove member success");

      let teamsRemoveMember = [...state.teams];

      _.remove(teamsRemoveMember, (team) => team.id === action.team.id);

      return {
        ...state,
        team: action.team,
        teams: teamsRemoveMember,
      };

    case REMOVE_MEMBER_FAILED:
      console.log("remove member failed");

      return {
        ...state,
        error: action.error,
      };

    case DELETE_TEAM_SUCCESS:
      let teams = [...state.teams];
      _.remove(teams, (team) => team.id === action.id);

      return {
        ...state,
        teams: teams,
      };

    case DELETE_TEAM_FAILED:
      console.log("delete team failed");

      return {
        ...state,
        error: action.error,
      };

    case SET_SELECTED_TEAM_MEMBER:
      console.log("set selected team member: ", action.teamUser);

      return {
        ...state,
        selectedTeamMember: action.teamUser,
      };

    case GET_CURRENT_ACCOUNT_TEAM_USER_SUCCESS:
      console.log("get current account team user success");
      return {
        ...state,
        currentAccountTeamMember: action.teamUser,
      };
    case GET_CURRENT_ACCOUNT_TEAM_USER_FAILED:
      console.log("get current account team user failed");
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};
