import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  logoutAction,
  refreshToken,
} from "../../models/account/accountActions";
import { Box, Button, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const StillThereModal = (props) => {
  const { open, setOpen } = props;
  const [countdown, setCountdown] = useState(60); // 60 seconds countdown
  const dispatch = useDispatch();

  useEffect(() => {
    let interval;
    if (open) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [open]);

  useEffect(() => {
    if (countdown === 0) {
      setOpen(false);
    }
  }, [countdown, setOpen, dispatch]);

  const handleRefresh = () => {
    setOpen(false);
    dispatch(refreshToken());
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <h2>Are you still there?</h2>
          <p>
            Your session is about to expire. Click "Still Here" to remain logged
            in.
          </p>
          <p>Remaining Time: {countdown} seconds</p>
          <Button onClick={handleRefresh}>Still Here</Button>
          <Button onClick={() => setOpen(false)}>Sign Out</Button>
        </div>
      </Box>
    </Modal>
  );
};

export default StillThereModal;
