import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const ProfileAvatar = (props) => {
  const { url, size, name } = props;
  console.log("url: ", url);

  return url ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <img
        style={{ width: size, height: size, borderRadius: 10000 }}
        src={url}
      />
    </div>
  ) : (
    <div
      style={{
        width: size,
        height: size,
        fontSize: size / 2,
        borderRadius: 10000,
        backgroundColor: "purple",
        textTransform: "uppercase",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        color: "white",
      }}
    >
      {name?.charAt(0)}
    </div>
  );
};

export default ProfileAvatar;
