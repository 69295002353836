import React, { Component } from "react";
import { useSelector } from "react-redux";
import { Route, useLocation, Navigate } from "react-router-dom";
import ResponsiveNav from "../ResponsiveNav/ResponsiveNav";
import { useTheme } from "@mui/material";
import {
  ROLE_LENDER_ADMIN,
  ROLE_MERCHANT,
  ROLE_LENDER_USER,
  ROLE_PRE_AUTH_MFA_REQUIRED,
} from "../../config/contants";

const ProtectedRoute = ({
  children,
  nav = true,
  roles = [ROLE_LENDER_ADMIN, ROLE_LENDER_USER, ROLE_MERCHANT],
}) => {
  const account = useSelector((state) => state.account);
  const authorities = account?.account?.authorities;

  const canAccess = () => {
    console.log("roles: ", roles);
    console.log("authorities: ", authorities);
    if (
      !roles?.includes(ROLE_PRE_AUTH_MFA_REQUIRED) &&
      authorities?.includes(ROLE_PRE_AUTH_MFA_REQUIRED)
    ) {
      return false;
    }
    // at least one of the roles must exist in the authorities
    let canAccess = false;

    for (let i = 0; i < roles.length; i++) {
      if (authorities?.includes(roles[i])) {
        canAccess = true;
        break;
      }
    }

    return canAccess;
  };

  const {
    mixins: { toolbar },
  } = useTheme();

  let location = useLocation();

  if (!account.isSignedIn) {
    console.log("wasnt logged in: ", account);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!canAccess()) {
    console.log("cant access the route: ", account);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return nav ? (
    <div style={{ width: "100vw", height: "100vh" }}>
      <ResponsiveNav />
      <div
        style={{
          position: "absolute",
          top: `calc(${toolbar?.minHeight}px + ${8}px)`,
          height: `calc(100vh - (${toolbar?.minHeight}px + ${8}px))`,
          width: "100%",
        }}
      >
        {children}
      </div>
    </div>
  ) : (
    <div style={{ width: "100vw", height: "100vh" }}>{children}</div>
  );
};

export default ProtectedRoute;
