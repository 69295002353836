import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { sendlenderInviteAction } from "../../models/lender/lenderThunks";
import { acceptInvestmentStatusAction } from "../../models/investor/investorActions";
import { FundingSourceSelect } from "../Form/FinancialForm";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export default function InvestmentPayInModal(props) {
  const { open, setOpen } = props;
  const accountSlice = useSelector((state) => state.account);
  const account = accountSlice.account;
  const loading = useSelector((state) => state.ui).loading;
  const { investment } = props;
  const status = investment.investors[0]?.status;
  const contributionPercentage =
    investment.investors[0]?.contributionPercentage;
  const merchant = investment?.merchant;
  const lender = merchant?.lender;
  const { control, handleSubmit, reset, setValue } = useForm();

  const investmentAmount =
    (contributionPercentage * investment?.fundedAmount) / 100;
  const id = investment?.id;

  const [accepted, setAccepted] = React.useState(false);

  const [fundingSourceOptions, setFundingSourceOptions] = React.useState([]);
  const [selectedFundingSource, setSelectedFundingSource] = React.useState();

  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log(
      "set funding source option use effect: ",
      account.investorPersonalAccount
    );
    const personalFundingSources =
      account?.investorPersonalAccount?.fundingSources || [];
    const businessFundingSources =
      account?.investorBusinessAccount?.fundingSources || [];

    setFundingSourceOptions([
      ...personalFundingSources,
      ...businessFundingSources,
    ]);
  }, [account.investorPersonalAccount, account.investorBusinessAccount]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <h3>Investment Pay-in</h3>

            <p>
              {`By checking [accept], you authorize us to debit your bank account
              for your investment contribution percentage (${contributionPercentage}%), which is
               $${investmentAmount.toLocaleString()}. 
               It may take up to 4 days for the ACH debit to process. This transaction is non-refundable.`}
            </p>

            <FormControlLabel
              control={
                <Checkbox
                  checked={accepted}
                  onChange={() => {
                    setAccepted(!accepted);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Accept"
            />
            <Button
              disabled={(!accepted && !selectedFundingSource) || loading}
              style={{ marginTop: "2em" }}
              variant="outlined"
              onClick={() => {
                console.log("pay in invocation...");
                dispatch(
                  acceptInvestmentStatusAction(id, {
                    fundingSourceDTO: selectedFundingSource,
                    status: "Accepted",
                  })
                );
              }}
            >
              Accept & Invest
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
