import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDealThunk } from "../../models/deal/dealThunks";

import DealDetails from "../DealDetails/DealDetails";
import moment from "moment";
import _ from "lodash";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function DealModal(props) {
  const { open, setOpen } = props;
  const merchants = useSelector((state) => state.merchants);
  const startOfToday = moment().startOf("day");

  let initalDeal = {
    factorRate: 1.0,
    firstPaymentDate: moment(startOfToday).add(1, "week"),
    paymentFrequency: "Daily",
    merchant: merchants?.merchant,
  };

  const [dealInfo, setDealInfo] = useState(initalDeal);

  React.useEffect(() => {
    if (!_.isNil(merchants.merchant)) {
      setDealInfo({ ...dealInfo, merchant: merchants?.merchant });
    }
  }, [merchants?.merchant]);

  const { control, handleSubmit, reset } = useForm();

  const dispatch = useDispatch();

  const handleAddDeal = () => {
    console.log("add deal: ", dealInfo);
    dispatch(addDealThunk(dealInfo));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setDealInfo(initalDeal);
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <h3>Add Deal</h3>

            <DealDetails
              control={control}
              dealInfo={dealInfo}
              setDealInfo={setDealInfo}
            />

            <Button onClick={handleSubmit(handleAddDeal)}>Submit</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
