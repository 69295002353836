import { Button, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getElapsedTimeString } from "../../shared/commonUtils";
import { useNavigate } from "react-router-dom";

import { getSelectedInvestmentFromDataRefAction } from "../../models/investor/investorActions";

import { useEffect, useRef, useState } from "react";
import _ from "lodash";

export const InvestmentNotification = (props) => {
  const { notification } = props;
  const dispatch = useDispatch();
  const elapsedTime = getElapsedTimeString(notification.createdDate);
  const investment = useSelector((state) => state.deals)?.investment;

  const navigator = useNavigate();

  console.log("clicked on investment: ", investment);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
        {notification.title ? notification.title : ""}
      </p>
      <p style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "14px" }}>
        {notification.body ? notification.body : ""}
      </p>
      <p
        style={{
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "12px",
          marginTop: "0px",
        }}
      >
        {elapsedTime}
      </p>
      <div
        style={{
          display: "flex",
          width: "100",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={async () => {
            await dispatch(
              getSelectedInvestmentFromDataRefAction(notification.dataRef)
            );
            navigator(
              `/investor-dashboard/${investment?.merchant?.name}/${investment.id}`
            );
          }}
        >
          View
        </Button>
      </div>

      <Divider />
    </div>
  );
};
