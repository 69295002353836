import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";

import { Paper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FinancialTransactionsTable from "../../components/FinancialTransactionsTable/FinancialTransactionsTable";
import BasicTabs from "../../components/BasicTabs/BasicTabs";
import moment from "moment/moment";
import DealMetrics from "../../components/DealMetrics/DealMetrics";
import PaymentCalendar from "../../components/PaymentCalendar/PaymentCalendar";
import _ from "lodash";
import DealStatus from "../../components/DealStatus/DealStatus";
import MyInvestmentTab from "../../components/InvestorTab/MyInvestmentTab";
import { getCustomerTransactionsThunk } from "../../models/dwolla/dwollaActions";

const InvestmentSelection = () => {
  const navigate = useNavigate();
  const accountSlice = useSelector((state) => state.account);
  const account = accountSlice.account;
  const holiday = useSelector((state) => state.holiday);

  const dealSlice = useSelector((state) => state.deals);
  const deal = dealSlice.deal;
  const selectedInvestment = dealSlice?.investment;
  const investmentDetails = selectedInvestment?.investors[0];
  const currentMerchant = selectedInvestment?.merchant;
  const dwolla = useSelector((state) => state.dwolla);

  let events = selectedInvestment?.merchantPayments
    ? selectedInvestment.merchantPayments
    : [];

  let holidays = holiday?.holidays?.map((holiday) => {
    return {
      ...holiday,
      type: "holiday",
    };
  });

  console.log("holiday: ", holiday);
  events = [...events, ...holidays];

  const profit =
    selectedInvestment?.fundedAmount * selectedInvestment?.factorRate -
    selectedInvestment?.fundedAmount;

  const myProfit =
    profit * Number(investmentDetails?.contributionPercentage / 100).toFixed(2);

  console.log("the deal: ", selectedInvestment);
  console.log("the merchant: ", currentMerchant);

  useEffect(() => {
    const source = account.sendPaymentsFundingSourceId;
    const dest = account.receivePaymentsFundingSourceId;

    let sourceAcct = null;
    let destAcct = null;

    if (_.isNil(source) || _.isNil(dest)) {
      return;
    }

    if (
      account?.investorBusinessAccount &&
      account?.investorBusinessAccount?.fundingSources
    ) {
      let filtered = account.investorBusinessAccount.fundingSources.filter(
        (fs) => {
          return (
            fs.dwollaFundingSourceId === source ||
            fs.dwollaFundingSourceId === dest
          );
        }
      );

      if (filtered && filtered.length === 1) {
        let found = filtered[0];
        if (found.dwollaFundingSourceId === source) {
          sourceAcct = account.investorBusinessAccount;
        }

        if (found.dwollaFundingSourceId === dest) {
          destAcct = account.investorBusinessAccount;
        }
      }
    }

    if (
      account?.investorPersonalAccount &&
      account?.investorPersonalAccount?.fundingSources
    ) {
      let filtered = account.investorPersonalAccount.fundingSources.filter(
        (fs) => {
          return (
            fs.dwollaFundingSourceId === source ||
            fs.dwollaFundingSourceId === dest
          );
        }
      );

      if (filtered && filtered.length === 1) {
        let found = filtered[0];
        if (found.dwollaFundingSourceId === source) {
          sourceAcct = account.investorPersonalAccount;
        }

        if (found.dwollaFundingSourceId === dest) {
          destAcct = account.investorPersonalAccount;
        }
      }
    }

    if (
      sourceAcct &&
      destAcct &&
      sourceAcct.dwollaCustomerId === destAcct.dwollaCustomerId
    ) {
      dispatch(
        getCustomerTransactionsThunk(
          sourceAcct.dwollaCustomerId,
          selectedInvestment.id
        )
      );
    }
  }, []);

  const [dealInfo, setDealInfo] = useState({
    fundedAmount: selectedInvestment?.fundedAmount,
    factorRate: selectedInvestment?.factorRate,
    fundedDate: selectedInvestment?.fundedDate,
    firstPaymentDate: selectedInvestment?.firstPaymentDate,
    paymentFrequency: selectedInvestment?.paymentFrequency,
  });

  const [merchantInfo, setMerchantInfo] = useState({
    name: currentMerchant?.name,
    owner: currentMerchant?.owner,
    address: currentMerchant?.address,
  });

  useEffect(() => {
    setDealInfo({
      fundedAmount: selectedInvestment?.fundedAmount,
      factorRate: selectedInvestment?.factorRate,
      fundedDate: selectedInvestment?.fundedDate,
      firstPaymentDate: selectedInvestment?.firstPaymentDate,
      paymentFrequency: selectedInvestment?.paymentFrequency,
    });
  }, [selectedInvestment]);

  useEffect(() => {
    setMerchantInfo({
      name: currentMerchant?.name,
      owner: currentMerchant?.owner,
      address: currentMerchant?.address,
    });
  }, [currentMerchant]);

  const { control, handleSubmit, reset, setValue } = useForm();

  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}
        >
          <div
            style={{
              marginTop: "3em",
              marginBottom: "3em",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1 style={{ margin: "0px" }}>{`${
              currentMerchant?.businessName
                ? currentMerchant?.businessName
                : "--"
            }`}</h1>

            <h5 style={{ margin: "0px" }}>{`Created: ${
              selectedInvestment?.createdDate
                ? moment(selectedInvestment?.createdDate).format("MMMM Do YYYY")
                : "TBD"
            }`}</h5>
            <h5 style={{ margin: "0px" }}>{`Funded: ${
              selectedInvestment?.fundedDate
                ? moment(selectedInvestment?.fundedDate).format("MMMM Do YYYY")
                : "TBD"
            }`}</h5>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DealStatus deal={selectedInvestment} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "2em",
            columnGap: "2em",
          }}
        >
          {[
            {
              title: `$${
                selectedInvestment?.fundedAmount
                  ? Number(
                      Number(selectedInvestment.fundedAmount).toFixed(2)
                    ).toLocaleString()
                  : "--"
              }`,
              subTitle: "Funded Amount",
            },
            {
              title: `${
                selectedInvestment?.factorRate
                  ? selectedInvestment.factorRate
                  : "--"
              }`,
              subTitle: "Factor Rate",
            },
            {
              title: `${
                !_.isNil(investmentDetails?.contributionPercentage) &&
                !_.isNaN(investmentDetails?.contributionPercentage)
                  ? Number(investmentDetails?.contributionPercentage).toFixed(2)
                  : "--"
              }%`,
              subTitle: "My Contribution %",
            },
            {
              title: `$${
                !_.isNil(myProfit) && !_.isNaN(myProfit)
                  ? Number(Number(myProfit).toFixed(2)).toLocaleString()
                  : "--"
              }`,
              subTitle: "My Forecasted Profit",
            },
          ].map((card) => {
            return (
              <Paper
                style={{
                  width: "220px",
                  height: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                elevation={1}
              >
                <div>
                  <h1 style={{ margin: "0px" }}>{card.title}</h1>
                  <h6 style={{ margin: "0px" }}>{card.subTitle}</h6>
                </div>
              </Paper>
            );
          })}
        </div>

        <div style={{ marginTop: "2em" }}>
          <BasicTabs
            data={[
              {
                label: "Payment Schedule",
                panel: <PaymentCalendar events={events} />,
              },

              {
                label: "My Investment",
                panel: <MyInvestmentTab />,
              },
              {
                label: "Payment History",
                panel: (
                  <FinancialTransactionsTable
                    data={
                      dwolla?.transactions?.length > 0
                        ? dwolla?.transactions
                        : []
                    }
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default InvestmentSelection;
