import axios from "axios";
import _ from "lodash";
import { applicationJson, JWT, ROOT_URL } from "../config/contants";
import axiosInstance from "./interceptors";

export const getDataFromDataRef = async (dataRef) => {
  let url = `${ROOT_URL}${dataRef}`;

  console.log("the dataRef was: ", dataRef);
  console.log("the url was: ", url);

  return axiosInstance.get(url, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};
