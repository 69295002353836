import { useDispatch, useSelector } from "react-redux";
import PlaidLink from "../../components/PlaidLink/PlaidLink";

import { useEffect } from "react";
import FundingSourceCard from "../../components/FundingSourceCard/FundingSourceCard";
import { CircularProgress } from "@mui/material";

const LinkAccountScreen = (props) => {
  const {
    loading,
    org,
    getOrgThunk,
    defaultOrgId,
    unlinkFundingSourceThunk,
    createPlaidFundingSourceThunk,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!org?.id) {
      console.log("getting org...", defaultOrgId);
      dispatch(getOrgThunk(defaultOrgId));
    }
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: props.justifyButton ? props.justifyButton : "center",
          marginBottom: "2em",
        }}
      >
        <PlaidLink
          org={org}
          createPlaidFundingSourceThunk={createPlaidFundingSourceThunk}
        />
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "50vh",
            marginTop: "2em",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          {org?.fundingSources?.map((fundingSource) => {
            return (
              <FundingSourceCard
                unlinkFundingSourceThunk={unlinkFundingSourceThunk}
                org={org}
                fundingSource={fundingSource}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LinkAccountScreen;
