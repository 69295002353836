import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../models/account/accountActions";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import logo from "../../shared/images/ls-logo-png.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotificationBell } from "../NotificationBell/NotificationBell";
import { Notifications } from "../Notifications/Notifications";
import {
  ROLE_INVITED_USER,
  ROLE_LENDER_ADMIN,
  ROLE_LENDER_USER,
} from "../../config/contants";

function ResponsiveNav() {
  const account = useSelector((state) => state.account).account;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    console.log("nav account: ", account);
    if (account?.authorities?.includes(ROLE_LENDER_ADMIN)) {
      console.log("account was an admin");
      setPages([
        { name: "Fund", path: "/funding-dashboard" },
        { name: "Invest", path: "/investor-dashboard" },
        { name: "Admin", path: "/admin-dashboard" },
      ]);
    } else if (
      account?.authorities?.includes(ROLE_INVITED_USER) &&
      account?.authorities?.includes(ROLE_LENDER_USER)
    ) {
      setPages([
        { name: "Funding", path: "/funding-dashboard" },
        {
          name: "Invest",
          path: "/investor-dashboard",
        },
      ]);
    } else {
      setPages([{ name: "Funding", path: "/funding-dashboard" }]);
    }
  }, [account]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar style={{ backgroundColor: "hwb(199deg 7% 83%)" }} position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Button
            style={{
              color: "white",
              fontSize: "22px",
              textTransform: "none",
              fontWeight: "bold",
              border: "none !important",
              outline: "none !important",
              boxShadow: "none !important",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <div
              style={{
                height: "60px",
                width: "60px",
                marginRight: "10px",
                border: "none !important",
                outline: "none !important",
                boxShadow: "none !important",

                position: "relative",
                backgroundPosition: "center center",

                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${logo})`,
              }}
            />
            Syndication Platform
          </Button>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(page.path);
                  }}
                >
                  <p>{page.name}</p>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => {
                  handleCloseNavMenu();
                  navigate(page.path);
                }}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "2em",
              }}
            >
              <Notifications />
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <ProfileAvatar
                    size={50}
                    url={account?.imageUrl}
                    name={account.firstName}
                  />
                </IconButton>
              </Tooltip>
            </div>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key={"profile"}
                onClick={() => {
                  navigate("/profile");
                  handleCloseUserMenu();
                }}
              >
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem
                key={"logout"}
                onClick={() => {
                  dispatch(logoutAction());
                }}
              >
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveNav;
