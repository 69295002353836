import axios from "axios";
import { JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const addInvestor = (investor) => {
  console.log(`investor: ${investor}`);

  return axiosInstance.post(`${ROOT_URL}/api/investor`, investor, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getInvestor = (id) => {
  console.log(`get investor with id: ${id}`);

  return axiosInstance.get(`${ROOT_URL}/api/investor/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getInvestors = () => {
  console.log(`get investors`);

  return axiosInstance.get(`${ROOT_URL}/api/investor}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getUserInvestments = (dealId) => {
  console.log(`get user investments`, dealId);

  return axiosInstance.get(`${ROOT_URL}api/deals/user-investments`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const investorPayIn = (dealId, payInRequest) => {
  console.log(`accept user investment status`, dealId);

  return axiosInstance.post(
    `${ROOT_URL}api/deals/${dealId}/pay-into-deal`,
    payInRequest,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const rejectInvestorStatus = (dealId, status) => {
  console.log(`reject user investment status`, dealId);

  return axiosInstance.post(
    `${ROOT_URL}api/deals/${dealId}/reject-investor-status`,
    { status: status },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};
