import { LOADING } from "../uiReducer";

export const CREATE_NOTIFICATION_REQUEST = "CREATE_NOTIFICATION_REQUEST";
export const CREATE_NOTIFICATION_SUCCESS =
  "CREATE_NOTIFICATION_REQUEST_SUCCESS";
export const CREATE_NOTIFICATION_FAILED = "CREATE_NOTIFICATION_REQUEST_FAILED";

export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_REQUEST_SUCCESS";
export const GET_NOTIFICATIONS_FAILED = "GET_NOTIFICATIONS_REQUEST_FAILED";

export const ACCEPT_INVITE_SUCCESS = "ACCEPT_INVITE_SUCCESS";
export const ACCEPT_INVITE_FAILED = "ACCEPT_INVITE_FAILED";
export const SEND_CONNECTION_INVITE_SUCCESS = "SEND_CONNECTION_INVITE_SUCCESS";
export const GET_UNOPENED_NOTIFICATIONS_COUNT_SUCCESS =
  "GET_UNOPENED_NOTIFICATIONS_COUNT_SUCCESS";
export const GET_UNOPENED_NOTIFICATIONS_COUNT_FAILED =
  "GET_UNOPENED_NOTIFICATIONS_COUNT_FAILED";

export const INCREMENT_UNOPENED_NOTIFICATIONS =
  "INCREMENT_UNOPENED_NOTIFICATIONS";

export const RESET_NOTIFICATIONS_BELL = "RESET_NOTIFICATIONS_BELL";

export const OPEN_ALL_UNOPENED_NOTIFICATIONS_SUCCESS =
  "OPEN_ALL_UNOPENED_NOTIFICATIONS_SUCCESS";

export const OPEN_ALL_UNOPENED_NOTIFICATIONS_FAILED =
  "OPEN_ALL_UNOPENED_NOTIFICATIONS_FAILED";

export const REJECT_INVITE_SUCCESS = "REJECT_INVITE_SUCCESS";
export const REJECT_INVITE_FAILED = "REJECT_INVITE_FAILED";
export const DECREMENT_UNOPENED_NOTIFICATIONS =
  "DECREMENT_UNOPENED_NOTIFICATIONS";

export interface PushNotification {
  id: number;
  title: string;
  body: string;
  sender: any;
  recipient: any;
  notificationType: NotificationType;
  notificationAcknowlegement: NotificationAcknowlegement;
}

export enum NotificationType {
  CONNECTION_INVITE_REQUEST = "Connection Invite Request",
  CONNECTION_INVITE_ACCEPTED = "Connection Invite Accepted",
  TEAM_INVITE_REQUEST = "Team Invite Request",
  TEAM_INVITE_ACCEPTED = "Team Invite Accepted",
  INVESTOR_CONTRIBUTION_CONFIRMATION = "Investor Contribution Confirmation",
  DEAL_READY_TO_SCHEDULE = "Ready to Schedule",
  DEAL_DEFAULTED_NOTICE = "Deal Defaulted Notice",

  ORGANIZATION_INVITE_REQUEST = "Organization Invite Request",
  ORGANIZATION_INVITE_ACCEPTED = "Organization Invite Accepted",

  MERCHANT_INVITE_REQUEST = "Merchant Invite Request",
  MERCHANT_INVITE_ACCEPTED = "Merchant Invite Accepted",

  CALENDAR_EVENT_CREATED = "Calendar Event Created",
  CALENDAR_EVENT_UPDATED = "Calendar Event Updated",
  CALENDAR_EVENT_DELETED = "Calendar Event Deleted",
  TEAM_CALENDAR_EVENT_CREATED = "Team Calendar Event Created",
  TEAM_CALENDAR_EVENT_UPDATED = "Team Calendar Event Updated",
  TEAM_CALENDAR_EVENT_DELETED = "Team Calendar Event Deleted",
  TEAM_UPDATED = "Team Updated",
  TEAM_DELETED = "Team Deleted",

  LENDER_TO_INVESTOR_PAYMENT_SUCCEEDED = "Investor to Lender Payment Succeeded",
  LENDER_TO_MERCHANT_PAYMENT_SUCCEEDED = "Lender to Merchant Payment Succeeded",
  MERCHANT_TO_LENDER_PAYMENT_SUCCEEDED = "Merchant to Lender Payment Succeeded",
}

export enum NotificationAcknowlegement {
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  NONE = "None",
}

export function sendConnectionInviteSuccess(notification: PushNotification) {
  return {
    type: SEND_CONNECTION_INVITE_SUCCESS,
    notification: notification,
  };
}

export const getNotificationSuccess = (notifications: PushNotification[]) => {
  console.log("got some notifications: ", notifications);
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    notifications: notifications,
  };
};

export const incrementUnopenedNotifications = () => {
  console.log("increment unopenedNotifications count");
  return {
    type: INCREMENT_UNOPENED_NOTIFICATIONS,
  };
};

export const decrementUnopenedNotifications = () => {
  console.log("decrement unopenedNotifications count");
  return {
    type: DECREMENT_UNOPENED_NOTIFICATIONS,
  };
};

export const openAllUnopenedNotificationsSuccess = () => {
  console.log("open all unopened notifications success");
  return {
    type: OPEN_ALL_UNOPENED_NOTIFICATIONS_SUCCESS,
  };
};

export const openAllUnopenedNotificationsFailed = (error: any) => {
  console.log("open all unopened notifications failed");
  return {
    type: OPEN_ALL_UNOPENED_NOTIFICATIONS_FAILED,
    error: error,
  };
};

export const resetNotificationsBell = () => {
  console.log("reset notifications bell");
  return {
    type: RESET_NOTIFICATIONS_BELL,
  };
};

export const getUnopenedNotificationCountSuccess = (count: number) => {
  console.log("got notifications count succeeded ", count);
  return {
    type: GET_UNOPENED_NOTIFICATIONS_COUNT_SUCCESS,
    unopenedNotificationsCount: count,
  };
};

export const getUnopenedNotificationCountFailed = (error: any) => {
  console.log("got notifications count failed ", error);
  return {
    type: GET_UNOPENED_NOTIFICATIONS_COUNT_FAILED,
    error: error,
  };
};

export const getNotificationFailed = (error: any) => {
  return {
    type: GET_NOTIFICATIONS_FAILED,
    error: error,
  };
};

export const acceptInviteSuccess = (notificationId: number) => {
  return {
    type: ACCEPT_INVITE_SUCCESS,
    notificationId: notificationId,
  };
};

export const acceptInviteFailed = (error: any) => {
  return {
    type: ACCEPT_INVITE_FAILED,
    error: error,
  };
};

export const rejectInviteSuccess = (notificationId: number) => {
  return {
    type: REJECT_INVITE_SUCCESS,
    notificationId: notificationId,
  };
};

export const rejectInviteFailed = (error: any) => {
  return {
    type: REJECT_INVITE_FAILED,
    error: error,
  };
};

interface NotificationState {
  error: string | null;
  pushToken: string | null;
  savedNotification: any;
  notifications: PushNotification[];
  unopenedNotificationsCount: number;
}

const initialState: NotificationState = {
  error: null,
  pushToken: null,
  savedNotification: null,
  notifications: [],
  unopenedNotificationsCount: 0,
};

export default (state = initialState, action: any): NotificationState => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        error: null,
      };
    case CREATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        error: null,
      };

    case OPEN_ALL_UNOPENED_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case OPEN_ALL_UNOPENED_NOTIFICATIONS_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case INCREMENT_UNOPENED_NOTIFICATIONS:
      return {
        ...state,
        unopenedNotificationsCount: state.unopenedNotificationsCount + 1,
      };
    case DECREMENT_UNOPENED_NOTIFICATIONS:
      return {
        ...state,
        unopenedNotificationsCount: state.unopenedNotificationsCount - 1,
      };

    case RESET_NOTIFICATIONS_BELL:
      return {
        ...state,
        unopenedNotificationsCount: 0,
      };

    case GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        error: null,
        notifications: action.notifications,
      };

    case GET_UNOPENED_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        error: null,
        unopenedNotificationsCount: action.unopenedNotificationsCount,
      };

    case GET_UNOPENED_NOTIFICATIONS_COUNT_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case SEND_CONNECTION_INVITE_SUCCESS:
      console.log("send connection success");
      return {
        ...state,
      };

    case ACCEPT_INVITE_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case ACCEPT_INVITE_SUCCESS:
      return {
        ...state,
        error: null,
        notifications: state.notifications.map((notification) => {
          console.log("the current notification id is: ", notification.id);
          console.log("the target notification id is: ", action.notificationId);

          if (notification.id === action.notificationId) {
            notification = {
              ...notification,
              notificationAcknowlegement: NotificationAcknowlegement.ACCEPTED,
            };
            return notification;
          } else {
            return notification;
          }
        }),
      };

    case REJECT_INVITE_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case REJECT_INVITE_SUCCESS:
      return {
        ...state,
        error: null,
        notifications: state.notifications.map((notification) => {
          if (notification.id === action.notificationId) {
            notification = {
              ...notification,
              notificationAcknowlegement: NotificationAcknowlegement.REJECTED,
            };
            return notification;
          } else {
            return notification;
          }
        }),
      };

    default:
      return state;
  }
};
