import {
  getMerchantSuccess,
  getMerchantsSuccess,
  getMerchantKPIsSuccess,
  getOwnedMerchantSuccess,
  loading,
  merchantFailure,
  sendMerchantInviteSuccess,
  updateMerchantSuccess,
  createMerchantSuccess,
  getPendingMerchantInvitesSuccess,
  acceptMerchantInviteSuccess,
  createMerchantBusinessDetailsSuccess,
} from "./merchantReducer";

import _ from "lodash";
import { failure, toastFailure, toastSuccess } from "../uiReducer";
import {
  acceptMerchantInvite,
  createMerchant,
  createMerchantBusinessDetails,
  createPlaidFundingSource,
  getMerchant,
  getMerchantKPIs,
  getMerchants,
  getOwnedMerchant,
  getPendingMerchantInvites,
  inviteMerchant,
  setCurrentUserDefaultMerchant,
  unlinkFundingSource,
  updateFundsFlow,
} from "./merchantApi";
import { updateAccountSuccess } from "../account/accountReducer";

export const inviteMerchantThunk = (merchant) => async (dispatch) => {
  console.log(`handle invite merchant: `, merchant);
  dispatch(loading(true));

  try {
    const response = await inviteMerchant(merchant);

    dispatch(sendMerchantInviteSuccess(response.data));

    const responseGetMerchants = await getMerchants(merchant.lenderId);

    dispatch(getMerchantsSuccess(responseGetMerchants.data));
    toastSuccess("Success!", "The merchant was added successfully.");
  } catch (error) {
    toastFailure(error);
    dispatch(merchantFailure(error?.response?.data?.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const createPlaidFundingSourceThunk =
  (orgId, plaidToken, accountName, accountId, accessToken) =>
  async (dispatch) => {
    console.log(`handle create plaid funding source`);
    dispatch(loading(true));

    try {
      const response = await createPlaidFundingSource(
        orgId,
        plaidToken,
        accountName,
        accountId,
        accessToken
      );

      dispatch(updateMerchantSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const updateFundsFlowConfigThunk =
  (orgId, fundsFlowDTO) => async (dispatch) => {
    console.log(`handle update funds flow`);
    dispatch(loading(true));

    try {
      const response = await updateFundsFlow(orgId, fundsFlowDTO);

      dispatch(updateMerchantSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(merchantFailure(error?.response?.data?.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const unlinkFundingSourceThunk =
  (orgId, fundingSourceId) => async (dispatch) => {
    console.log(`handle unlink funding source`);
    dispatch(loading(true));

    try {
      const response = await unlinkFundingSource(orgId, fundingSourceId);

      dispatch(updateMerchantSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(merchantFailure(error?.response?.data?.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const setCurrentUserDefaultMerchantThunk = (id) => async (dispatch) => {
  dispatch(loading(true));

  try {
    const response = await setCurrentUserDefaultMerchant(id);
    dispatch(updateAccountSuccess(response.data));
  } catch (error) {
    console.log("inside of merchant error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(merchantFailure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const createMerchantBusinessDetailsThunk =
  (merchant) => async (dispatch, getState) => {
    console.log("create merchant action: ", merchant);

    const { id } = getState().merchants?.merchant; // Accessing merchant.id from Redux state

    dispatch(loading(true));

    try {
      const response = await createMerchantBusinessDetails(id, merchant);

      dispatch(createMerchantBusinessDetailsSuccess(response.data));
      toastSuccess("Success!", "Your merchant was created");
    } catch (error) {
      console.log("inside of create merchant error");
      toastFailure(error);
      dispatch(loading(false));
      dispatch(merchantFailure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const getMerchantThunk = (id) => async (dispatch) => {
  console.log(`handle get merchant with id: ${id}`);
  dispatch(loading(true));

  try {
    const response = await getMerchant(id);

    console.log("response: ", JSON.stringify(response));

    dispatch(getMerchantSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(merchantFailure(error?.response?.data?.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getMerchantsThunk = (organizationId) => async (dispatch) => {
  console.log(`handle get merchants: ${organizationId}`);
  dispatch(loading(true));

  try {
    const response = await getMerchants(organizationId);

    console.log("response: ", JSON.stringify(response));

    dispatch(getMerchantsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(merchantFailure(error?.response?.data?.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getPendingMerchantInvitesThunk = () => async (dispatch) => {
  dispatch(loading(true));

  try {
    const response = await getPendingMerchantInvites();

    dispatch(getPendingMerchantInvitesSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(merchantFailure(error?.response?.data?.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getMerchantKPIsThunk = (merchantId) => async (dispatch) => {
  console.log(`handle get merchant KPIs ${merchantId}`);
  dispatch(loading(true));

  try {
    const response = await getMerchantKPIs(merchantId);

    console.log("response: ", JSON.stringify(response));

    dispatch(getMerchantKPIsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(merchantFailure(error?.response?.data?.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getOwnedMerchantThunk = () => async (dispatch) => {
  console.log(`handle get owned merchant`);
  dispatch(loading(true));

  try {
    const response = await getOwnedMerchant();

    console.log("response: ", JSON.stringify(response));

    dispatch(getOwnedMerchantSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(merchantFailure(error?.response?.data?.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};
