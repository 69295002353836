import _ from "lodash";
import React from "react";
import { Chart } from "react-google-charts";

const totalFunded = 123456;
const factorRate = 1.5;

export const options = {
  title: "Investor Contributions & Earnings",
  chartArea: { width: "50%" },
  colors: ["#00308F", "#32de84"],

  hAxis: {
    title: "$ Amount",
    minValue: 0,
  },
  vAxis: {
    title: "Investor",
  },
};

export function FundedDealBarChart(props) {
  const { data } = props;

  console.log("data: ", data);
  const header = ["Investor", "$ Amount Invested", "$ Amount Projected Profit"];
  let chartBody;

  if (_.isNil(data) || data.length === 0) {
    chartBody = [["", 0, 0]];
  } else {
    chartBody = data.map((curr) => {
      const { investor, contribution } = curr;

      const amountInvested = totalFunded * (contribution / 100);
      const totalProfit = factorRate * amountInvested;

      let row = [investor.name, amountInvested, totalProfit];
      return row;
    });
  }

  const chartData = [header, ...chartBody];

  console.log("chart data: ", chartData);

  return (
    <Chart
      chartType="BarChart"
      width="100%"
      height="400px"
      data={chartData}
      options={options}
    />
  );
}
