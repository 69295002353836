import { createSlice } from "@reduxjs/toolkit";

interface Deal {
  businessName: string;
  email: string;
}

interface DealState {
  deals: Deal[];
  exploreDeals: Deal[];
  deal: Deal | {};
  investments: Deal[];
  investment: {};
  loading: boolean;
}

const initialState: DealState = {
  deals: [],
  exploreDeals: [],
  investments: [],
  investment: {},
  deal: {},
  loading: false,
};

const dealSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    addDealSuccess(state, action) {
      state.deal = action.payload;
      state.deals = [...state.deals, action.payload];
    },
    getDealSuccess(state, action) {
      state.deal = action.payload;
    },

    getDealsSuccess(state, action) {
      state.deals = action.payload;
    },

    exploreDealsSuccess(state, action) {
      state.exploreDeals = action.payload;
    },

    getUserInvestmentsSuccess(state, action) {
      state.investments = action.payload;
    },

    getUserInvestmentSuccess(state, action) {
      state.investment = action.payload;
    },

    getInvestmentSuccess(state, action) {
      state.investment = action.payload;
    },

    getSelectedInvestment(state, action) {
      state.investment = action.payload;
    },

    setSelectedInvestment(state, action) {
      state.investment = action.payload;
    },
    fundDealSuccess(state, action) {
      state.deal = action.payload;
    },

    addUserToDealSuccess(state, action) {
      state.deal = action.payload;
    },

    removeUserFromDealSuccess(state, action) {
      state.deal = action.payload;
    },

    markDealAsDefaultedSuccess(state, action) {
      state.deal = action.payload;
    },

    markDealAsPausedSuccess(state, action) {
      state.deal = action.payload;
    },

    markDealAsUnPausedSuccess(state, action) {
      state.deal = action.payload;
    },

    confirmPaymentScheduleSuccess(state, action) {
      state.deal = action.payload;
    },
    setPaymentScheduleSuccess(state, action) {
      state.deal = action.payload;
    },
    submitDealForApprovalSuccess(state, action) {
      state.deal = action.payload;
    },

    updateInvestorContributionsSuccess(state, action) {
      state.deal = action.payload;
    },

    updateInvestorContributionSuccess(state, action) {
      state.deal = action.payload;
    },

    changeUserInvestmentStatusSuccess(state, action) {
      state.deal = action.payload;
      state.investment = action.payload;
    },

    loading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  addDealSuccess,
  getDealSuccess,
  getDealsSuccess,
  exploreDealsSuccess,
  getUserInvestmentsSuccess,
  getInvestmentSuccess,
  changeUserInvestmentStatusSuccess,
  submitDealForApprovalSuccess,
  updateInvestorContributionsSuccess,
  setSelectedInvestment,
  markDealAsDefaultedSuccess,
  markDealAsPausedSuccess,
  markDealAsUnPausedSuccess,
  setPaymentScheduleSuccess,
  fundDealSuccess,
  confirmPaymentScheduleSuccess,
  loading,
} = dealSlice.actions;

export default dealSlice.reducer;
