import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

export default function HorizontalStepper(props) {
  const {
    steps,
    control,
    stepperTitle,
    stepperSubtitle,
    stepperCompletedMessage,
    hideSteps,
    activeStep,
  } = props;

  console.log("control: ", control);

  const handleNext = () => {
    steps[activeStep].handleSubmit();
  };

  const handleBack = () => {
    steps[activeStep].handleBack();
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {stepperTitle}
        {stepperSubtitle}

        <div
          style={{
            display: "flex",
            width: props.stepperWidth ? props.stepperWidth : "70%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {hideSteps ? (
            ""
          ) : (
            <Stepper
              style={{ width: "100%", marginTop: "1em", marginBottom: "2em" }}
              activeStep={activeStep}
            >
              {steps.map((step, index) => {
                const { title, panel } = step;
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={title} {...stepProps}>
                    <StepLabel {...labelProps}>{title}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}

          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {steps[activeStep]?.panel}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>

            <Button
              disabled={steps[activeStep].isNextDisabled}
              onClick={handleNext}

              // onClick={handleSubmit(handleNext)}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
}
