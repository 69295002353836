import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DealsTable from "../../components/DealsTable/DealsTable";

import DealModal from "../../components/DealModal/DealModal";
import _ from "lodash";
import { getElapsedTimeString } from "../../shared/commonUtils";
import MyInvestmentsTable from "../../components/MyInvestmentsTable/MyInvestmentsTable";
import { getUserInvestmentsAction } from "../../models/investor/investorActions";
import BasicTabs from "../../components/BasicTabs/BasicTabs";
import InvestmentAccountOptionCardsPersonal from "../../components/InvestorAccountOptionCards/InvestorAccountOptionCardsPersonal";
import InvestmentAccountOptionCardsBusiness from "../../components/InvestorAccountOptionCards/InvestorAccountOptionCardsBusiness";
import InvestorFundsFlowConfig from "../../components/InvestorFundsFlowConfig/InvestorFundsFlowConfig";
import { exploreDealsThunk } from "../../models/deal/dealThunks";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const MyInvestments = () => {
  const navigate = useNavigate();
  const deals = useSelector((state) => state.deals);
  const merchantSlice = useSelector((state) => state.merchants);
  const merchant = merchantSlice?.merchant;

  const [addDeal, setAddDeal] = useState(false);
  //   const merchant = useSelector((state) => state.merchant);
  const { control, handleSubmit, errors, setValue } = useForm();

  console.log("the merchant is: ", merchant);
  console.log("the deals are: ", deals);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInvestmentsAction());
    dispatch(exploreDealsThunk());
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "3em", marginBottom: "3em" }}>
          <div
            style={{
              marginTop: "3em",
              marginBottom: "3em",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MonetizationOnIcon
              style={{ fontSize: "40px", color: "#1565C0" }}
            />
            <h1 style={{ margin: "0px" }}>Invest</h1>
          </div>
        </div>

        <div style={{ marginTop: "2em" }}>
          <BasicTabs
            data={[
              {
                label: "Explore",
                panel: (
                  <DealsTable
                    opportunities={true}
                    setAddDeal={setAddDeal}
                    data={deals?.exploreDeals ? deals.exploreDeals : []}
                  />
                ),
              },
              {
                label: "Invest",
                panel: (
                  <MyInvestmentsTable
                    setAddDeal={setAddDeal}
                    data={deals?.investments ? deals.investments : []}
                  />
                ),
              },
              {
                label: "Personal Investment Account",
                panel: (
                  <div
                    style={{
                      display: "flex",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      height: "600px",
                    }}
                  >
                    <InvestmentAccountOptionCardsPersonal />
                  </div>
                ),
              },
              {
                label: "Business Investment Account",
                panel: (
                  <div
                    style={{
                      display: "flex",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      height: "600px",
                    }}
                  >
                    <InvestmentAccountOptionCardsBusiness />
                  </div>
                ),
              },
              {
                label: "Funds Flow Configuration",
                panel: (
                  <div
                    style={{
                      display: "flex",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      height: "300px",
                    }}
                  >
                    <InvestorFundsFlowConfig />
                  </div>
                ),
              },
            ]}
          />
        </div>

        <DealModal open={addDeal} setOpen={setAddDeal} />
      </div>
    </div>
  );
};

export default MyInvestments;
