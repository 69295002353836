import React, { Component, useState } from "react";

import _ from "lodash";

import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";

const InvestorSearch = (props) => {
  const { investors, setSelectedInvestors } = props;
  const [key, setKey] = useState(true);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  console.log("investor search investors: ", investors);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div>
        <Autocomplete
          key={key}
          freeSolo
          openOnFocus={false}
          options={investors}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              label="Search"
            />
          )}
          getOptionLabel={(option) => {
            console.log("the option: ", option);
            return typeof option === "string" ? option : option.fullName;
          }}
          onChange={(event, newValue) => {
            setKey(!key);

            setInputValue("");

            if (_.isNil(newValue) || _.isEmpty(newValue)) {
              return;
            }

            setSelectedInvestors(newValue);
          }}
          renderOption={(props, option) => {
            console.log("option: ", option);
            const fullName = `${option.firstName} ${option.lastName}`;
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: ".5em",
                      }}
                    >
                      <ProfileAvatar
                        size={50}
                        url={option?.imageUrl}
                        name={fullName}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          key={fullName}
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          {fullName}
                        </Box>

                        <Typography variant="body2" color="text.secondary">
                          {option.email}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    <AddIcon sx={{ color: "text.secondary" }} />
                  </Grid>
                </Grid>
              </li>
            );
          }}
          open={open}
          onOpen={() => {
            console.log("on open input value: ", inputValue);
            if (inputValue) {
              setOpen(true);
            }
          }}
          onClose={() => setOpen(false)}
          inputValue={inputValue}
          onInputChange={(e, value, reason) => {
            console.log("input change input value: ", value);
            setInputValue(value);

            if (!open && value) {
              console.log("setting open");
              setOpen(true);
            }

            if (!value) {
              setOpen(false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default InvestorSearch;
