import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { fundDealThunk } from "../../models/deal/dealThunks";
import { FundingSourceSelect } from "../Form/FinancialForm";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function FundedDealTermsModal(props) {
  const { open, setOpen, deal } = props;
  const merchantSlice = useSelector((state) => state.merchants);
  const lenderSlice = useSelector((state) => state.lenders);
  const lender = lenderSlice.lender;
  const merchant = merchantSlice.merchant;
  const account = useSelector((state) => state.account);

  const { control, handleSubmit, reset, setValue } = useForm();

  const [accepted, setAccepted] = React.useState(false);

  const [fundingSourceOptions, setFundingSourceOptions] = React.useState([]);
  const [selectedFundingSource, setSelectedFundingSource] = React.useState();
  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log(
      "set funding source option use effect: ",
      lender.fundingSources
    );

    setFundingSourceOptions([...lender.fundingSources]);
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <h3>Fund Deal</h3>

            <p>
              {`By checking [accept], you agree to fund the merchant ${
                merchant.businessName
              } the amount of ${deal?.fundedAmount?.toLocaleString()} via ACH transaction from your business checking account.
               It may take up to 4 days for the ACH debit to process. This transaction is non-refundable.`}
            </p>

            <FormControlLabel
              control={
                <Checkbox
                  checked={accepted}
                  onChange={() => {
                    setAccepted(!accepted);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Accept"
            />
            <Button
              disabled={!accepted}
              style={{ marginTop: "2em" }}
              variant="outlined"
              onClick={() => {
                dispatch(fundDealThunk(deal?.id, selectedFundingSource));
              }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
