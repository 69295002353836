import { Button, IconButton } from "@mui/material";
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import PortraitIcon from "@mui/icons-material/Portrait";
import { useDispatch } from "react-redux";
import { changeProfilePicture } from "../../models/account/accountApi";
import { changeProfilePictureAction } from "../../models/account/accountActions";

export function StyledDropzone(props) {
  // const { file, setFile } = props;

  const dispatch = useDispatch();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/*": [],
      },
      maxFiles: 1,
      onDrop: async (files) => {
        console.log("acceptedFiles: ", files);
        const file = files[0];

        const blob = await fetchImageFromUri(URL.createObjectURL(file));
        dispatch(changeProfilePictureAction(blob));
      },
    });

  const fetchImageFromUri = async (uri) => {
    console.log("the uri: ", uri);
    const response = await fetch(uri);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  return (
    <Button
      raised
      variant="contained"
      component="label"
      style={{ textTransform: "none", backgroundColor: "black" }}
      startIcon={<PortraitIcon style={{ height: "30px", width: "30px" }} />}
    >
      Update profile picture
      <input {...getInputProps()} />
    </Button>
  );
}
