import React, { useCallback, useState } from "react";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Link } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { GoogleLogin } from "@react-oauth/google";
import {
  credentialsLoginAction,
  ssoLoginAction,
} from "../../models/account/accountActions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import logo from "../../shared/images/ls-logo-png.png";
import { Email, EnterPassword } from "../../components/Form/Form";
import moment from "moment/moment";
import { Copyright } from "@mui/icons-material";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import { store, persistor } from "../../models/store";
import { useDidMountEffect } from "../../shared/commonUtils";
import useRedirect from "../../shared/useRedirect";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: "2em",
  },
  [theme.breakpoints?.down("md")]: {
    form: {
      width: "80%",
      backgroundColor: "#FFFFFF",
      padding: "20px",
    },
  },
  [theme.breakpoints?.up("md")]: {
    form: {
      width: "400px",
      backgroundColor: "#FFFFFF",
      padding: "20px",
    },
  },
  header: {
    marginTop: "5%",
    fontSize: "35px",
    fontWeight: "bold",
    margingTop: "5%",
  },
}));

const LoginScreen = (props) => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const { control, handleSubmit } = useForm();

  const classes = useStyles();

  const [recaptchaToken, setRecaptchaToken] = useState();

  const onVerifyRecaptcha = useCallback((token) => {
    console.log("token: ", token);
    setRecaptchaToken(token);
  });

  const handleCredentialsLogin = () => {
    console.log("Credentials Login button pressed");
    console.log("user email: ", email, "user password: ", password);
    dispatch(credentialsLoginAction(email, password));
  };

  const responseGoogle = (response) => {
    console.log("google response: ", response);
    dispatch(ssoLoginAction(response.credential));
  };

  useEffect(() => {
    persistor.purge();
  }, []);

  useRedirect();

  return (
    <>
      <div>
        <div>
          <div className={classes.paper}>
            <div
              style={{
                height: "8em",
                width: "8em",

                position: "relative",
                marginBottom: "3em",
                backgroundPosition: "center center",
                margin: "3em",

                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${logo})`,
              }}
            />

            <div
              class="row"
              style={{
                marginTop: "5%",
                height: "100%",
                marginLeft: "8%",
                marginRight: "8%",
                display: "flex",
                flexDirection: "row",
                padding: "7.5px",
              }}
            ></div>
            <div className={classes.form}>
              <VpnKeyIcon
                style={{ fontSize: 40, color: "gray", marginBottom: "16px" }}
              />

              <h3>Login</h3>

              <Email
                control={control}
                email={email}
                setEmail={(email) => {
                  setEmail(email);
                }}
              />

              <EnterPassword
                control={control}
                password={password}
                setPassword={(password) => {
                  setPassword(password);
                }}
              />

              <Link
                style={{
                  fontWeight: "bold",
                  display: "block",
                  marginTop: "5%",
                }}
                href="/forgot-password"
                variant="body2"
              >
                Forgot password?
              </Link>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "4%",
                  marginBottom: "3.5%",
                  rowGap: "1em",
                }}
              >
                <ReCAPTCHA
                  sitekey="6Lch5OslAAAAAKJ9GKfbycd5Sk7obGDBq2OgB9V9"
                  onChange={onVerifyRecaptcha}
                />
                <Button
                  type="submit"
                  disabled={_.isNil(recaptchaToken)}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    opacity: _.isNil(recaptchaToken) ? ".5" : "1",
                  }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(handleCredentialsLogin)}
                >
                  Sign In
                </Button>
              </div>
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <hr />
                  </td>
                  <td
                    style={{
                      width: "1px",
                      padding: "0 10px",
                      whiteSpace: "nowrap",
                      color: "gray",
                    }}
                  >
                    OR
                  </td>
                  <td>
                    <hr />
                  </td>
                </tr>
              </table>
              <div
                style={{
                  display: "flex",
                  borderRadius: "15px",
                  marginTop: "2%",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <GoogleLogin
                  size="large"
                  onSuccess={(response) => responseGoogle(response)}
                  onError={(response) => console.log(response)}
                />
              </div>
              <div
                style={{
                  marginTop: "5%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Link
                  style={{ fontWeight: "bold" }}
                  href="/sign-up"
                  variant="body2"
                >
                  New to the Platform? Sign Up
                </Link>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Copyright />
                  <p>{`${moment().year()} Launch Site LLC`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
