import { CompatClient } from "@stomp/stompjs";
import { store, persistor } from "../store.js";

import { createSlice } from "@reduxjs/toolkit";

export enum OauthType {
  Login = "Login",
  Signup = "Sign up",
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  address: { city: string; state: string; zip: number };
  phone: string;
}

interface AccountState {
  accessToken: string | null;
  isSignedIn: boolean;
  loading: boolean;
  accountSetup: any;

  organization: any;
  account: any;
  error: string | null;
  resetKey: string | null;
  pushToken: string | null;
  stompClient: CompatClient | null;
  createInvestorBusinessAccountSuccess: boolean;
  updateInvestorBusinessAccountSuccess: boolean;
  createInvestorPersonalAccountSuccess: boolean;
  updateInvestorPersonalAccountSuccess: boolean;
}

const initialState: AccountState = {
  loading: false,
  accessToken: null,
  isSignedIn: false,
  account: undefined,
  organization: undefined,
  accountSetup: {},
  error: null,
  resetKey: null,
  pushToken: null,
  stompClient: null,
  createInvestorBusinessAccountSuccess: false,
  updateInvestorBusinessAccountSuccess: false,

  createInvestorPersonalAccountSuccess: false,
  updateInvestorPersonalAccountSuccess: false,
};

export const DISCONNECT_STOMP = "DISCONNECT_STOMP";

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    changeProfilePictureSuccess(state, action) {
      state.account = action.payload;
      state.loading = false;
      state.error = null;
    },
    forgotPasswordVerifySuccess(state, action) {
      state.resetKey = action.payload;
      state.loading = false;
      state.error = null;
    },
    forgotPasswordInitSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    changePasswordSuccess(state, action) {
      state.account = action.payload;
      state.loading = false;
      state.error = null;
    },
    logoutSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isSignedIn = false;
      state.account = null;
      state.accessToken = null;
      state.resetKey = null;
      state.stompClient = null;
    },
    verifySMSCodeSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.account = action.payload;
    },
    sendSMSCodeSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    updateAccountSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.account = action.payload;
    },
    getAccountSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.account = action.payload;
      state.isSignedIn = true;
    },
    signupSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.account = action.payload;
    },
    updateAccountSetupSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.accountSetup = action.payload;
    },
    getAccountSetupSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.accountSetup = action.payload;
    },
    registerForWebSocketNotificationsSuccess(state, action) {
      state.error = null;
      state.loading = false;
      state.stompClient = action.payload;
    },
    disconnectStomp(state, action) {
      const client = state.stompClient;
      console.log("trying to disconnect stomp...", client);

      if (client != null) {
        client.disconnect();
        console.log("DISCONNECTED");
      }
      state.stompClient = null;
      state.error = null;
      state.loading = false;
    },

    resetCreateUpdateInvestmentBusinessAccountSuccess(state, action) {
      state.createInvestorBusinessAccountSuccess = false;
      state.updateInvestorBusinessAccountSuccess = false;

      state.error = null;
      state.loading = false;
    },

    getInvestmentBusinessAccountSuccess(state, action) {
      state.account.investorBusinessAccount = action.payload;
      state.loading = false;
      state.error = null;
    },

    getInvestmentPersonalAccountSuccess(state, action) {
      state.account.investorPersonalAccount = action.payload;
      state.loading = false;
      state.error = null;
    },

    removeInvestmentBusinessAccountSuccess(state) {
      state.account.investorBusinessAccount = null;
      state.loading = false;
      state.error = null;
    },

    unlinkInvestmentBusinessAccountFundingSourceSuccess(state, action) {
      state.account.investorBusinessAccount = action.payload;
      state.loading = false;
      state.error = null;
    },

    removeInvestmentPersonalAccountSuccess(state) {
      state.account.investorPersonalAccount = null;
      state.loading = false;
      state.error = null;
    },

    unlinkInvestmentPersonalAccountFundingSourceSuccess(state, action) {
      state.account.investorPersonalAccount = action.payload;
      state.loading = false;
      state.error = null;
    },

    createInvestmentBusinessAccountSuccess(state, action) {
      state.account.investorBusinessAccount = action.payload;
      state.loading = false;
      state.createInvestorBusinessAccountSuccess = true;
      state.error = null;
    },

    createInvestmentPersonalAccountSuccess(state, action) {
      state.account.investorPersonalAccount = action.payload;
      state.loading = false;
      state.createInvestorPersonalAccountSuccess = true;
      state.error = null;
    },

    setAccessToken(state, action) {
      state.accessToken = action.payload;
      state.error = null;
      state.loading = false;
    },
    loading(state, action) {
      state.loading = action.payload;
    },
    failure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  changeProfilePictureSuccess,
  forgotPasswordVerifySuccess,
  forgotPasswordInitSuccess,
  changePasswordSuccess,
  logoutSuccess,
  verifySMSCodeSuccess,
  sendSMSCodeSuccess,
  updateAccountSuccess,
  getAccountSuccess,
  updateAccountSetupSuccess,
  getInvestmentBusinessAccountSuccess,
  getInvestmentPersonalAccountSuccess,
  signupSuccess,
  getAccountSetupSuccess,
  registerForWebSocketNotificationsSuccess,
  resetCreateUpdateInvestmentBusinessAccountSuccess,
  unlinkInvestmentBusinessAccountFundingSourceSuccess,
  unlinkInvestmentPersonalAccountFundingSourceSuccess,
  createInvestmentBusinessAccountSuccess,
  createInvestmentPersonalAccountSuccess,
  removeInvestmentBusinessAccountSuccess,
  removeInvestmentPersonalAccountSuccess,
  disconnectStomp,
  setAccessToken,
  loading,
  failure,
} = accountSlice.actions;

export default accountSlice.reducer;
