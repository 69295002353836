import React, { useState } from "react";
import { Alert, AlertTitle, Button } from "@mui/material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import InvestmentTerms from "../InvestmentPayInModal/InvestmentPayInModal";
import { rejectInvestmentStatusAction } from "../../models/investor/investorActions";
import InvestmentPayInModal from "../InvestmentPayInModal/InvestmentPayInModal";

const InvestmentStatus = (props) => {
  const { investment } = props;
  const accountSlice = useSelector((state) => state.account);
  const account = accountSlice.account;
  const status = investment.investors.filter(
    (investor) => investor.user.id === account.id
  )[0]?.status;
  const id = investment?.id;
  const dispatch = useDispatch();

  const [acceptInvestment, setAcceptInvestment] = useState(false);

  console.log("investment!!!! ", investment);

  const getStatusPrompt = () => {
    if (status === "Needs Review") {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Please accept or reject your investment contribution percentage</p>
          <div
            style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}
          >
            <Button
              variant="outlined"
              endIcon={<TaskAltIcon style={{ color: "green" }} />}
              onClick={() => {
                setAcceptInvestment(true);
              }}
            >
              Accept
            </Button>
            <Button
              variant="outlined"
              endIcon={<HighlightOffIcon style={{ color: "red" }} />}
              onClick={() => {
                dispatch(rejectInvestmentStatusAction(id, "Rejected"));
              }}
            >
              Reject
            </Button>
          </div>
          <InvestmentPayInModal
            investment={investment}
            open={acceptInvestment}
            setOpen={setAcceptInvestment}
          />
        </div>
      );
    } else if (status === "Accepted") {
      return (
        <p>You accepted this investment contribution percentage position</p>
      );
    } else if (status === "Rejected") {
      return (
        <p>You rejected this investment contribution percentage position</p>
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {getStatusPrompt()}
    </div>
  );
};

export default InvestmentStatus;
