import { failure, toastFailure, toastSuccess } from "../uiReducer";
import {
  createLender,
  createPlaidFundingSource,
  deleteLender,
  getLender,
  getLenderInvestors,
  getLenderKPIs,
  getLenderUsers,
  getLenders,
  getOwnedLenders,
  sendLenderInvite,
  unlinkFundingSource,
  updateFundsFlow,
  updateLender,
} from "./lenderApi";
import {
  getLenderSuccess,
  getLendersSuccess,
  createLenderSuccess,
  sendLenderInviteSuccess,
  updateLenderSuccess,
  getOwnedLendersSuccess,
  getLenderKPIsSuccess,
  loading,
  lenderFailure,
  getLenderInvestorsSuccess,
  getLenderUsersSuccess,
} from "./lenderReducer";

export const getLendersThunk = () => async (dispatch) => {
  console.log("get lenders action");
  dispatch(loading(true));

  try {
    const response = await getLenders();

    console.log("the lenders: ", response.data);

    dispatch(getLendersSuccess(response.data));
  } catch (error) {
    console.log("inside of get lenders error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const getOwnedLendersThunk = () => async (dispatch) => {
  console.log("get owned lenders action");
  dispatch(loading(true));

  try {
    const response = await getOwnedLenders();

    console.log("the lenders: ", response.data);

    dispatch(getOwnedLendersSuccess(response.data));
  } catch (error) {
    console.log("inside of get lenders error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const getLenderThunk = (id) => async (dispatch) => {
  console.log("get lender action");
  dispatch(loading(true));

  try {
    const response = await getLender(id);

    console.log("the lender: ", response.data);

    dispatch(getLenderSuccess(response.data));
  } catch (error) {
    console.log("inside of get lender error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(lenderFailure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const createLenderThunk = (lender, setDefault) => async (dispatch) => {
  console.log("create lender action: ", lender);

  dispatch(loading(true));

  try {
    const response = await createLender(lender, setDefault);

    console.log("the lender: ", response.data);

    dispatch(createLenderSuccess(response.data));
    toastSuccess("Success!", "Your lender was created");
  } catch (error) {
    console.log("inside of create lender error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(lenderFailure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const updateLenderThunk = (lender) => async (dispatch) => {
  console.log("update lender action: ", lender);

  dispatch(loading(true));

  try {
    const response = await updateLender(lender.id, lender);

    console.log("the updated lender: ", response.data);

    dispatch(updateLenderSuccess(response.data));
    toastSuccess("Success!", "Your lender was updated");
  } catch (error) {
    console.log("inside of update lender error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(lenderFailure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const createPlaidFundingSourceThunk =
  (orgId, plaidToken, accountName, accountId, accessToken) =>
  async (dispatch) => {
    console.log(`handle create plaid funding source`);
    dispatch(loading(true));

    try {
      const response = await createPlaidFundingSource(
        orgId,
        plaidToken,
        accountName,
        accountId,
        accessToken
      );

      dispatch(updateLenderSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const unlinkFundingSourceThunk =
  (orgId, fundingSourceId) => async (dispatch) => {
    console.log(`handle unlink funding source`);
    dispatch(loading(true));

    try {
      const response = await unlinkFundingSource(orgId, fundingSourceId);

      dispatch(updateLenderSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const deleteLenderThunk = (id) => async (dispatch) => {
  console.log("delete lender action for lender: ", id);

  dispatch(loading(true));

  try {
    await deleteLender(id);

    // dispatch(deleteLenderS(id));
    toastSuccess("Success!", "The lender was deleted");
  } catch (error) {
    console.log("inside of delete lender error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const updateFundsFlowConfigThunk =
  (orgId, fundsFlowDTO) => async (dispatch) => {
    console.log(`handle update funds flow`);
    dispatch(loading(true));

    try {
      const response = await updateFundsFlow(orgId, fundsFlowDTO);

      dispatch(updateLenderSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error?.response?.data?.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const sendLenderUserInviteThunk =
  (lenderId, user) => async (dispatch) => {
    console.log("add member action");

    dispatch(loading(true));

    try {
      const response = await sendLenderInvite(lenderId, user);

      console.log("send lender invite response: ", response.data);

      dispatch(sendLenderInviteSuccess());

      const responseLender = await getLender(lenderId);

      console.log("the lender: ", responseLender.data);

      dispatch(getLenderSuccess(responseLender.data));

      toastSuccess("Success!", "Your invitation was sent");
    } catch (error) {
      console.log("inside of add member error: ", error);
      toastFailure(error);
      dispatch(loading(false));
      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const getLenderKPIsThunk = (orgId) => async (dispatch) => {
  console.log(`handle get lender KPIs ${orgId}`);
  dispatch(loading(true));

  try {
    const response = await getLenderKPIs(orgId);

    console.log("response: ", JSON.stringify(response));

    dispatch(getLenderKPIsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getInvestorsForLenderThunk = (orgId) => async (dispatch) => {
  console.log(`handle get lender investors ${orgId}`);
  dispatch(loading(true));

  try {
    const response = await getLenderInvestors(orgId);

    console.log("response: ", response);

    dispatch(getLenderInvestorsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getLenderUsersThunk = (orgId) => async (dispatch) => {
  console.log(`handle get lender users ${orgId}`);
  dispatch(loading(true));

  try {
    const response = await getLenderUsers(orgId);

    console.log("response: ", response);

    dispatch(getLenderUsersSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};
