import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export const getElapsedTimeString = (date: Date): string => {
  let now = moment();
  // the database always saves to 0 offset UTC
  let createdDate = moment.utc(date);
  let seconds = now.diff(createdDate, "seconds");
  let minutes = now.diff(createdDate, "minutes");
  let hours = now.diff(createdDate, "hours");
  let days = now.diff(createdDate, "days");
  let weeks = now.diff(createdDate, "weeks");
  let months = now.diff(createdDate, "months");

  console.log(`now: ${now}`);
  console.log(`createdDate: ${createdDate}`);
  console.log(`seconds: ${seconds}`);
  console.log(`minutes: ${minutes}`);
  console.log(`hours: ${hours}`);

  let displayDiff = "";

  // return `now: ${moment(now).format()} | createdDate: ${moment
  //   .utc(createdDate)
  //   .format()}`;

  if (seconds < 0) {
    return `${now} --- ${createdDate}`;
  }

  if (seconds < 60) {
    if (seconds === 1) {
      displayDiff = `${seconds} second ago`;
    } else {
      displayDiff = `${seconds} seconds ago`;
    }

    return displayDiff;
  }

  if (minutes < 60) {
    if (minutes === 1) {
      displayDiff = `${minutes} minute ago`;
    } else {
      displayDiff = `${minutes} minutes ago`;
    }

    return displayDiff;
  }

  if (hours < 24) {
    if (hours === 1) {
      displayDiff = `${hours} hour ago`;
    } else {
      displayDiff = `${hours} hours ago`;
    }

    return displayDiff;
  }

  if (days < 7) {
    if (days === 1) {
      displayDiff = `${days} day ago`;
    } else {
      displayDiff = `${days} days ago`;
    }

    return displayDiff;
  }

  if (weeks < 4) {
    if (weeks === 1) {
      displayDiff = `${weeks} week ago`;
    } else {
      displayDiff = `${weeks} weeks ago`;
    }

    return displayDiff;
  }

  if (months < 12) {
    if (months === 1) {
      displayDiff = `${months} month ago`;
    } else {
      displayDiff = `${months} months ago`;
    }

    return displayDiff;
  }

  return moment(createdDate).format("MM-DD-YYYY");
};

export const adjustColor = (color: string, amount: number): string => {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
};

export const useDebouncedCallback = (callback: any, delay: number) => {
  const callbackRef = useRef();
  callbackRef.current = callback;
  return useCallback(
    _.debounce((...args) => callbackRef.current(...args), delay),
    []
  );
};

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
