import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";

import _ from "lodash";
import HorizontalStepper from "../HorizontalStepper/HorizontalStepper";
import { makeStyles } from "@mui/styles";

import LinkAccountScreen from "../../screens/LinkAccountStep/LinkAccountScreen";
import {
  createInvestmentPersonalAccountPlaidFundingSourceThunk,
  createInvestorPersonalAccountThunk,
  getInvestmentPersonalAccountThunk,
  removeInvestmentPersonalAccountThunk,
} from "../../models/account/accountActions";
import PersonalVerifiedCustomer from "../PersonalVerifiedCustomer/PersonalVerifiedCustomer";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      margin: "0px",
    },
  },
  [theme.breakpoints.up("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "550px",
      height: "400px",
      marginTop: "0em",
    },
  },
}));

const InvestmentPersonalAccountSetup = (props) => {
  const { handleViewChange } = props;
  const classes = useStyles();

  const navigate = useNavigate();
  const accountSlice = useSelector((state) => state.account);
  const account = accountSlice?.account;
  const investorPersonalAccount =
    accountSlice?.account?.investorPersonalAccount;
  const [activeStep, setActiveStep] = useState(0);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const freshInvestmentAccount = {
    firstName: account.firstName,
    lastName: account.lastName,
    email: "",
    ssn: "",
    dateOfBirth: null,

    address: {
      address1: "",
      address2: "",
      zip: "",
      city: "",
      stateCode: "",
    },
  };

  const [personalInfo, setPersonalInfo] = useState(freshInvestmentAccount);

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
    setValue,
    reset,
  } = useForm();

  const dispatch = useDispatch();

  const handleMoveForward = () => {
    setActiveStep(activeStep + 1);
  };

  const handleMoveBackward = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    if (!_.isNil(investorPersonalAccount?.id)) {
      const mergedInfo = {
        ...investorPersonalAccount,
        dateOfBirth: null,

        fullAddress: {
          address1: investorPersonalAccount.address1,
          address2: investorPersonalAccount.address2,
          zip: investorPersonalAccount.postalCode,
          city: investorPersonalAccount.city,
          state: investorPersonalAccount.state,
        },
      };

      console.log("setting the business info: ", mergedInfo);
      setPersonalInfo(mergedInfo);

      setValue("address1", investorPersonalAccount.address1);
      setValue("address2", investorPersonalAccount.address2);
      setValue("city", investorPersonalAccount.city);
      setValue("zip", investorPersonalAccount.postalCode);

      setValue("birthDate", investorPersonalAccount.dateOfBirth);
    }
  }, [investorPersonalAccount?.id]);

  const handleCreatePersonalVerifiedCustomer = async () => {
    const { address1, address2, zip, city, state } = personalInfo.address;

    const requestDTO = {
      ...personalInfo,
      address1: address1,
      address2: address2,
      postalCode: zip,
      city: city,
      state: state.code,
    };

    await dispatch(createInvestorPersonalAccountThunk(requestDTO));
  };

  const businessDetailStep = {
    title: "Business",
    handleSubmit: () => {
      handleMoveForward();
    },
    handleBack: () => {
      console.log("no op");
    },
    isNextDisabled: _.isNil(investorPersonalAccount?.id),

    panel: (
      <div className={classes.panel}>
        <PersonalVerifiedCustomer
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          createCustomerThunk={createInvestorPersonalAccountThunk}
          handleCreatePersonalVerifiedCustomer={
            handleCreatePersonalVerifiedCustomer
          }
        />
      </div>
    ),
  };

  const linkAccountStep = {
    title: "Link your Business Bank Account",
    handleSubmit: () => {
      console.log("handle view change to cards");
      handleViewChange("cards");
    },
    handleBack: () => {
      handleMoveBackward();
    },

    isNextDisabled: !investorPersonalAccount?.fundingSources?.length > 0,

    panel: (
      <div className={classes.panel}>
        <LinkAccountScreen
          loading={accountSlice?.loading}
          org={investorPersonalAccount}
          defaultOrgId={investorPersonalAccount?.id}
          getOrgThunk={getInvestmentPersonalAccountThunk}
          createPlaidFundingSourceThunk={
            createInvestmentPersonalAccountPlaidFundingSourceThunk
          }
          unlinkFundingSourceThunk={removeInvestmentPersonalAccountThunk}
        />
      </div>
    ),
  };

  const accountSetupSteps = [businessDetailStep, linkAccountStep];

  return (
    <div
      style={{
        width: "100vw",
        height: "65vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "2em",
      }}
    >
      <div style={{ width: "100%", height: "100%", marginBottom: "2em" }}>
        <HorizontalStepper
          stepperWidth={"40%"}
          activeStep={activeStep}
          stepperTitle={""}
          stepperSubtitle={""}
          stepperCompletedMessage={""}
          acceptedTerms={acceptedTerms}
          steps={accountSetupSteps}
          control={control}
          isDirty={isDirty}
          isValid={isValid}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      </div>
    </div>
  );
};

export default InvestmentPersonalAccountSetup;
