import axios from "axios";
import { JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const addDeal = (deal, merchantId) => {
  console.log(`deal: ${deal}, merchantId: ${merchantId}`);

  return axiosInstance.post(`${ROOT_URL}api/deals`, deal, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getDeal = (id) => {
  console.log(`get deal with id: ${id}`);

  return axiosInstance.get(`${ROOT_URL}api/deals/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getMerchantDeals = (merchantId) => {
  console.log(`get deals`);

  return axiosInstance.get(`${ROOT_URL}api/deals?merchant-id=${merchantId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const exploreDeals = () => {
  console.log(`get deals`);

  return axiosInstance.get(`${ROOT_URL}api/deals/explore`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const addInvestorToDeal = (dealUser) => {
  console.log(`add user to deal: `, dealUser);

  return axiosInstance.post(`${ROOT_URL}api/deals/add-investor`, dealUser, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const removeInvestorFromDeal = (dealUser) => {
  console.log(`remove investor from deal: `, dealUser);

  return axiosInstance.post(`${ROOT_URL}api/deals/remove-investor`, dealUser, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const updateInvestorContribution = (dealUser) => {
  console.log(`updating investor contribution: `, dealUser);

  return axiosInstance.put(
    `${ROOT_URL}api/deals/update-investor-contribution`,
    dealUser,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const updateInvestorContributions = (dealUsers, dealId) => {
  console.log(`updating investor contribution: `, dealUsers);

  return axiosInstance.put(
    `${ROOT_URL}api/deals/${dealId}/update-investor-contributions`,
    dealUsers,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const submitDealForApproval = (dealId) => {
  console.log(`submit deal for approval`, dealId);

  return axiosInstance.post(
    `${ROOT_URL}api/deals/${dealId}/submit-for-approval`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const confirmPaymentSchedule = (dealId) => {
  console.log(`confirm payment schedule`, dealId);

  return axiosInstance.post(
    `${ROOT_URL}api/deals/${dealId}/confirm-payment-schedule`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const fundDeal = (dealId, fundingSource) => {
  console.log(`fund deal`, dealId);

  return axiosInstance.post(
    `${ROOT_URL}api/deals/${dealId}/fund`,
    fundingSource,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const setPaymentSchedule = (dealId, setPaymentScheduleDTO) => {
  console.log(`set payment schedule`, dealId);

  return axiosInstance.post(
    `${ROOT_URL}api/deals/${dealId}/set-payment-schedule`,
    setPaymentScheduleDTO,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const markDealAsDefaulted = (dealId) => {
  console.log(`mark deal as defaulted`, dealId);

  return axiosInstance.post(
    `${ROOT_URL}api/deals/${dealId}/mark-as-defaulted`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const markDealAsPaused = (dealId) => {
  console.log(`mark deal as paused`, dealId);

  return axiosInstance.post(
    `${ROOT_URL}api/deals/${dealId}/pause-payments`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const markDealAsUnPaused = (dealId) => {
  console.log(`mark deal as unpaused`, dealId);

  return axiosInstance.post(
    `${ROOT_URL}api/deals/${dealId}/resume-payments`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};
