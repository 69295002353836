import React, { useEffect, useState } from "react";

import {
  BirthDate,
  BusinessClassificationSelect,
  BusinessName,
  BusinessType,
  Email,
  EmployerIdentificationNumber,
  FirstName,
  FullAddress,
  JobTitle,
  LastName,
  SocialSecurityNumberLastFour,
} from "../Form/Form";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessClassificationsAction } from "../../models/dwolla/dwollaActions";
import _ from "lodash";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";
import AddOwners from "../AddOwners/AddOwners";
import { updateAccountSetupAction } from "../../models/account/accountActions";
import { useDidMountEffect } from "../../shared/commonUtils";

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    columnGap: "1em",
  },
}));

const CreateBusiness = (props) => {
  const {
    org,
    createOrgSuccess,
    updateOrgSuccess,
    createOrgThunk,
    resetCreateUpdateOrgSuccess,
    loading,
    defaultOrgId,
    getOrgThunk,
    useAccountSetup,
    getActiveStep,
    parentMoveForward,
    parentMoveBackward,
  } = props;
  const account = useSelector((state) => state.account);
  const accountSetup = account.accountSetup;

  const [activeStep, setActiveStep] = useState(0);

  const dwolla = useSelector((state) => state.dwolla);

  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    businessInfo,
    setBusinessInfo,
    control,
    handleSubmit,
    reset,
    trigger,
    setValue,
  } = props;

  const [businessClassifications, setBusinessClassifications] = useState([]);
  const [isController, setIsController] = useState("true");

  useEffect(() => {
    if (org?.id && businessInfo?.controller) {
      setValue("controllerFirstName", businessInfo?.controller?.firstName);
      setValue("controllerLastName", businessInfo?.controller?.lastName);
    }
  }, []);

  useEffect(() => {
    const currentStep = activeStep;
    const nextStep = getActiveStep();
    if (currentStep != nextStep) {
      setActiveStep(nextStep);
    }
  }, [accountSetup.activeStep]);

  const handleSetController = (key, value) => {
    setBusinessInfo({
      ...businessInfo,
      controller: {
        ...businessInfo.controller,
        [key]: value,
      },
    });
  };

  const handleNext = () => {
    console.log("inside of handle next: ", useAccountSetup);
    if (useAccountSetup == false) {
      if (businessInfo.businessType === "Sole Proprietorship") {
        parentMoveForward();
      } else {
        console.log("setting active step");
        setActiveStep(activeStep + 1);
      }
    } else {
      if (accountSetup.activeStep === "BUSINESS_DETAILS") {
        if (businessInfo.businessType === "Sole Proprietorship") {
          dispatch(updateAccountSetupAction("LINK_BANK_ACCOUNT"));
        } else {
          dispatch(updateAccountSetupAction("CONTROLLER_DETAILS"));
        }
      } else if (accountSetup.activeStep === "CONTROLLER_DETAILS") {
        dispatch(updateAccountSetupAction("ADD_OWNERS_YES_NO"));
      }
    }

    dispatch(resetCreateUpdateOrgSuccess());
  };

  const handleSetIsController = (e) => {
    setIsController(e.target.value);
  };

  useDidMountEffect(() => {
    if (createOrgSuccess || updateOrgSuccess) {
      handleNext();
    }
  }, [createOrgSuccess, updateOrgSuccess]);

  useEffect(() => {
    setActiveStep(getActiveStep());
  }, []);

  const handleCreateBusiness = async () => {
    console.log("inside of handle create business: ", businessInfo);
    if (businessInfo.businessType === "Sole Proprietorship") {
      await dispatch(
        createOrgThunk(
          {
            ...businessInfo,
            businessClassification: businessInfo.businessClassification.id,
            industryClassification: businessInfo.industryClassification.id,
          },
          true
        )
      );
    } else {
      await dispatch(
        createOrgThunk(
          {
            ...businessInfo,
            businessClassification: businessInfo.businessClassification.id,
            industryClassification: businessInfo.industryClassification.id,
            firstName: account?.account?.firstName,
            lastName: account?.account?.lastName,
            // email: account?.account?.email,
          },
          true
        )
      );
    }
  };

  const businessDetailsStep = (
    <>
      <h3>Business Details</h3>

      <div className={classes.flexRow}>
        <BusinessName
          businessName={businessInfo.businessName}
          setBusinessName={(businessName) => {
            setBusinessInfo({ ...businessInfo, businessName: businessName });
          }}
          control={control}
          name="businessName"
          label="Legal Business Name"
          tooltip="This should be exactly the same as the name used with your Employer Identification Number."
        />

        <BusinessType
          businessType={businessInfo.businessType}
          setBusinessType={(businessType) => {
            setBusinessInfo({ ...businessInfo, businessType: businessType });
            setIsController(
              businessType === "Sole Proprietorship" ? "true" : "false"
            );
          }}
          control={control}
        />
      </div>
      <BusinessClassificationSelect
        setValue={setValue}
        businessClassification={businessInfo.businessClassification}
        industryClassification={businessInfo.industryClassification}
        businessClassifications={businessClassifications}
        setBusinessClassification={(classification) => {
          setBusinessInfo({
            ...businessInfo,
            businessClassification: classification,
          });
        }}
        setIndustryClassification={(classification) => {
          setBusinessInfo({
            ...businessInfo,
            industryClassification: classification,
          });
        }}
        control={control}
      />
      {businessInfo.businessType != "Sole Proprietorship" && (
        <div className={classes.flexRow}>
          <div style={{ width: "70%" }}>
            <Email
              label="Business Email"
              controlName="businessEmail"
              name="Business Email"
              email={businessInfo.email}
              setEmail={(email) => {
                setBusinessInfo({
                  ...businessInfo,
                  email: email,
                });
              }}
              control={control}
            />
          </div>
          <div style={{ width: "30%" }}>
            <EmployerIdentificationNumber
              control={control}
              ein={businessInfo.ein}
              setEin={(ein) => {
                setBusinessInfo({ ...businessInfo, ein: ein });
              }}
            />
          </div>
        </div>
      )}
      {businessInfo.businessType === "Sole Proprietorship" && (
        <>
          <div className={classes.flexRow}>
            <div style={{ width: "39%" }}>
              <FirstName
                controlName="adminFirstName"
                label="Owner First Name"
                firstName={businessInfo.firstName}
                setFirstName={(firstName) => {
                  setBusinessInfo({
                    ...businessInfo,
                    firstName: firstName,
                  });
                }}
                control={control}
              />
            </div>
            <div style={{ width: "39%" }}>
              <LastName
                label="Owner Last Name"
                controlName="adminLastName"
                lastName={businessInfo.lastName}
                setLastName={(lastName) => {
                  setBusinessInfo({
                    ...businessInfo,
                    lastName: lastName,
                  });
                }}
                control={control}
              />
            </div>

            <div style={{ width: "22%" }}>
              <SocialSecurityNumberLastFour
                socialSecurityNumber={businessInfo.ssn}
                setSocialSecurityNumber={(ssn) => {
                  setBusinessInfo({
                    ...businessInfo,
                    ssn: ssn,
                  });
                }}
                control={control}
              />
            </div>
          </div>
          <div className={classes.flexRow}>
            <div style={{ width: "70%" }}>
              <Email
                label="Owner Email"
                controlName="adminEmail"
                name="Business Email"
                email={businessInfo.email}
                setEmail={(email) => {
                  setBusinessInfo({
                    ...businessInfo,
                    email: email,
                  });
                }}
                control={control}
              />
            </div>
            <div style={{ width: "30%" }}>
              <BirthDate
                name="Birth Date"
                birthDate={businessInfo.dateOfBirth}
                setBirthDate={(birthDate) => {
                  console.log("birthdate: ", birthDate);
                  setBusinessInfo({
                    ...businessInfo,
                    dateOfBirth: moment(birthDate).format("YYYY-MM-DD"),
                  });
                }}
                control={control}
              />
            </div>
          </div>
        </>
      )}
      <div className={classes.flexRow}>
        <FullAddress
          control={control}
          fullAddress={businessInfo?.fullAddress}
          setValue={setValue}
          setFullAddress={(address) => {
            setBusinessInfo({ ...businessInfo, fullAddress: address });
          }}
        />
      </div>
      {businessInfo.businessType &&
        businessInfo.businessType != "" &&
        businessInfo.businessName &&
        businessInfo.businessName != "" &&
        businessInfo.businessType != "Sole Proprietorship" && (
          <div className={classes.flexRow}>
            <FormControl>
              <RadioGroup
                aria-labelledby="controller-group"
                name="controller-group"
                value={isController}
                onChange={handleSetIsController}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={`I hereby certify that I am a controller for ${businessInfo.businessName}.`}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={`I am not a controller for ${businessInfo.businessName}.`}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}

      <div style={{ marginTop: "2em" }} className={classes.flexRow}>
        {businessInfo.businessType === "Sole Proprietorship" ? (
          <LoadingButton
            onClick={handleSubmit(handleCreateBusiness)}
            fullWidth
            loading={loading}
            loadingPosition="start"
            variant="outlined"
          >
            Create Business
          </LoadingButton>
        ) : (
          <Button
            onClick={handleSubmit(handleNext)}
            variant="outlined"
            fullWidth
          >
            Continue
          </Button>
        )}
      </div>
    </>
  );

  const controllerDetailsStep = (
    <>
      <>
        <div className={classes.flexRow}>
          <h3>Controller Details</h3>
          <Tooltip
            title="A controller is a natural person who holds significant responsibilities to control, manage or direct a company or other corporate entity (i.e. CEO, CFO, General Partner, President, etc)."
            arrow
          >
            <HelpOutlineIcon />
          </Tooltip>
        </div>
        <div className={classes.flexRow}>
          <FirstName
            controlName="controllerFirstName"
            firstName={businessInfo?.controller?.firstName}
            setFirstName={(firstName) => {
              handleSetController("firstName", firstName);
            }}
            control={control}
          />
          <LastName
            controlName="controllerLastName"
            lastName={businessInfo?.controller?.lastName}
            setLastName={(lastName) => {
              handleSetController("lastName", lastName);
            }}
            control={control}
          />
        </div>
        <div className={classes.flexRow}>
          <div style={{ width: "70%" }}>
            <Email
              controlName="controllerEmail"
              name="Business Email"
              email={businessInfo?.controller?.email}
              setEmail={(email) => {
                handleSetController("email", email);
              }}
              control={control}
            />
          </div>
          <div style={{ width: "30%" }}>
            <SocialSecurityNumberLastFour
              socialSecurityNumber={businessInfo?.controller?.ssn}
              setSocialSecurityNumber={(ssn) => {
                handleSetController("ssn", ssn);
              }}
              control={control}
            />
          </div>
        </div>
        <div className={classes.flexRow}>
          <FullAddress
            control={control}
            setValue={setValue}
            fullAddress={businessInfo?.controller?.address}
            setFullAddress={(address) => {
              handleSetController("address", address);
            }}
          />
        </div>
      </>

      <div className={classes.flexRow}>
        <div style={{ width: "35%" }}>
          <BirthDate
            name="Birth Date"
            birthDate={businessInfo?.controller?.dateOfBirth}
            setBirthDate={(birthDate) => {
              console.log("birthdate: ", birthDate);
              console.log(
                "formatted: ",
                moment(birthDate).format("YYYY-MM-DD")
              );
              handleSetController(
                "dateOfBirth",
                moment(birthDate).format("YYYY-MM-DD")
              );
            }}
            control={control}
          />
        </div>
        <div style={{ width: "65%" }}>
          {businessInfo.businessType &&
            businessInfo.businessType != "" &&
            businessInfo.businessType != "Sole Proprietorship" && (
              <JobTitle
                control={control}
                title={businessInfo?.controller?.title}
                setTitle={(title) => {
                  handleSetController("title", title);
                }}
              />
            )}
        </div>
      </div>

      <div
        style={{ marginTop: "2em", columnGap: "1em" }}
        className={classes.flexRow}
      >
        <Button
          onClick={() => {
            if (!useAccountSetup) {
              dispatch(updateAccountSetupAction("BUSINESS_DETAILS"));
            } else if (businessInfo.businessType === "Sole Proprietorship") {
              parentMoveBackward();
            } else {
              setActiveStep(activeStep - 1);
            }
          }}
          variant="outlined"
          fullWidth
        >
          Back
        </Button>

        <LoadingButton
          onClick={handleSubmit(handleCreateBusiness)}
          fullWidth
          loading={loading}
          loadingPosition="start"
          variant="outlined"
        >
          Create Business
        </LoadingButton>
      </div>
    </>
  );

  const ownershipStep = (
    <>
      <AddOwners
        useAccountSetup={useAccountSetup}
        org={org}
        defaultOrgId={defaultOrgId}
        getOrgThunk={getOrgThunk}
        loading={loading}
        control={control}
        reset={reset}
        handleSubmit={handleSubmit}
        certifiedNoBeneficialOwners={businessInfo.certifiedNoBeneficialOwners}
        certifiedCompleteAndCorrect={businessInfo.certifiedCompleteAndCorrect}
        hasBeneficialOwners={businessInfo.hasBeneficialOwners}
        setHasBeneficialOwners={(hasBeneficialOwners) => {
          console.log(
            "setting has beneficial owners to: ",
            hasBeneficialOwners
          );
          setBusinessInfo({
            ...businessInfo,
            hasBeneficialOwners: hasBeneficialOwners,
          });
        }}
        setCertifiedCompleteAndCorrect={(certified) => {
          console.log("setting certified complete and correct to ", certified);
          setBusinessInfo({
            ...businessInfo,
            certifiedCompleteAndCorrect: certified,
          });
        }}
        setCertifiedNoBeneficialOwners={(certified) => {
          console.log("setting certified no beneficial owners to ", certified);

          setBusinessInfo({
            ...businessInfo,
            certifiedNoBeneficialOwners: certified,
          });
        }}
        businessInfo={businessInfo}
        getActiveStep={() => {
          if (accountSetup.activeStep === "ADD_OWNERS_YES_NO") {
            return 0;
          } else if (accountSetup.activeStep === "ADD_OWNERS_YES") {
            // setHasBeneficialOwners("true");
            setBusinessInfo({
              ...businessInfo,
              hasBeneficialOwners: "true",
            });
            return 1;
          } else if (accountSetup.activeStep === "ADD_OWNERS_NO") {
            // setHasBeneficialOwners("false");
            setBusinessInfo({
              ...businessInfo,
              hasBeneficialOwners: "false",
            });

            return 1;
          }
        }}
      />
    </>
  );

  const steps =
    businessInfo.businessType != "Sole Proprietorship"
      ? [businessDetailsStep, controllerDetailsStep, ownershipStep]
      : [businessDetailsStep];

  useEffect(() => {
    console.log("getting the classifications");
    dispatch(getBusinessClassificationsAction());
  }, []);

  useEffect(() => {
    if (!_.isNil(dwolla.businessClassifications)) {
      console.log("setting classifications: ", dwolla.businessClassifications);
      setBusinessClassifications(dwolla.businessClassifications);
    }
  }, [dwolla.businessClassifications]);

  return <div className={classes.flexColumn}>{steps[activeStep]}</div>;
};

export default CreateBusiness;
