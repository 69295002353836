// useRedirect.js

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearRedirection } from "../models/uiReducer";

const useRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectTo = useSelector((state) => state.ui.redirectTo); // Assuming 'redirectTo' is stored in the 'auth' slice

  useEffect(() => {
    if (redirectTo) {
      console.log("useRedirect hook: ", redirectTo);
      navigate(redirectTo);
      dispatch(clearRedirection()); // Clear the redirection state after use
    }
  }, [redirectTo, navigate, dispatch]);
};

export default useRedirect;
