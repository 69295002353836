import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { isValidPhoneNumber } from "libphonenumber-js";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AsYouType } from "libphonenumber-js";
import {
  EMAIL_REGEX,
  ROLE_LENDER_ADMIN,
  ROLE_LENDER_USER,
} from "../../config/contants";
import GooglePlacesAddress from "./GooglePlacesAddress";
import { useEffect, useRef, useState } from "react";

import _ from "lodash";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Box } from "@mui/system";
import ReactInputMask from "react-input-mask";
import { InputMask } from "imask";

export const PhoneNumber = (props) => {
  const { phone, setPhone, control, name } = props;

  const handleValidatePhoneNumber = (num) => {
    const asYouType = new AsYouType("US");
    const phone = asYouType.input(num);

    const number = asYouType.getNumber()?.number;
    const isValid = isValidPhoneNumber(number);
    console.log(`number:  ${number} | valid: ${isValid}`);
    if (!isValid) {
      return "The Phone Number is invalid";
    }
  };

  return (
    <Controller
      control={control}
      name={name ? name : "phone"}
      defaultValue={!_.isNil(phone) ? phone : ""}
      rules={{
        required: {
          value: true,
          message: "Phone number is required",
        },
        validate: handleValidatePhoneNumber,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Phone Number"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="phoneNumber"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const raw = e.target.value;
            console.log("raw: ", raw);
            const asYouType = new AsYouType("US");
            const phone = asYouType.input(raw);
            const number = asYouType.getNumber()?.number;

            console.log(`number: ${number} | length: ${number?.length}`);

            if (!number || number.length < 13) {
              onChange(number);
              setPhone(number);
            }
          }}
          value={phone ? new AsYouType("US").input(phone) : ""}
          type="tel"
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const Email = (props) => {
  const { email, setEmail, control, controlName, label } = props;

  return (
    <Controller
      control={control}
      name={controlName ? controlName : "email"}
      defaultValue={!_.isNil(email) ? email : ""}
      rules={{
        required: {
          value: true,
          message: "Email is required",
        },
        pattern: {
          value: EMAIL_REGEX,
          message: "Not a valid email",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          autoComplete="off"
          label={label ? label : "Email Address"}
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="email"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const email = e.target.value;

            onChange(email);
            setEmail(email);
          }}
          value={email}
          type="email"
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const BusinessName = (props) => {
  const { businessName, setBusinessName, control, name, label, tooltip } =
    props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={!_.isNil(businessName) ? businessName : ""}
      rules={{
        required: {
          value: true,
          message: `${label} is required`,
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label={label}
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name={name}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "off",
            },
            endAdornment: (
              <Tooltip title={tooltip} arrow>
                <HelpOutlineIcon />
              </Tooltip>
            ),
          }}
          onChange={(e) => {
            const businessName = e.target.value;
            onChange(businessName);
            setBusinessName(businessName);
          }}
          value={businessName}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const FirstName = (props) => {
  const { firstName, setFirstName, control, controlName, label } = props;

  return (
    <Controller
      control={control}
      name={controlName ? controlName : "firstName"}
      defaultValue={!_.isNil(firstName) ? firstName : ""}
      rules={{
        required: {
          value: true,
          message: "First Name is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label={label ? label : "First Name"}
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="firstName"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const firstName = e.target.value;
            onChange(firstName);
            setFirstName(firstName);
          }}
          value={firstName}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const LastName = (props) => {
  const { lastName, setLastName, control, controlName, label } = props;

  return (
    <Controller
      control={control}
      name={controlName ? controlName : "lastName"}
      defaultValue={!_.isNil(lastName) ? lastName : ""}
      rules={{
        required: {
          value: true,
          message: "Last Name is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label={label ? label : "Last Name"}
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="lastName"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const lastName = e.target.value;
            onChange(lastName);
            setLastName(lastName);
          }}
          value={lastName}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const JobTitle = (props) => {
  const { title, setTitle, control } = props;

  return (
    <Controller
      control={control}
      name="jobTitle"
      defaultValue={!_.isNil(title) ? title : ""}
      rules={{
        required: {
          value: true,
          message: "Job Title is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Job Title"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="jobTitle"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const jobTitle = e.target.value;
            onChange(jobTitle);
            setTitle(jobTitle);
          }}
          value={title}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const Zip = (props) => {
  const { zip, setZip, control, disabled, name } = props;

  return (
    <Controller
      control={control}
      name={name ? `${name}-zip` : "zip"}
      defaultValue={zip}
      rules={{
        required: {
          value: true,
          message: "Zip is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          disabled={disabled}
          label="Zip Code"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="zip"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const zip = e.target.value;
            const regex = /^[0-9]*$/;
            if (zip === "" || regex.test(zip)) {
              onChange(zip);
              setZip(zip);
            }
          }}
          inputProps={{ maxLength: 5 }}
          value={zip}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const Address = (props) => {
  const { place, setPlace, setAddressComponents, control, name } = props;

  console.log("logging place: ", place);

  const placesService = { current: null };

  const callback = (placeResult, status) => {
    console.log("inside of the callback");
    console.log(`the place details result: `, placeResult);

    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
      console.log("set address components 1: ", placeResult);
      setAddressComponents(placeResult);
    }
  };

  useEffect(() => {
    if (_.isNil(place)) {
      console.log("the place was nil");
      setAddressComponents();
      return;
    }

    if (!placesService.current && window.google) {
      let map = new window.google.maps.Map(document.createElement("div"));
      placesService.current = new window.google.maps.places.PlacesService(map);
    }

    if (!placesService.current) {
      return undefined;
    }

    const request = {
      placeId: place["place_id"],
      fields: ["address_components", "formatted_address"],
    };

    console.log("making place details request with place: ", place);

    if (!_.isNil(place["place_id"])) {
      placesService.current.getDetails(request, callback);
    }
  }, [place]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        columnGap: "1em",
      }}
    >
      <Controller
        control={control}
        name={name ? `${name}-address` : "address"}
        defaultValue={!_.isNil(place) ? place : ""}
        rules={{
          required: {
            value: true,
            message: "Address is required",
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <GooglePlacesAddress
            value={place}
            setValue={setPlace}
            onChange={onChange}
            error={error}
          />
        )}
      />
    </div>
  );
};

export const FullAddress = (props) => {
  const { control, fullAddress, setFullAddress, setValue } = props;

  console.log("the full address: ", fullAddress);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <StreetAddress
          control={control}
          name="address1"
          label="Address Line 1"
          required={true}
          streetAddress={fullAddress?.address1}
          setStreetAddress={(address1) => {
            setFullAddress({ ...fullAddress, address1: address1 });
          }}
        />

        <StreetAddress
          control={control}
          name="address2"
          label="Address Line 2"
          required={false}
          streetAddress={fullAddress?.address2}
          setStreetAddress={(address2) => {
            setFullAddress({ ...fullAddress, address2: address2 });
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "1em",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "50%",
          }}
        >
          <City
            control={control}
            city={fullAddress?.city}
            setCity={(city) => {
              setFullAddress({ ...fullAddress, city: city });
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "25%",
          }}
        >
          <StateCodeSelect
            setValue={setValue}
            control={control}
            stateCode={fullAddress?.state ? fullAddress?.state : null}
            setStateCode={(state) => {
              console.log("inside of stateCodeSelect setState: ", state);
              setFullAddress({
                ...fullAddress,
                state: state ? state : null,
                stateCode: state?.code ? state?.code : null,
              });
              // trigger("stateCode");
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "25%",
          }}
        >
          <Zip
            control={control}
            zip={fullAddress?.zip}
            setZip={(zip) => {
              setFullAddress({ ...fullAddress, zip: zip });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const BusinessType = (props) => {
  const { control, businessType, setBusinessType } = props;

  return (
    <Controller
      control={control}
      name="businessType"
      defaultValue={businessType ? businessType : ""}
      rules={{
        required: {
          value: true,
          message: "Business Type is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <FormControl margin="normal" fullWidth error={error !== undefined}>
          <InputLabel id="payment-frequency-label" shrink>
            Business Type
          </InputLabel>
          <Select
            notched
            labelId="business-type-label"
            id="business-type-select"
            value={businessType}
            label="Business Type"
            onChange={(e) => {
              const type = e.target.value;
              onChange(type);
              setBusinessType(type);
            }}
          >
            <MenuItem value={"Sole Proprietorship"}>
              Sole Proprietorship
            </MenuItem>
            <MenuItem value={"Corporation"}>Corporation</MenuItem>
            <MenuItem value={"LLC"}>LLC</MenuItem>
            <MenuItem value={"Partnership"}>Partnership</MenuItem>
          </Select>
          <FormHelperText>{error ? error.message : ""}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export const StreetAddress = (props) => {
  const { control, streetAddress, setStreetAddress, name, required } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={{
        required: {
          value: required ? true : false,
          message: "You must enter the street address",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          margin="normal"
          label="Street Address"
          type="text"
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setStreetAddress(curr);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          required={required}
          value={streetAddress}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export const City = (props) => {
  const { control, city, setCity } = props;

  return (
    <Controller
      control={control}
      name="city"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "You must enter the city",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          margin="normal"
          label="City"
          type="text"
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setCity(curr);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          required
          value={city}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export const StateCodeSelect = (props) => {
  const { control, stateCode, setStateCode, setValue } = props;
  const [inputValue, setInputValue] = useState("");

  const stateCodes = [
    { code: "AL", label: "Alabama" },
    {
      code: "AK",
      label: "Alaska",
    },
    { code: "AZ", label: "Arizona" },
    { code: "AR", label: "Arkansas" },
    { code: "CA", label: "California" },
    {
      code: "CO",
      label: "Colorado",
    },
    {
      code: "CT",
      label: "Connecticut",
    },
    {
      code: "DE",
      label: "Deleware",
    },
    {
      code: "DC",
      label: "District Of Columbia",
    },
    {
      code: "FL",
      label: "Florida",
    },
    {
      code: "GA",
      label: "Georgia",
    },
    {
      code: "HI",
      label: "Hiwaii",
    },
    {
      code: "ID",
      label: "Idaho",
    },
    {
      code: "IL",
      label: "Illinois",
    },
    {
      code: "IN",
      label: "Indiana",
    },
    {
      code: "IA",
      label: "Iowa",
    },
    {
      code: "KS",
      label: "Kansas",
    },
    {
      code: "KY",
      label: "Kentucky",
    },
    {
      code: "LA",
      label: "Louisiana",
    },
    {
      code: "ME",
      label: "Maine",
    },
    {
      code: "MD",
      label: "Maryland",
    },
    {
      code: "MA",
      label: "Massachusetts",
    },
    {
      code: "MI",
      label: "Michigan",
    },
    {
      code: "MI",
      label: "Minnesota",
    },
    {
      code: "MS",
      label: "Mississipi",
    },
    {
      code: "MO",
      label: "Missouri",
    },
    {
      code: "MT",
      label: "Montana",
    },
    {
      code: "NE",
      label: "Nebraska",
    },
    {
      code: "NV",
      label: "Nevada",
    },
    {
      code: "NH",
      label: "New Hampshire",
    },
    {
      code: "NJ",
      label: "New Jersey",
    },
    {
      code: "NM",
      label: "New Mexico",
    },
    {
      code: "NY",
      label: "New York",
    },
    {
      code: "NC",
      label: "North Carolina",
    },
    {
      code: "ND",
      label: "North Dakota",
    },
    {
      code: "OH",
      label: "Ohio",
    },
    {
      code: "OK",
      label: "Oklahoma",
    },
    {
      code: "OR",
      label: "Oregon",
    },
    {
      code: "PA",
      label: "Pennsylvania",
    },
    {
      code: "RI",
      label: "Rhode Island",
    },
    {
      code: "SC",
      label: "South Carolina",
    },
    {
      code: "SD",
      label: "South Dakota",
    },
    {
      code: "TN",
      label: "Tennessee",
    },
    {
      code: "TX",
      label: "Texas",
    },
    {
      code: "UT",
      label: "Utah",
    },
    {
      code: "VT",
      label: "Vermont",
    },
    {
      code: "VA",
      label: "Virginia",
    },
    {
      code: "WA",
      label: "Washington",
    },
    {
      code: "WV",
      label: "West Virgina",
    },
    {
      code: "WI",
      label: "Wisconsin",
    },
    {
      code: "WY",
      label: "Wyoming",
    },
  ];

  useEffect(() => {
    console.log("inside use effect the statecode: ", stateCode);

    if (typeof stateCode === "string" && !_.isEmpty(stateCode)) {
      console.log("the statecode: ", stateCode);
      let el = stateCodes.filter((curr) => curr.code === stateCode)[0];
      console.log("the el: ", el);
      setStateCode(el);
      setInputValue(el.label);
      setValue("stateCode", el.label);
    }
  }, [stateCode]);

  return (
    <Controller
      control={control}
      name="stateCode"
      defaultValue={stateCode}
      rules={{
        required: {
          value: true,
          message: "You must enter the state code",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Autocomplete
          id={`state-select-${stateCode}`}
          value={stateCode ? stateCode : ""}
          onChange={(event, newValue) => {
            console.log("changed: ", newValue);
            onChange(newValue);
            setStateCode(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          sx={{ width: 300 }}
          options={stateCodes}
          autoHighlight
          getOptionLabel={(option) => option.code || ""}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.code}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a State"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
              fullWidth
              error={error !== undefined}
              helperText={error ? error.message : ""}
              margin="normal"
            />
          )}
        />
      )}
    />
  );
};

export const BusinessClassificationSelect = (props) => {
  let {
    control,
    businessClassification,
    setBusinessClassification,
    industryClassification,
    setIndustryClassification,
    businessClassifications,
    setValue,
  } = props;

  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const [industryClassifications, setIndustryClassifications] = useState([]);

  useEffect(() => {
    console.log(
      "inside use effect the business Classification: ",
      businessClassification
    );
    console.log(
      "inside use effect the industry Classification: ",
      industryClassification
    );

    if (
      typeof businessClassification === "string" &&
      !_.isEmpty(businessClassification)
    ) {
      console.log("the businessClassification: ", businessClassification);
      let el = businessClassifications?.filter(
        (curr) => curr.id === businessClassification
      )[0];
      console.log("the el: ", el);

      if (el && el.name && el["_embedded"]) {
        setBusinessClassification(el);
        setInputValue1(el?.name);
        setValue("businessClassification", el?.name);
        setIndustryClassifications(el["_embedded"]["industryClassifications"]);
      }
    }

    if (
      typeof industryClassification === "string" &&
      !_.isEmpty(industryClassification) &&
      industryClassifications &&
      industryClassifications.length > 0
    ) {
      console.log("the industryClassification: ", industryClassification);
      let el = industryClassifications?.filter(
        (curr) => curr.id === industryClassification
      )[0];
      console.log("the el: ", el);
      if (el && el.name) {
        setIndustryClassification(el);
        setInputValue2(el?.name);
        setValue("industryClassification", el?.name);
      }
    }
  }, [
    businessClassification,
    businessClassifications,
    industryClassification,
    industryClassifications,
  ]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        columnGap: "1em",
      }}
    >
      <Controller
        control={control}
        name="businessClassification"
        defaultValue={null}
        rules={{
          required: {
            value: true,
            message: "You must enter the business classification",
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Autocomplete
            id="business-classification-select"
            value={businessClassification ? businessClassification : null}
            onChange={(event, newValue) => {
              console.log("business classification changed: ", newValue);
              onChange(newValue);
              setBusinessClassification(newValue);

              if (newValue && newValue["_embedded"]) {
                setIndustryClassifications(
                  newValue["_embedded"]["industryClassifications"]
                );
              }
            }}
            inputValue={inputValue1}
            onInputChange={(event, newInputValue) => {
              console.log("new input value: ", newInputValue);
              setInputValue1(newInputValue);
            }}
            sx={{ width: 300 }}
            options={businessClassifications ? businessClassifications : []}
            autoHighlight
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select the Business Classification"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                error={error !== undefined}
                helperText={error ? error.message : ""}
                fullWidth
                margin="normal"
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name="industryClassification"
        defaultValue=""
        rules={{
          required: {
            value: true,
            message: "You must enter the industry classification",
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Autocomplete
            id="industry-classification-select"
            value={industryClassification ? industryClassification : null}
            onChange={(event, newValue) => {
              console.log("changed: ", newValue);
              onChange(newValue);
              setIndustryClassification(newValue);
            }}
            inputValue={inputValue2}
            onInputChange={(event, newInputValue) => {
              setInputValue2(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => {
              if (option.id === value || option.id === value.id) {
                return true;
              } else {
                return false;
              }
            }}
            sx={{ width: 300 }}
            options={industryClassifications ? industryClassifications : []}
            autoHighlight
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select the Industry Classification"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                fullWidth
                error={error !== undefined}
                helperText={error ? error.message : ""}
                margin="normal"
              />
            )}
          />
        )}
      />
    </div>
  );
};

export const SocialSecurityNumberLastFour = (props) => {
  const { socialSecurityNumber, setSocialSecurityNumber, control } = props;

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Controller
      control={control}
      name="socialSecurityNumberLastFour"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Last four ssn is required",
        },
        pattern: {
          value: /^[0-9]{4}$/,
          message: "Invalid Social Security Number",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          onCut={preventDefault}
          onCopy={preventDefault}
          onPaste={preventDefault}
          label="SSN (Last 4)"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="socialSecurityNumLastFour"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const social = e.target.value;

            const regex = /^[0-9]*$/;

            if (!isNaN(social) && regex.test(social)) {
              onChange(social);
              setSocialSecurityNumber(social);
            }
          }}
          inputProps={{ maxLength: 4 }}
          value={socialSecurityNumber}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const SocialSecurityNumber = (props) => {
  const { socialSecurityNumber, setSocialSecurityNumber, control } = props;

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Controller
      control={control}
      name="socialSecurityNumber"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Social Security Number is required",
        },
        pattern: {
          value: /^[0-9]{9}$/,
          message: "Invalid Social Security Number",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          onCut={preventDefault}
          onCopy={preventDefault}
          onPaste={preventDefault}
          label="Social Security Number"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="socialSecurityNum"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const social = e.target.value;

            const regex = /^[0-9]*$/;

            if (!isNaN(social) && regex.test(social)) {
              onChange(social);
              setSocialSecurityNumber(social);
            }
          }}
          inputProps={{ maxLength: 9 }}
          value={socialSecurityNumber}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const IAmControllerCheckbox = (props) => {
  const { checked, setChecked, businessName, control } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Controller
        control={control}
        name="companyControllerCertify"
        defaultValue=""
        rules={{
          required: {
            value: true,
            message:
              "You must certify that you are a Company Controller to continue",
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  console.log("checkbox checked: ", e);
                  onChange(e);
                  setChecked(e);
                }}
                inputProps={{ "aria-label": "controlled" }}
                error={error !== undefined}
                helperText={error ? error.message : ""}
              />
            }
            label={`I hereby certify that I am a Controller for ${businessName}`}
          />
        )}
      />
      <Tooltip
        title="A controller is a natural person who holds significant responsibilities to control, manage or direct a company or other corporate entity (i.e. CEO, CFO, General Partner, President, etc)."
        arrow
      >
        <HelpOutlineIcon />
      </Tooltip>
    </div>
  );
};

export const EmployerIdentificationNumber = (props) => {
  const { ein, setEin, control } = props;

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Controller
      control={control}
      name="employerIdentificationNumber"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Employer Identification Number is required",
        },
        pattern: {
          value: /^[0-9]{9}$/,
          message: "Invalid Employer Identification Number",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          onCut={preventDefault}
          onCopy={preventDefault}
          onPaste={preventDefault}
          label="EIN"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="employerIdentificationNumber"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const num = e.target.value;

            const regex = /^[0-9]*$/;

            if (!isNaN(num) && regex.test(num)) {
              onChange(num);
              setEin(num);
            }
          }}
          inputProps={{ maxLength: 9 }}
          value={ein}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const BirthDate = (props) => {
  const { birthDate, setBirthDate, control } = props;

  return (
    <Controller
      control={control}
      name="birthDate"
      defaultValue={birthDate ? birthDate : ""}
      rules={{
        required: {
          value: true,
          message: "Birth Date is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="Birth Date"
            inputFormat="MM/DD/YYYY"
            value={birthDate}
            onChange={(newValue) => {
              onChange(newValue);
              setBirthDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error !== undefined}
                helperText={error ? error.message : ""}
                margin="normal"
                style={{ backgroundColor: "white" }}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export const AddressWithZip = (props) => {
  const { control, address, setAddress, name, setValue } = props;

  const zipFieldName = name ? `${name}-zip` : "zip";

  console.log("given address: ", address);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "1em",
        width: "100%",
      }}
    >
      <div style={{ width: "80%" }}>
        <Address
          name={name}
          control={control}
          place={
            address?.place
              ? address?.place
              : {
                  description: !_.isNil(address?.description)
                    ? address.description
                    : "",
                  ["place_id"]: address?.placeId,
                }
          }
          setPlace={(place) => {
            setAddress({ ...address, place: place });
          }}
          setAddressComponents={(placeDetails) => {
            console.log("set address components 2: ", placeDetails);
            if (_.isNil(placeDetails)) {
              setAddress({ ...address, city: "", state: "", zip: "" });
              setValue(zipFieldName, "", {
                shouldValidate: true,
              });

              return;
            }

            const addressComponents = placeDetails["address_components"];

            console.log("address components: ", addressComponents);

            const streetNumComponent = addressComponents.filter((component) => {
              return component["types"].includes("street_number");
            });

            const routeComponent = addressComponents.filter((component) => {
              return component["types"].includes("route");
            });

            const cityComponent = addressComponents.filter((component) => {
              return component["types"].includes("locality");
            });
            const stateComponent = addressComponents.filter((component) => {
              return component["types"].includes("administrative_area_level_1");
            });
            const zipComponent = addressComponents.filter((component) => {
              return component["types"].includes("postal_code");
            });

            let streetNum;
            let street;
            let addressCity;
            let addressState;
            let addressZip;

            if (
              !_.isNil(streetNumComponent) &&
              streetNumComponent.length != 0
            ) {
              console.log(
                "setting streetNum: ",
                streetNumComponent[0]["short_name"]
              );
              streetNum = streetNumComponent[0]["short_name"];
            }

            if (!_.isNil(routeComponent) && routeComponent.length != 0) {
              console.log("setting street: ", routeComponent[0]["short_name"]);
              street = routeComponent[0]["short_name"];
            }

            if (!_.isNil(cityComponent) && cityComponent.length != 0) {
              console.log("setting city: ", cityComponent[0]["short_name"]);
              addressCity = cityComponent[0]["short_name"];
            }

            if (!_.isNil(stateComponent) && stateComponent.length != 0) {
              console.log("setting state: ", stateComponent[0]["short_name"]);
              addressState = stateComponent[0]["short_name"];
            }

            if (!_.isNil(zipComponent) && zipComponent.length != 0) {
              console.log("setting zip: ", zipComponent[0]["short_name"]);

              setValue(zipFieldName, zipComponent[0]["short_name"], {
                shouldValidate: true,
              });

              addressZip = zipComponent[0]["short_name"];
            }

            setAddress({
              ...address,
              addressLine1: `${streetNum} ${street}`,
              city: addressCity,
              state: addressState,
              zip: Number(addressZip),
            });
          }}
        />
      </div>

      <div style={{ width: "20%" }}>
        <Zip name={name} control={control} zip={address?.zip} disabled={true} />
      </div>
    </div>
  );
};

export const RoleSelection = (props) => {
  const { role, setRole } = props;

  const roles = [
    {
      name: "Admin",
      value: ROLE_LENDER_ADMIN,
      description: "Gives the user full access to the application.",
    },
    {
      name: "User",
      value: ROLE_LENDER_USER,
      description: "Gives the user access to their own data only.",
    },
  ];

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Role</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={role}
        onChange={(e) => {
          const role = e.target.value;
          setRole(role);
        }}
      >
        {roles.map((role) => {
          return (
            <>
              <Tooltip title={role.description} placement="right">
                <FormControlLabel
                  value={role.value}
                  control={<Radio />}
                  label={role.name}
                />
              </Tooltip>
            </>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export const PasswordWithStrength = (props) => {
  const { password, setPassword, control } = props;
  const [passwordVisible, setPasswordVisible] = useState();
  const [meter, setMeter] = useState(false);

  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;
  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{8,}/g; // eight characters or more

  const passwordTracker = {
    uppercase: password.match(atLeastOneUppercase),
    lowercase: password.match(atLeastOneLowercase),
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const createErrorMessage = () => {
    let errors = [];

    if (passwordStrength < 5) {
      if (!passwordTracker.uppercase) {
        errors = [...errors, "at least 1 uppercase character"];
      }

      if (!passwordTracker.lowercase) {
        errors = [...errors, "at least 1 lowercase character"];
      }

      if (!passwordTracker.specialChar) {
        errors = [...errors, "at least 1 special character"];
      }

      if (!passwordTracker.number) {
        errors = [...errors, "at least 1 number"];
      }

      if (!passwordTracker.eightCharsOrGreater) {
        errors = [...errors, "be eight characters or more"];
      }
    }

    if (!_.isNil(errors) && errors.length != 0) {
      return "Your password must contain: " + errors.join(", ");
    }

    return errors;
  };

  return (
    <Controller
      control={control}
      name="password"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Password is required",
        },

        pattern: {
          value: passwordRegex,
          message: "The password does not meet the requirements",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          <TextField
            autoComplete="off"
            margin="normal"
            label="Password"
            type={passwordVisible ? "text" : "password"}
            onFocus={() => setMeter(true)}
            onChange={(e) => {
              const curr = e.target.value;
              onChange(curr);
              setPassword(curr);
            }}
            error={error !== undefined}
            required
            value={password}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {meter && (
            <div>
              <div className="password-strength-meter"></div>
              <div>{createErrorMessage()}</div>
            </div>
          )}
          <style jsx>
            {`
              .password-strength-meter {
                height: 0.3rem;
                background-color: lightgrey;
                border-radius: 3px;
                margin: 0.5rem 0;
              }

              .password-strength-meter::before {
                content: "";
                background-color: ${[
                  "red",
                  "orange",
                  "#03a2cc",
                  "#03a2cc",
                  "#0ce052",
                ][passwordStrength - 1] || ""};
                height: 100%;
                width: ${(passwordStrength / 5) * 100}%;
                display: block;
                border-radius: 3px;
                transition: width 0.2s;
              }
            `}
          </style>
        </>
      )}
    />
  );
};

export const ConfirmPassword = (props) => {
  const { password, confirmPassword, setConfirmPassword, control } = props;
  const [passwordVisible, setPasswordVisible] = useState();

  const handleValidatePassword = (curr) => {
    if (curr != password) {
      return "The passwords do not match";
    }
  };

  return (
    <Controller
      control={control}
      name="confirmPassword"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "You must confirm your password",
        },

        validate: handleValidatePassword,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          margin="normal"
          label="Confirm Password"
          type={passwordVisible ? "text" : "password"}
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setConfirmPassword(curr);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          required
          value={confirmPassword}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            form: {
              autocomplete: "off",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const CreatePassword = (props) => {
  const {
    control,
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
  } = props;

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <PasswordWithStrength
        control={control}
        password={password}
        setPassword={(curr) => {
          setPassword(curr);
        }}
      />

      <ConfirmPassword
        control={control}
        password={password}
        confirmPassword={confirmPassword}
        setConfirmPassword={(curr) => {
          setConfirmPassword(curr);
        }}
      />
    </div>
  );
};

export const EnterPassword = (props) => {
  const { password, setPassword, control } = props;
  const [passwordVisible, setPasswordVisible] = useState();

  return (
    <Controller
      control={control}
      name="confirmPassword"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Password is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          autoComplete="new-password"
          margin="normal"
          label="Password"
          type={passwordVisible ? "text" : "password"}
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setPassword(curr);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          required
          value={password}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            form: {
              autoComplete: "off",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const PinPad = (props) => {
  const { pinLength, pins, setPins, control, errors } = props;
  const pinRefs = useRef([]);

  let pinError;

  if (!_.isNil(errors)) {
    pinError = Object.keys(errors).some((error) => error.includes("pins-"));
  }

  console.log("errors: ", errors);

  console.log("the controller: ", control);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          columnGap: "1em",
          flexDirection: "row",
        }}
      >
        {[...Array(pinLength).keys()].map((index) => {
          return (
            <Controller
              control={control}
              name={`pins-${index}`}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                },
              }}
              render={({ field: { onChange } }) => (
                <TextField
                  autoComplete="off"
                  margin="normal"
                  style={{ backgroundColor: "white" }}
                  required
                  value={pins[index] ? pins[index] : ""}
                  id={index}
                  key={`pin-${index}`}
                  name={`pin-${index}`}
                  inputRef={(input) => (pinRefs.current[index] = input)}
                  variant="outlined"
                  inputProps={{
                    autoFocus: index === 0 ? true : false,
                    maxLength: 1,
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={errors[`pins-${index}`] !== undefined}
                  onKeyDown={(e) => {
                    if (e.key === "Delete" || e.key === "Backspace") {
                      console.log("delete pressed");
                      const newPins = [...pins];

                      newPins[index] = "";
                      setPins(newPins);
                      onChange(newPins[index]);
                      console.log(`newPins: `, newPins);
                      if (index > 0) {
                        pinRefs.current[index - 1].focus();
                      }
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const num = e.target.value;
                    const regex = /^[0-9]*$/;

                    if (!isNaN(num) && regex.test(num)) {
                      const newPins = [...pins];
                      newPins[index] = num;
                      setPins(newPins);
                      onChange(newPins[index]);

                      if (index < pinLength - 1) {
                        pinRefs.current[index + 1].focus();
                      }
                      console.log("pins: ", newPins);
                    }
                  }}
                />
              )}
            />
          );
        })}
      </div>
      <div>
        {pinError && <p>You must enter the 6 digit verification code</p>}
      </div>
    </div>
  );
};

export const FoundedDate = (props) => {
  const { foundedDate, setFoundedDate, control } = props;

  console.log("founded date from founded date: ", foundedDate);

  return (
    <Controller
      control={control}
      name="foundedDate"
      defaultValue={foundedDate}
      rules={{
        required: {
          value: true,
          message: "Founded Date is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="Founded Date"
            inputFormat="MM/DD/YYYY"
            value={foundedDate}
            onChange={(newValue) => {
              onChange(newValue);
              setFoundedDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error !== undefined}
                helperText={error ? error.message : ""}
                margin="normal"
                style={{ backgroundColor: "white" }}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};
