import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";

import { updateAccountSetupAction } from "../../models/account/accountActions";
import { ChangePasswordStep } from "../ChangePasswordStep/ChangePasswordStep";
import _ from "lodash";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import HorizontalStepper from "../../components/HorizontalStepper/HorizontalStepper";
import { makeStyles } from "@mui/styles";
import CreateBusiness from "../../components/CreateBusiness/CreateBusiness";
import {
  acceptMerchantInviteThunk,
  createMerchantBusinessDetailsThunk,
  createMerchantThunk,
  createPlaidFundingSourceThunk,
  getMerchantThunk,
  getPendingMerchantInvitesThunk,
  setCurrentUserDefaultMerchantThunk,
  unlinkFundingSourceThunk,
} from "../../models/merchant/merchantThunks";
import LinkAccountScreen from "../LinkAccountStep/LinkAccountScreen";
import { CircularProgress } from "@mui/material";
import {
  acceptMerchantInviteSuccess,
  resetCreateUpdateMerchantSuccess,
  setSelectedMerchant,
} from "../../models/merchant/merchantReducer";
import moment from "moment";
import InviteCards from "../../components/InviteCards/InviteCards";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      margin: "0px",
    },
  },
  [theme.breakpoints.up("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "500px",
      height: "400px",
      marginTop: "0em",
    },
  },
}));

const freshMerchant = {
  businessName: "",
  businessType: "",
  businessClassification: "",
  industryClassification: "",
  dateOfBirth: null,

  fullAddress: {
    address1: "",
    address2: "",
    zip: "",
    city: "",
    stateCode: "",
  },
  controller: {
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    ssn: "",
    email: "",
    title: "",
    address: {
      address1: "",
      address2: "",
      zip: "",
      city: "",
      stateCode: "",
    },
  },
};

const MerchantAccountSetupScreen = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const accountSetup = account.accountSetup;
  const merchantSlice = useSelector((state) => state.merchants);
  const merchant = merchantSlice.merchant;

  const dwolla = useSelector((state) => state.dwolla);

  const [businessInfo, setBusinessInfo] = useState(freshMerchant);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  //   const [selectedInvite, setSelectedInvite] = useState(null);

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
    setValue,
    reset,
  } = useForm();

  const dispatch = useDispatch();

  const getActiveStep = () => {
    switch (accountSetup.activeStep) {
      case "CHANGE_PASSWORD":
        return 0;
      case "PENDING_INVITE_SELECTION":
        return 1;
      case "BUSINESS_DETAILS":
      case "CONTROLLER_DETAILS":
      case "ADD_OWNERS_YES_NO":
      case "ADD_OWNERS_YES":
      case "ADD_OWNERS_NO":
        return 2;
      case "LINK_BANK_ACCOUNT":
        return 3;
      case "ACCEPT_TERMS_CONDITIONS":
      case "COMPLETED_SETUP":
        return 4;
      default:
        return 0;
    }
  };

  const canProceedToLinkAccount = () => {
    if (_.isNil(merchant) || _.isNil(merchant.id)) {
      console.log("can proceed 1");
      return true;
    }

    if (
      businessInfo.hasBeneficialOwners === "true" ||
      merchant?.beneficialOwners?.length > 0 ||
      dwolla?.owners?.length > 0
    ) {
      // if the user indicated that the business has beneficial owners, but did not save any,
      // do not proceed
      if (
        (_.isNil(dwolla.owners) || dwolla?.owners?.length === 0) &&
        merchant.beneficialOwners.length === 0
      ) {
        console.log("can proceed 2");

        return true;
      }
      console.log("can proceed 3");

      // if there are beneficial owners listed, but the user did not confirm that the info is complete/correct, do not proceed
      return !businessInfo.certifiedCompleteAndCorrect;
    } else {
      console.log("can proceed 4");

      // if there were no beneficial owners, the user has to confirm that there are none + the info is complete/correct
      return !(
        businessInfo.certifiedNoBeneficialOwners &&
        businessInfo.certifiedCompleteAndCorrect
      );
    }
  };

  useEffect(() => {
    dispatch(getPendingMerchantInvitesThunk());
  }, []);

  useEffect(() => {
    console.log("in the use effect that gets merchant ", account.account);
    if (!_.isNil(account?.account?.defaultMerchantId)) {
      dispatch(getMerchantThunk(account.account.defaultMerchantId));
    }
  }, []);

  useEffect(() => {
    if (!_.isNil(merchant?.id)) {
      const mergedInfo = {
        ...merchant,
        dateOfBirth: null,
        businessClassification: merchant.businessClassification,
        industryClassification: merchant.industryClassification,
        fullAddress: {
          address1: merchant.address1,
          address2: merchant.address2,
          zip: merchant.postalCode,
          city: merchant.city,
          state: merchant.state,
        },
        controller: {
          ...merchant.controller,
          address: {
            ...merchant?.controller?.address,
            zip: merchant?.controller?.address?.postalCode,
            state: merchant?.controller?.address?.stateProvinceRegion,
          },
        },
      };

      console.log("setting the business info: ", mergedInfo);
      setBusinessInfo(mergedInfo);
      setValue("businessName", merchant.businessName);
      setValue("businessType", merchant.businessType);
      setValue("address1", merchant.address1);
      setValue("address2", merchant.address2);
      setValue("city", merchant.city);
      setValue("zip", merchant.postalCode);

      setValue("birthDate", merchant.dateOfBirth);
    }
  }, [merchant?.id]);

  React.useEffect(() => {
    if (account?.account && accountSetup?.activeStep === "COMPLETED_SETUP") {
      console.log("the account is setup");

      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [accountSetup?.activeStep]);

  const changePasswordStep = {
    title: "Password update required",
    subTitle: "Please update your password",
    handleSubmit: async () => {
      dispatch(updateAccountSetupAction("PENDING_INVITE_SELECTION"));
    },
    handleBack: () => {
      console.log("no op");
    },
    isNextDisabled: account?.account?.hasTempPassword ? true : false,

    panel: (
      <div className={classes.panel}>
        <ChangePasswordStep
          handleSubmit={handleSubmit}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          currentPassword={currentPassword}
          setCurrentPassword={setCurrentPassword}
          confirmNewPassword={confirmNewPassword}
          setConfirmNewPassword={setConfirmNewPassword}
          control={control}
        />
      </div>
    ),
  };

  const pendingInviteSelectStep = {
    title: "Invitation Selection",
    subTitle: "",
    handleSubmit: async () => {
      dispatch(updateAccountSetupAction("BUSINESS_DETAILS"));
    },
    handleBack: () => {
      dispatch(updateAccountSetupAction("CHANGE_PASSWORD"));
    },
    isNextDisabled: !merchant?.id ? true : false,

    panel: (
      <div className={classes.panel}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>Select a pending invite to set up your merchant account</h3>
          <InviteCards
            selectedInvite={merchant}
            setSelectedInvite={async (invite) => {
              if (invite?.id) {
                await dispatch(setCurrentUserDefaultMerchantThunk(invite?.id));
              }
              dispatch(setSelectedMerchant(invite));
            }}
            invites={merchantSlice.pendingMerchants}
          />
        </div>
      </div>
    ),
  };

  const businessDetailsStep = {
    title: "Business",
    handleSubmit: async () => {
      dispatch(updateAccountSetupAction("LINK_BANK_ACCOUNT"));
    },
    handleBack: () => {
      dispatch(updateAccountSetupAction("PENDING_INVITE_SELECTION"));
    },
    // isNextDisabled: canProceedToLinkAccount(),
    isNextDisabled:
      dwolla?.certificationStatus?.status != "certified" ||
      !merchant?.dwollaCustomerId,

    panel: (
      <div className={classes.panel}>
        <CreateBusiness
          org={merchant}
          resetCreateUpdateOrgSuccess={resetCreateUpdateMerchantSuccess}
          defaultOrgId={account?.account?.defaultMerchantId}
          loading={merchantSlice.loading}
          createOrgSuccess={merchantSlice.createMerchantSuccess}
          updateOrgSuccess={merchantSlice.updateMerchantSuccess}
          getOrgThunk={getMerchantThunk}
          createOrgThunk={createMerchantBusinessDetailsThunk}
          trigger={trigger}
          reset={reset}
          setValue={setValue}
          isDirty={isDirty}
          isValid={isValid}
          control={control}
          handleSubmit={handleSubmit}
          businessInfo={businessInfo}
          setBusinessInfo={setBusinessInfo}
          useAccountSetup={true}
          getActiveStep={() => {
            if (accountSetup.activeStep === "BUSINESS_DETAILS") {
              return 0;
            } else if (accountSetup.activeStep === "CONTROLLER_DETAILS") {
              return 1;
            } else if (
              accountSetup.activeStep === "ADD_OWNERS_YES_NO" ||
              accountSetup.activeStep === "ADD_OWNERS_YES" ||
              accountSetup.activeStep === "ADD_OWNERS_NO"
            ) {
              return 2;
            }
          }}
        />
      </div>
    ),
  };

  const linkAccountStep = {
    title: "Link your Business Bank Account",
    handleSubmit: () => {
      dispatch(updateAccountSetupAction("ACCEPT_TERMS_CONDITIONS"));
    },
    handleBack: () => {
      if (merchant.businessType === "Sole Proprietorship") {
        dispatch(updateAccountSetupAction("BUSINESS_DETAILS"));
      } else if (merchant?.beneficialOwners?.length > 0) {
        dispatch(updateAccountSetupAction("ADD_OWNERS_YES"));
      } else if (merchant?.beneficialOwners?.length == 0) {
        dispatch(updateAccountSetupAction("ADD_OWNERS_NO"));
      } else {
        dispatch(updateAccountSetupAction("ADD_OWNERS_YES_NO"));
      }
    },

    isNextDisabled: !merchant?.fundingSources?.length > 0,

    panel: (
      <div className={classes.panel}>
        <LinkAccountScreen
          loading={merchantSlice?.loading}
          org={merchant}
          defaultOrgId={account?.account?.defaultMerchantId}
          getOrgThunk={getMerchantThunk}
          createPlaidFundingSourceThunk={createPlaidFundingSourceThunk}
          unlinkFundingSourceThunk={unlinkFundingSourceThunk}
        />
      </div>
    ),
  };

  const termsAndConditionsStep = {
    title: "Terms & Conditions",
    subTitle: "You must read and accept the Terms & Conditions to continue.",
    handleSubmit: () => {
      dispatch(updateAccountSetupAction("COMPLETED_SETUP"));
    },
    handleBack: () => {
      dispatch(updateAccountSetupAction("LINK_BANK_ACCOUNT"));
    },
    isNextDisabled: !acceptedTerms,

    panel: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "600px",
          marginTop: "0em",
        }}
      >
        {accountSetup.activeStep === "ACCEPT_TERMS_CONDITIONS" ? (
          <TermsAndConditions
            acceptedTerms={acceptedTerms}
            setAcceptedTerms={setAcceptedTerms}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              rowGap: "2em",
            }}
          >
            <h2>Hang tight while we get your account all set up</h2>
            <CircularProgress />
          </div>
        )}
      </div>
    ),
  };

  const accountSetupSteps = [
    changePasswordStep,
    pendingInviteSelectStep,
    businessDetailsStep,
    linkAccountStep,
    termsAndConditionsStep,
  ];

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%", height: "90%" }}>
        <HorizontalStepper
          activeStep={getActiveStep()}
          stepperTitle={<h1 style={{ margin: "0px" }}>Welcome</h1>}
          stepperSubtitle={<h3>Let's get your account all set up</h3>}
          stepperCompletedMessage={"Hang tight while we create your account"}
          acceptedTerms={acceptedTerms}
          steps={accountSetupSteps}
          control={control}
          isDirty={isDirty}
          isValid={isValid}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      </div>
    </div>
  );
};

export default MerchantAccountSetupScreen;
