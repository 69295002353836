import { createSlice } from "@reduxjs/toolkit";

interface LenderKPIs {
  totalFundedAmount: number | null;
  averageFactorRate: number | null;
  defaultRate: number | null;
  totalProfit: number | null;
  totalCompletedDeals: number | null;
  totalOpenDeals: number | null;
  totalInvestors: number | null;
}

interface LenderState {
  lender: any;
  lenders: any[];
  lenderUsers: any[];
  ownedLenders: any[];
  error: string | null;
  selectedLenderMember: any | null;
  currentAccountLenderMember: any | null;
  kpis: LenderKPIs | {};
  pendingInvites: any[];
  loading: boolean;
  updateLenderSuccess: boolean;
  createLenderSuccess: boolean;
  investors: any[];
}

const initialState: LenderState = {
  lender: undefined,
  error: null,
  lenders: [],
  lenderUsers: [],
  ownedLenders: [],
  selectedLenderMember: null,
  currentAccountLenderMember: null,
  kpis: {},
  pendingInvites: [],
  loading: false,
  updateLenderSuccess: true,
  createLenderSuccess: true,
  investors: [],
};

const lenderSlice = createSlice({
  name: "lenders",
  initialState,
  reducers: {
    getLenderSuccess(state, action) {
      state.lender = action.payload;
      state.loading = false;
      state.error = null;
    },
    getLenderUsersSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.lenderUsers = action.payload;
    },
    getLendersSuccess(state, action) {
      state.lenders = action.payload;
      state.loading = false;
      state.error = null;
    },
    getOwnedLendersSuccess(state, action) {
      state.ownedLenders = action.payload;
      state.loading = false;
      state.error = null;
    },
    createLenderSuccess(state, action) {
      state.lender = action.payload;
      state.loading = false;
      state.lenders = [...state.lenders, action.payload];
      state.createLenderSuccess = true;
      state.error = null;
    },
    updateLenderSuccess: (state, action) => {
      const updatedLender = action.payload;
      const index = state.lenders.findIndex(
        (lender) => lender.id === updatedLender.id
      );
      if (index !== -1) {
        state.lenders[index] = updatedLender;
      }
      state.lender = updatedLender;
      state.updateLenderSuccess = true;
      state.loading = false;
      state.error = null;
    },
    getCurrentAccountOrganizationUserSuccess(state, action) {
      state.currentAccountLenderMember = action.payload;
      state.error = null;
      state.loading = false;
    },
    resetCreateUpdateLenderSuccess(state, action) {
      state.createLenderSuccess = false;
      state.updateLenderSuccess = false;

      state.error = null;
      state.loading = false;
    },
    sendLenderInviteSuccess(state, action) {
      state.error = null;
      state.loading = false;
    },

    getLenderInvestorsSuccess(state, action) {
      state.error = null;
      state.loading = false;
      state.investors = action.payload;
    },

    getLenderKPIsSuccess(state, action) {
      state.kpis = action.payload;
      state.error = null;
      state.loading = false;
    },
    loading(state, action) {
      state.loading = action.payload;
    },
    setSelectedLender: (state, action) => {
      state.lender = action.payload;
      state.loading = false;
    },
    lenderFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  getLenderSuccess,
  getLendersSuccess,
  getLenderUsersSuccess,
  getOwnedLendersSuccess,
  getLenderInvestorsSuccess,
  getLenderKPIsSuccess,
  createLenderSuccess,
  updateLenderSuccess,
  resetCreateUpdateLenderSuccess,
  setSelectedLender,
  sendLenderInviteSuccess,
  getCurrentAccountOrganizationUserSuccess,
  loading,
  lenderFailure,
} = lenderSlice.actions;

export default lenderSlice.reducer;
