import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

import _ from "lodash";
import NumericFormatCustom from "./NumberFormatCustom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useDispatch } from "react-redux";
import { changeUserInvestmentStatusAction } from "../../models/deal/dealThunks";
import moment from "moment";
import { useState } from "react";

function disableWeekends(date) {
  const weekday = date.format("dddd"); // Monday ... Sunday
  const isWeekend = weekday === "Sunday" || weekday === "Saturday";
  return isWeekend;
}

function disableWeekendsAndDaysLessThanOneWeekFromToday(date) {
  const startOfToday = moment().startOf("day");
  const oneWeekFromToday = startOfToday.add(1, "week");

  if (date < oneWeekFromToday || disableWeekends(date)) {
    return true;
  }
}

export const MyInvestmentPercent = (props) => {
  const MIN = 0.0;
  const STEP = 1.0;

  const { max, investorContribution, control, investment } = props;
  const dispatch = useDispatch();

  const { investor, contribution } = investorContribution;
  const { id } = investment;

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "1em",
        }}
      >
        <ProfileAvatar
          size={50}
          url={investor?.user?.imageUrl}
          name={investor?.name}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ fontWeight: "bold", margin: "0px" }}>{investor.name} </p>

          <Typography variant="body2" color="text.secondary">
            {investor.email}
          </Typography>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          columnGap: "1em",
        }}
      >
        <div style={{ width: "65%", alignItems: "center", display: "flex" }}>
          <Slider
            disabled={true}
            value={Number(contribution)}
            onChange={() => {}}
            aria-labelledby="input-slider"
            step={STEP}
            min={MIN}
            max={100}
          />
        </div>
        <div
          style={{
            width: "35%",
            display: "flex",
            columnGap: "1em",
            alignItems: "center",
          }}
        >
          <InvestmentPercent
            disabled={true}
            max={max}
            step={STEP}
            control={control}
            amount={contribution}
            setAmount={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export const InvestmentPercentWithSlider = (props) => {
  const MIN = 0.0;
  const STEP = 1.0;

  const {
    setAmount,
    setLocked,
    max,
    investorContribution,
    removeInvestor,
    control,
    deal,
  } = props;

  let { investor, id, contribution, locked } = investorContribution;

  if (investor.user) {
    investor = investor.user;
  }

  const fullName = investor.name
    ? investor.name
    : `${investor.firstName} ${investor.lastName}`;

  console.log("investorContribution!!!: ", investorContribution);

  const handleSliderChange = (event, newValue) => {
    if (newValue <= max) {
      setAmount(newValue);
    }
  };

  const handleInputChange = (rate) => {
    if (rate <= max) {
      setAmount(rate === "" ? "" : Number(rate));
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "1em",
        }}
      >
        <ProfileAvatar size={50} url={investor?.imageUrl} name={fullName} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ fontWeight: "bold", margin: "0px" }}>{fullName} </p>

          <Typography variant="body2" color="text.secondary">
            {investor.email}
          </Typography>
        </div>

        {deal.dealStatus === "Needs Action" && (
          <IconButton aria-label="remove" size="large">
            <RemoveCircleOutlineIcon
              onClick={() => {
                console.log("remove investor: ", id);
                removeInvestor(id);
              }}
              fontSize="inherit"
            />
          </IconButton>
        )}
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          columnGap: "1em",
        }}
      >
        <div style={{ width: "65%", alignItems: "center", display: "flex" }}>
          <Slider
            disabled={locked}
            value={Number(contribution)}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={STEP}
            min={MIN}
            max={100}
          />
        </div>
        <div
          style={{
            width: "35%",
            display: "flex",
            columnGap: "1em",
            alignItems: "center",
          }}
        >
          <InvestmentPercent
            disabled={locked}
            max={max}
            step={STEP}
            control={control}
            amount={contribution}
            setAmount={handleInputChange}
          />
          <div>
            <IconButton
              aria-label="lock"
              size="large"
              onClick={() => {
                setLocked();
              }}
            >
              {locked ? (
                <LockIcon fontSize="inherit" />
              ) : (
                <LockOpenIcon fontSize="inherit" />
              )}
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InvestmentPercent = (props) => {
  const { amount, setAmount, max, control, disabled } = props;

  const handleValidateAmount = (number) => {
    console.log("validity: ", number);
    const isValid = number >= 0 && number <= max;
    if (!isValid) {
      return "The Amount is invalid";
    }
  };

  return (
    <Controller
      control={control}
      name="investmentPercent"
      defaultValue={amount}
      rules={{
        validate: handleValidateAmount,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          disabled={disabled}
          label="Contribution %"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="investmentPercent"
          type="number"
          value={amount}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            step: 1.0,
            min: 0,
            max: max,
          }}
          onChange={(e) => {
            const percent = e.target.value;
            onChange(percent);
            setAmount(percent);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const NumberOfPaymentsWithSlider = (props) => {
  const STEP = 1;

  const { numberOfPayments, setNumberOfPayments, min, max, control } = props;

  const handleSliderChange = (event, newValue) => {
    setNumberOfPayments(newValue);
  };

  const handleInputChange = (rate) => {
    setNumberOfPayments(rate === "" ? "" : Number(rate));
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        columnGap: "1em",
      }}
    >
      <div style={{ width: "65%", alignItems: "center", display: "flex" }}>
        <Slider
          value={Number(numberOfPayments)}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          step={STEP}
          min={min}
          max={max}
        />
      </div>
      <div style={{ width: "35%" }}>
        <NumberOfPayments
          min={min}
          max={max}
          step={STEP}
          control={control}
          numberOfPayments={numberOfPayments}
          setNumberOfPayments={handleInputChange}
        />
      </div>
    </div>
  );
};

export const NumberOfPayments = (props) => {
  const { numberOfPayments, setNumberOfPayments, min, max, step, control } =
    props;

  const handleValidateNumberOfPayments = (number) => {
    console.log("validity: ", number);
    const isValid = number >= min && number <= max;
    if (!isValid) {
      return "The number is invalid";
    }
  };

  return (
    <Controller
      control={control}
      name="numberOfPayments"
      defaultValue={numberOfPayments}
      // rules={{
      //   validate: handleValidateNumberOfPayments,
      // }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="# Of Payments"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="numberOfPayments"
          type="number"
          value={numberOfPayments}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            step: step ? step : 1,
            min: min ? min : 10,
            max: max ? max : 365,
          }}
          onChange={(e) => {
            const rate = Math.floor(e.target.value);
            onChange(rate);
            setNumberOfPayments(rate);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const PaymentAmountWithSlider = (props) => {
  const STEP = 1;

  const { paymentAmount, setPaymentAmount, max, min, control } = props;

  console.log("max: ", max);
  console.log("min: ", min);

  const handleSliderChange = (event, newValue) => {
    setPaymentAmount(newValue);
  };

  const handleInputChange = (rate) => {
    setPaymentAmount(rate === "" ? "" : Number(rate));
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        columnGap: "1em",
      }}
    >
      <div style={{ width: "65%", alignItems: "center", display: "flex" }}>
        <Slider
          value={Number(paymentAmount).toFixed(2)}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          step={STEP}
          min={min}
          max={max}
        />
      </div>
      <div style={{ width: "35%" }}>
        <PaymentAmount
          control={control}
          amount={paymentAmount}
          setAmount={handleInputChange}
        />
      </div>
    </div>
  );
};

export const PaymentFrequency = (props) => {
  const { paymentFrequency, setPaymentFrequency, control } = props;

  console.log("given frequency: ", paymentFrequency);

  return (
    <Controller
      control={control}
      name="paymentFrequency"
      defaultValue={paymentFrequency}
      rules={{
        required: {
          value: true,
          message: "Payment Frequency is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <FormControl margin="normal" fullWidth error={error !== undefined}>
          <InputLabel id="payment-frequency-label" shrink>
            Payment Frequency
          </InputLabel>
          <Select
            notched
            labelId="payment-frequency-label"
            id="demo-simple-select"
            value={paymentFrequency}
            label="Payment Frequency"
            onChange={(e) => {
              console.log("frequency: ", e.target.value);
              const frequency = e.target.value;
              onChange(frequency);
              setPaymentFrequency(frequency);
            }}
          >
            <MenuItem value={"Minutely"}>Minutely</MenuItem>
            <MenuItem value={"Hourly"}>Hourly</MenuItem>
            <MenuItem value={"Daily"}>Daily</MenuItem>
            <MenuItem value={"Weekly"}>Weekly</MenuItem>
            <MenuItem value={"Monthly"}>Monthly</MenuItem>
          </Select>
          <FormHelperText>{error ? error.message : ""}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export const FirstPaymentDate = (props) => {
  const { firstPaymentDate, setFirstPaymentDate, control } = props;

  return (
    <Controller
      control={control}
      name="firstPaymentDate"
      defaultValue={firstPaymentDate}
      rules={{
        required: {
          value: true,
          message: "First Payment Date is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="First Payment Date"
            inputFormat="MM/DD/YYYY"
            value={firstPaymentDate}
            // shouldDisableDate={disableWeekendsAndDaysLessThanOneWeekFromToday}
            onChange={(newValue) => {
              onChange(newValue);
              setFirstPaymentDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error !== undefined}
                helperText={error ? error.message : ""}
                margin="normal"
                style={{ backgroundColor: "white" }}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export const FundedDate = (props) => {
  const { fundedDate, setFundedDate, control } = props;

  return (
    <Controller
      control={control}
      name="fundedDate"
      defaultValue={fundedDate}
      rules={{
        required: {
          value: true,
          message: "Funded Date is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="Funded Date"
            inputFormat="MM/DD/YYYY"
            value={fundedDate}
            shouldDisableDate={disableWeekends}
            onChange={(newValue) => {
              onChange(newValue);
              setFundedDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error !== undefined}
                helperText={error ? error.message : ""}
                margin="normal"
                style={{ backgroundColor: "white" }}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export const TotalProfit = (props) => {
  const { amount, control } = props;

  return (
    <Controller
      control={control}
      name="profit"
      defaultValue=""
      rules={{
        required: {
          value: false,
          message: "Profit is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          disabled
          label="Total Profit"
          value={amount}
          name="numberformat"
          id="formatted-numberformat-input"
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export const PaymentAmount = (props) => {
  const { amount, setAmount, control } = props;

  const handleValidatePaymentAmount = (number) => {
    const isValid = number >= 0;
    if (!isValid) {
      return "The Payment Amount is invalid";
    }
  };

  return (
    <Controller
      control={control}
      name="paymentAmount"
      defaultValue=""
      rules={{
        required: {
          value: false,
          message: "Payment Amount is required",
        },
        validate: handleValidatePaymentAmount,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Payment Amount"
          value={amount}
          onChange={(e) => {
            const value = e.target.value;

            onChange(value);
            setAmount(value);
          }}
          name="numberformat"
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export const TotalPaybackAmount = (props) => {
  const { amount, control } = props;

  return (
    <Controller
      control={control}
      name="paybackAmount"
      defaultValue=""
      rules={{
        required: {
          value: false,
          message: "Total Payback Amount is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          disabled
          label="Total Payback Amount"
          value={amount}
          name="numberformat"
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export const FundedAmount = (props) => {
  const { amount, setAmount, control } = props;

  const handleValidateFundedAmount = (number) => {
    const isValid = number >= 5000;
    if (!isValid) {
      return "The Funded Amount must be greater than $5,000";
    }
  };

  return (
    <Controller
      control={control}
      name="fundedAmount"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Funded Amount is required",
        },
        validate: handleValidateFundedAmount,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Funded Amount"
          value={amount}
          onChange={(e) => {
            const value = e.target.value;

            onChange(value);
            setAmount(value);
          }}
          name="numberformat"
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export const FactorRateWithSlider = (props) => {
  const MIN = 1.0;
  const MAX = 2.0;
  const STEP = 0.01;

  const { factorRate, setFactorRate, control } = props;

  const handleSliderChange = (event, newValue) => {
    setFactorRate(newValue);
  };

  const handleInputChange = (rate) => {
    setFactorRate(rate === "" ? "" : Number(rate));
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        columnGap: "1em",
      }}
    >
      <div style={{ width: "65%", alignItems: "center", display: "flex" }}>
        <Slider
          value={Number(factorRate)}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          step={STEP}
          min={MIN}
          max={MAX}
        />
      </div>
      <div style={{ width: "35%" }}>
        <FactorRate
          min={MIN}
          max={MAX}
          step={STEP}
          control={control}
          factorRate={factorRate}
          setFactorRate={handleInputChange}
        />
      </div>
    </div>
  );
};

export const RoutingNumber = (props) => {
  const { routingNumber, setRoutingNumber, control } = props;

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Controller
      control={control}
      name="routingNum"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Routing Number is required",
        },
        pattern: {
          value: /^[0-9]{9}$/,
          message: "Invalid Routing Number",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          onCut={preventDefault}
          onCopy={preventDefault}
          onPaste={preventDefault}
          label="Routing Number"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="routingNum"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const routingNum = e.target.value;

            const regex = /^[0-9]*$/;

            // const regex = /^[\d*]+$/;

            if (!isNaN(routingNum) && regex.test(routingNum)) {
              onChange(routingNum);
              setRoutingNumber(routingNum);
            }
          }}
          inputProps={{ maxLength: 9 }}
          value={routingNumber}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const ConfirmRoutingNumber = (props) => {
  const {
    routingNumber,
    confirmRoutingNumber,
    setConfirmRoutingNumber,
    control,
  } = props;

  const handleConfirmRoutingNumber = (confirmRoutingNum) => {
    if (routingNumber != confirmRoutingNum) {
      return "The routing numbers do not match";
    }
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Controller
      control={control}
      name="confirmRoutingNum"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "You must confirm the routing number",
        },
        validate: handleConfirmRoutingNumber,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          onCut={preventDefault}
          onCopy={preventDefault}
          onPaste={preventDefault}
          label="Confirm Routing Number"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="confirmRoutingNum"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const routingNum = e.target.value;

            const regex = /^[0-9]*$/;

            if (!isNaN(routingNum) && regex.test(routingNum)) {
              onChange(routingNum);
              setConfirmRoutingNumber(routingNum);
            }
          }}
          inputProps={{ maxLength: 9 }}
          value={confirmRoutingNumber}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const AccountNumber = (props) => {
  const { accountNumber, setAccountNumber, control } = props;

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Controller
      control={control}
      name="Account Number"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Account Number is required",
        },
        minLength: {
          value: 10,
          message: "Please enter a valid account number",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          onCut={preventDefault}
          onCopy={preventDefault}
          onPaste={preventDefault}
          label="Account Number"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="acctNum"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const accountNum = e.target.value;
            onChange(accountNum);
            setAccountNumber(accountNum);
          }}
          value={accountNumber}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const ConfirmAccountNumber = (props) => {
  const {
    accountNumber,
    confirmAccountNumber,
    setConfirmAccountNumber,
    control,
  } = props;

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const handleValidateAccountNumber = (confirmAccountNum) => {
    if (accountNumber != confirmAccountNum) {
      return "The account numbers do not match";
    }
  };

  return (
    <Controller
      control={control}
      name="Confirm Account Number"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "You must confirm the account number",
        },
        validate: handleValidateAccountNumber,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          onCut={preventDefault}
          onCopy={preventDefault}
          onPaste={preventDefault}
          label="Confirm Account Number"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="confirmAccountNum"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const accountNum = e.target.value;
            onChange(accountNum);
            setConfirmAccountNumber(accountNum);
          }}
          value={confirmAccountNumber}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const BankAccountNumbers = (props) => {
  const {
    accountNumber,
    setAccountNumber,
    confirmAccountNumber,
    setConfirmAccountNumber,
    routingNumber,
    setRoutingNumber,
    confirmRoutingNumber,
    setConfirmRoutingNumber,
    control,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <RoutingNumber
          control={control}
          routingNumber={routingNumber}
          setRoutingNumber={setRoutingNumber}
        />
        <ConfirmRoutingNumber
          control={control}
          routingNumber={routingNumber}
          confirmRoutingNumber={confirmRoutingNumber}
          setConfirmRoutingNumber={setConfirmRoutingNumber}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <AccountNumber
          control={control}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
        />
        <ConfirmAccountNumber
          control={control}
          accountNumber={accountNumber}
          confirmAccountNumber={confirmAccountNumber}
          setConfirmAccountNumber={setConfirmAccountNumber}
        />
      </div>
    </div>
  );
};

export const FactorRate = (props) => {
  const { factorRate, setFactorRate, min, max, step, control } = props;

  const handleValidateFactorRate = (number) => {
    console.log("validity: ", number);
    const isValid = number >= 1 && number <= 2;
    if (!isValid) {
      return "The Factor Rate is invalid";
    }
  };

  return (
    <Controller
      control={control}
      name="factorRate"
      defaultValue={factorRate}
      rules={{
        validate: handleValidateFactorRate,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Factor Rate"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="factorRate"
          type="number"
          value={factorRate}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            step: step ? step : 0.01,
            min: min ? min : 0,
            max: max ? max : 2.0,
          }}
          onChange={(e) => {
            const rate = e.target.value;
            onChange(rate);
            setFactorRate(rate);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const UsioMerchantId = (props) => {
  const { usioMerchantId, setUsioMerchantId, control } = props;

  return (
    <Controller
      control={control}
      name="usioMerchantId"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Usio Merchant ID is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Usio Merchant ID"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="merchantId"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setUsioMerchantId(curr);
          }}
          value={usioMerchantId}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const UsioLogin = (props) => {
  const { usioLogin, setUsioLogin, control } = props;

  return (
    <Controller
      control={control}
      name="usioLogin"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Usio Login is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Usio Login"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="usioLogin"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setUsioLogin(curr);
          }}
          value={usioLogin}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const UsioPassword = (props) => {
  const { usioPassword, setUsioPassword, control } = props;

  return (
    <Controller
      control={control}
      name="usioPassword"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Usio Password is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Usio Password"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="usioPassword"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setUsioPassword(curr);
          }}
          value={usioPassword}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const UsioDetails = (props) => {
  const {
    usioPassword,
    setUsioPassword,
    usioLogin,
    setUsioLogin,
    usioMerchantId,
    setUsioMerchantId,
    control,
  } = props;

  return (
    <div>
      <UsioMerchantId
        usioMerchantId={usioMerchantId}
        setUsioMerchantId={setUsioMerchantId}
        control={control}
      />
      <UsioLogin
        control={control}
        usioLogin={usioLogin}
        setUsioLogin={setUsioLogin}
      />
      <UsioPassword
        usioPassword={usioPassword}
        setUsioPassword={setUsioPassword}
        control={control}
      />
    </div>
  );
};

export const FundingSourceSelect = (props) => {
  const {
    control,
    fundingSourceOptions,
    fundingSource,
    setFundingSource,
    setValue,
  } = props;
  const [inputValue, setInputValue] = useState("");

  return (
    <Controller
      control={control}
      name="fundingSourceSelect"
      defaultValue={null}
      rules={{
        required: {
          value: true,
          message: "You must select a funding source",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Autocomplete
          key={fundingSource?.id}
          id={`funding-source-select-${fundingSource?.id}`}
          value={fundingSource ? fundingSource : ""}
          onChange={(event, newValue) => {
            console.log("changed: ", newValue);
            onChange(newValue);
            setFundingSource(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          sx={{ width: 300 }}
          options={fundingSourceOptions}
          autoHighlight
          getOptionLabel={(option) => option.name || ""}
          renderOption={(props, option) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
              {...props}
            >
              <p style={{ fontWeight: "bold", margin: "0px" }}>{option.name}</p>
              <div>
                <p
                  style={{ margin: "0px", fontSize: "14px" }}
                >{`Available Balance: ${option.availableBalance} `}</p>
                <p
                  style={{ margin: "0px", fontSize: "14px" }}
                >{`Current Balance: ${option.currentBalance} `}</p>
              </div>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a funding source"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
              fullWidth
              error={error !== undefined}
              helperText={error ? error.message : ""}
              margin="normal"
            />
          )}
        />
      )}
    />
  );
};
