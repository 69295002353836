import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Home from "./screens/HomeScreen/HomeScreen";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import SignupScreen from "./screens/SignUpScreen/SignupScreen";
import { ToastContainer } from "react-toastify";

import { Provider, useDispatch, useSelector } from "react-redux";

import { GoogleOAuthProvider } from "@react-oauth/google";
import MerchantDashboard from "./screens/MerchantDashboard/MerchantDashboard";
import MerchantSelection from "./screens/MerchantSelection/MerchantSelection";
import DealSelection from "./screens/DealSelection/DealSelection";
import AdminDashboard from "./screens/AdminDashboard/AdminDashboard";
import MyProfileScreen from "./screens/MyProfileScreen/MyProfileScreen";
import UserDetailsScreen from "./screens/UserDetailsScreen/UserDetailsScreen";
import PinPadVerificationScreen from "./screens/PinPadVerificationScreen/PinPadVerificationScreen";
import { CompatClient, Stomp } from "@stomp/stompjs";
import { useEffect } from "react";
import { failure, hideRefreshModal } from "./models/uiReducer";
import { registerForWebSocketNotificationsSuccess } from "./models/account/accountReducer";
import { incrementUnopenedNotifications } from "./models/notification/notificationReducer";
import SockJS from "sockjs-client";
import {
  ROLE_LENDER_ADMIN,
  ROLE_MERCHANT,
  ROLE_LENDER_USER,
  ROOT_URL,
  ROLE_PRE_AUTH_MFA_REQUIRED,
  ROLE_USER,
  ROLE_INVITED_USER,
} from "./config/contants";
import _ from "lodash";
import MyInvestments from "./screens/MyInvestments/MyInvestments";
import InvestmentSelection from "./screens/InvestmentSelection/InvestmentSelection";
import MerchantHomeScreen from "./screens/MerchantHomeScreen/MerchantHomeScreen";

// import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import { responsiveFontSizes } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import SetupMFA from "./screens/SetupMFA/SetupMFA";
import LenderAccountSetupScreen from "./screens/LenderAccountSetupScreen/LenderAccountSetupScreen";
import MerchantAccountSetupScreen from "./screens/MerchantAccountSetupScreen/MerchantAccountSetupScreen";
import LenderDashboard from "./screens/LenderDashboard/LenderDashboard";
import InvestorAccountSetupScreen from "./screens/InvestorAccountSetupScreen/InvestorAccountSetupScreen";
import DealOpportunity from "./screens/DealOpportunity/DealOpportunity";
import StillThereModal from "./components/StillThereModal/StillThereModal";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";

function App() {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const authorities = account?.account?.authorities;
  const showRefreshModal = useSelector((state) => state.ui.showRefreshModal);

  let theme = createTheme({
    typography: {
      fontFamily: '"Poppins", sans-serif',
    },

    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            cursor: "pointer",
            fontFamily: '"Poppins", sans-serif',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: '"Poppins", sans-serif',
          },
        },
      },

      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textTransform: "none",

            whiteSpace: "nowrap",
            overflow: "hidden",
          },
          data: {
            textAlign: "left",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "12px",
            fontFamily: '"Poppins", sans-serif',
          },
        },
      },

      MuiToolbar: {
        styleOverrides: {
          root: {
            fontFamily: '"Poppins", sans-serif',
            paddingLeft: "16px !important",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            paddingLeft: "0px !important",
            fontFamily: '"Poppins", sans-serif',
          },
          h6: {
            // Or whichever variant is used for the title
            paddingLeft: "0px !important",
            fontFamily: '"Poppins", sans-serif',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontFamily: '"Poppins", sans-serif',
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  let socket;
  let stompClient;
  let sessionId;

  useEffect(() => {
    if (account && account.account && account.account.login) {
      // socket = new SockJS(`${ROOT_URL}ws`);
      // stompClient = Stomp.over(socket);
      stompClient = Stomp.over(function () {
        return new SockJS(`${ROOT_URL}ws`);
      });
      sessionId = "";

      stompClient.heartbeatOutgoing = 20000;
      stompClient.heartbeatIncoming = 0;
      // this is crucial in order to reconnect when spring boot goes down / comes back up
      // the docs incorrectly claim that this defaults to 5000, but it really defaults to 0 which disabled reconnect behavior
      stompClient.reconnectDelay = 10000;

      stompClient.debug = function (str) {
        console.log("StompJS Debug Log:");
        console.log(str);
      };

      stompClient.connect(
        {
          login: account.account.login,
          passcode: account.accessToken.split("Bearer ")[1],
        },
        // success callback
        function () {
          console.log("CONNECTION OPENED!");

          sessionId = stompClient.ws._transport.url.split("/");
          sessionId = sessionId[sessionId.length - 1];

          console.log("connected, session id: " + sessionId);

          stompClient.subscribe("/user/queue/private", function (message) {
            console.log("got message with body " + message.body);
            dispatch(incrementUnopenedNotifications());
          });
          stompClient.onWebSocketError(function (evt) {
            console.log("there was a websocket error: ", evt);
          });
          stompClient.onWebSocketClose(function (evt) {
            console.log("the websocket was closed");
          });

          dispatch(registerForWebSocketNotificationsSuccess(stompClient));
        },
        function () {
          console.log("CONNECTION ERROR");
          dispatch(failure());
        }
      );
    }
  }, [account.isSignedIn]);

  const handleSetOpen = (open) => {
    if (open === true) {
      dispatch(showRefreshModal());
    } else {
      dispatch(hideRefreshModal());
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider
        clientId={
          "120783408495-vo5ahmk113psblckesuk0in5ps635act.apps.googleusercontent.com"
        }
      >
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/sign-up" element={<SignupScreen />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />

            <Route path="/verify-pin" element={<PinPadVerificationScreen />} />
            <Route
              path="/setup-mfa"
              element={
                <ProtectedRoute
                  roles={[ROLE_PRE_AUTH_MFA_REQUIRED, ROLE_USER]}
                  nav={false}
                >
                  <SetupMFA />
                </ProtectedRoute>
              }
            />
            <Route
              path="/lender-account-setup"
              element={
                <ProtectedRoute roles={[ROLE_LENDER_ADMIN]} nav={false}>
                  <LenderAccountSetupScreen />
                </ProtectedRoute>
              }
            />

            <Route
              path="/merchant-account-setup"
              element={
                <ProtectedRoute roles={[ROLE_MERCHANT]} nav={false}>
                  <MerchantAccountSetupScreen />
                </ProtectedRoute>
              }
            />

            <Route
              path="/investor-account-setup"
              element={
                <ProtectedRoute
                  roles={[ROLE_LENDER_USER, ROLE_INVITED_USER]}
                  nav={false}
                >
                  <InvestorAccountSetupScreen />
                </ProtectedRoute>
              }
            />

            {!authorities?.includes(ROLE_MERCHANT) && (
              <Route
                path="/"
                element={
                  <ProtectedRoute roles={[ROLE_LENDER_ADMIN, ROLE_LENDER_USER]}>
                    <Home />
                  </ProtectedRoute>
                }
              />
            )}

            {!authorities?.includes(ROLE_MERCHANT) && (
              <Route
                path="/funding-dashboard"
                element={
                  <ProtectedRoute roles={[ROLE_LENDER_ADMIN, ROLE_LENDER_USER]}>
                    <MerchantDashboard />
                  </ProtectedRoute>
                }
              />
            )}

            {!authorities?.includes(ROLE_MERCHANT) && (
              <Route
                path="/investor-dashboard"
                element={
                  <ProtectedRoute>
                    <MyInvestments />
                  </ProtectedRoute>
                }
              />
            )}

            {!authorities?.includes(ROLE_MERCHANT) && (
              <Route
                path="/investor-dashboard/:merchantName/:id"
                element={
                  <ProtectedRoute>
                    <InvestmentSelection />
                  </ProtectedRoute>
                }
              />
            )}

            <Route
              path="/admin-dashboard"
              element={
                <ProtectedRoute roles={[ROLE_LENDER_ADMIN]}>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="admin-dashboard/lender-management/:lender"
              element={
                <ProtectedRoute roles={[ROLE_LENDER_ADMIN]}>
                  <LenderDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin-dashboard/user-details/:user"
              element={
                <ProtectedRoute roles={[ROLE_LENDER_ADMIN]}>
                  <UserDetailsScreen />
                </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <MyProfileScreen />
                </ProtectedRoute>
              }
            />

            <Route
              path={
                authorities?.includes(ROLE_MERCHANT)
                  ? "/"
                  : "/funding-dashboard/:businessName"
              }
              element={
                <ProtectedRoute>
                  {authorities?.includes(ROLE_MERCHANT) ? (
                    <MerchantHomeScreen />
                  ) : (
                    <MerchantSelection />
                  )}
                </ProtectedRoute>
              }
            />
            <Route
              path={
                authorities?.includes(ROLE_MERCHANT)
                  ? "/"
                  : "/funding-dashboard/:businessName/funded-deal"
              }
              element={
                <ProtectedRoute>
                  <DealSelection />
                </ProtectedRoute>
              }
            />

            <Route
              path={
                authorities?.includes(ROLE_MERCHANT)
                  ? "/"
                  : "/investor-dashboard/investment-opportunity"
              }
              element={
                <ProtectedRoute>
                  <DealOpportunity />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer />
          <StillThereModal
            open={showRefreshModal}
            setOpen={(open) => handleSetOpen(open)}
          />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
