import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getOrganizationTransactionsAction } from "../../models/financial/financialActions";

import { useForm } from "react-hook-form";
import _ from "lodash";

const UserDetailsScreen = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const organization = useSelector((state) => state.organizations);
  const { control, handleSubmit, errors } = useForm();
  const user = {
    firstName: "Andrew",
    lastName: "White",
    email: "awhite@launchsitellc.com",
    phone: "555-555-5555",
    role: "Admin",
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !_.isNil(organization) &&
      !_.isNil(organization.organization) &&
      !_.isNil(organization.organization.id)
    )
      dispatch(
        getOrganizationTransactionsAction(
          organization.organization.id,
          "02/01/2023 00:00:00",
          "02/02/2023 00:00:00"
        )
      );
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "500px",
          height: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "1em",
          flexDirection: "column",
        }}
      >
        <h1>{`${user.firstName} ${user.lastName}`}</h1>
        <h3>{`role: ${user.role}`}</h3>
        <h3>{`email: ${user.email}`}</h3>
        <h3>{`phone: ${user.phone}`}</h3>
      </div>
    </div>
  );
};

export default UserDetailsScreen;
