import React from "react";
import { Button } from "@mui/material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  markDealAsDefaultedThunk,
  markDealAsPausedThunk,
  markDealAsUnPausedThunk,
} from "../../models/deal/dealThunks";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const DealActions = (props) => {
  const dealSlice = useSelector((state) => state.deals);
  const deal = dealSlice.deal;

  const { dealStatus } = deal;
  const dispatch = useDispatch();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px", // Horizontal space between buttons
      }}
    >
      <Button
        disabled={dealStatus !== "In Progress"}
        style={{ borderColor: "red", color: "red" }}
        variant="outlined"
        endIcon={<HighlightOffIcon style={{ color: "red" }} />}
        onClick={() => {
          dispatch(markDealAsDefaultedThunk(deal.id));
        }}
      >
        Mark as Defaulted
      </Button>

      <Button
        disabled={dealStatus !== "In Progress"}
        style={{ borderColor: "#FF9800", color: "#FF9800" }}
        variant="outlined"
        endIcon={<PauseCircleOutlineIcon style={{ color: "#FF9800" }} />}
        onClick={() => {
          dispatch(markDealAsPausedThunk(deal.id));
        }}
      >
        Pause Payments
      </Button>

      <Button
        disabled={dealStatus !== "Paused"}
        style={{ borderColor: "#4CAF50", color: "#4CAF50" }}
        variant="outlined"
        endIcon={<PlayCircleOutlineIcon style={{ color: "#4CAF50" }} />}
        onClick={() => {
          dispatch(markDealAsUnPausedThunk(deal.id));
        }}
      >
        Unpause Payments
      </Button>
    </div>
  );
};

export default DealActions;
