import { createSlice } from "@reduxjs/toolkit";

interface MerchantKPIs {
  totalFundedAmount: number | null;
  averageFactorRate: number | null;
  defaultRate: number | null;
  totalProfit: number | null;
  totalCompletedDeals: number | null;
  totalOpenDeals: number | null;
  totalInvestors: number | null;
}

interface MerchantState {
  merchant: any;
  merchants: any[];
  pendingMerchants: any[];
  ownedMerchants: any[];
  error: string | null;
  selectedMerchantMember: any | null;
  currentAccountMerchantMember: any | null;
  kpis: MerchantKPIs | {};
  pendingInvites: any[];
  loading: boolean;
  updateMerchantSuccess: boolean;
  createMerchantSuccess: boolean;
}

const initialState: MerchantState = {
  merchant: undefined,
  error: null,
  merchants: [],
  pendingMerchants: [],
  ownedMerchants: [],
  selectedMerchantMember: null,
  currentAccountMerchantMember: null,
  kpis: {},
  pendingInvites: [],
  loading: false,
  updateMerchantSuccess: true,
  createMerchantSuccess: true,
};

const merchantSlice = createSlice({
  name: "merchants",
  initialState,
  reducers: {
    getMerchantSuccess(state, action) {
      state.merchant = action.payload;
      state.loading = false;
      state.error = null;
    },
    getMerchantsSuccess(state, action) {
      console.log("get merchants success: ", action.payload);
      state.merchants = action.payload;
      state.loading = false;
      state.error = null;
    },
    getOwnedMerchantSuccess(state, action) {
      state.merchant = action.payload;
      state.loading = false;
      state.error = null;
    },
    createMerchantBusinessDetailsSuccess(state, action) {
      state.merchant = action.payload;
      state.loading = false;
      state.merchants = [...state.merchants, action.payload];
      state.createMerchantSuccess = true;
      state.error = null;
    },

    getCurrentAccountOrganizationUserSuccess(state, action) {
      state.currentAccountMerchantMember = action.payload;
      state.error = null;
      state.loading = false;
    },
    resetCreateUpdateMerchantSuccess(state, action) {
      state.createMerchantSuccess = false;
      state.updateMerchantSuccess = false;

      state.error = null;
      state.loading = false;
    },
    sendMerchantInviteSuccess(state, action) {
      state.error = null;
      state.loading = false;
    },
    getPendingMerchantInvitesSuccess(state, action) {
      state.pendingMerchants = action.payload;
      state.error = null;
      state.loading = false;
    },
    updateMerchantSuccess: (state, action) => {
      const updatedMerchant = action.payload;
      const index = state.merchants.findIndex(
        (merchant) => merchant.id === updatedMerchant.id
      );
      if (index !== -1) {
        state.merchants[index] = updatedMerchant;
      }
      state.merchant = updatedMerchant;
      state.updateMerchantSuccess = true;
      state.loading = false;
      state.error = null;
    },

    getMerchantKPIsSuccess(state, action) {
      state.kpis = action.payload;
      state.error = null;
      state.loading = false;
    },
    loading(state, action) {
      state.loading = action.payload;
    },
    merchantFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    setSelectedMerchant: (state, action) => {
      state.merchant = action.payload;
    },
    setCurrentUserDefaultMerchantSuccess: (state, action) => {
      state.merchant = action.payload;
    },
  },
});

export const {
  getMerchantSuccess,
  getMerchantsSuccess,
  getOwnedMerchantSuccess,
  getMerchantKPIsSuccess,
  createMerchantBusinessDetailsSuccess,
  getPendingMerchantInvitesSuccess,
  updateMerchantSuccess,
  resetCreateUpdateMerchantSuccess,
  setSelectedMerchant,
  sendMerchantInviteSuccess,
  getCurrentAccountOrganizationUserSuccess,
  setCurrentUserDefaultMerchantSuccess,
  merchantFailure,
  loading,
} = merchantSlice.actions;

export default merchantSlice.reducer;
