import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FinancialTransactionsTable from "../../components/FinancialTransactionsTable/FinancialTransactionsTable";
import { Paper } from "@mui/material";
import BasicTabs from "../../components/BasicTabs/BasicTabs";
import _ from "lodash";
import {
  createPlaidFundingSourceThunk,
  getLenderKPIsThunk,
  getLenderThunk,
  unlinkFundingSourceThunk,
} from "../../models/lender/lenderThunks";
import moment from "moment";
import { getObservedHolidaysAction } from "../../models/holiday/holidayActions";
import LinkAccountScreen from "../LinkAccountStep/LinkAccountScreen";
import LenderSelection from "../../components/LenderSelection/LenderSelection";
import { getCustomerTransactions } from "../../models/dwolla/dwollaApi";
import { getCustomerTransactionsThunk } from "../../models/dwolla/dwollaActions";
import LenderFundsFlowConfig from "../../components/LenderFundsFlowConfig/LenderFundsFlowConfig";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BarChartIcon from "@mui/icons-material/BarChart";
import PercentageIcon from "@mui/icons-material/PieChart"; // Representing Factor Rate as a portion or percentage.

import EqualizerIcon from "@mui/icons-material/Equalizer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import PeopleIcon from "@mui/icons-material/People";
import GroupIcon from "@mui/icons-material/Group";
import KpiCard from "../../components/KpiCard/KpiCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import StoreIcon from "@mui/icons-material/Store";
import AssessmentIcon from "@mui/icons-material/Assessment";

const Home = () => {
  const navigate = useNavigate();
  const accountSlice = useSelector((state) => state.account);
  const account = accountSlice.account;
  const lenderSlice = useSelector((state) => state.lenders);
  const dwolla = useSelector((state) => state.dwolla);
  const lender = lenderSlice.lender;
  const kpis = lenderSlice?.kpis;
  const deal = useSelector((state) => state.deals);

  console.log("state deals: ", deal);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !_.isNil(lender) &&
      !_.isNil(lender.id) &&
      !_.isNil(lender.dwollaCustomerId)
    ) {
      dispatch(getLenderKPIsThunk(lender.id));
      dispatch(getCustomerTransactionsThunk(lender.dwollaCustomerId));
    }
  }, [lender]);

  useEffect(() => {
    dispatch(getObservedHolidaysAction(moment().year()));
  }, []);

  useEffect(() => {
    if (_.isNil(lender) && account?.defaultLenderId) {
      console.log("getting the default lender");
      dispatch(getLenderThunk(account.defaultLenderId));
    }
  }, []);

  const kpiData = [
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalFundedAmount)
          ? `$${Number(
              Number(kpis.totalFundedAmount).toFixed(2)
            ).toLocaleString()}`
          : "--"
      }`,
      subTitle: "Total Funded Amount",
      IconComponent: AttachMoneyIcon,
      color: "#4CAF50",
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.averageFactorRate)
          ? `${Number(kpis.averageFactorRate).toFixed(2)}`
          : "--"
      }`,
      subTitle: "Average Factor Rate",
      IconComponent: PercentageIcon,
      color: "#2196F3",
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalCompletedDeals)
          ? kpis.totalCompletedDeals
          : "--"
      }`,
      subTitle: "Completed Deals",
      IconComponent: CheckCircleIcon,
      color: "#4CAF50",
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.defaultRate)
          ? `${kpis.defaultRate}%`
          : "--"
      }`,
      subTitle: "Default Rate",
      IconComponent: ErrorIcon,
      color: "#F44336",
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalOpenDeals)
          ? kpis.totalOpenDeals
          : "--"
      }`,
      subTitle: "Open Deals",
      IconComponent: FolderOpenIcon,
      color: "#FF9800",
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalInvestors)
          ? kpis.totalInvestors
          : "--"
      }`,
      subTitle: "Total Investors",
      IconComponent: PeopleIcon,
      color: "#2196F3",
    },
  ];

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "5em",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "3em", marginBottom: "3em" }}>
          <div style={{ width: "25%", marginBottom: "2em", marginTop: "1em" }}>
            <LenderSelection />
          </div>
          {/* <StoreIcon style={{ fontSize: "48px", color: "#4CAF50" }} /> */}
          <AssessmentIcon style={{ fontSize: "48px", color: "#C9A66B" }} />{" "}
          {/* Muted gold color code */}
          <h1 style={{ margin: "0px" }}>
            {lender?.businessName ? lender.businessName : "--"}
          </h1>
          <h4 style={{ margin: "0px", marginTop: "1em" }}>Account Summary</h4>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            rowGap: "2em",
            columnGap: "2em",
            flexWrap: "wrap",
          }}
        >
          {kpiData.map((card) => (
            <KpiCard
              title={card.title}
              subTitle={card.subTitle}
              IconComponent={card.IconComponent}
              color={card.color}
            />
          ))}
        </div>

        <div style={{ marginTop: "2em" }}>
          <BasicTabs
            data={[
              {
                label: "Linked Accounts",
                panel: (
                  <LinkAccountScreen
                    justifyButton="flex-start"
                    loading={lenderSlice?.loading}
                    org={lender}
                    defaultOrgId={account?.defaultLenderId}
                    getOrgThunk={getLenderThunk}
                    createPlaidFundingSourceThunk={
                      createPlaidFundingSourceThunk
                    }
                    unlinkFundingSourceThunk={unlinkFundingSourceThunk}
                  />
                ),
              },
              {
                label: "Funds Flow Configuration",
                panel: <LenderFundsFlowConfig />,
              },

              {
                label: "Transactions",
                panel: (
                  <FinancialTransactionsTable
                    data={
                      dwolla?.transactions?.length > 0
                        ? dwolla?.transactions
                        : []
                    }
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
