import { getHolidaysSuccess } from "./holidayReducer";

import _ from "lodash";
import { failure, loading, toastFailure, toastSuccess } from "../uiReducer";
import { getObservedHolidays } from "./holidayApi";

export const getObservedHolidaysAction = (year) => async (dispatch) => {
  console.log(`handle get observed holidays for: ${year}`);
  dispatch(loading(true));

  try {
    const response = await getObservedHolidays(year);

    console.log("response: ", JSON.stringify(response));

    dispatch(getHolidaysSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};
