import { Button } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

import { usePlaidLink } from "react-plaid-link";
import { useDispatch, useSelector } from "react-redux";
import {
  createLinkTokenThunk,
  exchangePublicTokenForAccessTokenThunk,
  getDwollaProcessorTokenThunk,
} from "../../models/plaid/plaidThunks";
import _ from "lodash";

const PlaidLink = (props) => {
  const { createPlaidFundingSourceThunk, org } = props;
  const dispatch = useDispatch();
  const plaid = useSelector((state) => state.plaid);
  const token = plaid?.linkTokenResponse?.linkToken;

  const [accountName, setAccountName] = useState("");
  const [accountId, setAccountId] = useState("");

  useEffect(() => {
    if (
      plaid?.processorTokenResponse &&
      plaid?.processorTokenResponse?.processorToken
    ) {
      const plaidToken = plaid?.processorTokenResponse?.processorToken;

      if (
        !_.isEmpty(plaidToken) &&
        !_.isEmpty(accountName) &&
        !_.isEmpty(accountId) &&
        !_.isEmpty(plaid?.accessTokenResponse)
      ) {
        dispatch(
          createPlaidFundingSourceThunk(
            org?.id,
            plaidToken,
            accountName,
            accountId,
            plaid.accessTokenResponse
          )
        );
        setAccountName("");
        setAccountId("");
        console.log(
          "the processorToken changed: ",
          plaid?.processorTokenResponse?.processorToken
        );
      }
    }
  }, [plaid?.processorTokenResponse]);

  useEffect(() => {
    if (
      plaid.accessTokenResponse &&
      !_.isEmpty(plaid.accessTokenResponse) &&
      accountId &&
      !_.isEmpty(accountId)
    ) {
      dispatch(
        getDwollaProcessorTokenThunk(plaid.accessTokenResponse, accountId)
      );
    }
  }, [plaid?.accessTokenResponse]);

  // get a link_token from your API when component mounts
  useEffect(() => {
    dispatch(createLinkTokenThunk());
  }, []);

  const onSuccess = useCallback((publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    console.log(publicToken, metadata);
    console.log("Public Token: " + publicToken);
    console.log("Customer-selected account ID: " + metadata.account_id);

    setAccountName(metadata?.account?.name);
    setAccountId(metadata?.account_id);

    dispatch(exchangePublicTokenForAccessTokenThunk(publicToken));
  }, []);

  const onEvent = useCallback((eventName, metadata) => {
    console.log("plaid event");
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  }, []);

  const onExit = useCallback((error, metadata) => {
    "plaid exit";
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log(error, metadata);
  }, []);

  const config = {
    token,
    onSuccess,
    onExit,
    onEvent,
  };

  const {
    open,
    ready,
    // exit
  } = usePlaidLink(config);

  console.log("plaid ready: ", ready);
  console.log("plaid link token: ", token);

  return (
    <Button variant="outlined" onClick={() => open()} disabled={!ready}>
      Connect a bank account
    </Button>
  );
};

export default PlaidLink;
