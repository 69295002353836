import axios from "axios";
import { JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const getObservedHolidays = (year) => {
  console.log(`get holidays with year: ${year}`);

  return axiosInstance.get(`${ROOT_URL}api/holidays?year=${year}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};
