export const GET_HOLIDAYS_SUCCESS = "GET_HOLIDAYS_SUCCESS";

export const getHolidaysSuccess = (holidays: any[]) => ({
  type: GET_HOLIDAYS_SUCCESS,
  holidays: holidays,
});

interface HolidayState {
  holidays: any[];
}

const initialState: HolidayState = {
  holidays: [],
};

export default (state = initialState, action: any): HolidayState => {
  switch (action.type) {
    case GET_HOLIDAYS_SUCCESS:
      console.log("the holidays: ", action.holidays);
      return {
        ...state,
        holidays: action.holidays,
      };

    default:
      return state;
  }
};
