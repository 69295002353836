import { applicationJson, JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const getLender = async (id) => {
  return axiosInstance.get(`${ROOT_URL}api/lenders/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getLenders = async () => {
  return axiosInstance.get(`${ROOT_URL}api/lenders`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getOwnedLenders = async () => {
  return axiosInstance.get(`${ROOT_URL}api/lenders/owned`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const createLender = async (lender, setDefault) => {
  return axiosInstance.post(
    `${ROOT_URL}api/lenders?set-default=${setDefault}`,
    lender,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const createPlaidFundingSource = (
  orgId,
  processorToken,
  accountName,
  accountId,
  accessToken
) => {
  console.log("createPlaidFundingSource got orgId: ", orgId);
  console.log("createPlaidFundingSource got plaidToken: ", processorToken);
  console.log("createPlaidFundingSource got accountName: ", accountName);
  console.log("createPlaidFundingSource got accessToken: ", accessToken);

  return axiosInstance.post(
    `${ROOT_URL}api/lenders/${orgId}/funding-sources`,
    {
      processorToken: processorToken,
      accountName: accountName,
      accountId: accountId,
      accessToken: accessToken,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const unlinkFundingSource = (orgId, fundingSourceId) => {
  return axiosInstance.delete(
    `${ROOT_URL}api/lenders/${orgId}/funding-sources/${fundingSourceId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const updateLender = async (lenderId, lender) => {
  return axiosInstance.put(`${ROOT_URL}api/lenders/${lenderId}`, lender, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const addLenderAchAccount = async (lenderId, usioInfo) => {
  return axiosInstance.put(
    `${ROOT_URL}api/lenders/${lenderId}/add-ach`,
    usioInfo,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const addLenderBankAccount = async (lenderId, bankAccount) => {
  return axiosInstance.put(
    `${ROOT_URL}api/lenders/${lenderId}/add-bank-account`,
    bankAccount,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const deleteLender = async (id) => {
  return axiosInstance.delete(`${ROOT_URL}api/lenders/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const sendLenderInvite = async (id, recipient) => {
  console.log("add lender member api");
  return axiosInstance.post(
    `${ROOT_URL}api/lenders/${id}/send-lender-user-invite`,
    recipient,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const removeLenderMember = async (id, user) => {
  return axiosInstance.post(
    `${ROOT_URL}api/lenders/${id}/remove-member`,
    user,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const updateLenderUserPermissions = async (lenderUser) => {
  return axiosInstance.put(
    `${ROOT_URL}api/lender-users/${lenderUser.id}`,
    lenderUser,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getCurrentAccountLenderUser = async (lenderId) => {
  return axiosInstance.get(
    `${ROOT_URL}api/lender-users/current?lenderId=${lenderId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getLenderKPIs = (id) => {
  console.log(`count lender KPIs with id: ${id}`);

  return axiosInstance.get(`${ROOT_URL}api/lenders/${id}/kpis`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getLenderInvestors = (id) => {
  return axiosInstance.get(`${ROOT_URL}api/lenders/${id}/investors`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getLenderUsers = (id) => {
  return axiosInstance.get(`${ROOT_URL}api/lenders/${id}/lender-users`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const updateFundsFlow = (orgId, fundsFlowDTO) => {
  return axiosInstance.post(
    `${ROOT_URL}api/lenders/${orgId}/funds-flow-config`,
    fundsFlowDTO,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getLenderPendingInvites = (id) => {
  console.log(`count lender pending invites: ${id}`);

  return axiosInstance.get(`${ROOT_URL}api/lenders/${id}/pending-invites`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};
