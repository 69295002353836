import {
  getTransactionsSuccess,
  usioAccountVerificationSuccess,
  usioStatusCheckSuccess,
  usioVerifyAccountSuccess,
} from "./financialReducer";

import _ from "lodash";
import { failure, loading, toastFailure } from "../uiReducer";
import {
  getOrganizationTransactions,
  usioStatusCheck,
  verifyAchAccount,
} from "./financialApi";

export const getOrganizationTransactionsAction =
  (orgId, from, to) => async (dispatch) => {
    console.log(`handle get transactions from: ${from} - ${to}`);
    dispatch(loading(true));

    try {
      const response = await getOrganizationTransactions(orgId, from, to);

      console.log("transactions response: ", response);

      dispatch(getTransactionsSuccess(response.data.Transactions));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const usioStatusCheckAction = (usioAchAccount) => async (dispatch) => {
  console.log(`handle usio status check action`, usioAchAccount);
  dispatch(loading(true));

  try {
    const response = await usioStatusCheck(usioAchAccount);

    dispatch(usioStatusCheckSuccess());
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const usioAchVerifyAction = (bankAccount) => async (dispatch) => {
  console.log(`handle verify ach account action`, bankAccount);
  dispatch(loading(true));

  try {
    const response = await verifyAchAccount(bankAccount);

    dispatch(usioVerifyAccountSuccess());
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};
