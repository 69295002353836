import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createSlice } from "@reduxjs/toolkit";

export const LOADING = "LOADING";
export const FAILURE = "FAILURE";

export const toastSuccess = (title, message) => {
  console.log("toast success: ", message);

  toast.success(`${title} ${message}`, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const toastFailure = (error) => {
  console.log("toast error: ", error);
  console.log(error.response);
  console.log("foo bar baz", error?.response?.data);
  let errorText = "";
  const status = error?.response?.data?.status;
  const errorResponse = error.response;

  // 401 errors don't need toasts because the interceptor transparently handles and refreshes the token
  if (status === "UNAUTHORIZED" || status === 401) {
    console.log("oooof!", 401);
    return;
  }

  if (!_.isNil(errorResponse)) {
    const errorData = errorResponse.data;
    if (!_.isNil(errorData)) {
      const errorMessage = errorData.message;
      if (!_.isNil(errorMessage)) {
        errorText = errorMessage;
      }
    }
  }

  toast.error(`There was an error: ${errorText}`, {
    position: toast.POSITION.TOP_LEFT,
  });
};

interface UIState {
  loading: boolean;
  showRefreshModal: boolean;
  error: string | null;
  redirectTo: string;
}

const initialState: UIState = {
  loading: false,
  error: null,
  showRefreshModal: false,
  redirectTo: "/login",
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    loading(state, action) {
      state.loading = action.payload;
      state.error = null;
    },
    failure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    showRefreshModal(state, action) {
      console.log("fucking shit");
      state.showRefreshModal = true;
    },
    hideRefreshModal(state, action) {
      state.showRefreshModal = false;
    },
    setRedirection: (state, action) => {
      console.log("redirect to: ", action.payload);
      state.redirectTo = action.payload;
    },
    clearRedirection: (state, action) => {
      state.redirectTo = "";
    },
  },
});

export const {
  loading,
  failure,
  showRefreshModal,
  hideRefreshModal,
  setRedirection,
  clearRedirection,
} = uiSlice.actions;

export default uiSlice.reducer;
