import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "300px", // Adjust the width to your preference
    height: "150px",
    border: "2px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "10px",
    padding: "10px",
    backgroundColor: "#fff",
    transition: "border-color 0.3s ease",
  },
  selected: {
    borderColor: theme.palette.primary.main,
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "0.9rem",
    marginRight: "5px",
  },
  content: {
    fontWeight: "normal",
    fontSize: "0.9rem",
  },
  icon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: theme.palette.primary.main,
    fontSize: "2rem",
  },
}));

const InviteCard = ({ invite, onClick, isSelected }) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.card} ${isSelected ? classes.selected : ""}`}
      onClick={onClick}
    >
      {isSelected ? (
        <CheckCircleIcon className={classes.icon} />
      ) : (
        <CheckCircleOutlineIcon className={classes.icon} />
      )}
      <div className={classes.header}>
        <Typography variant="body1" className={classes.title}>
          Invite Name:
        </Typography>
        <Typography variant="body2" className={classes.content}>
          {invite.invitedAsName}
        </Typography>
      </div>
      <div className={classes.header}>
        <Typography variant="body1" className={classes.title}>
          Invite Sent:
        </Typography>
        <Typography variant="body2" className={classes.content}>
          {moment
            .utc(invite.createdDate)
            .local()
            .format("dddd, MMMM D @ h:mmA z")}
        </Typography>
      </div>
    </div>
  );
};

const InviteCards = (props) => {
  const { invites, selectedInvite, setSelectedInvite } = props;
  const containerRef = useRef(null);

  const handleCardClick = (invite) => {
    if (invite.id === selectedInvite?.id) {
      setSelectedInvite(null);
    } else {
      setSelectedInvite(invite);
    }
  };

  return (
    <div
      ref={containerRef}
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
    >
      {invites.map((invite) => (
        <InviteCard
          key={invite.id}
          invite={invite}
          isSelected={selectedInvite?.id === invite.id}
          onClick={() => handleCardClick(invite)}
        />
      ))}
    </div>
  );
};

export default InviteCards;
