import { Button, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  resetNotificationsBellAction,
  openAllUnopenedNotificationsAction,
  acceptMerchantInviteAction,
  rejectMerchantInviteAction,
} from "../../models/notification/notificationActions";
import { NotificationAcknowlegement } from "../../models/notification/notificationReducer";
import { getElapsedTimeString } from "../../shared/commonUtils";

export const MerchantInviteNotification = (props) => {
  const { notification } = props;
  const dispatch = useDispatch();
  const elapsedTime = getElapsedTimeString(notification.createdDate);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
        {notification.title ? notification.title : ""}
      </p>
      <p style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "14px" }}>
        {notification.body ? notification.body : ""}
      </p>
      <p
        style={{
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "12px",
          marginTop: "0px",
        }}
      >
        {elapsedTime}
      </p>
      {notification.notificationAcknowlegement === "None" ? (
        <div
          style={{
            display: "flex",
            width: "100",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              dispatch(acceptMerchantInviteAction(notification.id));
            }}
          >
            Accept
          </Button>
          <Button
            onClick={() => {
              dispatch(rejectMerchantInviteAction(notification.id));
            }}
          >
            Reject
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          {notification.notificationAcknowlegement ===
          NotificationAcknowlegement.ACCEPTED ? (
            <p>You accepted this invite</p>
          ) : (
            <p>You rejected this invite</p>
          )}
        </div>
      )}

      <Divider />
    </div>
  );
};
