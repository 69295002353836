import axios from "axios";
import moment from "moment";
import { JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

// export const getTransactions = (from, to) => {
//   console.log(`from: ${from}, to: ${to}`);
//   const url =
//     "https://payments.usiopay.com/2.0/payments.svc/JSON/GetTransactionList";

//   return axiosInstance.post(url, {
//     DateFrom: from,
//     DateTo: to,
//     IncludeHPPValues: true,
//     MerchantID: "0000000001",
//     Login: "API0000000001",
//     Password: "Temp1234!",
//     ReturnAccountMask: false,
//   });
// };

export const getOrganizationTransactions = (orgId, from, to) => {
  console.log(`from: ${from}, to: ${to}`);

  const newFrom = moment().startOf("day").format();
  const newTo = moment().endOf("day").format();

  return axiosInstance.get(
    `${ROOT_URL}api/organization-payments?org-id=${orgId}&from=${newFrom}&to=${newTo}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const usioStatusCheck = (usioAccount) => {
  return axiosInstance.post(`${ROOT_URL}api/usio/status-check`, usioAccount, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const verifyAchAccount = (bankAccount) => {
  return axiosInstance.post(
    `${ROOT_URL}api/usio/verify-ach-account`,
    bankAccount,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};
