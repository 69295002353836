import { combineReducers } from "redux";
import accountReducer from "../models/account/accountReducer";
import notificationReducer from "./notification/notificationReducer";
import uiReducer from "./uiReducer";
import calendarReducer from "./calendar/calendarReducer";
import teamReducer from "./team/teamReducer";
import financialReducer from "./financial/financialReducer";
import merchantReducer from "./merchant/merchantReducer";
import dealReducer from "./deal/dealReducer";
import holidayReducer from "./holiday/holidayReducer";
import plaidReducer from "./plaid/plaidReducer";
import dwollaReducer from "./dwolla/dwollaReducer";
import lenderReducer from "./lender/lenderReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  notification: notificationReducer,
  ui: uiReducer,
  calendar: calendarReducer,
  teams: teamReducer,
  lenders: lenderReducer,
  merchants: merchantReducer,
  deals: dealReducer,
  finance: financialReducer,
  holiday: holidayReducer,
  plaid: plaidReducer,
  dwolla: dwollaReducer,
});

export default (state, action) =>
  rootReducer(action.type === "CLEAR_REDUX" ? undefined : state, action);

// export default rootReducer;
