import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";

import _ from "lodash";
import HorizontalStepper from "../HorizontalStepper/HorizontalStepper";
import { makeStyles } from "@mui/styles";
import CreateBusiness from "../CreateBusiness/CreateBusiness";

import LinkAccountScreen from "../../screens/LinkAccountStep/LinkAccountScreen";
import {
  createInvestmentBusinessAccountPlaidFundingSourceThunk,
  createInvestorBusinessAccountThunk,
  getInvestmentBusinessAccountThunk,
  removeInvestmentBusinessAccountThunk,
} from "../../models/account/accountActions";
import { resetCreateUpdateInvestmentBusinessAccountSuccess } from "../../models/account/accountReducer";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      margin: "0px",
    },
  },
  [theme.breakpoints.up("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "500px",
      height: "400px",
      marginTop: "0em",
    },
  },
}));

const freshInvestmentAccount = {
  businessName: "",
  businessType: "",
  businessClassification: "",
  industryClassification: "",
  dateOfBirth: null,

  fullAddress: {
    address1: "",
    address2: "",
    zip: "",
    city: "",
    stateCode: "",
  },
  controller: {
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    ssn: "",
    email: "",
    title: "",
    address: {
      address1: "",
      address2: "",
      zip: "",
      city: "",
      stateCode: "",
    },
  },
};

const InvestmentBusinessAccountSetup = (props) => {
  const { handleViewChange } = props;
  const classes = useStyles();

  const navigate = useNavigate();
  const accountSlice = useSelector((state) => state.account);
  const investorBusinessAccount =
    accountSlice?.account?.investorBusinessAccount;
  const [activeStep, setActiveStep] = useState(0);

  const dwolla = useSelector((state) => state.dwolla);

  const [businessInfo, setBusinessInfo] = useState(freshInvestmentAccount);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
    setValue,
    reset,
  } = useForm();

  const dispatch = useDispatch();

  const canProceedToLinkAccount = () => {
    if (
      _.isNil(investorBusinessAccount) ||
      _.isNil(investorBusinessAccount.id)
    ) {
      console.log("can proceed 1");
      return true;
    }

    if (
      businessInfo.hasBeneficialOwners === "true" ||
      investorBusinessAccount?.beneficialOwners?.length > 0 ||
      dwolla?.owners?.length > 0
    ) {
      // if the user indicated that the business has beneficial owners, but did not save any,
      // do not proceed
      if (
        (_.isNil(dwolla.owners) || dwolla?.owners?.length === 0) &&
        investorBusinessAccount.beneficialOwners.length === 0
      ) {
        console.log("can proceed 2");

        return true;
      }
      console.log("can proceed 3");

      // if there are beneficial owners listed, but the user did not confirm that the info is complete/correct, do not proceed
      return !businessInfo.certifiedCompleteAndCorrect;
    } else {
      console.log("can proceed 4");

      // if there were no beneficial owners, the user has to confirm that there are none + the info is complete/correct
      return !(
        businessInfo.certifiedNoBeneficialOwners &&
        businessInfo.certifiedCompleteAndCorrect
      );
    }
  };

  const handleMoveForward = () => {
    setActiveStep(activeStep + 1);
  };

  const handleMoveBackward = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    if (!_.isNil(investorBusinessAccount?.id)) {
      const mergedInfo = {
        ...investorBusinessAccount,
        dateOfBirth: null,
        businessClassification: investorBusinessAccount.businessClassification,
        industryClassification: investorBusinessAccount.industryClassification,
        fullAddress: {
          address1: investorBusinessAccount.address1,
          address2: investorBusinessAccount.address2,
          zip: investorBusinessAccount.postalCode,
          city: investorBusinessAccount.city,
          state: investorBusinessAccount.state,
        },
        controller: {
          ...investorBusinessAccount.controller,
          address: {
            ...investorBusinessAccount?.controller?.address,
            zip: investorBusinessAccount?.controller?.address?.postalCode,
            state:
              investorBusinessAccount?.controller?.address?.stateProvinceRegion,
          },
        },
      };

      console.log("setting the business info: ", mergedInfo);
      setBusinessInfo(mergedInfo);
      setValue("businessName", investorBusinessAccount.businessName);
      setValue("businessType", investorBusinessAccount.businessType);
      setValue("address1", investorBusinessAccount.address1);
      setValue("address2", investorBusinessAccount.address2);
      setValue("city", investorBusinessAccount.city);
      setValue("zip", investorBusinessAccount.postalCode);

      setValue("birthDate", investorBusinessAccount.dateOfBirth);
    }
  }, [investorBusinessAccount?.id]);

  const businessDetailStep = {
    title: "Business",
    handleSubmit: () => {
      handleMoveForward();
    },
    handleBack: () => {
      console.log("no op");
    },
    isNextDisabled: canProceedToLinkAccount(),

    panel: (
      <div className={classes.panel}>
        <CreateBusiness
          org={investorBusinessAccount}
          resetCreateUpdateOrgSuccess={
            resetCreateUpdateInvestmentBusinessAccountSuccess
          }
          defaultOrgId={investorBusinessAccount?.id}
          loading={accountSlice.loading}
          createOrgSuccess={accountSlice.createInvestorBusinessAccountSuccess}
          updateOrgSuccess={accountSlice.updateInvestorBusinessAccountSuccess}
          createOrgThunk={createInvestorBusinessAccountThunk}
          getOrgThunk={getInvestmentBusinessAccountThunk}
          useAccountSetup={false}
          parentMoveForward={handleMoveForward}
          parentMoveBackward={handleMoveBackward}
          trigger={trigger}
          reset={reset}
          setValue={setValue}
          isDirty={isDirty}
          isValid={isValid}
          control={control}
          handleSubmit={handleSubmit}
          businessInfo={businessInfo}
          setBusinessInfo={setBusinessInfo}
          getActiveStep={() => {
            return activeStep;
          }}
        />
      </div>
    ),
  };

  const linkAccountStep = {
    title: "Link your Business Bank Account",
    handleSubmit: () => {
      console.log("handle view change to cards");
      handleViewChange("cards");
    },
    handleBack: () => {
      handleMoveBackward();
    },

    isNextDisabled: !investorBusinessAccount?.fundingSources?.length > 0,

    panel: (
      <div className={classes.panel}>
        <LinkAccountScreen
          org={investorBusinessAccount}
          loading={accountSlice?.loading}
          defaultOrgId={investorBusinessAccount?.id}
          getOrgThunk={getInvestmentBusinessAccountThunk}
          createPlaidFundingSourceThunk={
            createInvestmentBusinessAccountPlaidFundingSourceThunk
          }
          unlinkFundingSourceThunk={removeInvestmentBusinessAccountThunk}
        />
      </div>
    ),
  };

  const accountSetupSteps = [businessDetailStep, linkAccountStep];

  return (
    <div
      style={{
        width: "100vw",
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%", height: "100%", marginBottom: "2em" }}>
        <HorizontalStepper
          stepperWidth={"40%"}
          activeStep={activeStep}
          stepperTitle={""}
          stepperSubtitle={""}
          stepperCompletedMessage={""}
          acceptedTerms={acceptedTerms}
          steps={accountSetupSteps}
          control={control}
          isDirty={isDirty}
          isValid={isValid}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      </div>
    </div>
  );
};

export default InvestmentBusinessAccountSetup;
