import React from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box } from "@mui/material";

const formatPhoneNumber = (phoneNumberString) => {
  console.log("got phone as: ", phoneNumberString);
  if (typeof phoneNumberString === "string") {
    const phoneNumber = parsePhoneNumberFromString(phoneNumberString);
    return phoneNumber ? phoneNumber.formatInternational() : phoneNumberString;
  }
  return phoneNumberString; // or return a default value or placeholder
};

const getStatusStyling = (status) => {
  switch (status) {
    case "processed":
      return {
        icon: <DoneIcon style={{ color: "#4CAF50" }} />, // Darker Green
        backgroundColor: "#C8E6C9", // Light Green
      };
    case "failed":
      return {
        icon: <ClearIcon style={{ color: "#FF5252" }} />, // Darker Red
        backgroundColor: "#FFCDD2", // Light Red
      };
    case "cancelled":
      return {
        icon: <CancelIcon style={{ color: "#FFA726" }} />, // Darker Orange
        backgroundColor: "#FFE0B2", // Light Orange
      };
    case "pending":
      return {
        icon: <HourglassEmptyIcon style={{ color: "#64B5F6" }} />, // Darker Blue
        backgroundColor: "#E0F7FA", // Light Blue
      };
    default:
      return {
        icon: null,
        backgroundColor: "#B0E0E6", // Default color for unexpected status
      };
  }
};

const StatusPill = ({ status }) => {
  const { icon, backgroundColor } = getStatusStyling(status);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor,
        borderRadius: "15px",
        padding: "8px 15px",
        width: "40%",
      }}
    >
      {icon}
      <p style={{ margin: "0 0 0 5px", textTransform: "capitalize" }}>
        {status}
      </p>
    </div>
  );
};

const TransferPill = ({ type, children }) => {
  const getStyling = (transferType) => {
    switch (transferType) {
      case "Sent":
        return {
          backgroundColor: "#E0F7FA", // light blue background
          color: "#007BFF", // blue text
          IconComponent: ArrowDownwardIcon, // sending money away (downward)
          iconColor: "#0056b3", // slightly darker blue for the icon
        };
      case "Received":
        return {
          backgroundColor: "#E8F5E9", // light green background
          color: "#4CAF50", // green text
          IconComponent: ArrowUpwardIcon, // receiving money (upward)
          iconColor: "#087f23", // slightly darker green for the icon
        };
      default:
        return {
          backgroundColor: "#ECEFF1", // light grey as default background
          color: "#78909C", // grey text for unknown type
          IconComponent: null,
          iconColor: "inherit",
        };
    }
  };

  const { backgroundColor, color, IconComponent, iconColor } = getStyling(type);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor,
        color,
        width: "50%",
        borderRadius: "15px",
        padding: "0.5em 1em",
        textTransform: "capitalize",
      }}
    >
      {IconComponent && (
        <IconComponent style={{ color: iconColor, marginRight: "0.5em" }} />
      )}
      {children}
    </div>
  );
};

const FinancialTransactionsTable = (props) => {
  const { data } = props;

  const formatCustomDate = (date) => {
    const now = moment();
    const inputDate = moment(date);

    if (inputDate.isSame(now, "day")) {
      return `today @ ${inputDate.format("h:mma")}`;
    } else if (inputDate.isSame(now.clone().subtract(1, "day"), "day")) {
      return `yesterday @ ${inputDate.format("h:mma")}`;
    } else {
      return inputDate.format("M/DD/YYYY @ h:mma");
    }
  };

  const normalizedData = data.map((transfer) => {
    let amount;
    if (transfer.type === "Sent") {
      amount = transfer.amount.value * -1.0;
    } else {
      amount = transfer.amount.value;
    }

    amount = Number(amount).toFixed(2);

    let normalTransfer = {
      ...transfer,
      amount: `${amount} ${transfer.amount.currency}`,
      name:
        transfer.type === "Sent"
          ? `TO:  ${transfer.destination.name}`
          : `FROM:  ${transfer.source.name}`,
      date: formatCustomDate(transfer.created),
    };

    return normalTransfer;
  });

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const transfer = normalizedData[dataIndex];
          return (
            <TransferPill type={transfer.type}>{transfer.name}</TransferPill>
          );
        },
      },
    },

    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StatusPill status={value} />;
        },
      },
    },

    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => {
          console.log("value: ", value);

          let numericValue = Number(value.split(" ")[0]).toFixed(2);
          numericValue = parseFloat(numericValue);

          return (
            <div
              style={{
                color: numericValue >= 0 ? "green" : "black",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              $
              {isNaN(numericValue)
                ? "N/A"
                : numericValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    download: false, // Set to false to remove the download option
    print: false, // Set to false to remove the print option
    viewColumns: false, // Disable view columns option
    filter: false, // Disable filter table option
  };

  return (
    <MUIDataTable
      title={"Transactions"}
      data={normalizedData}
      columns={columns}
      options={options}
    />
  );
};

export default FinancialTransactionsTable;
