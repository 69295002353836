import { useDispatch } from "react-redux";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloseIcon from "@mui/icons-material/Close";
import MoneyIcon from "@mui/icons-material/Money"; // For Available balance
import PaymentIcon from "@mui/icons-material/Payment"; // For Current balance
import LockOpenIcon from "@mui/icons-material/LockOpen"; // For Available balance
import PieChartIcon from "@mui/icons-material/PieChart"; // For Current balance
import { IconButton } from "@mui/material";
import moment from "moment";

const FundingSourceCard = (props) => {
  const { org, fundingSource, unlinkFundingSourceThunk, extraLabel } = props;
  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: "350px",
        border: "1px solid lightGray",
        padding: "15px",
        borderRadius: "5px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <IconButton
        onClick={() => {
          dispatch(unlinkFundingSourceThunk(org.id, fundingSource.id));
        }}
        aria-label="delete"
        size="small"
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          backgroundColor: "#F5F5F5", // Circle background
          borderRadius: "50%",
        }}
      >
        <CloseIcon fontSize="inherit" style={{ color: "#F44336" }} />
      </IconButton>

      <div style={{ textAlign: "center", marginBottom: "15px" }}>
        <AccountBalanceIcon style={{ width: "40px", height: "40px" }} />
        <p
          style={{
            margin: "5px 0",
            fontWeight: "bold",
            fontSize: "18px",
            color: "#333",
          }}
        >
          {fundingSource.name}
        </p>
      </div>

      {extraLabel && (
        <p
          style={{
            fontSize: "12px",
            fontStyle: "italic",
            marginBottom: "10px",
          }}
        >
          {extraLabel}
        </p>
      )}

      <p
        style={{
          margin: "5px 0",
          fontSize: "12px",
          fontStyle: "italic",
          color: "#777",
        }}
      >
        Linked on:{" "}
        {`${moment
          .utc(fundingSource.createdDate)
          .local()
          .format("dddd, MMMM D @ h:mmA z")}`}
      </p>

      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <LockOpenIcon style={{ color: "#4CAF50" }} />
        <p style={{ margin: "0px", color: "#333" }}>
          <strong>Available Balance:</strong> ${fundingSource.availableBalance}
        </p>
      </div>

      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <PaymentIcon style={{ color: "#4CAF50" }} />
        <p style={{ margin: "0px", color: "#333" }}>
          <strong>Current Balance:</strong> ${fundingSource.currentBalance}
        </p>
      </div>
    </div>
  );
};

export default FundingSourceCard;
