import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import moment from "moment";
import { FirstPaymentDate, FundedDate } from "../Form/FinancialForm";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmPaymentScheduleThunk,
  setPaymentScheduleThunk,
} from "../../models/deal/dealThunks";
import { useDidMountEffect } from "../../shared/commonUtils";
import FundedDealTermsModal from "../FundDealTermsModal/FundDealTermsModal";
import _ from "lodash";
import EventNoteIcon from "@mui/icons-material/EventNote";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const PaymentCalendar = (props) => {
  const { events } = props;
  const { control, handleSubmit, reset } = useForm();
  const startOfToday = moment().startOf("day");
  const [paymentScheduleDTO, setPaymentScheduleDTO] = useState({
    firstPaymentDate: null,
  });
  const [acceptTerms, setAcceptTerms] = useState(false);
  const loading = useSelector((state) => state.ui).loading;

  const deals = useSelector((state) => state.deals);
  const currentDeal = deals?.deal;

  const dispatch = useDispatch();

  const colors = {
    processed: "#4CAF50", // A nicer shade of green
    failed: "#F44336", // A nicer shade of red
    cancelled: "#F44336", // A nicer shade of red
    pending: "#2196F3", // Chosen blue for pending
    scheduled: "#9E9E9E", // Cyan for scheduled, or you can keep the gray #9E9E9E
    holidayBackground: "#E3F2FD", // A very soft and muted blue for background
    holidayIcon: "#2196F3", // A standard blue for the icon
  };

  const darkenColor = (color, percent) => {
    const num = parseInt(color.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;
    return `#${((1 << 24) | (R << 16) | (G << 8) | B)
      .toString(16)
      .slice(1)
      .toUpperCase()}`;
  };

  const getIcon = (status) => {
    const iconColors = {
      processed: darkenColor(colors.processed, -20),
      failed: darkenColor(colors.failed, -20),
      cancelled: darkenColor(colors.cancelled, -20),
      scheduled: darkenColor(colors.scheduled, -20),
    };

    const icons = {
      processed: <CheckIcon style={{ color: iconColors.processed }} />,
      failed: <CloseIcon style={{ color: iconColors.failed }} />,
      cancelled: <CloseIcon style={{ color: iconColors.cancelled }} />,
      scheduled: <AccessTimeIcon style={{ color: iconColors.scheduled }} />,
    };

    return icons[status] || null; // Default to null if status is not recognized
  };

  useDidMountEffect(() => {
    dispatch(setPaymentScheduleThunk(currentDeal?.id, paymentScheduleDTO));
  }, [paymentScheduleDTO.firstPaymentDate]);

  const getBackgroundColor = (status) => {
    return colors[status] || "#9E9E9E"; // Default to gray if status is not recognized
  };

  const normalizedEvents = events.map((curr) => {
    if (curr.type === "holiday") {
      return {
        ...curr,
        title: curr.observed ? `${curr.name} (Observed)` : `${curr.name}`,
        date: moment.unix(curr.date).format("YYYY-MM-DD"),
      };
    } else {
      return {
        ...curr,
        title: curr.status,
        date: moment(curr.scheduledDate).format("YYYY-MM-DD"),
        backgroundColor: getBackgroundColor(curr.status),
        icon: getIcon(curr.status),
        type: "payment",
      };
    }
  });

  console.log("normalized events: ", normalizedEvents);

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const { extendedProps } = event;

    if (extendedProps.type === "holiday") {
      return (
        <div
          style={{
            whiteSpace: "initial",
            wordWrap: "break-word",
            display: "flex",
            alignItems: "center",
            backgroundColor: colors.holidayBackground,
          }}
        >
          <EventNoteIcon
            style={{ color: colors.holidayIcon, marginRight: "10px" }}
          />
          <p style={{ color: "black" }}> {`${event.title}`}</p>
        </div>
      );
    } else {
      return (
        <div
          style={{
            whiteSpace: "initial",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          {extendedProps?.amount?.value && (
            <p style={{ fontWeight: "bold", color: "black" }}>
              {`Amount: $${extendedProps?.amount?.value}`}
            </p>
          )}

          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {getIcon(extendedProps?.status)}
            <p style={{ fontWeight: "bold", color: "black" }}>
              {`Status: ${extendedProps?.status}`}
            </p>
          </div>
        </div>
      );
    }
  };

  console.log("the events: ", events);
  return (
    <div
      style={{
        width: "50vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        rowGap: "1em",
      }}
    >
      {currentDeal?.dealStatus === "Ready to Fund" ? (
        <div
          style={{ display: "flex", rowGap: "1em", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              width: "80%",
              flexDirection: "row",
              columnGap: "1em",
            }}
          >
            <div style={{ display: "flex", width: "50%" }}>
              <FirstPaymentDate
                control={control}
                firstPaymentDate={paymentScheduleDTO.firstPaymentDate}
                setFirstPaymentDate={(value) => {
                  setPaymentScheduleDTO({
                    ...paymentScheduleDTO,
                    firstPaymentDate: value,
                  });
                }}
              />
            </div>
          </div>
          <div
            style={{ display: "flex", columnGap: "1em", marginBottom: "1em" }}
          >
            <Button
              disabled={
                loading || _.isNil(paymentScheduleDTO?.firstPaymentDate)
              }
              onClick={() => {
                setAcceptTerms(true);
              }}
              variant="outlined"
            >
              Fund Deal
            </Button>
          </div>
          <FundedDealTermsModal
            deal={currentDeal}
            open={acceptTerms}
            setOpen={setAcceptTerms}
          />
        </div>
      ) : (
        <></>
      )}

      <div
        style={{ marginRight: "3em", marginTop: "3em", marginBottom: "3em" }}
      >
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          // weekends={false}
          events={normalizedEvents}
          eventContent={renderEventContent}
        />
      </div>
    </div>
  );
};

export default PaymentCalendar;
