import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import InvestorSearch from "./InvestorSearch";
import { InvestmentPercentWithSlider } from "../Form/FinancialForm";
import { FundedDealBarChart } from "../FundedDealBarChart/FundedDealBarChart";
import { useDispatch, useSelector } from "react-redux";
import { updateInvestorContributionsThunk } from "../../models/deal/dealThunks";
import {
  useDebouncedCallback,
  useDidMountEffect,
} from "../../shared/commonUtils";
import _, { curry } from "lodash";
import {
  getInvestorsForLenderThunk,
  getLenderThunk,
} from "../../models/lender/lenderThunks";
import { Button } from "@mui/material";

const InvestorTab = (props) => {
  const { opportunity } = props;
  const accountSlice = useSelector((state) => state.account);
  const account = accountSlice.account;
  const lenderSlice = useSelector((state) => state.lenders);
  const dealSlice = useSelector((state) => state.deals);
  const merchantSlice = useSelector((state) => state.merchants);

  const lender = lenderSlice.lender;
  const investors = lenderSlice?.investors;
  const merchant = merchantSlice.merchant;
  const deal = dealSlice?.deal;

  const { control, handleSubmit, reset } = useForm();
  const [selectedInvestors, setSelectedInvestors] = useState(
    deal?.investors?.map((curr) => {
      return {
        locked: true,
        contribution: curr.contributionPercentage,
        id: curr.id,
        investor: {
          name: `${curr.user.firstName} ${curr.user.lastName}`,
          email: curr.user.email,
          user: curr.user,
        },
      };
    })
  );

  const [max, setMax] = useState(100);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLenderThunk(lender?.id));
  }, []);

  useEffect(() => {
    if (!_.isNil(lender) && !_.isNil(lender.id)) {
      dispatch(getInvestorsForLenderThunk(lender.id));
    }
  }, [lender.id]);

  const updateInvestorContributions = useDebouncedCallback(() => {
    const dtos = selectedInvestors.map((curr) => {
      if (!curr.investor.user) {
        let user = { ...curr.investor };
        curr.investor = { user: {} };
        curr.investor.user = user;
      }

      console.log("currrrr: ", curr);

      return {
        dealId: deal.id,
        userId: curr.investor.user.id,
        contributionPercentage: curr.contribution,
      };
    });
    dispatch(updateInvestorContributionsThunk(dtos, deal.id));
  }, 500);

  useDidMountEffect(() => {
    if (!_.isNil(selectedInvestors)) {
      updateInvestorContributions();
    }
  }, [selectedInvestors]);

  const setInvestorContribution = (id, value) => {
    console.log(`set investor contribution: ${id}, locked: ${value}`);

    const idx = selectedInvestors.findIndex((selected) => {
      console.log("selected: ", selected);
      return selected.id === id;
    });

    const shallow = [...selectedInvestors];
    let investorContribution = shallow[idx];

    investorContribution = { ...investorContribution, locked: value };
    shallow[idx] = investorContribution;

    setSelectedInvestors(shallow);
  };

  const removeInvestor = (id) => {
    const idx = selectedInvestors.findIndex((selected) => {
      console.log("selected: ", selected);
      return selected.id === id;
    });

    const investorToRemove = selectedInvestors[idx];
    console.log("investor to remove: ", investorToRemove);

    console.log("index: ", idx);
    const shallow = [...selectedInvestors];
    const forfeitedPercentage = shallow[idx].contribution;
    shallow.splice(idx, 1); // 2nd parameter means remove one item only

    let numLocked = 0;
    let numUnlocked = 0;

    shallow.forEach((curr) => {
      if (curr.locked) {
        numLocked++;
      } else {
        numUnlocked++;
      }
    });

    const distribution = forfeitedPercentage / numUnlocked;
    console.log(
      `forfeited %: ${forfeitedPercentage}, distribution: ${distribution}`
    );

    // interate over remaining, and if unlocked, distribute forfeited percentage
    shallow.forEach((curr, index) => {
      shallow[index] = {
        ...curr,
        contribution: curr.contribution + distribution,
      };
    });

    setSelectedInvestors(shallow);
  };

  const balancePercentages = (selected, percentage) => {
    console.log("selected: ", selected);
    console.log("percentage: ", percentage);
    const { investor, id, contribution } = selected;

    let lockedPercentage = 0;
    let numLocked = 0;
    let numUnlocked = 0;

    selectedInvestors.forEach((selected) => {
      if (selected.locked) {
        lockedPercentage += selected.contribution;
        numLocked++;
      } else {
        numUnlocked++;
      }
    });

    const unlockedPercentage = 100 - lockedPercentage;
    setMax(unlockedPercentage);

    console.log(
      `unlocked percentage: ${unlockedPercentage}, locked percentage: ${lockedPercentage}`
    );

    const remainingPercentage = unlockedPercentage - percentage;
    console.log("remaining percentage: ", remainingPercentage);

    // distribute remaining across other unlocked investors equally
    const distribution = remainingPercentage / (numUnlocked - 1);

    console.log("distribution: ", distribution);

    let shallow = [...selectedInvestors]?.map((curr) => {
      console.log("curr: ", curr);
      if (curr.id === id) {
        return { ...curr, contribution: percentage };
      } else if (!curr.locked) {
        return { ...curr, contribution: distribution };
      } else {
        return curr;
      }
    });

    setSelectedInvestors(shallow);
  };

  const showDefaultUX = () => {
    return (
      <InvestorSearch
        investors={investors ? investors : []}
        setSelectedInvestors={(selectedInvestor) => {
          if (
            !selectedInvestors.some(
              (investor) => investor.id === selectedInvestor.id
            )
          ) {
            console.log("setting the selected investors: ", selectedInvestor);
            setSelectedInvestors([
              ...selectedInvestors,
              {
                investor: selectedInvestor,
                contribution: 0,
              },
            ]);
          }
        }}
        control={control}
      />
    );
  };

  const shouldDisableIWantIn = (currentUserInvestor) => {
    const isAlreadyOnDeal = selectedInvestors.some(
      (investor) => investor?.investor?.user?.id === currentUserInvestor.id
    );

    console.log("deal contrib: ", deal.percentOfDealContributedTo);

    return isAlreadyOnDeal || deal.percentOfDealContributedTo >= 100;
  };

  const showOpportunityUX = () => {
    let canUserBeAddedToDeal = false;

    const doesCurrentUserHaveInvestmentAccount = isCurrentUserAnInvestor();
    const currentUserInvestor = doesCurrentUserHaveInvestmentAccount
      ? investors.filter((investor) => investor.email === account.email)[0]
      : null;

    console.log("currentUserInvestor: ", currentUserInvestor);

    console.log("foooo: ", selectedInvestors);

    if (
      !_.isNil(currentUserInvestor) &&
      !selectedInvestors.some(
        (investor) => investor.id === currentUserInvestor.id
      )
    ) {
      canUserBeAddedToDeal = true;
    }

    return canUserBeAddedToDeal ? (
      <Button
        disabled={shouldDisableIWantIn(currentUserInvestor)}
        onClick={() => {
          setSelectedInvestors([
            ...selectedInvestors,
            {
              investor: currentUserInvestor,
              contribution: 0,
            },
          ]);
        }}
        variant="outlined"
      >
        I want in
      </Button>
    ) : (
      <p>
        It doesn't look like you've set up an investment account yet. Please
        link an investment account to start syndicating.
      </p>
    );
  };

  const isCurrentUserAnInvestor = () => {
    console.log("investors: ", investors);
    console.log("account email: ", account.email);
    return investors.some((investor) => {
      return investor.email === account.email;
    });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          {deal.dealStatus === "Needs Action" && (
            <div
              style={{ marginTop: "1em", marginBottom: "2em", width: "100%" }}
            >
              {opportunity ? showOpportunityUX() : showDefaultUX()}
            </div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {selectedInvestors?.map((selected) => {
              return (
                <div style={{ marginTop: "1em", marginBottom: "1em" }}>
                  <InvestmentPercentWithSlider
                    deal={deal}
                    investorContribution={selected}
                    control={control}
                    max={max}
                    removeInvestor={(id) => {
                      removeInvestor(id);
                    }}
                    setAmount={(percentage) => {
                      balancePercentages(selected, percentage);
                    }}
                    setLocked={() => {
                      setInvestorContribution(selected.id, !selected.locked);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "60%",
            }}
          >
            <FundedDealBarChart data={selectedInvestors} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorTab;
