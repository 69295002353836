import React from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { createTheme, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ThemeProvider } from "@emotion/react";
import _ from "lodash";
import { ROLE_LENDER_ADMIN } from "../../config/contants";
import ProtectedComponent from "../ProtectedComponent/ProtectedComponent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const formatPhoneNumber = (phoneNumberString) => {
  console.log("got phone as: ", phoneNumberString);
  if (typeof phoneNumberString === "string") {
    const phoneNumber = parsePhoneNumberFromString(phoneNumberString);
    return phoneNumber ? phoneNumber.formatInternational() : phoneNumberString;
  }
  return phoneNumberString; // or return a default value or placeholder
};

const getRoleStyling = (role) => {
  switch (role) {
    case "Admin":
      return {
        icon: <AdminPanelSettingsIcon style={{ color: "#3f51b5" }} />,
        color: "#3f51b5", // Adjust color for Admin
        backgroundColor: "#e8eaf6", // Adjust background color for Admin
      };
    case "User":
      return {
        icon: <SupervisorAccountIcon style={{ color: "#4CAF50" }} />,
        color: "#4CAF50", // Adjust color for User
        backgroundColor: "#e8f5e9", // Adjust background color for User
      };
    default:
      return {
        icon: null,
        color: "gray",
        backgroundColor: "#f5f5f5", // Adjust default color and background as needed
      };
  }
};

function getInviteStatusStyling(status) {
  switch (status) {
    case "Accepted":
      return {
        icon: <CheckCircleOutlineIcon style={{ color: "green" }} />,
        color: "green",
        backgroundColor: "#e8f5e9", // Light green
      };
    case "Pending":
      return {
        icon: <HourglassEmptyIcon style={{ color: "#FF9800" }} />, // Or any color representing 'pending' status
        color: "#FF9800",
        backgroundColor: "#FFF3E0", // Light orange
      };
    default:
      return {
        icon: null,
        color: "gray",
        backgroundColor: "#f5f5f5", // Light gray for unknown statuses
      };
  }
}

const UserTable = (props) => {
  const { data, setAddUser } = props;
  const navigate = useNavigate();

  console.log("the organization user data was: ", data);

  const normalized = data?.map((curr) => {
    const { user } = curr;

    return {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.phoneNumber
        ? formatPhoneNumber(user.phoneNumber)
        : "N/A",
      role: `${
        user?.authorities.includes(ROLE_LENDER_ADMIN) ? "Admin" : "User"
      }`,
      inviteStatus: curr?.inviteStatus,
    };
  });

  const options = {
    download: false, // Set to false to remove the download option
    print: false, // Set to false to remove the print option
    viewColumns: false, // Disable view columns option
    filter: false, // Disable filter table option
    selectableRows: false,

    filterType: "checkbox",

    onRowClick: (rowData, rowMeta) => {
      //   dispatch(getMerchantById(rowData[0]));

      navigate(`user-details/${rowData[0]}-${rowData[1]}`, {
        merchantName: rowData[0],
      });
    },

    customToolbar: () => {
      return (
        <ProtectedComponent roles={[ROLE_LENDER_ADMIN]}>
          <IconButton
            onClick={() => {
              setAddUser(true);
            }}
            style={{ order: -1 }}
          >
            <AddIcon />
          </IconButton>
        </ProtectedComponent>
      );
    },
    selectableRows: false,
  };

  const columns = [
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const role = data[dataIndex].user?.authorities.includes(
            ROLE_LENDER_ADMIN
          )
            ? "Admin"
            : "User";
          const { icon, color, backgroundColor } = getRoleStyling(role);

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor,
                padding: "4px 8px",
                borderRadius: "8px",
              }}
            >
              {icon}
              <span style={{ marginLeft: "8px", color }}>{role}</span>
            </div>
          );
        },
      },
    },
    {
      name: "inviteStatus",
      label: "Invite Status",
      options: {
        filter: true,
        sort: true,

        customBodyRenderLite: (dataIndex) => {
          const status = data[dataIndex].inviteStatus;
          const { icon, color, backgroundColor } =
            getInviteStatusStyling(status);

          return (
            <div
              style={{
                padding: "0.5em",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                backgroundColor,
              }}
            >
              {icon}
              <span style={{ marginLeft: "0.5em", color }}>{status}</span>
            </div>
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      style={{ cursor: "pointer" }}
      title={"Users"}
      data={!_.isNil(normalized) && normalized.length > 0 ? normalized : []}
      columns={columns}
      options={options}
    />
  );
};

export default UserTable;
