import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Link } from "@mui/material";

import _ from "lodash";
import { PhoneNumber, PinPad } from "../../components/Form/Form";

import {
  mfaVerifySMSVerificationCode,
  sendSMSVerificationCode,
} from "../../models/account/accountActions";
import { Box } from "@mui/system";
import FingerprintIcon from "@mui/icons-material/Fingerprint";

import useRedirect from "../../shared/useRedirect";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const SetupMFA = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [pins, setPins] = useState([]);

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
  } = useForm();

  const handleAddPhoneNumber = () => {
    dispatch(sendSMSVerificationCode(phoneNumber));
    setCodeSent(true);
  };

  const handleMfaVerifySMS = () => {
    dispatch(
      mfaVerifySMSVerificationCode({
        code: pins.join(""),
        phoneNumber: phoneNumber,
      })
    );
  };

  useRedirect();

  return (
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "1em",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "1000px",
              }}
            >
              <FingerprintIcon
                style={{
                  height: "40px",
                  width: "40px",
                  color: "white",
                }}
              />
            </div>

            <h2 style={{ margin: "0em" }}>Setup Two Factor Authentication</h2>
          </div>

          {codeSent ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                rowGap: "1em",
                height: "300px",
              }}
            >
              <p style={{ margin: "0px" }}>
                We sent a 6 digit verification code to your device
              </p>
              <PinPad
                pinLength={6}
                pins={pins}
                setPins={setPins}
                control={control}
                errors={errors}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  columnGap: "1em",
                }}
              >
                <Button
                  onClick={() => {
                    setCodeSent(false);
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleAddPhoneNumber();
                  }}
                >
                  Send Code
                </Button>
                <Button
                  disabled={!isDirty || !isValid} // here
                  variant="outlined"
                  onClick={handleSubmit(handleMfaVerifySMS)}
                >
                  Verify
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                rowGap: "1em",
                width: "100%",
                height: "300px",
              }}
            >
              <PhoneNumber
                control={control}
                phone={phoneNumber}
                setPhone={(phone) => {
                  setPhoneNumber(phone);
                }}
              />
              <Button
                disabled={!isValid}
                variant="outlined"
                onClick={handleSubmit(handleAddPhoneNumber)}
              >
                Send Code
              </Button>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

export default SetupMFA;
