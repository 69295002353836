import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MerchantTable from "../../components/MerchantTable/MerchantTable";
import { useState } from "react";
import { Button, Paper } from "@mui/material";
import InviteMerchantModal from "../../components/InviteMerchantModal/InviteMerchantModal";
import { getMerchantsThunk } from "../../models/merchant/merchantThunks";
import _ from "lodash";
import LenderSelection from "../../components/LenderSelection/LenderSelection";
import SendIcon from "@mui/icons-material/Send";
import StoreIcon from "@mui/icons-material/Store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import KpiCard from "../../components/KpiCard/KpiCard";
import StorefrontIcon from "@mui/icons-material/Storefront";

const MerchantDashboard = () => {
  const navigate = useNavigate();
  const merchantSlice = useSelector((state) => state.merchants);
  const merchants = merchantSlice?.merchants;
  const lenders = useSelector((state) => state.lenders);

  const lender = lenders?.lender;
  const kpis = lenders?.kpis;
  const account = useSelector((state) => state.account);
  const [addMerchant, setAddMerchant] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isNil(lender) && !_.isNil(lender.id)) {
      dispatch(getMerchantsThunk(lender.id));
    }
  }, []);

  const kpiData = [
    {
      title: `${merchants ? merchants.length : "--"}`,
      subTitle: "Total Merchants",
      IconComponent: StoreIcon,
      color: "#3f51b5", // blue
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalCompletedDeals)
          ? kpis.totalCompletedDeals
          : "--"
      }`,
      subTitle: "Completed Deals",
      IconComponent: CheckCircleIcon,
      color: "#4CAF50", // green
    },
    {
      title: `${
        !_.isNil(kpis) && !_.isNil(kpis.totalOpenDeals)
          ? kpis.totalOpenDeals
          : "--"
      }`,
      subTitle: "Open Deals",
      IconComponent: HourglassEmptyIcon,
      color: "#FF9800", // orange
    },
  ];

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "3em", marginBottom: "3em" }}>
          <div style={{ width: "25%", marginBottom: "2em", marginTop: "1em" }}>
            <LenderSelection />
          </div>
          <StorefrontIcon style={{ fontSize: "48px", color: "#4CAF50" }} />

          <h1 style={{ margin: "0px" }}>Merchants</h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
            rowGap: "2em",
            columnGap: "2em",
            flexWrap: "wrap",
          }}
        >
          {kpiData.map((card) => (
            <KpiCard
              title={card.title}
              subTitle={card.subTitle}
              IconComponent={card.IconComponent}
              color={card.color}
            />
          ))}
        </div>
        <div style={{ width: "100%", marginTop: "2em" }}>
          {_.isNil(merchants) || merchants.length === 0 ? (
            <>
              <h2>
                Looks like you haven't invited any merchants to your platform
                yet.
              </h2>
              <Button
                variant="outlined"
                endIcon={<SendIcon />}
                onClick={() => {
                  setAddMerchant(true);
                }}
              >
                Invite a merchant
              </Button>
            </>
          ) : (
            <>
              <MerchantTable
                setAddMerchant={setAddMerchant}
                data={merchants ? merchants : []}
              />
            </>
          )}
          <InviteMerchantModal open={addMerchant} setOpen={setAddMerchant} />
        </div>
      </div>
    </div>
  );
};

export default MerchantDashboard;
