import { Button, Divider } from "@mui/material";
import { useDispatch } from "react-redux";

import { getElapsedTimeString } from "../../shared/commonUtils";

export const InviteAcceptedNotification = (props) => {
  const { notification } = props;
  const dispatch = useDispatch();
  const elapsedTime = getElapsedTimeString(notification.createdDate);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
        {notification.title ? notification.title : ""}
      </p>
      <p style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "14px" }}>
        {notification.body ? notification.body : ""}
      </p>
      <p
        style={{
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "12px",
          marginTop: "0px",
        }}
      >
        {elapsedTime}
      </p>

      <Divider />
    </div>
  );
};
