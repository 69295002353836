import { failure, loading, toastFailure } from "../uiReducer";
import {
  createLinkToken,
  exchangeLinkTokenForAccessToken,
  exchangePublicTokenForAccessToken,
  getDwollaProcessorToken,
} from "./plaidApi";
import {
  createLinkTokenSuccess,
  exchangePublicTokenForAccessTokenSuccess,
  exchangeTokensSuccess,
  getDwollaProcessorTokenSuccess,
} from "./plaidReducer";

export const createLinkTokenThunk = () => async (dispatch) => {
  console.log(`handle create link token`);
  dispatch(loading(true));

  try {
    const response = await createLinkToken();

    console.log("plaid response: ", response);

    dispatch(createLinkTokenSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const exchangePublicTokenForAccessTokenThunk =
  (publicToken) => async (dispatch) => {
    console.log(`handle exchange public token for access token`);
    dispatch(loading(true));

    try {
      const response = await exchangePublicTokenForAccessToken(publicToken);

      console.log("plaid response: ", response);

      dispatch(exchangePublicTokenForAccessTokenSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const getDwollaProcessorTokenThunk =
  (accessToken, accountId) => async (dispatch) => {
    console.log(`handle get dwolla processor token action`);
    dispatch(loading(true));

    try {
      const response = await getDwollaProcessorToken(accessToken, accountId);

      console.log("plaid response: ", response);

      dispatch(getDwollaProcessorTokenSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };
