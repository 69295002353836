export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const JWT = "JWT";
export const GOOGLE_TOKEN = "GOOGLE_TOKEN";
export const applicationJson = "application/json";

export const getToken = async (key: string) => {
  try {
    const value = sessionStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    throw e;
  }
};

export const storeToken = async (key: string, value: string) => {
  console.log("STORE TOKEN INVOKATION");
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    throw e;
  }
};

export const revokeToken = async (key: string) => {
  console.log("revoke token");
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    throw e;
  }
};

const nodeEnv = process.env.NODE_ENV;

export const ROLE_PRE_AUTH_MFA_REQUIRED = "ROLE_PRE_AUTH_MFA_REQUIRED";
export const ROLE_LENDER_ADMIN = "ROLE_LENDER_ADMIN";
export const ROLE_LENDER_USER = "ROLE_LENDER_USER";
export const ROLE_INVITED_USER = "ROLE_INVITED_USER";
export const ROLE_MERCHANT = "ROLE_MERCHANT";
export const ROLE_USER = "ROLE_USER";

export const ROOT_URL =
  nodeEnv === "production"
    ? "https://api.syndicationmanager.app/"
    : "http://localhost:8080/";

export const DWOLLA_ROOT_URL =
  nodeEnv === "production"
    ? "https://api-sandbox.dwolla.com"
    : "https://api-sandbox.dwolla.com";

console.log(`the node env is: ${nodeEnv}`);
