import _ from "lodash";
import { failure, loading, toastFailure, toastSuccess } from "../uiReducer";
import {
  acceptLenderUserInvite,
  acceptMerchantInvite,
  acceptOrganizationInvite,
  createNotification,
  getNotifications,
  getUnopenedNotificationsCount,
  openAll,
  rejectMerchantInvite,
  rejectOrganizationInvite,
  sendConnectionInvite,
} from "./notificationApi";
import {
  acceptInviteSuccess,
  createNotificationSuccess,
  decrementUnopenedNotifications,
  getNotificationSuccess,
  getUnopenedNotificationCountSuccess,
  incrementUnopenedNotifications,
  openAllUnopenedNotificationsSuccess,
  rejectInviteSuccess,
  resetNotificationsBell,
  sendConnectionInviteSuccess,
} from "./notificationReducer";

export const getNotificationsAction = () => async (dispatch) => {
  console.log("handle get notifications");
  dispatch(loading(true));

  try {
    const response = await getNotifications();
    console.log(response);
    console.log("the get notifications response was: ", response.data);

    dispatch(getNotificationSuccess(response.data));
  } catch (error) {
    console.log("inside of get notifications error");
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const getUnopenedNotificationsCountAction = () => async (dispatch) => {
  console.log("handle get unopened notifications count");
  dispatch(loading(true));

  try {
    const response = await getUnopenedNotificationsCount();
    console.log(response);
    console.log("the response was: ", response.data);

    dispatch(getUnopenedNotificationCountSuccess(response.data));
  } catch (error) {
    console.log("inside of get notifications count error");
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const incrementUnopenedNotificationsCountAction =
  () => async (dispatch) => {
    console.log("handle increment unopened notifications count");
    dispatch(loading(true));

    try {
      dispatch(incrementUnopenedNotifications());
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const decrementUnopenedNotificationsCountAction =
  () => async (dispatch) => {
    console.log("handle decrement unopened notifications count");
    dispatch(loading(true));

    try {
      dispatch(decrementUnopenedNotifications());
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const openAllUnopenedNotificationsAction = () => async (dispatch) => {
  console.log("handle open all unopened notifications action");
  try {
    const response = await openAll();
    console.log(response);
    console.log("the response was: ", response.data);

    dispatch(openAllUnopenedNotificationsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const resetNotificationsBellAction = () => async (dispatch) => {
  console.log("handle reset notifications bell action");
  try {
    dispatch(resetNotificationsBell());
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const acceptLenderUserInviteThunk = (id) => async (dispatch) => {
  console.log("handle accept org invite");
  dispatch(loading(true));

  try {
    const response = await acceptLenderUserInvite(id);

    dispatch(acceptInviteSuccess(id));
  } catch (error) {
    console.log("inside of accept org invite error");
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const acceptMerchantInviteAction = (id) => async (dispatch) => {
  console.log("handle accept merchant invite");
  dispatch(loading(true));

  try {
    const response = await acceptMerchantInvite(id);

    dispatch(acceptInviteSuccess(id));
  } catch (error) {
    console.log("inside of accept merchant invite error");
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

// export const acceptMerchantInviteAction = (merchantDTO) => async (dispatch) => {
//   console.log("handle accept merchant invite");
//   dispatch(loading(true));

//   try {
//     const response = await acceptMerchantInvite(merchantDTO);

//     dispatch(acceptInviteSuccess(response?.data));
//   } catch (error) {
//     console.log("inside of accept merchant invite error");
//     toastFailure(error);
//     dispatch(loading(false));

//     dispatch(failure(error?.response?.data?.message));
//   }
//   dispatch(loading(false));
// };

export const rejectMerchantInviteAction = (id) => async (dispatch) => {
  console.log("handle reject merchant invite");
  dispatch(loading(true));

  try {
    const response = await rejectMerchantInvite(id);
    console.log(response);
    console.log("the response was: ", response.data);

    dispatch(rejectInviteSuccess(id));
  } catch (error) {
    console.log("inside of reject merchant invite error");
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const rejectOrganizationInviteAction = (id) => async (dispatch) => {
  console.log("handle reject org invite");
  dispatch(loading(true));

  try {
    const response = await rejectOrganizationInvite(id);
    console.log(response);
    console.log("the response was: ", response.data);

    dispatch(rejectInviteSuccess(id));
  } catch (error) {
    console.log("inside of reject org invite error");
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const sendConnectionInviteAction = (recipientId) => async (dispatch) => {
  dispatch(loading(true));

  try {
    console.log("add connection action to recipient: ", recipientId);

    const response = await sendConnectionInvite(recipientId);
    console.log("the response was: ", response.data);

    dispatch(sendConnectionInviteSuccess(response.data));
    toastSuccess("Success!", "Your connection invite was sent");
  } catch (error) {
    console.log("inside of send connection invite error");

    toastFailure(error);

    dispatch(loading(false));
  }

  dispatch(loading(false));
};
