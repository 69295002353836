import { storeToken, revokeToken, JWT } from "../../config/contants";
import { store, persistor } from "../store.js";

import {
  credentialsLogin,
  credentialsSignup,
  googleSignup,
  msftSignup,
  getAccount,
  activateAccount,
  forgotPasswordInit,
  forgotPasswordVerify,
  resetPassword,
  logout,
  sso,
  sendSMSCode,
  verifySMSCode,
  changeProfilePicture,
  updateAccount,
  changePassword,
  mfaVerifySMSCode,
  updateAccountSetup,
  getAccountSetup,
  createInvestmentBusinessAccount,
  getInvestmentBusinessAccount,
  removeInvestmentBusinessAccount,
  createInvestmentBusinessAccountPlaidFundingSource,
  createInvestmentPersonalAccount,
  removeInvestmentPersonalAccount,
  getInvestmentPersonalAccount,
  createInvestmentPersonalAccountPlaidFundingSource,
  unlinkInvestmentBusinessAccountFundingSource,
  unlinkInvestmentPersonalAccountFundingSource,
  updateFundsFlow,
  refresh,
} from "./accountApi";

import * as RootNavigation from "../../config/RootNavigation";

import {
  setAccessToken,
  signupSuccess,
  logoutSuccess,
  forgotPasswordVerifySuccess,
  forgotPasswordInitSuccess,
  getAccountSuccess,
  disconnectStomp,
  sendSMSCodeSuccess,
  verifySMSCodeSuccess,
  changeProfilePictureSuccess,
  updateAccountSuccess,
  changePasswordSuccess,
  getAccountSetupSuccess,
  updateAccountSetupSuccess,
  loading,
  getInvestmentBusinessAccountSuccess,
  failure,
  createInvestmentBusinessAccountSuccess,
  createInvestmentPersonalAccountSuccess,
  removeInvestmentBusinessAccountSuccess,
  removeInvestmentPersonalAccountSuccess,
  getInvestmentPersonalAccountSuccess,
  unlinkInvestmentPersonalAccountFundingSourceSuccess,
  unlinkInvestmentBusinessAccountFundingSourceSuccess,
} from "./accountReducer";

import _ from "lodash";
import { setRedirection, toastFailure, toastSuccess } from "../uiReducer";

export const getAccountSetupAction = () => async (dispatch) => {
  console.log("handle get account setup ");
  dispatch(loading(true));

  try {
    const response = await getAccountSetup();

    console.log("the account setup response was: ", response.data);

    dispatch(getAccountSetupSuccess(response.data));
  } catch (error) {
    toastFailure(error);

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
  }
  dispatch(loading(false));
};

export const updateAccountSetupAction = (accountSetup) => async (dispatch) => {
  console.log("handle update account setup ");
  dispatch(loading(true));

  try {
    const response = await updateAccountSetup(accountSetup);

    dispatch(updateAccountSetupSuccess(response.data));
  } catch (error) {
    toastFailure(error);

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
  }
  dispatch(loading(false));
};

export const credentialsLoginAction = (email, password) => async (dispatch) => {
  console.log("handle credentials login");
  dispatch(loading(true));

  try {
    const response = await credentialsLogin(email, password);
    const { id_token } = response.data;
    const jwt = `Bearer ${id_token}`;

    console.log(`jwt: ${jwt}`);
    // store token to local storage
    await storeToken(JWT, jwt);
    // store the token in redux state
    dispatch(setAccessToken(jwt));
    // get the logged in user's account info
    await dispatch(getAccountAction());
    await dispatch(getAccountSetupAction());
    dispatch(handleRedirectAction(response));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const handleRedirectAction = (response) => async (dispatch) => {
  const redirectTo = response.headers["x-target-url"]; // Get target URL from the header
  console.log("recieved redirectTo: ", redirectTo);
  console.log("recieved response: ", response);

  dispatch(setRedirection(redirectTo));
};

export const credentialsSignupAction = (user) => async (dispatch) => {
  console.log("handle credentials sign up");
  dispatch(loading(true));

  try {
    const response = await credentialsSignup(user);
    console.log("credentials signup response: ", response);
    const { data } = response;
    console.log(data);
    // show the account activation screen
    dispatch(signupSuccess(data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const refreshToken = () => async (dispatch) => {
  console.log("handle refresh");
  dispatch(loading(true));

  try {
    const response = await refresh();
    const { id_token } = response.data;
    const jwt = `Bearer ${id_token}`;

    console.log(`jwt: ${jwt}`);
    // store token to local storage
    await storeToken(JWT, jwt);
    // store the token in redux state
    await dispatch(setAccessToken(jwt));
    console.log("the token was refreshed");
  } catch (error) {
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const ssoLoginAction = (token) => async (dispatch) => {
  console.log("handle sso login");
  dispatch(loading(true));

  try {
    // store google token to local storage
    const response = await sso(token);

    const { id_token } = response.data;
    const jwt = `Bearer ${id_token}`;

    // store API token to local storage
    await storeToken(JWT, jwt);
    // store the token in redux state

    console.log("before dispatch");
    await dispatch(setAccessToken(jwt));
    console.log("after dispatch");
    dispatch(getAccountAction());

    // get the logged in user's account info
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
  }
  dispatch(loading(false));
};

export const googleSignupAction = (token) => async (dispatch) => {
  console.log("handle google sign up");
  dispatch(loading(true));

  try {
    const response = await googleSignup(token);
    const { id_token } = response.data;
    const jwt = `Bearer ${id_token}`;

    // store token to local storage
    await storeToken(JWT, jwt);
    // store the token in redux state
    await dispatch(setAccessToken(jwt));
    // get the logged in user's account info
    dispatch(getAccountAction());
    dispatch(loading(false));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error));
  }
  dispatch(loading(false));
};

export const msftSignupAction = (tokenResponse) => async (dispatch) => {
  console.log("handle msft sign up");
  dispatch(loading(true));

  try {
    // Log in to get an authentication token
    console.log("auth-state: ", tokenResponse);

    // Send the id token to the backend
    const idToken = tokenResponse.params["id_token"];
    console.log("id token: ", idToken);

    const response = await msftSignup(idToken);
    console.log(response);
    const { authorization } = response.headers;

    // store token to local storage
    await storeToken(JWT, authorization);
    // store the token in redux state
    await dispatch(setAccessToken(authorization));
    // get the logged in user's account info
    dispatch(getAccountAction());
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error));
  }
  dispatch(loading(false));
};

export const getAccountAction = () => async (dispatch) => {
  console.log("handle get user account");
  dispatch(loading(true));

  try {
    const jwt = sessionStorage.getItem(JWT);
    console.log("JWT: ", jwt);
    const response = await getAccount(jwt);
    const { data } = response;

    console.log("full account response data: ", data);

    dispatch(getAccountSuccess(data));
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const updateAccountAction = (account) => async (dispatch) => {
  console.log("updating with account: ", account);

  dispatch(loading(true));

  try {
    const response = await updateAccount(account);
    const { data } = response;

    console.log("response: ", data);

    dispatch(updateAccountSuccess(data));
    toastSuccess("Success!", "Your account was successfully updated.");
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const sendSMSVerificationCode = (phoneNumber) => async (dispatch) => {
  console.log(
    "handle send SMS verification code to provided phone number: ",
    phoneNumber
  );
  dispatch(loading(true));

  try {
    const response = await sendSMSCode(phoneNumber);
    const { data } = response;
    dispatch(sendSMSCodeSuccess(data));
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const verifySMSVerificationCode =
  ({ code, phoneNumber }) =>
  async (dispatch) => {
    console.log(
      `handle verify verification code: ${code} for number ${phoneNumber}`
    );
    dispatch(loading(true));

    try {
      const response = await verifySMSCode(code, phoneNumber);
      const { data } = response;
      await dispatch(verifySMSCodeSuccess(data));
      dispatch(handleRedirectAction(response));
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));

      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const mfaVerifySMSVerificationCode =
  ({ code, phoneNumber }) =>
  async (dispatch) => {
    console.log(
      `handle mfa verify verification code: ${code} for number ${phoneNumber}`
    );
    dispatch(loading(true));

    try {
      const response = await mfaVerifySMSCode(code, phoneNumber);
      const { id_token } = response.data;
      const jwt = `Bearer ${id_token}`;

      console.log(`jwt: ${jwt}`);
      // store token to local storage
      await storeToken(JWT, jwt);
      // store the token in redux state
      await dispatch(setAccessToken(jwt));
      // get the logged in user's account info
      await dispatch(getAccountAction());
      dispatch(handleRedirectAction(response));
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));

      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const logoutAction = () => async (dispatch) => {
  console.log("handle logout");
  dispatch(loading(true));

  try {
    // log out of the API
    const jwt = sessionStorage.getItem(JWT);
    console.log("JWT: ", jwt);
    await logout(jwt);
    // remove tokens from local storage
    await revokeToken(JWT);
    await dispatch(disconnectStomp());
    persistor.purge();
    dispatch(logoutSuccess());
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error.response.data.message));
  }
  dispatch(loading(false));
};

export const activateAccountAction = (activationKey) => async (dispatch) => {
  console.log("handle activate account");
  dispatch(loading(true));

  try {
    console.log("activation key: ", activationKey);
    const response = await activateAccount(activationKey);
    const { id_token } = response.data;
    const jwt = `Bearer ${id_token}`;

    console.log(`jwt: ${jwt}`);
    // store token to local storage
    await storeToken(JWT, jwt);
    // store the token in redux state
    dispatch(setAccessToken(jwt));
    // get the logged in user's account info
    dispatch(getAccountAction());
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error.response.data.message));
  }
  dispatch(loading(false));
};

export const changePasswordAction = (changePasswordDTO) => async (dispatch) => {
  console.log("handle change password action");
  dispatch(loading(true));

  try {
    const response = await changePassword(changePasswordDTO);
    const { data } = response;
    dispatch(changePasswordSuccess(data));
    toastSuccess("Success!", "Your password was successfully updated.");
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error.response.data.message));
  }
  dispatch(loading(false));
};

// the API will attempt to send an email to the user with a reset code
export const forgotPasswordInitAction = (email) => async (dispatch) => {
  console.log("handle forgot password init");
  dispatch(loading(true));

  try {
    console.log("email: ", email);

    const response = await forgotPasswordInit(email);
    console.log(response);
    dispatch(forgotPasswordInitSuccess());
    // redirect the user to the verification screen
    RootNavigation.navigate("ForgotPasswordVerification");
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
  }
  dispatch(loading(false));
};

// the API will attempt to verify the reset code
export const forgotPasswordVerifyAction = (resetKey) => async (dispatch) => {
  console.log("handle forgot password verify");
  dispatch(loading(true));

  try {
    console.log("RESET KEY: ", resetKey);
    const response = await forgotPasswordVerify(resetKey);
    // redirect to the password reset screen
    dispatch(forgotPasswordVerifySuccess(resetKey));
    RootNavigation.navigate("ResetPassword");
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
  }
  dispatch(loading(false));
};

// the API will reset the user's password
export const resetPasswordAction =
  (newPassword, resetKey) => async (dispatch) => {
    console.log("handle reset password");
    dispatch(loading(true));

    try {
      const response = await resetPassword(newPassword, resetKey);
      console.log(response);
      // once we reset the password, we'll have a valid JWT token
      const { authorization } = response.headers;
      console.log(authorization);
      // store token to local storage
      await storeToken(authorization);
      // store the token in redux state
      await dispatch(setAccessToken(authorization));
      // get the logged in user's account info
      dispatch(getAccountAction());
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));

      if (error.response) {
        dispatch(failure(error.response?.data?.message));
      } else {
        dispatch(failure(error));
      }
    }
    dispatch(loading(false));
  };

export const changeProfilePictureAction = (photo) => async (dispatch) => {
  console.log("handle change profile picture: ", photo);
  dispatch(loading(true));

  try {
    console.log("handle change profile picture");
    const response = await changeProfilePicture(photo);

    console.log("the user response data was: ", response.data);
    dispatch(changeProfilePictureSuccess(response.data));
  } catch (error) {
    toastFailure(error);

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
  }
  dispatch(loading(false));
};

export const createInvestmentBusinessAccountPlaidFundingSourceThunk =
  (orgId, plaidToken, accountName, accountId, accessToken) =>
  async (dispatch) => {
    console.log(`handle create plaid funding source`);
    dispatch(loading(true));

    try {
      const response = await createInvestmentBusinessAccountPlaidFundingSource(
        orgId,
        plaidToken,
        accountName,
        accountId,
        accessToken
      );

      dispatch(createInvestmentBusinessAccountSuccess(response.data));
      // dispatch(getAccountAction());
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const createInvestmentPersonalAccountPlaidFundingSourceThunk =
  (orgId, plaidToken, accountName, accountId, accessToken) =>
  async (dispatch) => {
    console.log(`handle create plaid funding source`);
    dispatch(loading(true));

    try {
      const response = await createInvestmentPersonalAccountPlaidFundingSource(
        orgId,
        plaidToken,
        accountName,
        accountId,
        accessToken
      );

      dispatch(createInvestmentPersonalAccountSuccess(response.data));
      // dispatch(getAccountAction());
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const removeInvestmentBusinessAccountThunk = () => async (dispatch) => {
  console.log(`handle unlink funding source`);
  dispatch(loading(true));

  try {
    const response = await removeInvestmentBusinessAccount();

    dispatch(removeInvestmentBusinessAccountSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const removeInvestmentPersonalAccountThunk = () => async (dispatch) => {
  console.log(`handle unlink funding source`);
  dispatch(loading(true));

  try {
    const response = await removeInvestmentPersonalAccount();

    dispatch(removeInvestmentPersonalAccountSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const unlinkInvestmentPersonalAccountFundingSourceThunk =
  (orgId, fundingSourceId) => async (dispatch) => {
    console.log(`handle unlink funding source`);
    dispatch(loading(true));

    try {
      const response = await unlinkInvestmentPersonalAccountFundingSource(
        fundingSourceId
      );

      dispatch(
        unlinkInvestmentPersonalAccountFundingSourceSuccess(response.data)
      );
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const updateFundsFlowConfigThunk =
  (fundsFlowDTO) => async (dispatch) => {
    console.log(`handle update funds flow`);
    dispatch(loading(true));

    try {
      const response = await updateFundsFlow(fundsFlowDTO);

      dispatch(updateAccountSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error?.response?.data?.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const unlinkInvestmentBusinessAccountFundingSourceThunk =
  (orgId, fundingSourceId) => async (dispatch) => {
    console.log(`handle unlink funding source`);
    dispatch(loading(true));

    try {
      const response = await unlinkInvestmentBusinessAccountFundingSource(
        fundingSourceId
      );

      dispatch(
        unlinkInvestmentBusinessAccountFundingSourceSuccess(response.data)
      );
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const createInvestorBusinessAccountThunk =
  (businessAccount) => async (dispatch) => {
    console.log("create investor business account: ", businessAccount);

    dispatch(loading(true));

    try {
      const response = await createInvestmentBusinessAccount(businessAccount);

      console.log("the businessAccount: ", response.data);

      dispatch(createInvestmentBusinessAccountSuccess(response.data));
      toastSuccess("Success!", "Your business investment account was created");
    } catch (error) {
      console.log("inside of create businessAccount error");
      toastFailure(error);
      dispatch(loading(false));
      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const createInvestorPersonalAccountThunk =
  (personalAccount) => async (dispatch) => {
    console.log("create investor personal account: ", personalAccount);

    dispatch(loading(true));

    try {
      const response = await createInvestmentPersonalAccount(personalAccount);

      console.log("the businessAccount: ", response.data);

      dispatch(createInvestmentPersonalAccountSuccess(response.data));
      // dispatch(getAccountAction());
      toastSuccess("Success!", "Your personal investment account was created");
    } catch (error) {
      console.log("inside of create personal investment error");
      toastFailure(error);
      dispatch(loading(false));
      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const getInvestmentBusinessAccountThunk = () => async (dispatch) => {
  console.log("get investor business account");
  dispatch(loading(true));

  try {
    const response = await getInvestmentBusinessAccount();

    console.log("the lender: ", response.data);

    dispatch(getInvestmentBusinessAccountSuccess(response.data));
    // dispatch(getAccountAction());
  } catch (error) {
    console.log("inside of get investment business account error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const getInvestmentPersonalAccountThunk = () => async (dispatch) => {
  console.log("get investor business account");
  dispatch(loading(true));

  try {
    const response = await getInvestmentPersonalAccount();

    console.log("the lender: ", response.data);

    dispatch(getInvestmentPersonalAccountSuccess(response.data));
  } catch (error) {
    console.log("inside of get investment business account error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};
