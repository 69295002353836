import React from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { createTheme, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ThemeProvider } from "@emotion/react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { setSelectedLender } from "../../models/lender/lenderReducer";
import { getLenderThunk } from "../../models/lender/lenderThunks";

const LendersTable = (props) => {
  const { data, setAddLender } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log("got lender data as: ", data);

  const normalized = data.map((curr) => {
    return {
      ...curr,
      address: `${curr?.city}, ${curr?.state} ${curr?.postalCode}`,
      owner: `${curr.owner.firstName} ${curr.owner.lastName}`,
    };
  });

  const options = {
    filterType: "checkbox",
    download: false, // Set to false to remove the download option
    print: false, // Set to false to remove the print option
    viewColumns: false, // Disable view columns option
    filter: false, // Disable filter table option

    onRowClick: (rowData, rowMeta) => {
      console.log("row data: ", rowData);
      console.log("row meta: ", rowMeta);

      dispatch(getLenderThunk(data[rowMeta.rowIndex].id));

      navigate(`lender-management/${rowData[0]}}`, {
        lenderName: rowData[0],
      });
    },

    customToolbar: () => {
      return (
        <IconButton
          onClick={() => {
            setAddLender(true);
          }}
          style={{ order: -1 }}
        >
          <AddIcon />
        </IconButton>
      );
    },
    selectableRows: false,
  };

  const columns = [
    {
      name: "businessName",
      label: "Lender Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "owner",
      label: "Lender Owner",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <MUIDataTable
      style={{ cursor: "pointer" }}
      title={"Lenders"}
      data={!_.isNil(normalized) && normalized.length != 0 ? normalized : []}
      columns={columns}
      options={options}
    />
  );
};

export default LendersTable;
