import React, { useState } from "react";

import {
  AddressWithZip,
  CreatePassword,
  EnterPassword,
  FirstName,
  LastName,
  PhoneNumber,
} from "../../components/Form/Form";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { changePasswordAction } from "../../models/account/accountActions";

export const ChangePasswordStep = (props) => {
  const {
    control,
    currentPassword,
    setCurrentPassword,
    setNewPassword,
    newPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    handleSubmit,
  } = props;

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui).loading;

  const handleChangePassword = () => {
    dispatch(
      changePasswordAction({
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
    );
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <p>Current Password</p>
      <EnterPassword
        control={control}
        password={currentPassword}
        setPassword={(password) => {
          setCurrentPassword(password);
        }}
      />
      <p>New Password</p>
      <CreatePassword
        control={control}
        password={newPassword}
        confirmPassword={confirmNewPassword}
        setPassword={(password) => {
          setNewPassword(password);
        }}
        setConfirmPassword={(password) => {
          setConfirmNewPassword(password);
        }}
      />
      <LoadingButton
        style={{ marginTop: "1em" }}
        onClick={handleSubmit(handleChangePassword)}
        fullWidth
        loading={loading}
        loadingPosition="start"
        variant="outlined"
      >
        Update Password
      </LoadingButton>
    </div>
  );
};
