import React, { Component } from "react";

import { AddressWithZip, BusinessName, Email, PhoneNumber } from "../Form/Form";
import _ from "lodash";

const OrganizationDetails = (props) => {
  const { organizationInfo, setOrganizationInfo, control, setValue } = props;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <BusinessName
        control={control}
        businessName={organizationInfo?.name}
        setBusinessName={(businessName) => {
          setOrganizationInfo({
            ...organizationInfo,
            name: businessName,
          });
        }}
      />

      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <Email
          control={control}
          email={organizationInfo?.email}
          setEmail={(email) => {
            setOrganizationInfo({ ...organizationInfo, email: email });
          }}
        />

        <PhoneNumber
          name="business-phone"
          control={control}
          phone={organizationInfo?.phoneNumber}
          setPhone={(phone) => {
            setOrganizationInfo({ ...organizationInfo, phoneNumber: phone });
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "1em",
          width: "100%",
        }}
      >
        <AddressWithZip
          control={control}
          setValue={setValue}
          address={organizationInfo.address}
          setAddress={({ city, state, zip, addressLine1, place }) => {
            console.log("setting address: ");
            console.log("city: ", city);
            console.log("state: ", state);
            console.log("zip: ", zip);
            console.log("place: ", place);

            setOrganizationInfo({
              ...organizationInfo,
              address: {
                ...organizationInfo.address,
                addressLine1: addressLine1,
                city: city,
                state: state,
                zip: zip,
                place: place,
                placeId: place["place_id"],
                description: place.description,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default OrganizationDetails;
