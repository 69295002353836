import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";

import {
  createInvestmentBusinessAccountPlaidFundingSourceThunk,
  createInvestmentPersonalAccountPlaidFundingSourceThunk,
  createInvestorBusinessAccountThunk,
  createInvestorPersonalAccountThunk,
  getInvestmentBusinessAccountThunk,
  getInvestmentPersonalAccountThunk,
  removeInvestmentBusinessAccountThunk,
  removeInvestmentPersonalAccountThunk,
  updateAccountSetupAction,
} from "../../models/account/accountActions";
import _ from "lodash";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import HorizontalStepper from "../../components/HorizontalStepper/HorizontalStepper";
import { makeStyles } from "@mui/styles";
import CreateBusiness from "../../components/CreateBusiness/CreateBusiness";

import LinkAccountScreen from "../LinkAccountStep/LinkAccountScreen";
import { CircularProgress, Typography } from "@mui/material";
import { resetCreateUpdateLenderSuccess } from "../../models/lender/lenderReducer";
import PersonalVerifiedCustomer from "../../components/PersonalVerifiedCustomer/PersonalVerifiedCustomer";
import { resetCreateUpdateInvestmentBusinessAccountSuccess } from "../../models/account/accountReducer";

import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import { ChangePasswordStep } from "../ChangePasswordStep/ChangePasswordStep";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      margin: "0px",
    },
  },
  [theme.breakpoints.up("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "500px",
      height: "400px",
      marginTop: "0em",
    },
  },

  container: {
    display: "flex",
    justifyContent: "center",
    height: "375px",
    width: "100%",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    height: "100%",
  },
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "300px",
    height: "150px",
    border: "2px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "10px",
    padding: "10px",
    backgroundColor: "#fff",
    transition: "border-color 0.3s ease",
  },
  selected: {
    borderColor: theme.palette.primary.main,
  },
  title: {
    fontWeight: "bold",
    fontSize: "0.9rem",
    marginRight: "5px",
  },
  icon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: theme.palette.primary.main,
    fontSize: "2rem",
  },
}));

const CreateInvestmentAccountCard = ({
  title,
  icon: Icon,
  handleCardSelected,
}) => {
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState(false);

  return (
    <div
      className={`${classes.card} ${isSelected ? classes.selected : ""}`}
      onClick={handleCardSelected}
    >
      {isSelected ? (
        <Icon className={classes.icon} />
      ) : (
        <Icon className={classes.icon} />
      )}
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

const InvestorAccountSetupScreen = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const accountSlice = useSelector((state) => state.account);
  const account = accountSlice.account;
  const accountSetup = accountSlice.accountSetup;
  const [accountType, setAccountType] = useState("");
  const investorBusinessAccount = account.investorBusinessAccount;
  const investorPersonalAccount = account.investorPersonalAccount;

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const dwolla = useSelector((state) => state.dwolla);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const freshInvestmentPersonalAccount = {
    firstName: account.firstName,
    lastName: account.lastName,
    email: "",
    ssn: "",
    dateOfBirth: null,
    type: "personal",

    address: {
      address1: "",
      address2: "",
      zip: "",
      city: "",
      stateCode: "",
    },
  };

  const freshInvestmentBusinessAccount = {
    businessName: "",
    businessType: "",
    businessClassification: "",
    industryClassification: "",
    dateOfBirth: null,

    fullAddress: {
      address1: "",
      address2: "",
      zip: "",
      city: "",
      stateCode: "",
    },
    controller: {
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      ssn: "",
      email: "",
      title: "",
      address: {
        address1: "",
        address2: "",
        zip: "",
        city: "",
        stateCode: "",
      },
    },
  };

  const [businessInfo, setBusinessInfo] = useState(
    freshInvestmentBusinessAccount
  );

  const [personalInfo, setPersonalInfo] = useState(
    freshInvestmentPersonalAccount
  );

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
    setValue,
    reset,
  } = useForm();

  const dispatch = useDispatch();

  const canProceedToLinkAccount = () => {
    if (
      _.isNil(investorBusinessAccount) ||
      _.isNil(investorBusinessAccount.id)
    ) {
      console.log("can proceed 1");
      return true;
    }

    if (
      businessInfo.hasBeneficialOwners === "true" ||
      investorBusinessAccount?.beneficialOwners?.length > 0 ||
      dwolla?.owners?.length > 0
    ) {
      // if the user indicated that the business has beneficial owners, but did not save any,
      // do not proceed
      if (
        (_.isNil(dwolla.owners) || dwolla?.owners?.length === 0) &&
        investorBusinessAccount.beneficialOwners.length === 0
      ) {
        console.log("can proceed 2");

        return true;
      }
      console.log("can proceed 3");

      // if there are beneficial owners listed, but the user did not confirm that the info is complete/correct, do not proceed
      return !businessInfo.certifiedCompleteAndCorrect;
    } else {
      console.log("can proceed 4");

      // if there were no beneficial owners, the user has to confirm that there are none + the info is complete/correct
      return !(
        businessInfo.certifiedNoBeneficialOwners &&
        businessInfo.certifiedCompleteAndCorrect
      );
    }
  };

  useEffect(() => {
    if (!_.isNil(investorBusinessAccount?.id)) {
      const mergedInfo = {
        ...investorBusinessAccount,
        dateOfBirth: null,
        businessClassification: investorBusinessAccount.businessClassification,
        industryClassification: investorBusinessAccount.industryClassification,
        fullAddress: {
          address1: investorBusinessAccount.address1,
          address2: investorBusinessAccount.address2,
          zip: investorBusinessAccount.postalCode,
          city: investorBusinessAccount.city,
          state: investorBusinessAccount.state,
        },
        controller: {
          ...investorBusinessAccount.controller,
          address: {
            ...investorBusinessAccount?.controller?.address,
            zip: investorBusinessAccount?.controller?.address?.postalCode,
            state:
              investorBusinessAccount?.controller?.address?.stateProvinceRegion,
          },
        },
      };

      console.log("setting the business info: ", mergedInfo);
      setBusinessInfo(mergedInfo);
      setValue("firstName", investorBusinessAccount.firstName);
      setValue("lastName", investorBusinessAccount.lastName);
      setValue("businessName", investorBusinessAccount.businessName);
      setValue("businessType", investorBusinessAccount.businessType);
      setValue("address1", investorBusinessAccount.address1);
      setValue("address2", investorBusinessAccount.address2);
      setValue("city", investorBusinessAccount.city);
      setValue("zip", investorBusinessAccount.postalCode);

      setValue("birthDate", investorBusinessAccount.dateOfBirth);
    }
  }, [investorBusinessAccount?.id]);

  useEffect(() => {
    if (!_.isNil(investorPersonalAccount?.id)) {
      const mergedInfo = {
        ...investorPersonalAccount,
        dateOfBirth: null,
        type: "personal",

        fullAddress: {
          address1: investorPersonalAccount.address1,
          address2: investorPersonalAccount.address2,
          zip: investorPersonalAccount.postalCode,
          city: investorPersonalAccount.city,
          state: investorPersonalAccount.state,
        },
      };

      console.log("setting the business info: ", mergedInfo);
      setPersonalInfo(mergedInfo);

      setValue("firstName", investorPersonalAccount.firstName);
      setValue("lastName", investorPersonalAccount.lastName);

      setValue("address1", investorPersonalAccount.address1);
      setValue("address2", investorPersonalAccount.address2);
      setValue("city", investorPersonalAccount.city);
      setValue("zip", investorPersonalAccount.postalCode);

      setValue("birthDate", investorPersonalAccount.dateOfBirth);
    }
  }, [investorPersonalAccount?.id]);

  React.useEffect(() => {
    if (account && accountSetup?.activeStep === "COMPLETED_SETUP") {
      console.log("the account is setup");

      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [accountSetup?.activeStep]);

  const getActiveStep = () => {
    console.log("get active step: ", accountSetup.activeStep);
    switch (accountSetup.activeStep) {
      case "CHANGE_PASSWORD":
        return 0;
      case "ACCOUNT_TYPE_SELECTION":
        return 1;

      case "BUSINESS_DETAILS":
      case "PERSONAL_DETAILS":
      case "CONTROLLER_DETAILS":
      case "ADD_OWNERS_YES_NO":
      case "ADD_OWNERS_YES":
      case "ADD_OWNERS_NO":
        return 2;
      case "LINK_BANK_ACCOUNT":
        return 3;
      case "ACCEPT_TERMS_CONDITIONS":
      case "COMPLETED_SETUP":
        return 4;
      default:
        return 0;
    }
  };

  const changePasswordStep = {
    title: "Password update required",
    subTitle: "Please update your password",

    handleSubmit: async () => {
      dispatch(updateAccountSetupAction("ACCOUNT_TYPE_SELECTION"));
    },
    handleBack: () => {
      console.log("no op");
    },

    isNextDisabled: account?.account?.hasTempPassword ? true : false,

    panel: (
      <div className={classes.panel}>
        <ChangePasswordStep
          handleSubmit={handleSubmit}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          currentPassword={currentPassword}
          setCurrentPassword={setCurrentPassword}
          confirmNewPassword={confirmNewPassword}
          setConfirmNewPassword={setConfirmNewPassword}
          control={control}
        />
      </div>
    ),
  };

  const chooseAccountTypeStep = {
    title: "Account Type Selection",
    subTitle: "",
    handleSubmit: async () => {
      if (accountType === "business") {
        dispatch(updateAccountSetupAction("BUSINESS_DETAILS"));
      } else if (accountType === "personal") {
        dispatch(updateAccountSetupAction("PERSONAL_DETAILS"));
      }
    },
    handleBack: () => {
      setAccountType(null);
      dispatch(updateAccountSetupAction("CHANGE_PASSWORD"));
    },

    isNextDisabled: accountType != "personal" && accountType != "business",

    panel: (
      <div className={classes.panel}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>
            You can create your investment account as a business, or personal
            account
          </h3>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <CreateInvestmentAccountCard
              title="Create Business Investment Account"
              icon={BusinessIcon}
              handleCardSelected={() => {
                setAccountType("business");
                dispatch(updateAccountSetupAction("BUSINESS_DETAILS"));
              }}
            />
            <CreateInvestmentAccountCard
              title="Create Personal Investment Account"
              icon={PersonIcon}
              handleCardSelected={() => {
                setAccountType("personal");
                dispatch(updateAccountSetupAction("PERSONAL_DETAILS"));
              }}
            />
          </div>
        </div>
      </div>
    ),
  };

  const businessDetailStep = {
    title: "Business Investment Account",
    handleSubmit: async () => {
      dispatch(updateAccountSetupAction("LINK_BANK_ACCOUNT"));
    },
    handleBack: () => {
      dispatch(updateAccountSetupAction("ACCOUNT_TYPE_SELECTION"));
    },
    // isNextDisabled: canProceedToLinkAccount(),
    isNextDisabled: dwolla?.certificationStatus?.status != "certified",

    panel: (
      <div className={classes.panel}>
        <div className={classes.panel}>
          <CreateBusiness
            org={investorBusinessAccount}
            resetCreateUpdateOrgSuccess={
              resetCreateUpdateInvestmentBusinessAccountSuccess
            }
            defaultOrgId={investorBusinessAccount?.id}
            loading={accountSlice.loading}
            createOrgSuccess={accountSlice.createInvestorBusinessAccountSuccess}
            updateOrgSuccess={accountSlice.updateInvestorBusinessAccountSuccess}
            createOrgThunk={createInvestorBusinessAccountThunk}
            getOrgThunk={getInvestmentBusinessAccountThunk}
            trigger={trigger}
            reset={reset}
            setValue={setValue}
            isDirty={isDirty}
            isValid={isValid}
            control={control}
            handleSubmit={handleSubmit}
            businessInfo={businessInfo}
            setBusinessInfo={setBusinessInfo}
            getActiveStep={() => {
              if (accountSetup.activeStep === "BUSINESS_DETAILS") {
                return 0;
              } else if (accountSetup.activeStep === "CONTROLLER_DETAILS") {
                return 1;
              } else if (
                accountSetup.activeStep === "ADD_OWNERS_YES_NO" ||
                accountSetup.activeStep === "ADD_OWNERS_YES" ||
                accountSetup.activeStep === "ADD_OWNERS_NO"
              ) {
                return 2;
              }
            }}
          />
        </div>
      </div>
    ),
  };

  const handleCreatePersonalVerifiedCustomer = async () => {
    const { address1, address2, zip, city, state } = personalInfo.address;

    const requestDTO = {
      ...personalInfo,
      address1: address1,
      address2: address2,
      postalCode: zip,
      city: city,
      state: state.code,
    };

    await dispatch(createInvestorPersonalAccountThunk(requestDTO));
    dispatch(updateAccountSetupAction("LINK_BANK_ACCOUNT"));
  };

  const personalDetailStep = {
    title: "Personal Investment Account",
    handleSubmit: async () => {
      dispatch(updateAccountSetupAction("LINK_BANK_ACCOUNT"));
    },
    handleBack: () => {
      dispatch(updateAccountSetupAction("ACCOUNT_TYPE_SELECTION"));
    },
    isNextDisabled: _.isNil(investorPersonalAccount?.id),

    panel: (
      <div className={classes.panel}>
        <PersonalVerifiedCustomer
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          handleCreatePersonalVerifiedCustomer={
            handleCreatePersonalVerifiedCustomer
          }
          createCustomerThunk={createInvestorPersonalAccountThunk}
        />
      </div>
    ),
  };

  const linkInvestmentBusinessAccountStep = {
    title: "Link your Investment Business Bank Account",
    handleSubmit: () => {
      dispatch(updateAccountSetupAction("ACCEPT_TERMS_CONDITIONS"));
    },
    handleBack: () => {
      if (investorBusinessAccount.businessType === "Sole Proprietorship") {
        dispatch(updateAccountSetupAction("BUSINESS_DETAILS"));
      } else if (investorBusinessAccount?.beneficialOwners?.length > 0) {
        dispatch(updateAccountSetupAction("ADD_OWNERS_YES"));
      } else if (investorBusinessAccount?.beneficialOwners?.length == 0) {
        dispatch(updateAccountSetupAction("ADD_OWNERS_NO"));
      } else {
        dispatch(updateAccountSetupAction("ADD_OWNERS_YES_NO"));
      }
    },

    isNextDisabled: !investorBusinessAccount?.fundingSources?.length > 0,

    panel: (
      <div className={classes.panel}>
        <LinkAccountScreen
          loading={accountSlice?.loading}
          org={investorBusinessAccount}
          defaultOrgId={investorBusinessAccount?.id}
          getOrgThunk={getInvestmentBusinessAccountThunk}
          createPlaidFundingSourceThunk={
            createInvestmentBusinessAccountPlaidFundingSourceThunk
          }
          unlinkFundingSourceThunk={removeInvestmentBusinessAccountThunk}
        />
      </div>
    ),
  };

  const linkInvestmentPersonalAccountStep = {
    title: "Link your Personal Bank Account",
    handleSubmit: () => {
      dispatch(updateAccountSetupAction("ACCEPT_TERMS_CONDITIONS"));
    },
    handleBack: () => {
      dispatch(updateAccountSetupAction("PERSONAL_DETAILS"));
    },

    isNextDisabled: !investorPersonalAccount?.fundingSources?.length > 0,

    panel: (
      <div className={classes.panel}>
        <LinkAccountScreen
          loading={accountSlice?.loading}
          org={investorPersonalAccount}
          defaultOrgId={investorPersonalAccount?.id}
          getOrgThunk={getInvestmentPersonalAccountThunk}
          createPlaidFundingSourceThunk={
            createInvestmentPersonalAccountPlaidFundingSourceThunk
          }
          unlinkFundingSourceThunk={removeInvestmentPersonalAccountThunk}
        />
      </div>
    ),
  };

  const termsAndConditionsStep = {
    title: "Terms & Conditions",
    subTitle: "You must read and accept the Terms & Conditions to continue.",
    handleSubmit: () => {
      dispatch(updateAccountSetupAction("COMPLETED_SETUP"));
    },
    handleBack: () => {
      dispatch(updateAccountSetupAction("LINK_BANK_ACCOUNT"));
    },
    isNextDisabled: !acceptedTerms,

    panel: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "600px",
          marginTop: "0em",
        }}
      >
        {accountSetup.activeStep === "ACCEPT_TERMS_CONDITIONS" ? (
          <TermsAndConditions
            acceptedTerms={acceptedTerms}
            setAcceptedTerms={setAcceptedTerms}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              rowGap: "2em",
            }}
          >
            <h2>Hang tight while we get your account all set up</h2>
            <CircularProgress />
          </div>
        )}
      </div>
    ),
  };

  const personalAccountSetupSteps = [
    personalDetailStep,
    linkInvestmentPersonalAccountStep,
    termsAndConditionsStep,
  ];

  const businessAccountSetupSteps = [
    businessDetailStep,
    linkInvestmentBusinessAccountStep,
    termsAndConditionsStep,
  ];

  const commonSteps = [changePasswordStep, chooseAccountTypeStep];

  const steps =
    accountType === "personal"
      ? [...commonSteps, ...personalAccountSetupSteps]
      : [...commonSteps, ...businessAccountSetupSteps];

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%", height: "90%" }}>
        <HorizontalStepper
          hideSteps={
            accountType === "personal" || accountType === "business"
              ? false
              : true
          }
          activeStep={getActiveStep()}
          stepperTitle={<h1 style={{ margin: "0px" }}>Welcome</h1>}
          stepperSubtitle={<h3>Let's get your account all set up</h3>}
          stepperCompletedMessage={"Hang tight while we create your account"}
          acceptedTerms={acceptedTerms}
          steps={steps}
          control={control}
          isDirty={isDirty}
          isValid={isValid}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      </div>
    </div>
  );
};

export default InvestorAccountSetupScreen;
