import React, { Component } from "react";

import {
  Email,
  FirstName,
  LastName,
  PhoneNumber,
  RoleSelection,
} from "../Form/Form";
import _ from "lodash";

const UserDetails = (props) => {
  const { userInfo, setUserInfo, control } = props;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <FirstName
          control={control}
          firstName={userInfo?.firstName}
          setFirstName={(firstName) => {
            setUserInfo({ ...userInfo, firstName: firstName });
          }}
        />

        <LastName
          control={control}
          lastName={userInfo?.lastName}
          setLastName={(lastName) => {
            setUserInfo({ ...userInfo, lastName: lastName });
          }}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <Email
          control={control}
          email={userInfo?.email}
          setEmail={(email) => {
            setUserInfo({ ...userInfo, email: email });
          }}
        />
      </div>

      <div style={{ margin: "1em" }}>
        <RoleSelection
          role={userInfo?.authorities[0]}
          setRole={(role) => {
            setUserInfo({ ...userInfo, authorities: [role] });
          }}
        />
      </div>
    </div>
  );
};

export default UserDetails;
