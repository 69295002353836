import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DealsTable from "../../components/DealsTable/DealsTable";
import MerchantDetails from "../../components/MerchantDetails/MerchantDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
} from "@mui/material";
import DealModal from "../../components/DealModal/DealModal";
import _ from "lodash";
import {
  createPlaidFundingSourceThunk,
  getMerchantKPIsThunk,
  getMerchantThunk,
  unlinkFundingSourceThunk,
} from "../../models/merchant/merchantThunks";
import { getElapsedTimeString } from "../../shared/commonUtils";
import LinkAccountScreen from "../LinkAccountStep/LinkAccountScreen";
import MerchantFundsFlowConfig from "../../components/MerchantFundsFlowConfig/MerchantFundsFlowConfig";
import BasicTabs from "../../components/BasicTabs/BasicTabs";
import FinancialTransactionsTable from "../../components/FinancialTransactionsTable/FinancialTransactionsTable";
import { getCustomerTransactionsThunk } from "../../models/dwolla/dwollaActions";

const MerchantHomeScreen = () => {
  const navigate = useNavigate();
  const lender = useSelector((state) => state.lenders);
  const account = useSelector((state) => state.account);
  const deals = useSelector((state) => state.deals);
  const merchantSlice = useSelector((state) => state.merchants);
  const merchant = merchantSlice?.merchant;
  const kpis = merchantSlice.kpis;
  const dwolla = useSelector((state) => state.dwolla);

  const elapsedTimeInBusiness = getElapsedTimeString(merchant?.foundedDate);

  const [addDeal, setAddDeal] = useState(false);
  //   const merchant = useSelector((state) => state.merchant);
  const { control, handleSubmit, errors, setValue } = useForm();
  const [merchantInfo, setMerchantInfo] = useState({
    address: {
      city: "",
      state: "",
      zip: "",
      place: "",
    },
  });

  console.log("the merchant is: ", merchant);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !_.isNil(merchant) &&
      !_.isNil(merchant.id) &&
      !_.isNil(merchant.dwollaCustomerId)
    ) {
      // dispatch(getMerchantDealsThunk(merchant.id));

      dispatch(getMerchantKPIsThunk(merchant.id));
      dispatch(getCustomerTransactionsThunk(merchant.dwollaCustomerId));
    } else {
      dispatch(getMerchantThunk(account?.account?.defaultMerchantId));
    }
  }, [merchant]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "3em", marginBottom: "3em" }}>
          <h1 style={{ margin: "0px" }}>{merchant?.businessName}</h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
            rowGap: "2em",
            columnGap: "2em",
            flexWrap: "wrap",
            marginBottom: "2em",
          }}
        >
          {[
            {
              title: `${
                !_.isNil(kpis) && !_.isNil(kpis.totalFundedAmountPaidInFull)
                  ? `$${kpis.totalFundedAmountPaidInFull.toLocaleString()}`
                  : "--"
              }`,
              subTitle: "Total Funded Amount (Completed)",
            },
            {
              title: `${
                !_.isNil(kpis) && !_.isNil(kpis.totalFundedAmountInProgress)
                  ? `$${kpis.totalFundedAmountInProgress.toLocaleString()}`
                  : "--"
              }`,
              subTitle: "Total Funded Amount (Open)",
            },
            {
              title: `${
                !_.isNil(kpis) && !_.isNil(kpis.averageFactorRate)
                  ? `${kpis.averageFactorRate}%`
                  : "--"
              }`,
              subTitle: "Average Factor Rate (All time)",
            },
            {
              title: `${
                !_.isNil(kpis) && !_.isNil(kpis.totalCompletedDeals)
                  ? kpis.totalCompletedDeals
                  : "--"
              }`,
              subTitle: "Completed Deals",
            },

            {
              title: `${
                !_.isNil(kpis) && !_.isNil(kpis.totalOpenDeals)
                  ? kpis.totalOpenDeals
                  : "--"
              }`,
              subTitle: "Open Deals",
            },
            {
              title: `${
                !_.isNil(kpis) && !_.isNil(kpis.totalInvestors)
                  ? kpis.totalInvestors
                  : "--"
              }`,
              subTitle: "Total Investors",
            },
          ].map((card) => {
            return (
              <Paper
                style={{
                  width: "220px",
                  height: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                elevation={1}
              >
                <div>
                  <h1 style={{ margin: "0px" }}>{card.title}</h1>
                  <h6 style={{ margin: "0px" }}>{card.subTitle}</h6>
                </div>
              </Paper>
            );
          })}
        </div>

        <div style={{ marginTop: "2em" }}>
          <BasicTabs
            data={[
              {
                label: "Linked Accounts",
                panel: (
                  <LinkAccountScreen
                    justifyButton="flex-start"
                    loading={merchantSlice?.loading}
                    org={merchant}
                    defaultOrgId={account?.account?.defaultMerchantId}
                    getOrgThunk={getMerchantThunk}
                    createPlaidFundingSourceThunk={
                      createPlaidFundingSourceThunk
                    }
                    unlinkFundingSourceThunk={unlinkFundingSourceThunk}
                  />
                ),
              },
              {
                label: "Funds Flow Configuration",
                panel: <MerchantFundsFlowConfig />,
              },

              {
                label: "Transactions",
                panel: (
                  <FinancialTransactionsTable
                    data={
                      dwolla?.transactions?.length > 0
                        ? dwolla?.transactions
                        : []
                    }
                  />
                ),
              },
              {
                label: "My Deals",
                panel: (
                  <DealsTable
                    setAddDeal={setAddDeal}
                    data={deals?.deals ? deals.deals : []}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default MerchantHomeScreen;
