import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inviteMerchantThunk } from "../../models/merchant/merchantThunks";
import { BusinessName, Email, FirstName, LastName } from "../Form/Form";
import moment from "moment";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function InviteMerchantModal(props) {
  const { open, setOpen } = props;
  const lenderSlice = useSelector((state) => state.lenders);
  const lender = lenderSlice.lender;

  const [merchantInvite, setMerchantInvite] = useState({
    lenderId: lender?.lender?.id,
    businessName: "",
    recipientEmail: "",
    recipientFirstName: "",
    recipientLastName: "",
  });

  const { control, handleSubmit, reset, setValue } = useForm();

  const dispatch = useDispatch();

  const handleInviteMerchant = () => {
    console.log("merchant invite: ", merchantInvite);
    dispatch(inviteMerchantThunk(merchantInvite));
  };

  useEffect(() => {
    if (lender?.id) {
      setMerchantInvite({
        ...merchantInvite,
        lenderId: lender.id,
      });
    }
  }, [lender?.id]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setMerchantInvite({
            lenderId: lender?.id,
            businessName: "",
            recipientEmail: "",
            recipientFirstName: "",
            recipientLastName: "",
          });
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <h3>Invite Merchant</h3>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  columnGap: "1em",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <BusinessName
                    name="merchant-business-name"
                    label="Business Name"
                    control={control}
                    businessName={merchantInvite?.businessName}
                    setBusinessName={(businessName) => {
                      setMerchantInvite({
                        ...merchantInvite,
                        businessName: businessName,
                      });
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "1em",
                }}
              >
                <FirstName
                  control={control}
                  firstName={merchantInvite?.recipientFirstName}
                  setFirstName={(firstName) => {
                    setMerchantInvite({
                      ...merchantInvite,
                      recipientFirstName: firstName,
                    });
                  }}
                />

                <LastName
                  control={control}
                  lastName={merchantInvite?.lastName}
                  setLastName={(lastName) => {
                    setMerchantInvite({
                      ...merchantInvite,
                      recipientLastName: lastName,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "1em",
                }}
              >
                <Email
                  control={control}
                  email={merchantInvite?.email}
                  setEmail={(email) => {
                    setMerchantInvite({
                      ...merchantInvite,
                      recipientEmail: email,
                    });
                  }}
                />
              </div>
            </div>

            <Button onClick={handleSubmit(handleInviteMerchant)}>
              Send Invite
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
